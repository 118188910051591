/*
 * @Author: duyaoyao
 * @Date: 2022-06-28 11:18:20
 * @LastEditors: duyaoyao
 * @LastEditTime: 2022-06-28 11:35:21
 */
let routeData = [];
routeData = routeData.concat(require("org/routes/sysRouteData.js").default); //企业管理路由数据
routeData = routeData.concat(
  require("platform/routes/sysRouteData.js").default
); //平台路由数据
routeData = routeData.concat(require("antiFake/routes/routeData.js").default); //防伪路由数据
routeData = routeData.concat(require("trace/routes/routeData.js").default); //溯源路由数据
routeData = routeData.concat(require("code/routes/routeData.js").default); //码管理
// routeData = routeData.concat(require('logistics/routes/routeData.js').default); //物流
routeData = routeData.concat(require("base/routes/routeData.js").default); //基础数据
// routeData = routeData.concat(require('integralMarketing/routes/routeData.js').default); //营销
routeData = routeData.concat(require("gov/routes/routeData.js").default); //政府监管
routeData = routeData.concat(
  require("information/routes/routeData.js").default
); //信息化平台
routeData = routeData.concat(
  require("xianfengxian/routes/routeData.js").default
); //信息化平台
// routeData = routeData.concat(require('nurture/routes/routeData.js').default); //养殖
// routeData = routeData.concat(require('povertyAlleviation/routes/routeData.js').default); //扶贫
// routeData = routeData.concat(require('digitalVillage/routes/routeData.js').default); //数字乡村
// routeData = routeData.concat(require('concentricAlliance/routes/routeData.js').default); //统战部
// routeData = routeData.concat(require('enterpriseResumption/routes/routeData.js').default); //企业复工
// routeData = routeData.concat(require('minjian/routes/routeData.js').default); //民建
// routeData = routeData.concat(require('bigData/routes/routeData.js').default); //大数据
// routeData = routeData.concat(require('smartPackaging/routes/routeData.js').default); //智能包装
// routeData = routeData.concat(require('other/routeData.js').default); //其它
// routeData = routeData.concat(require('credit/routes/routeData.js').default); //农安信用
// routeData = routeData.concat(require('teaErp/routes/routeData.js').default); // 茶企erp

function routeDataFormat(routeData) {
  let data = [];
  // path重复的情况下 后面的覆盖前面的
  const obj = {};
  routeData.forEach((item) => {
    const { path } = item;
    if (!path) {
      console.log(item);
    }
    if (obj[path]) {
      console.error("router path覆盖", obj[path], item);
    }
    obj[path] = item;
  });
  data = Object.keys(obj).map((path) => {
    return obj[path];
  });
  //
  data = data.sort((routeA, routeB) => {
    const pathA = routeA.path;
    const pathB = routeB.path;
    return pathA.length <= pathB.length ? 1 : -1;
  });
  return data;
}

routeData = routeDataFormat(routeData);

export default routeData;
