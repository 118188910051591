export default {
  // 码转移相关接口
  // 获取列表
  GET_LIST: '/hydra-code-manager/code/transfer/page/list',
  // 添加
  ADD: '/hydra-code-manager/code/transfer/add',
  // 做码转移前校验数据
  CHECK_CODE_TRANSFER: '/hydra-code-manager/code/transfer/check/codeTransfer',
  //查询单码是否被转移过
  SELECT_CODE_TRANSFER: '/hydra-code-manager/code/transfer/select/code/transfer',
  // 获取平台组织列表
  GET_ORG_LIST: '/hydra-user/api/v1/org/list/platform',
  // 平台获取组织的生码批次
  GET_BATCH_LIST: '/hydra-code-manager/code/manager/code/apply/batch/page/list',
  // 设置防伪
  SAVE: '/hydra-code-manager/code/fake/setting/save',
  // 获取防伪信息
  GET_ANTIFAKE_SETTING: '/hydra-code-manager/code/fake/setting/get',
}
