import productionManage from './production';
import base from './base';
import sales from './sale';
import storage from './storage';
import statistics from './analysis';
import maintenanceManage from './maintenanceManage';
import agriculturalProductAcquisition from './agriculturalProductAcquisition';
import common from "./common";
import purchaseManagement from "./purchaseManage";
import material from "./material";

export default {
  // 农产品收购管理
  agriculturalProductAcquisition,
  // 公共（如电子秤）
  common,
  // 物料
  material,
  // 采购管理
  purchaseManagement,
  // 用工申请管理
  employmentApplyManage: {
    LIST: '/hydra-intelligent-planting/valid/api/v1/productionManageRecruit/list',
    EXPORT: '/hydra-intelligent-planting/valid/api/v1/productionManageRecruit/export',
    ADD: '/hydra-intelligent-planting/valid/api/v1/productionManageRecruit/save',
    // 执行招聘
    EXECUTE_RECRUIT: '/hydra-intelligent-planting/valid/api/v1/productionManageRecruit/execute-recruit',
    // 招聘完成
    COMPLETE_RECRUIT: '/hydra-intelligent-planting/valid/api/v1/productionManageRecruit/completed-recruit',
    DETAIL: '/hydra-intelligent-planting/valid/api/v1/productionManageRecruit/detail/',
    DELETE: '/hydra-intelligent-planting/valid/api/v1/productionManageRecruit/remove/',
    EDIT: '/hydra-intelligent-planting/valid/api/v1/productionManageRecruit/update',
    // 导出用工信息pdf
    EXPORT_PDF: '/hydra-intelligent-planting/valid/api/v1/productionManageRecruit/export-pdf/'
  },
  gis: {
    // gis地图搜索
    SEARCH_GIS: '/hydra-code-egis/api/v1/geoTools/poi-search-autonavi',
    // 经纬度匹配地址
    GET_ADDRESS_BY_COORDINATE: '/hydra-code-egis/api/v1/geoTools/get-address-by-coordinate',
    // 地址匹配经纬度
    GET_COORDINATE_BY_ADDRESS: '/hydra-code-egis/api/v1/geoTools/get-coordinate-by-address'
  },
  // 种植首页
  plantIndex: {
    // 根据组织id获取列表
    GET_BASE_BY_ORG_ID: '/hydra-base-data/api/v1/base/map/base-map/selectListByOrgId',
    // 获取地块种植进度
    GET_PLANTING_RATE: '/hydra-base-data/api/v1/base/map/base-map/getPlantingRateByBaseId',
    // 获取统计数据
    GET_STATISTIC: '/hydra-trace-planting/api/v1/massif-info-statistic/getPlantingInfoStatistic',
    //获取区域产量信息统计
    GET_STATISTICS:'/hydra-trace-planting/api/v1/massif-info-statistic/getProductionInfoStatistic',
    // 获取种植批次生产档案详情
    GET_PLANT_BATCH_INFO: '/hydra-trace-planting/api/v1/batch-Info/getPlantingBatchInfo',
    // 获取柱状图的数据
    GET_BAR_LIST: '/hydra-intelligent-planting/valid/api/v1/statistics/planting-yield/list',
    // 获取地块负责人列表
    GET_MASSIF_MANAGE: '/hydra-intelligent-planting/valid/api/v1/planting/work/remind/list-massif-principal',
    // 获取视频播放地址
    GET_VIDEO_URL: '/iotdevice/monitor/listDeviceByIds',
    // 获取设备参数
    GET_DEVICE_PARAMS: '/iotdevice/getListWithStatusByCodes',

    // iot获取数据屏监控列表
    //监控管理
    monitoringManage: {
      GET_LIST: "/iotdevice/monitor/listByOrgId",
      DELECT: "/iotdevice/monitor/delete",
      GET_STATE: "/iotdevice/monitor/getStatesByIds",
      //某个用户的监控设备列表
      GET_USER_MONITORLIST: "/iotdevice/monitor/listDevice",
      //初始化web插件
      INITIALIZE: "/iotdevice/monitor/init",
      //获取点击web插件实时视频参数
      GET_CLICKPARAMETER: "/iotdevice/monitor/preview",
      //获取点击web插件视频回放参数
      GET_BACKPARAMETER: "/iotdevice/monitor/playback",
      //mac获取监控地址的接口
      MAC_URL:'/iot/monitor/liveAddress'
    },
  },
  // 生产管理
  productionManage,
  // 基础数据
  base,
  // 销售
  sales,
  // 仓储管理
  storage,
  // 数据分析
  statistics,
  // 部门管理,部门列表
  department: {
    LIST: '/hydra-user/api/v1/org/dept/list',
  },
  // 产品档案
  productFile: {
    LIST: '/productmanage/product-record/list',
    DETAIL: '/productmanage/product-record/detail',
    // 销售客户列表
    SALE_OUT: '/productmanage/sale-out-record/list',
    EXPORT: '/productmanage/product-record/export',
    SORT_INTO_STORAGE_BY_BATCH_ID: '/productmanage/storagemanage/productSortInstorage/list-by-batch-id'
  },
  public: {
    // (下拉专用)获取规格信息列表
    SPECIFICATION_DROP_DOWN: '/hydra-base-data/api/v1/specification/select/list'
  },
  // 农旅管理
  operationManagement: {
    // 来访人员管理
    visitorManagement: {
      LIST: '/hydra-intelligent-planting/valid/api/v1/guGengVisitorManage/list',
      ADD: '/hydra-intelligent-planting/valid/api/v1/guGengVisitorManage/save',
      ADD_V2: '/hydra-intelligent-planting/valid/api/v1/guGengVisitorManage/v2/save',
      DETAIL: '/hydra-intelligent-planting/valid/api/v1/guGengVisitorManage/detail',
      DETAIL_V2: '/hydra-intelligent-planting/valid/api/v1/guGengVisitorManage/v2/detail',
      EDIT: '/hydra-intelligent-planting/valid/api/v1/guGengVisitorManage/update',
      EDIT_V2: '/hydra-intelligent-planting/valid/api/v1/guGengVisitorManage/v2/update',
      DELETE: '/hydra-intelligent-planting/valid/api/v1/guGengVisitorManage/deleteById',
      EXPORT: '/hydra-intelligent-planting/valid/api/v1/guGengVisitorManage/export'
    },
    // 房间类型/娱乐项目
    type: {
      LIST: '/hydra-intelligent-planting/valid/api/v1/guGengCommonType/list',
      ADD: '/hydra-intelligent-planting/valid/api/v1/guGengCommonType/save',
      EDIT: '/hydra-intelligent-planting/valid/api/v1/guGengCommonType/update',
      DELETE: '/hydra-intelligent-planting/valid/api/v1/guGengCommonType/deleteById',
      DROP_DOWN: '/hydra-intelligent-planting/valid/api/v1/guGengCommonType/dropDown'
    }
  },
  // 采茶工管理
  teaPicker: {
    // 采茶工管理
    teaPicker: {
      LIST: '/hydra-intelligent-planting/valid/api/v1/mobile/teaPicker/list',
      ADD: '/hydra-intelligent-planting/valid/api/v1/mobile/teaPicker',
      DETAIL: '/hydra-intelligent-planting/valid/api/v1/mobile/teaPicker/detail/',
      EDIT: '/hydra-intelligent-planting/valid/api/v1/mobile/teaPicker/update',
      DELETE: '/hydra-intelligent-planting/valid/api/v1/mobile/teaPicker/',
      EXPORT: '/hydra-intelligent-planting/valid/api/v1/mobile/teaPicker/export'
    },
    // 劳务公司管理
    labourCompany: {
      LIST: '/hydra-intelligent-planting/valid/api/v1/mobile/labourCompany/list',
      ADD: '/hydra-intelligent-planting/valid/api/v1/mobile/labourCompany',
      EDIT: '/hydra-intelligent-planting/valid/api/v1/mobile/labourCompany/update',
      DELETE: '/hydra-intelligent-planting/valid/api/v1/mobile/labourCompany/'
    },
    // 测量体温
    measureTemperature: {
      LIST: '/hydra-intelligent-planting/valid/api/v1/mobile/bodyTemperature/list',
      ADD: '/hydra-intelligent-planting/valid/api/v1/mobile/bodyTemperature',
      EDIT: '/hydra-intelligent-planting/valid/api/v1/mobile/bodyTemperature/update',
      DELETE: '/hydra-intelligent-planting/valid/api/v1/mobile/bodyTemperature/',
      EXPORT: '/hydra-intelligent-planting/valid/api/v1/mobile/bodyTemperature/export'
    },
  },
  // 用工管理
  employmentManagement: {
    // 竹筐管理
    basketManage: {
      LIST: '/hydra-intelligent-planting/valid/api/v1/bamboo-basket-manage/list',
      ADD: '/hydra-intelligent-planting/valid/api/v1/bamboo-basket-manage',
      ENABLE: '/hydra-intelligent-planting/valid/api/v1/bamboo-basket-manage/setUseStatus',
      EDIT: '/hydra-intelligent-planting/valid/api/v1/bamboo-basket-manage',
      EXPORT: '/hydra-intelligent-planting/valid/api/v1/bamboo-basket-manage/export'
    },
    // 竹筐关联
    basketRelate: {
      LIST: '/hydra-intelligent-planting/valid/api/v1/bamboo-basket-associate/list',
      ADD: '/hydra-intelligent-planting/valid/api/v1/bamboo-basket-associate',
      EDIT: '/hydra-intelligent-planting/valid/api/v1/bamboo-basket-associate',
      // 解除关联
      RELIEVE: '/hydra-intelligent-planting/valid/api/v1/bamboo-basket-associate',
      DROP_DOWN: '/hydra-intelligent-planting/valid/api/v1/bamboo-basket-associate/pull'
    }, 
    // 用工管理
    employManage: {
      LIST: '/hydra-intelligent-planting/valid/api/v1/labor-manage/list',
      ADD: '/hydra-intelligent-planting/valid/api/v1/labor-manage',
      DETAIL: '/hydra-intelligent-planting/valid/api/v1/labor-manage/get-by-id',
      EDIT: '/hydra-intelligent-planting/valid/api/v1/labor-manage',
      DELETE: '/hydra-intelligent-planting/valid/api/v1/labor-manage',
      EXPORT: '/hydra-intelligent-planting/valid/api/v1/labor-manage/export'
    }
  },
  //巡检管理
  inspectionRecord: {
    //巡检记录
    GET_LIST: "/hydra-intelligent-planting/valid/api/v1/daily-inspection/list",
    //获取合作社
    GET_COOPERATIVE: "/hydra-intelligent-planting/valid/api/v1/cooperative/list",
    //获取茶农
    GET_TEAFARMER: "/hydra-intelligent-planting/valid/api/v1/tea-farmer/byCooperativeId",
    //获取行为内容
    GET_CONTENT: "/hydra-intelligent-planting/valid/api/v1/behavioral/list",
    //添加巡检记录
    SAVE: "/hydra-intelligent-planting/valid/api/v1/daily-inspection",
    GET: "/hydra-intelligent-planting/valid/api/v1/daily-inspection",
    UPDATE: "/hydra-intelligent-planting/valid/api/v1/daily-inspection",
    DELECT: "/hydra-intelligent-planting/valid/api/v1/daily-inspection",
    EXPORT: "/hydra-intelligent-planting/valid/api/v1/daily-inspection/export"
  },
  behaviorMange: {
    //行为管理
    GET_LIST: "/hydra-intelligent-planting/valid/api/v1/behavioral/list",
    SAVE: "/hydra-intelligent-planting/valid/api/v1/behavioral",
    //获取行为记录信息
    GET: "/hydra-intelligent-planting/valid/api/v1/behavioral",
    UPDATE: "/hydra-intelligent-planting/valid/api/v1/behavioral",
    DELECT: "/hydra-intelligent-planting/valid/api/v1/behavioral/delete",
    EXPORT: "/hydra-intelligent-planting/valid/api/v1/daily-inspection/export"
  },
  teaFarmersRanked: {
    //茶农排名
    GET_LIST: "/hydra-intelligent-planting/valid/api/v1/tea-farmer/rank/list",
    EXPORT: "/hydra-intelligent-planting/valid/api/v1/tea-farmer/export-rank"
  },
  // 农旅管理
  operationManagement: {
    // 来访人员管理
    visitorManagement: {
      LIST: '/hydra-intelligent-planting/valid/api/v1/guGengVisitorManage/list',
      ADD: '/hydra-intelligent-planting/valid/api/v1/guGengVisitorManage/save',
      ADD_V2: '/hydra-intelligent-planting/valid/api/v1/guGengVisitorManage/v2/save',
      DETAIL: '/hydra-intelligent-planting/valid/api/v1/guGengVisitorManage/detail',
      DETAIL_V2: '/hydra-intelligent-planting/valid/api/v1/guGengVisitorManage/v2/detail',
      EDIT: '/hydra-intelligent-planting/valid/api/v1/guGengVisitorManage/update',
      EDIT_V2: '/hydra-intelligent-planting/valid/api/v1/guGengVisitorManage/v2/update',
      DELETE: '/hydra-intelligent-planting/valid/api/v1/guGengVisitorManage/deleteById',
      EXPORT: '/hydra-intelligent-planting/valid/api/v1/guGengVisitorManage/export'
    },
    // 房间类型/娱乐项目
    type: {
      LIST: '/hydra-intelligent-planting/valid/api/v1/guGengCommonType/list',
      ADD: '/hydra-intelligent-planting/valid/api/v1/guGengCommonType/save',
      EDIT: '/hydra-intelligent-planting/valid/api/v1/guGengCommonType/update',
      DELETE: '/hydra-intelligent-planting/valid/api/v1/guGengCommonType/deleteById',
      DROP_DOWN: '/hydra-intelligent-planting/valid/api/v1/guGengCommonType/dropDown'
    }
  },
  // 基地管理
  baseArea: {
    massif: {
      GET_LIST: '/hydra-base-data/api/v1/mass/list',
      SAVE: '/hydra-base-data/api/v1/mass',
      UPDATE: '/hydra-base-data/api/v1/mass',
      DISABLE: '/hydra-base-data/api/v1/mass/disable',
      ENABLE: '/hydra-base-data/api/v1/mass/enable',
      GET: '/hydra-base-data/api/v1/mass',
      GET_AREA: '/hydra-base-data/api/v1/partition/select/list',
      // 获取地块生产记录
      GET_RECORD: '/trace/massif/info/page',
      // 批次号获取h5溯源页数据接口
      GET_PAGE_DATA: '/trace/batch/info/h5PageData',
      // 批次号获取h5溯源页数据接口
      GET_PAGE_MASSIF_DATA: '/trace/massif/info/h5PageData',
    },
    base: {
      GET_LIST: '/hydra-base-data/api/v1/base/list',
      SAVE: '/hydra-base-data/api/v1/base',
      UPDATE: '/hydra-base-data/api/v1/base',
      DISABLE: '/hydra-base-data/api/v1/base/disable',
      ENABLE: '/hydra-base-data/api/v1/base/enable',
      GET: '/hydra-base-data/api/v1/base',
    },
    zone: {
      GET_LIST: '/hydra-base-data/api/v1/partition/list',
      SAVE: '/hydra-base-data/api/v1/partition',
      UPDATE: '/hydra-base-data/api/v1/partition',
      DISABLE: '/hydra-base-data/api/v1/partition/disable',
      ENABLE: '/hydra-base-data/api/v1/partition/enable',
      GET: '/hydra-base-data/api/v1/partition',
    }
  },

  // 维护管理
  maintenanceManage
};
