export default {
  // 采购管理
  purchase: {
    LIST: '/hydra-intelligent-planting/valid/api/v1/productionManagePurchasingManagement/list',
    EXPORT: '/hydra-intelligent-planting/valid/api/v1/productionManagePurchasingManagement/export',
    DELETE: '/hydra-intelligent-planting/valid/api/v1/productionManagePurchasingManagement/remove/',
    // 采购申请
    ADD: '/hydra-intelligent-planting/valid/api/v1/productionManagePurchasingManagement/save',
    // 确定供应商及价格
    ABSOLUTE_SUPPLIER_PRICE: '/hydra-intelligent-planting/valid/api/v1/productionManagePurchasingManagement/save-supplier-and-price',
    // 下单日期
    UPDATE_ORDER_DATE: '/hydra-intelligent-planting/valid/api/v1/productionManagePurchasingManagement/update-order-date',
    // 到货提醒
    UPDATE_ARRIVAL_DATE: '/hydra-intelligent-planting/valid/api/v1/productionManagePurchasingManagement/update-except-arrival-date',
    // 确认收货
    CONFIRM_RECEIPT: '/hydra-intelligent-planting/valid/api/v1/productionManagePurchasingManagement/confirm-receipt',
    // 获取采购管理详情信息
    DETAIL: '/hydra-intelligent-planting/valid/api/v1/productionManagePurchasingManagement/detail',
    // 获取确定供应商及价格信息
    GET_SUPPLIER_BY_ID: '/hydra-intelligent-planting/valid/api/v1/productionManagePurchasingManagement/get-supplier-by-id',
    // 编辑
    EDIT: '/hydra-intelligent-planting/valid/api/v1/productionManagePurchasingManagement/update', 
    // 导出pdf
    EXPORT_PDF: '/hydra-intelligent-planting/valid/api/v1/productionManagePurchasingManagement/export-pdf'
  },
  // 采购供应商管理
  supplier: {
    LIST: '/hydra-intelligent-planting/valid/api/v1/productionManageProcurementSupplier/list',
    DELETE: '/hydra-intelligent-planting/valid/api/v1/productionManageProcurementSupplier/remove/',
    ADD: '/hydra-intelligent-planting/valid/api/v1/productionManageProcurementSupplier/save',
    EDIT: '/hydra-intelligent-planting/valid/api/v1/productionManageProcurementSupplier/update',
    DETAIL: '/hydra-intelligent-planting/valid/api/v1/productionManageProcurementSupplier/detail',
    DROPDOWN_LIST: '/hydra-intelligent-planting/valid/api/v1/productionManageProcurementSupplier/get-by-name',
    EXPORT: '/hydra-intelligent-planting/valid/api/v1/productionManageProcurementSupplier/export',
    DROPDOWN: '/hydra-intelligent-planting/valid/api/v1/productionManageProcurementSupplier/dropDown'
  },
  // 采购商品维护
  product: {
    LIST: '/hydra-intelligent-planting/valid/api/v1/productionManagePurchaseProducts/list',
    DELETE: '/hydra-intelligent-planting/valid/api/v1/productionManagePurchaseProducts/remove/',
    ADD: '/hydra-intelligent-planting/valid/api/v1/productionManagePurchaseProducts/save',
    EDIT: '/hydra-intelligent-planting/valid/api/v1/productionManagePurchaseProducts/update',
    EXPORT: '/hydra-intelligent-planting/valid/api/v1/productionManagePurchaseProducts/export',
    DETAIL: '/hydra-intelligent-planting/valid/api/v1/productionManagePurchaseProducts/detail',
    LIST_BY_SUPPLIER: '/hydra-intelligent-planting/valid/api/v1/productionManagePurchaseProducts/list-from-supplier',
    // 供应商详情里的商品导出
    SUPPLIER_DETAIL_PRODUCT_EXPORT: '/hydra-intelligent-planting/valid/api/v1/productionManagePurchaseProducts/export-by-supplier'
  }
}