export default {
  //营销注册配置管理
  registerSetting: {
    GET_LIST: '/hydra-marketing/api/v1/platform/org/setting/h5/list',
    ADD: '/hydra-marketing/api/v1/platform/org/setting/register/save',
    EDIT: '/hydra-marketing/api/v1/platform/org/setting/register/save',
    DELETE: '/hydra-marketing/api/v1/platform/org/setting/deleteConfig',
    GET_DETAIL: '/hydra-marketing/api/v1/platform/org/setting/register/get'
  },
  //营销登录配置管理
  loginSetting: {
    GET_LIST: '/hydra-marketing/api/v1/platform/org/setting/h5/list',
    ADD: '/hydra-marketing/api/v1/platform/org/setting/login/save',
    EDIT: '/hydra-marketing/api/v1/platform/org/setting/login/save',
    DELETE: '/hydra-marketing/api/v1/platform/org/setting/deleteConfig',
    GET_DETAIL: '/hydra-marketing/api/v1/platform/org/setting/login/get'
  },
  //营销页面管理
  page: {
    //获取列表
    GET_LIST: '/hydra-marketing/api/v1/platform/ui/list',
    // 根据ids获取列表
    GET_LIST_BY_IDS: '/hydra-marketing/api/v1/activity/getUiTemplateByIds',
    //编辑
    EDIT: '/hydra-marketing/api/v1/platform/ui/update',
    //启用
    ENABLE: '/hydra-marketing/api/v1/platform/ui/status',
    //禁用
    DISABLE: '/hydra-marketing/api/v1/platform/ui/status',
    //删除
    DELETE: '/hydra-marketing/api/v1/platform/ui/delete',
    //新增
    ADD: '/hydra-marketing/api/v1/platform/ui/add',
    //获取详情
    DETAIL: '/hydra-marketing/api/v1/platform/ui/detail',
    SYS_DETAIL: '/hydra-marketing/api/v1/activity/getTemplateById',
    //扫码限制规则 1按单码;2按号段;3按产品批次;4按产品;5按生码批次;
    urlMap: {
      '1': '',
      '2': '',
      '3': '/hydra-marketing/api/v1/product-batch/batch/list',
      '4': '/hydra-marketing/api/v1/product/list',
      '5': '/hydra-marketing/api/v1/code/manager/code/apply/batch/list',
    },
    //获取渠道列表---增加渠道限制
    GET_CHANNEL_LIST: '/hydra-base-data/api/v1/customer/list',
    //获取自卖品列表
    GET_SALF_PRODUCTION_LIST: '/hydra-marketing/api/v1/product/list',
    //获取非自卖品列表
    GET_UNSALF_PRODUCTION_LIST: '/hydra-marketing/api/v1/unsaleProduct/list',
    //根据code获取非自卖品详细信息
    GET_UNSALF_PRODUCT_DETAIL_BY_CODE: '/hydra-marketing/api/v1/unsaleProduct/detailByCode',
    //获取问卷列表
    GET_QUESTION_LIST: '/hydra-marketing/api/v1/question/list',
    //获取会员自定义字段
    GET_MEMBER_CUSTOM_FIELDS: '/hydra-marketing/api/v1/member/fieldsDefine'
  },
  //营销模版管理
  template: {
    //获取列表
    GET_LIST: '/hydra-marketing/api/v1/platform/template/list',
    //编辑
    EDIT: '/hydra-marketing/api/v1/platform/template/update',
    //启用
    ENABLE: '/hydra-marketing/api/v1/platform/template/status',
    //禁用
    DISABLE: '/hydra-marketing/api/v1/platform/template/status',
    //删除
    DELETE: '/hydra-marketing/api/v1/platform/template/delete',
    //新增
    ADD: '/hydra-marketing/api/v1/platform/template/add',
    //获取详情
    DETAIL: '/hydra-marketing/api/v1/platform/template/detail',
    //获取渠道列表
    GET_CUSTOMER_LIST: '/hydra-base-data/api/v1/customer/list',
  },
  //营销配置管理
  config: {
    //获取列表
    GET_LIST: '/hydra-marketing/api/v1/platform/org/template/list',
    //编辑
    EDIT: '/hydra-marketing/api/v1/platform/org/template/update',
    //启用
    ENABLE: '/hydra-marketing/api/v1/platform/org/template/status',
    //禁用
    DISABLE: '/hydra-marketing/api/v1/platform/org/template/status',
    //删除
    DELETE: '/hydra-marketing/api/v1/platform/org/template/delete',
    //新增
    ADD: '/hydra-marketing/api/v1/platform/org/template/add',
    //获取详细信息
    DETAIL: '/hydra-marketing/api/v1/platform/org/template/detail'
  },
};