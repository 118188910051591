export default {
  //枚举下拉
  enumerate: {
    GET_LIST: "/sanjiang-county-ecommerce/api/v1/drop-down/list",
  },
  //电子商务大数据
  //电商平台维护
  platformMaintenance: {
    GET_LIST: "/sanjiang-county-ecommerce/api/v1/server/ecommerce-platform/list",
    SAVE: "/sanjiang-county-ecommerce/api/v1/server/ecommerce-platform/add",
    GET: "/sanjiang-county-ecommerce/api/v1/server/ecommerce-platform/getById",
    UPDATE: "/sanjiang-county-ecommerce/api/v1/server/ecommerce-platform/update",
    DELECT: "/sanjiang-county-ecommerce/api/v1/server/ecommerce-platform/deleteById"
  },
  //三江县电商店铺
  sjShop: {
    GET_LIST: "/sanjiang-county-ecommerce/api/v1/server/ecommerce-shop/list",
    SAVE: "/sanjiang-county-ecommerce/api/v1/server/ecommerce-shop/add",
    GET: "/sanjiang-county-ecommerce/api/v1/server/ecommerce-shop/getById",
    UPDATE: "/sanjiang-county-ecommerce/api/v1/server/ecommerce-shop/update",
    DELECT: "/sanjiang-county-ecommerce/api/v1/server/ecommerce-shop/deleteById"
  },
  //柳州市电商店铺
  liuzhouShop: {
    GET_LIST: "/sanjiang-county-ecommerce/api/v1/server/ecommerce-shop/list",
    SAVE: "/sanjiang-county-ecommerce/api/v1/server/ecommerce-shop/add",
    GET: "/sanjiang-county-ecommerce/api/v1/server/ecommerce-shop/getById",
    UPDATE: "/sanjiang-county-ecommerce/api/v1/server/ecommerce-shop/update",
    DELECT: "/sanjiang-county-ecommerce/api/v1/server/ecommerce-shop/deleteById"
  },
  //广西省电商店铺
  guangxiShop: {
    GET_LIST: "/sanjiang-county-ecommerce/api/v1/server/ecommerce-shop/list",
    SAVE: "/sanjiang-county-ecommerce/api/v1/server/ecommerce-shop/add",
    GET: "/sanjiang-county-ecommerce/api/v1/server/ecommerce-shop/getById",
    UPDATE: "/sanjiang-county-ecommerce/api/v1/server/ecommerce-shop/update",
    DELECT: "/sanjiang-county-ecommerce/api/v1/server/ecommerce-shop/deleteById"
  },
  //网络交易额
  onlineTransactionVolume: {
    GET_LIST: "/sanjiang-county-ecommerce/api/v1/server/online-transaction-volume/list",
    SAVE: "/sanjiang-county-ecommerce/api/v1/server/online-transaction-volume/add",
    GET: "/sanjiang-county-ecommerce/api/v1/server/online-transaction-volume/getById",
    UPDATE: "/sanjiang-county-ecommerce/api/v1/server/online-transaction-volume/update",
    DELECT: "/sanjiang-county-ecommerce/api/v1/server/online-transaction-volume/deleteById"
  },
  //网络零售额
  onlineTemporarySales: {
    GET_LIST: "/sanjiang-county-ecommerce/api/v1/server/online-retail-sale/list",
    SAVE: "/sanjiang-county-ecommerce/api/v1/server/online-retail-sale/add",
    GET: "/sanjiang-county-ecommerce/api/v1/server/online-retail-sale/getById",
    UPDATE: "/sanjiang-county-ecommerce/api/v1/server/online-retail-sale/update",
    DELECT: "/sanjiang-county-ecommerce/api/v1/server/online-retail-sale/deleteById"
  },
  //往年网络零售额
  onlineSalesLastYears: {
    GET_LIST: "/sanjiang-county-ecommerce/api/v1/server/previous-online-retail-sale/list",
    SAVE: "/sanjiang-county-ecommerce/api/v1/server/previous-online-retail-sale/add",
    GET: "/sanjiang-county-ecommerce/api/v1/server/previous-online-retail-sale/getById",
    UPDATE: "/sanjiang-county-ecommerce/api/v1/server/previous-online-retail-sale/update",
    DELECT: "/sanjiang-county-ecommerce/api/v1/server/previous-online-retail-sale/deleteById"
  },
  //电商企业
  enterprise: {
    GET_LIST: "/sanjiang-county-ecommerce/api/v1/server/ecommerce-enterprise/list",
    SAVE: "/sanjiang-county-ecommerce/api/v1/server/ecommerce-enterprise/add",
    GET: "/sanjiang-county-ecommerce/api/v1/server/ecommerce-enterprise/getById",
    UPDATE: "/sanjiang-county-ecommerce/api/v1/server/ecommerce-enterprise/update",
    DELECT: "/sanjiang-county-ecommerce/api/v1/server/ecommerce-enterprise/deleteById",
    IMPORT:"/sanjiang-county-ecommerce/api/v1/server/ecommerce-enterprise/importEnterprise"
  },
  //快递物流网点
  logisticsOutlets: {
    GET_LIST: "/sanjiang-county-ecommerce/api/v1/server/express-logistics-outlets/list",
    SAVE: "/sanjiang-county-ecommerce/api/v1/server/express-logistics-outlets/add",
    GET: "/sanjiang-county-ecommerce/api/v1/server/express-logistics-outlets/getById",
    UPDATE: "/sanjiang-county-ecommerce/api/v1/server/express-logistics-outlets/update",
    DELECT: "/sanjiang-county-ecommerce/api/v1/server/express-logistics-outlets/deleteById",
    IMPORT:"/sanjiang-county-ecommerce/api/v1/server/express-logistics-outlets/importOutlets"
  },
  //农特产品维护
  productMaintenance: {
    GET_LIST: "/sanjiang-county-ecommerce/api/v1/server/special-agricultural-product/list",
    SAVE: "/sanjiang-county-ecommerce/api/v1/server/special-agricultural-product/add",
    GET: "/sanjiang-county-ecommerce/api/v1/server/special-agricultural-product/getById",
    UPDATE: "/sanjiang-county-ecommerce/api/v1/server/special-agricultural-product/update",
    DELECT: "/sanjiang-county-ecommerce/api/v1/server/special-agricultural-product/deleteById"
  },
  //农特产品分布
  productDistribution: {
    GET_LIST: "/sanjiang-county-ecommerce/api/v1/server/special-agricultural-product-distributed/list",
    SAVE: "/sanjiang-county-ecommerce/api/v1/server/special-agricultural-product-distributed/add",
    GET: "/sanjiang-county-ecommerce/api/v1/server/special-agricultural-product-distributed/getById",
    UPDATE: "/sanjiang-county-ecommerce/api/v1/server/special-agricultural-product-distributed/update",
    DELECT: "/sanjiang-county-ecommerce/api/v1/server/special-agricultural-product-distributed/deleteById"
  },
  //网商监控
  internetMonitoring: {
    GET_LIST: "/sanjiang-county-ecommerce/api/v1/server/internet-business-monitor/list",
    SAVE: "/sanjiang-county-ecommerce/api/v1/server/internet-business-monitor/add",
    GET: "/sanjiang-county-ecommerce/api/v1/server/internet-business-monitor/getById",
    UPDATE: "/sanjiang-county-ecommerce/api/v1/server/internet-business-monitor/update",
    DELECT: "/sanjiang-county-ecommerce/api/v1/server/internet-business-monitor/deleteById",
    IMPORT:"/sanjiang-county-ecommerce/api/v1/server/internet-business-monitor/importMonitor"
  },
  //农产品溯源分布
  traceabilityDistribution: {
    GET_LIST: "/sanjiang-county-ecommerce/api/v1/server/agricultural-product-trace-distributed/list",
    SAVE: "/sanjiang-county-ecommerce/api/v1/server/agricultural-product-trace-distributed/add",
    GET: "/sanjiang-county-ecommerce/api/v1/server/agricultural-product-trace-distributed/getById",
    UPDATE: "/sanjiang-county-ecommerce/api/v1/server/agricultural-product-trace-distributed/update",
    DELECT: "/sanjiang-county-ecommerce/api/v1/server/agricultural-product-trace-distributed/deleteById"
  },
  //三江数据榜
  sanjiangDataList: {
    GET_LIST: "/sanjiang-county-ecommerce/api/v1/server/sanjiang-data-list/list",
    SAVE: "/sanjiang-county-ecommerce/api/v1/server/sanjiang-data-list/add",
    GET: "/sanjiang-county-ecommerce/api/v1/server/sanjiang-data-list/getById",
    UPDATE: "/sanjiang-county-ecommerce/api/v1/server/sanjiang-data-list/update",
    DELECT: "/sanjiang-county-ecommerce/api/v1/server/sanjiang-data-list/deleteById"
  },
  //指数维度
  indexDimension: {
    GET_LIST: "/sanjiang-county-ecommerce/api/v1/server/index-dimension/list",
    SAVE: "/sanjiang-county-ecommerce/api/v1/server/index-dimension/add",
    GET: "/sanjiang-county-ecommerce/api/v1/server/index-dimension/getById",
    UPDATE: "/sanjiang-county-ecommerce/api/v1/server/index-dimension/update",
    DELECT: "/sanjiang-county-ecommerce/api/v1/server/index-dimension/deleteById"
  },
  //实时网络零售额(未完成)
  realTimeSales: {
    GET_LIST: "/sanjiang-county-ecommerce/api/v1/server/real-time-online-retail-sale/list",
    SAVE: "/sanjiang-county-ecommerce/api/v1/server/real-time-online-retail-sale/addList",
    GET: "/sanjiang-county-ecommerce/api/v1/server/real-time-online-retail-sale/get",
    UPDATE: "/sanjiang-county-ecommerce/api/v1/server/real-time-online-retail-sale/update",
    DELECT: "/sanjiang-county-ecommerce/api/v1/server/real-time-online-retail-sale/delete"
  },
  //网络零售行业结构
  industryStructure: {
    GET_LIST: "/sanjiang-county-ecommerce/api/v1/server/online-retail-industry-structure/list",
    SAVE: "/sanjiang-county-ecommerce/api/v1/server/online-retail-industry-structure/addList",
    GET: "/sanjiang-county-ecommerce/api/v1/server/online-retail-industry-structure/get",
    UPDATE: "/sanjiang-county-ecommerce/api/v1/server/online-retail-industry-structure/update",
    DELECT: "/sanjiang-county-ecommerce/api/v1/server/online-retail-industry-structure/delete"
  },
  //行业维护
  industryMaintenance: {
    GET_LIST: "/sanjiang-county-ecommerce/api/v1/server/industry/list",
    SAVE: "/sanjiang-county-ecommerce/api/v1/server/industry/addList",
    GET: "/sanjiang-county-ecommerce/api/v1/server/industry/get",
    UPDATE: "/sanjiang-county-ecommerce/api/v1/server/industry/update",
    DELECT: "/sanjiang-county-ecommerce/api/v1/server/industry/delete"
  },
  //品类维护
  categoryMaintenance: {
    GET_LIST: "/sanjiang-county-ecommerce/api/v1/server/category/list",
    SAVE: "/sanjiang-county-ecommerce/api/v1/server/category/addList",
    GET: "/sanjiang-county-ecommerce/api/v1/server/category/get",
    UPDATE: "/sanjiang-county-ecommerce/api/v1/server/category/update",
    DELECT: "/sanjiang-county-ecommerce/api/v1/server/category/delete"
  },
  //网络零售行业细分
  industrySegmentation: {
    GET_LIST: "/sanjiang-county-ecommerce/api/v1/server/online-retail-industry-data/list",
    SAVE: "/sanjiang-county-ecommerce/api/v1/server/online-retail-industry-data/addList",
    GET: "/sanjiang-county-ecommerce/api/v1/server/online-retail-industry-data/get",
    UPDATE: "/sanjiang-county-ecommerce/api/v1/server/online-retail-industry-data/update",
    DELECT: "/sanjiang-county-ecommerce/api/v1/server/online-retail-industry-data/delete"
  },
  //网络零售品类细分
  categoryBreakdown: {
    GET_LIST: "/sanjiang-county-ecommerce/api/v1/server/online-retail-category-data/list",
    SAVE: "/sanjiang-county-ecommerce/api/v1/server/online-retail-category-data/addList",
    GET: "/sanjiang-county-ecommerce/api/v1/server/online-retail-category-data/get",
    UPDATE: "/sanjiang-county-ecommerce/api/v1/server/online-retail-category-data/update",
    DELECT: "/sanjiang-county-ecommerce/api/v1/server/online-retail-category-data/delete"
  },
  //农产品行业细分
  agriculturalSegmentation: {
    GET_LIST: "/sanjiang-county-ecommerce/api/v1/server/agricultural-industry-data/list",
    SAVE: "/sanjiang-county-ecommerce/api/v1/server/agricultural-industry-data/addList",
    GET: "/sanjiang-county-ecommerce/api/v1/server/agricultural-industry-data/get",
    UPDATE: "/sanjiang-county-ecommerce/api/v1/server/agricultural-industry-data/update",
    DELECT: "/sanjiang-county-ecommerce/api/v1/server/agricultural-industry-data/delete"
  },
  //-----------产业大数据
  //产业管理
  industryManagement: {
    GET_LIST: "/sanjiang-county-ecommerce/api/v1/server/industry-management/list",
    SAVE: "/sanjiang-county-ecommerce/api/v1/server/industry-management",
    GET: "/sanjiang-county-ecommerce/api/v1/server/industry-management",
    UPDATE: "/sanjiang-county-ecommerce/api/v1/server/industry-management",
    DELECT: "/sanjiang-county-ecommerce/api/v1/server/industry-management"
  },
   //全国产业
  nationalIndustry:{
    GET_LIST: "/sanjiang-county-ecommerce/api/v1/server/industry-sale/list",
    SAVE: "/sanjiang-county-ecommerce/api/v1/server/industry-sale/addList",
    GET: "/sanjiang-county-ecommerce/api/v1/server/industry-sale",
    UPDATE: "/sanjiang-county-ecommerce/api/v1/server/industry-sale",
    DELECT: "/sanjiang-county-ecommerce/api/v1/server/industry-sale"
  },
 //当地产业
  localIndustry:{
    GET_LIST: "/sanjiang-county-ecommerce/api/v1/server/industry-sale/list",
    SAVE: "/sanjiang-county-ecommerce/api/v1/server/industry-sale/addList",
    GET: "/sanjiang-county-ecommerce/api/v1/server/industry-sale",
    UPDATE: "/sanjiang-county-ecommerce/api/v1/server/industry-sale",
    DELECT: "/sanjiang-county-ecommerce/api/v1/server/industry-sale"
  },
  //全国产业网络零售
  nationalRetail:{
    GET_LIST: "/sanjiang-county-ecommerce/api/v1/server/online-retail-industry/list",
    SAVE: "/sanjiang-county-ecommerce/api/v1/server/online-retail-industry/addList",
    GET: "/sanjiang-county-ecommerce/api/v1/server/online-retail-industry",
    UPDATE: "/sanjiang-county-ecommerce/api/v1/server/online-retail-industry",
    DELECT: "/sanjiang-county-ecommerce/api/v1/server/online-retail-industry"
  },
 //当地产业网络零售
  localRetail:{
    GET_LIST: "/sanjiang-county-ecommerce/api/v1/server/online-retail-industry/list",
    SAVE: "/sanjiang-county-ecommerce/api/v1/server/online-retail-industry/addList",
    GET: "/sanjiang-county-ecommerce/api/v1/server/online-retail-industry",
    UPDATE: "/sanjiang-county-ecommerce/api/v1/server/online-retail-industry",
    DELECT: "/sanjiang-county-ecommerce/api/v1/server/online-retail-industry"
  },
//全国产品规格销售
  nationalProductSales:{
    GET_LIST: "/sanjiang-county-ecommerce/api/v1/server/product-specification-sale/list",
    SAVE: "/sanjiang-county-ecommerce/api/v1/server/product-specification-sale/addList",
    GET: "/sanjiang-county-ecommerce/api/v1/server/product-specification-sale",
    UPDATE: "/sanjiang-county-ecommerce/api/v1/server/product-specification-sale",
    DELECT: "/sanjiang-county-ecommerce/api/v1/server/product-specification-sale"
  },
  //当地产品规格销售
  localProductSales:{
    GET_LIST: "/sanjiang-county-ecommerce/api/v1/server/product-specification-sale/list",
    SAVE: "/sanjiang-county-ecommerce/api/v1/server/product-specification-sale/addList",
    GET: "/sanjiang-county-ecommerce/api/v1/server/product-specification-sale",
    UPDATE: "/sanjiang-county-ecommerce/api/v1/server/product-specification-sale",
    DELECT: "/sanjiang-county-ecommerce/api/v1/server/product-specification-sale"
  },
  //全国(当地)产品优势品牌
  nationalBrand:{
    GET_LIST: "/sanjiang-county-ecommerce/api/v1/server/product-advantage-brand/list",
    SAVE: "/sanjiang-county-ecommerce/api/v1/server/product-advantage-brand/addList",
    GET: "/sanjiang-county-ecommerce/api/v1/server/product-advantage-brand",
    UPDATE: "/sanjiang-county-ecommerce/api/v1/server/product-advantage-brand",
    DELECT: "/sanjiang-county-ecommerce/api/v1/server/product-advantage-brand"
  },
  //全国(当地)产品优势网商
  nationalBusiness:{
    GET_LIST: "/sanjiang-county-ecommerce/api/v1/server/product-advantage-version/list",
    SAVE: "/sanjiang-county-ecommerce/api/v1/server/product-advantage-version/addList",
    GET: "/sanjiang-county-ecommerce/api/v1/server/product-advantage-version",
    UPDATE: "/sanjiang-county-ecommerce/api/v1/server/product-advantage-version",
    DELECT: "/sanjiang-county-ecommerce/api/v1/server/product-advantage-version"
  },
  //主要经营平台
  mainBusinessPlatform:{
    GET_LIST: "/sanjiang-county-ecommerce/api/v1/server/main-business-platform/list",
    SAVE: "/sanjiang-county-ecommerce/api/v1/server/main-business-platform",
    GET: "/sanjiang-county-ecommerce/api/v1/server/main-business-platform",
    UPDATE: "/sanjiang-county-ecommerce/api/v1/server/main-business-platform",
    DELECT: "/sanjiang-county-ecommerce/api/v1/server/main-business-platform"
  },
  //本地外地渠道
  localForeignChannels:{
    GET_LIST: "/sanjiang-county-ecommerce/api/v1/server/industry-management/list",
    GET: "/sanjiang-county-ecommerce/api/v1/server/industry-management",
    UPDATE: "/sanjiang-county-ecommerce/api/v1/server/industry-management",
  },
  //产业热门词(产业网络舆情评论词)
  industryHotWords:{
    GET_LIST: "/sanjiang-county-ecommerce/api/v1/server/industry-correlative/list",
    SAVE: "/sanjiang-county-ecommerce/api/v1/server/industry-correlative",
    GET: "/sanjiang-county-ecommerce/api/v1/server/industry-correlative",
    UPDATE: "/sanjiang-county-ecommerce/api/v1/server/industry-correlative",
    DELECT: "/sanjiang-county-ecommerce/api/v1/server/industry-correlative"
  }
};
