export default {
  // 一体机查询页配置相关接口
  searchConfig: {
    // 获取列表
    GET_LIST: '/integrated/page/list',
    // 添加
    ADD: '/integrated/page/add',
    // 更新
    UPDATE: '/integrated/page/update',
    //获取详细信息
    GET_DETAIL: '/integrated/page/detail',
    //获取预览地址和配置码
    GET_VIEW_INFO: '/integrated/page/view'
  }
}
