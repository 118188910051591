import api from 'system/config/systemApi';
import {ajaxSync} from 'framework/utils/ajax';
// 获取系统下的开通的组织
export const getOrgListBySysId = async (sysId) => {
  const {err, res} = await ajaxSync.get(api.GET_ORG_BY_SYSTEM_ID, {sysId});
  if (err) {
    return [];
  } else if (res) {
    return res.results.list || [];
  }
}
// 切换组织，重新获取token
export const getTokenBySwitchOrg = async (data) => {
  const {err, res} = await ajaxSync.get(api.GET_TOKEN_BY_SWITCH_ORG, data);
  if (err) {
    return [];
  } else if (res) {
    return res.results || [];
  }
}
