export default {
    //销售数据概览
    salesOverview: {
        //销售额实收额（订单数据）
        GET_ORDER_DATA: '/gugeng/statistics/saleoverview/amountAndNum',
        //客户数据
        GET_CUSTOM_DATA: '/gugeng/statistics/saleoverview/selectCustomerNumList',
        //订单情况
        GET_ORDER_DETAIL: '/gugeng/statistics/saleoverview/selectSaleOrderList',
        //销售产品
        GET_SALES_PRODUCTION: '/gugeng/statistics/saleoverview/selectSaleProduct',
        //销售目标
        GET_SALES_TARGET: '/gugeng/statistics/saleoverview/histogram',
        //产品分类
        GET_PEODUCT_SORT: '/hydra-base-data/api/v1/product-sort/firsts'
    },
    //仓储数据概览
    manageOverview: {
        //库存数据
        GET_INVENTORY_DATA: '/gugeng/statistics/warehouseoverview/stock/instorageAndOutboundWeight',
        //库存报损数据
        GET_INVENTORY_DESTORY: '/gugeng/statistics/warehouseoverview/hsl-line-chart',
        //发货数据
        GET_DELIVERY_DATA: '/gugeng/statistics/warehouseoverview/list-line-chart',
    },
    //生产数据概览
    productOverview: {
        //生产数据
        GET_PRODUCT_DATA: '/gugeng/statistics/produceoverview/product/realWeightAndLossesWeight',
        //产量数据
        GET_PRODUCTION_DATA: '/gugeng/statistics/produceoverview/list-by-date-interval',
        //产量对比
        GET_YIELD_DATA: '/gugeng/statistics/produceoverview/list-yield-comparison-data-histogram',
        //产品产量
        GET_PRODUCT_PRODUCTION:'/gugeng/statistics/produceoverview/histogram'
    }

}