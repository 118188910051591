import dynamic from "dva/dynamic";

export default [
  // 企业接入平台流程
  {
    path: "/gov/applyEnterprise/address",
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/gov/enterPlatform/apply" */ "./views/enterPlatform/apply"
        ),
    }),
  },
  // 平台接入管理
  {
    path: "/gov/apply/applyManage",
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/gov/enterPlatform/apply" */ "./views/enterPlatform/applyManage"
        ),
    }),
  },
  // 系统管理员
  // 业务定制
  {
    path: "/gov/sys/made",
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/gov/sys/made" */ "./views/sys/made"
        ),
    }),
  },
  // 产品备案管理
  {
    path: "/gov/product/filingManagement",
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/gov/product/filingManagement" */ "./views/product/filingManagement"
        ),
    }),
  },
  // 产品备案审批
  {
    path: "/gov/product/filingApproval",
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/gov/product/filingApproval" */ "./views/product/filingApproval"
        ),
    }),
  },
  // 产品目录管理
  {
    path: "/gov/product/directoryManagement/govRole",
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/gov/product/directoryManagement/govRole" */ "./views/product/directoryManagement/govRole"
        ),
    }),
  },
  // 政府产品备案信息
  {
    path: "/gov/product/directoryManagement/corpRole",
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/gov/product/directoryManagement/corpRole" */ "./views/product/directoryManagement/corpRole"
        ),
    }),
  },
  // 备案模板管理
  {
    path: "/gov/sys/template",
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/gov/sys/template" */ "./views/sys/template"
        ),
    }),
  },
  // 政府
  // 数据中心
  {
    path: "/gov/gov/dataCenter",
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/gov/sys/dataCenter" */ "./views/gov/dataCenter"
        ),
    }),
  },
  // 政府  组织管理
  {
    path: "/gov/orgSys",
    component: dynamic({
      component: () =>
        import(/* webpackChunkName: "modules/gov/orgSys" */ "./views/orgSys"),
    }),
  },
  // 政府  主题备案信息
  {
    path: "/gov/traceSubject/enterprise",
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/gov/traceSubject/enterprise" */ "./views/traceSubject/enterprise"
        ),
    }),
  },
  // 政府  溯源信息检索
  {
    path: "/gov/traceSubject/traceInfo",
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/gov/traceSubject/traceInfo" */ "./views/traceSubject/traceInfo"
        ),
    }),
  },
  // 企业  企业基本信息
  {
    path: "/gov/enterprise/basic/corpRole",
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/gov/enterprise/basic/corpRole" */ "./views/enterprise/basic/corpRole"
        ),
    }),
  },
  // 政府  企业基本信息
  {
    path: "/gov/enterprise/basic/govRole",
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/gov/enterprise/basic/govRole" */ "./views/enterprise/basic/govRole"
        ),
    }),
  },
  {
    path: "/gov/enterprise/batch/list",
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/gov/enterprise/batch/list" */ "./views/enterprise/batch/list"
        ),
    }),
  },
  // 企业  传输统计
  {
    path: "/gov/enterprise/transmissionStatistics",
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/gov/enterprise/transmissionStatistics" */ "./views/enterprise/transmissionStatistics"
        ),
    }),
  },
  // 企业  追溯节点
  {
    path: "/gov/singleTraceNode",
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/gov/traceNode" */ "./views/singleTraceNode"
        ),
    }),
  },
  // 政府  追溯节点
  {
    path: "/gov/traceNode/:id",
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/gov/traceNode" */ "./views/traceNode"
        ),
    }),
  },
  // 企业  数据传输说明
  {
    path: "/gov/enterprise/transRemark",
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/gov/enterprise/transmissionStatistics" */ "./views/enterprise/transRemark"
        ),
    }),
  },
  // 企业  接口定义
  {
    path: "/gov/enterprise/interfaceDefined",
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/gov/enterprise/interfaceDefined" */ "./views/enterprise/interfaceDefined"
        ),
    }),
  },
  // 监管电子地图
  {
    path: "/gov/regulatoryMap/index",
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/gov/regulatoryMap/index" */ "./views/regulatoryMap/index"
        ),
    }),
  },

  // 农业基础数据
  {
    path: "/gov/agriculturalBase/index",
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/gov/agriculturalBase/index" */ "./views/agriculturalBase/index"
        ),
    }),
  },

  // 农业生产数据
  {
    path: "/gov/agriculturalProduct/index",
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/gov/agriculturalProduct/index" */ "./views/agriculturalProduct/index"
        ),
    }),
  },

  // 农产品追溯数据
  {
    path: "/gov/agriculturalTrace/index",
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/gov/agriculturalTrace/index" */ "./views/agriculturalTrace/index"
        ),
    }),
  },
  //企业证明
  {
    path: "/gov/companyProve",
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/gov/enterprise/interfaceDefined" */ "./views/companyProve"
        ),
    }),
  },
  //实时监控
  {
    path: "/gov/iot",
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/gov/enterprise/interfaceDefined" */ "./views/iot"
        ),
    }),
  },
  //企业溯源档案
  {
    path: "/gov/enterpriseRoots",
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/gov/enterprise/interfaceDefined" */ "./views/enterpriseRoots"
        ),
    }),
  },
  //检测数据
  {
    path: "/gov/testData",
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/gov/enterprise/interfaceDefined" */ "./views/testData"
        ),
    }),
  },
  //我的证明
  {
    path: "/gov/myProof",
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/gov/enterprise/interfaceDefined" */ "./views/myProof"
        ),
    }),
  },

  // 数据上报
  // 站点端
  //首页
  {
    path: "/gov/dataReport/siteEnd/homePage",
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/gov/enterprise/interfaceDefined" */ "./views/dataReport/siteEnd/homePage/index"
        ),
    }),
  },
  // 订单管理
  {
    path: "/gov/dataReport/order",
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/gov/enterprise/interfaceDefined" */ "./views/dataReport/siteEnd/order"
        ),
    }),
  },
  // 数据上报
  {
    path: "/gov/dataReport/siteDataReport",
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/gov/enterprise/interfaceDefined" */ "./views/dataReport/siteEnd/siteDataReport"
        ),
    }),
  },
  // 数据统计
  {
    path: "/gov/dataReport/dataAnalysis",
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/gov/enterprise/interfaceDefined" */ "./views/dataReport/siteEnd/dataAnalysis"
        ),
    }),
  },
  // 管理端
  //首页
  {
    path: "/gov/dataReport/managementEnd/homePage",
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/gov/enterprise/interfaceDefined" */ "./views/dataReport/managementEnd/homePage/index"
        ),
    }),
  },
  // 数据上报
  {
    path: "/gov/dataReport/manageDataReport",
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/gov/enterprise/interfaceDefined" */ "./views/dataReport/managementEnd/dataReport"
        ),
    }),
  },
  //站点管理
  {
    path: "/gov/dataReport/siteManagement",
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/gov/enterprise/interfaceDefined" */ "./views/dataReport/managementEnd/siteManagement/index"
        ),
    }),
  },
  // 日报查询
  {
    path: "/gov/dataReport/dailyReportQuery",
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/gov/enterprise/interfaceDefined" */ "./views/dataReport/managementEnd/dailyReportQuery"
        ),
    }),
  },
  // 站点数据
  {
    path: "/gov/dataReport/siteData",
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/gov/enterprise/interfaceDefined" */ "./views/dataReport/managementEnd/siteData"
        ),
    }),
  },
  // 传感器汇总
  {
    path: "/gov/sensorSummary",
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/gov/enterprise/interfaceDefined" */ "./views/sensorSummary/index.js"
        ),
    }),
  },
];
