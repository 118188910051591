import { ajaxSync } from 'framework/utils/ajax';
import { isDev } from 'framework/common/config';
import { parse } from 'framework/utils/url'
import api from './api';
import { Modal } from 'components';
import { loadDetail as loadUserDetail } from 'src/framework/services/user';
import _ from 'lodash';

function showError(err) {
  const errStr = (typeof err === 'object' && err !== null && err.message) ? err.message : (err + '');
  return new Promise(resolve => {
    Modal.error({
      title: '错误提示',
      content: errStr,
      onOk: (callback) => {
        callback();
        resolve();
      }
    });
  });
}

export default {
  async loadDetail() {
    const browserLocation = parse(window.location.href);
    const query = _.get(browserLocation, 'query', {});
    let sysUrl = isDev ? query.sysUrl : '';
    if (!sysUrl) {
      const userDetail = await loadUserDetail();
      const isAndroid = !!(window.native && native.getTokenUseless);
      if (isAndroid) {
        sysUrl = _.get(userDetail, 'organizationCache.sysCache.sysUrl') || _.get(userDetail, 'siteUrl') || '';
      } else {
        sysUrl = _.get(userDetail, 'siteUrl') || _.get(userDetail, 'organizationCache.sysCache.sysUrl') || '';
      }
    }
    const { err, res } = await ajaxSync.get(api.GET_SYSTEM_DETAIL, { sysUrl });
    if (!err && res) {
      return res.results;
    }
  },
  async setLocalSys(sysId) {
    let setSuccess = true;
    // const {err, res} = await setSystem(sysId);
    // if (err) {
    //   await showError(err);
    //   setSuccess = false;
    // }
    return setSuccess;
  }
}
