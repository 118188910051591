import getIntegralMarketingRouterConfig from 'integralMarketing/routes/routerConfig';

export default ({app, dynamicWrapper, modelNotExisted}) => {
  //营销路由layout配置
  const integralMarketingRouterConfig = getIntegralMarketingRouterConfig({ app, dynamicWrapper, modelNotExisted });
  //
  return {
    '/user': {
      component: dynamicWrapper(app, [], () =>
        import(/* webpackChunkName: "modules/layouts/UserLayout" */ '../layouts/UserLayout')
      ),
    },
    ...integralMarketingRouterConfig
  };
};
