export default {
  // 产品管理
  product: {
    GET_LIST: '/hydra-base-data/api/v1/product/list',
    // 获取产品列表
    SAVE: '/hydra-base-data/api/v1/product',
    // 禁用产品
    DISABLE: '/hydra-base-data/api/v1/product/disable',
    // 启用产品
    ENABLE: '/hydra-base-data/api/v1/product/enable',
    // 更新产品
    UPDATE: '/hydra-base-data/api/v1/product',
    // 更新产品
    GET: '/hydra-base-data/api/v1/product/one',
    PRODUCT_CATEGORY: '/hydra-base-data/api/v1/product-category/',
    // 产品仓储管理
    PRODUCT_WARE_HOUSE: '/hydra-base-data/api/v1/product-ware-house',
    // 获取产品分类
    PRODUCT_SORT_FIRSTS: '/hydra-base-data/api/v1/product-sort/firsts',
    // 获取产品子分类
    PRODUCT_SORT_CHILD: '/hydra-base-data/api/v1/product-sort/childs',
    // 增加编辑产品分类
    PRODUCT_SORT: '/hydra-base-data/api/v1/product-sort',
    // 添加产品下级分类
    PRODUCT_SORT_NEXT: '/hydra-base-data/api/v1/product-sort/next',
    // 获取当前系统标签
    SYS_LABEL: '/hydra-user/api/v1/sys/label',
    // 获取营销信息
    GET_MAKET: '/hydra-base-data/api/v1/product/market/no-filter',
    // 获取启用的产品,模糊搜索只有产品名称
    GET_LIST_AUTO: '/hydra-base-data/api/v1/product/enable/like/name/list',
  },
  // 物料管理
  materials: {
    manage: {
      GET_LIST: '/hydra-intelligent-planting/valid/api/v1/material/info/list',
      ADD_MATERIAL: '/hydra-intelligent-planting/valid/api/v1/material/info',
      GET_MATERIAL_BYID: '/hydra-intelligent-planting/valid/api/v1/material/info/',
      UPDATE_MATERIAL: '/hydra-intelligent-planting/valid/api/v1/material/info/',
      DISABLE_OR_ENABLE_MATERIAL: '/hydra-intelligent-planting/valid/api/v1/material/info/disable/',
    },
    stock: {
      LIST: '/hydra-intelligent-planting/valid/api/v1/material/inventory/list',
      MATERIAL_LIST: '/hydra-intelligent-planting/valid/api/v1/material/inventory/in/material/list',
      MATERIAL_OUT_LIST: '/hydra-intelligent-planting/valid/api/v1/material/inventory/out/material/list',
      MATERIAL_SPEC_LIST: '/hydra-intelligent-planting/valid/api/v1/material/specification/list',
      ADD: '/hydra-intelligent-planting/valid/api/v1/material/inWarehouse/batch/in',
      DETAIL: '/hydra-intelligent-planting/valid/api/v1/material/inventory',
      OUT_IN_LIST: '/hydra-intelligent-planting/valid/api/v1/material/inventory/single/out-in/list',
      OUT_IN_DETAIL_LIST: '/hydra-intelligent-planting/valid/api/v1/material/inventory/single/out/list',
      WARN: '/hydra-intelligent-planting/valid/api/v1/material/inventory/warning',
      // 根据物料id获取该物料入库批次列表
      GET_IN_BATCH_BY_MATERIAL_ID: '/hydra-intelligent-planting/valid/api/v1/material/inventory/in/batch',
      ADD_OUT: '/hydra-intelligent-planting/valid/api/v1/material/outWarehouse/batch/out',
      // 校验物料是否符合出库条件
      CHECK_INVENTORY_OUT: '/hydra-intelligent-planting/valid/api/v1/material/inventory/check/out',
      // 切换物料规格获取相应规格库存
      OUT_MATERIAL_LIST: '/hydra-intelligent-planting/valid/api/v1/material/inventory/out/material/list'
    },
    category: {
      LIST: '/hydra-intelligent-planting/valid/api/v1/material/sort/list',
      ADD: '/hydra-intelligent-planting/valid/api/v1/material/sort',
      EDIT: '/hydra-intelligent-planting/valid/api/v1/material/sort',
      DETAIL: '/hydra-intelligent-planting/valid/api/v1/material/sort/',
      DIS_OR_ENABLE: '/hydra-intelligent-planting/valid/api/v1/material/sort/'
    },
    // 物料流水
    flow: {
      LIST: '/hydra-intelligent-planting/valid/api/v1/material/inventory/out-in/list'
    },
    GET_INVENTORY_LIST: '/hydra-base-data/api/v1/material/warehouse/productmanage/list',
    GET_IN_LIST: '/hydra-base-data/api/v1/material/warehouse/productmanage/in/material/list',
    GET_BATCH_LIST: '/hydra-base-data/api/v1/material/warehouse/productmanage/in/batch',
    GET_OUT_LIST: '/hydra-base-data/api/v1/material/warehouse/productmanage/out/material/list',
    DISABLE_MATERIAL: '/hydra-base-data/api/v1/material/productmanage/disable',
    ENABLE_MATERIAL: '/hydra-base-data/api/v1/material/productmanage/enable',
    GET_STOCK_LIST: '/hydra-base-data/api/v1/material/warehouse/productmanage/list',
    GET_OUT_IN_CODE: '/hydra-base-data/api/v1/material/warehouse/productmanage/code',
    GET_MATERIAL_SIZE: '/hydra-base-data/api/v1/material/specification/productmanage/list',
    ADD_MATERIAL_IN: '/hydra-base-data/api/v1/material/warehouse/productmanage/batch/in',
    ADD_MATERIAL_OUT: '/hydra-base-data/api/v1/material/warehouse/productmanage/batch/out',
    GET_MATERIAL_DETAILS_BYID: '/hydra-base-data/api/v1/material/warehouse/productmanage',
    SET_WARNING: '/hydra-base-data/api/v1/material/warehouse/productmanage/warning',
    GET_LIST_BATCH: '/hydra-base-data/api/v1/material/warehouse/productmanage/signle/out/list',
    GET_LIST_TURNOVER: '/hydra-base-data/api/v1/material/warehouse/productmanage/signle/out-in/list',
    GET_FLOWING_LIST: '/hydra-base-data/api/v1/material/warehouse/productmanage/out-in/list',
    GET_VERIFY: '/hydra-base-data/api/v1/material/warehouse/productmanage/check/out',
    GET_PLATE_BATCH: '/hydra-base-data/api/v1/runda/product-batch/dropPage',
    // 物料使用统计
    STATISTIC_LIST: '/hydra-base-data/api/v1/runda/productmanage/material/materialUseStatisics/list',
    // 导出物料使用统计
    EXPORT_STATISTIC_LIST: '/hydra-base-data/api/v1/runda/productmanage/material/materialUseStatisics/export',
    // 肥料登记号
    FERTILIZER_LIST: '/hydra-user/api/v1/user/fertilizer/select',
    // 农药登记号
    PESTICIDE_LIST: '/hydra-user/api/v1/user/pesticide/select',
    // 农药登记号、肥料登记号
    FERTILIZER_PESTICIDE_LIST: '/hydra-base-data/api/v1/input/catalog/manage/enable/list'
  },
  // 基础信息维护
  baseInfoAssert: {
    // 获取规格列表
    GET_LIST: '/hydra-base-data/api/v1/specification/list',
    // 新增/编辑/删除规格信息
    ADD: '/hydra-base-data/api/v1/specification',
    // 获取启用的产品
    GET_PRODUCT_LIST: '/hydra-base-data/api/v1/product/enable/list',
    // 获取产品规格信息列表
    GET_PRODUCT_SPEC_LIST: '/hydra-base-data/api/v1/specification/product/list',
    // 添加产品信息
    ADD_PRODUCT_SPEC: '/hydra-base-data/api/v1/specification/product/addProductInfo',
    // 编辑产品信息
    EDIT_PRODUCT_SPEC: '/hydra-base-data/api/v1/specification/product/updateProductInfo',
    // 删除产品信息
    DEL_PRODUCT_SPEC: '/hydra-base-data/api/v1/specification/product/delete',
    //获取产品规格信息列表
    GET_PEODUCT_SIZE: '/hydra-base-data/api/v1/specification/product/dropDown',
    //获取规格信息列表
    GET_SPEC_DROP: '/hydra-base-data/api/v1/specification/select/list',
  },
  // 单位设置
  setUnit: {
    GET_ORG_UNIT: '/hydra-intelligent-planting/valid/api/v1/dictionary/weight/unit',
    SET_ORG_UNIT: '/hydra-intelligent-planting/valid/api/v1/dictionary/weight/unit',
    // 获取产量取值
    GET_PRODUCTION: '/hydra-intelligent-planting/valid/api/v1/dictionary/yield/list',
    // 设置产量取值
    SET_PRODUCTION: '/hydra-intelligent-planting/valid/api/v1/dictionary/yield/update',
    // 获取首页图片缩放比例
    GET_IMG_RATE: '/hydra-intelligent-planting/valid/api/v1/dictionary/system-setting/get',
    // 设置首页图片缩放比例
    SET_IMG_RATE: '/hydra-intelligent-planting/valid/api/v1/dictionary/system-setting/update'
  },
  // 产品规格维护
  proSpecification: {
    LIST: '/hydra-intelligent-planting/valid/api/v1/product/specification/list',
    ADD: '/hydra-intelligent-planting/valid/api/v1/product/specification',
    EDIT: '/hydra-intelligent-planting/valid/api/v1/product/specification',
    ENABLE_OR_DISABLE: '/hydra-intelligent-planting/valid/api/v1/product/specification/status',
    DETAIL: '/hydra-intelligent-planting/valid/api/v1/product/specification'
  },
  // 电子秤维护
  maintenanceElectronicScale: {
    LIST: '/hydra-intelligent-planting/valid/api/v1/electronic-scale/maintain/list'
  },
  // 基地地图配置
  mapConfig: {
    // 获取列表
    LIST: '/hydra-base-data/api/v1/base/map/base-map/list',
    // 添加
    ADD: '/hydra-base-data/api/v1/base/map/base-map',
    // 编辑
    UPDATE: '/hydra-base-data/api/v1/base/map/base-map/update',
    // 详情
    DETAIL: '/hydra-base-data/api/v1/base/map/base-map/detail',
    // 删除
    DELETE: '/hydra-base-data/api/v1/base/map/base-map/',
    // 获取平台组织列表
    GET_ORG_LIST: '/hydra-user/api/v1/org/list/platform',
    // gis地图搜索
    SEARCH_GIS: '/hydra-code-egis/api/v1/geoTools/poi-search-autonavi',
    // 获取组织下的设备列表
    GET_DEVICE_LIST: '/iotdevice/notoken/listByOrgId',
    // 获取平台列表
    GET_IOT_PLATFORM_LIST: '/iotdevice/notoken/monitor/listByOrgId',
    // 获取监控点
    GET_MONITOR_POINTER_LIST: '/iotdevice/notoken/monitor/listDevice'
  }
}
