export default {
  common: {
    // 系统员工
    USER_LIST: '/hydra-user/api/v1/employee/list'
  },
  // 用户管理
  userData: {
    LIST: '/cx-social-production/api/user/list',
    EXPORT: '/cx-social-production/api/user/export'
  },
  // 农工管理
  agriculturalManagement: {
    LIST: '/cx-social-production/api/agricultural/worker/list',
    EXPORT: '/cx-social-production/api/agricultural/worker/export',
    // 增删改查
    MSG: '/cx-social-production/api/agricultural/worker'
  },
  // 发布用工服务
  releaseEmployService: {
    LIST: '/cx-social-production/api/employment/service/list',
    EXPORT: '/cx-social-production/api/employment/service/export',
    // 增删改查
    MSG: '/cx-social-production/api/employment/service',
    SUPPLY_COMPLETE: '/cx-social-production/api/employment/service/finish'
  },
  // 用工服务订单
  serveOrder: {
    LIST: '/cx-social-production/api/employment/service/order/list',
    EXPORT: '/cx-social-production/api/employment/service/order/export',
    VIEW: '/cx-social-production/api/employment/service/order',
    ACCEPT: '/cx-social-production/api/employment/service/order/accept',
    REJECT: '/cx-social-production/api/employment/service/order/un-accept',
    UPLOAD: '/cx-social-production/api/employment/service/order/contract',
    CANCEL: '/cx-social-production/api/employment/service/order/cancel',
    FINISH: '/cx-social-production/api/employment/service/order/finished'
  },
  // 流水记录
  flowRecord: {
    LIST: '/cx-social-production/api/pay/order/flow/list'
  },
  // 用工需求
  laborDemand: {
    LIST: '/cx-social-production/api/labor/demand/list',
    VIEW: '/cx-social-production/api/labor/demand',
    ACCEPT: '/cx-social-production/api/labor/demand/accept'
  },
  // 农机
  agriculturalMachinery: {
    type: {
      LIST: '/cx-social-production/api/agricultural-machinery-classify/list',
      CRUD: '/cx-social-production/api/agricultural-machinery-classify',
      DISABLE: '/cx-social-production/api/agricultural-machinery-classify/disable',
      ENABLE: '/cx-social-production/api/agricultural-machinery-classify/enable'
    },
    management: {
      LIST: '/cx-social-production/api/agricultural-machinery/list',
      CRUD: '/cx-social-production/api/agricultural-machinery',
      DISABLE: '/cx-social-production/api/agricultural-machinery/disable',
      ENABLE: '/cx-social-production/api/agricultural-machinery/enable',
      EXPORT: '/cx-social-production/api/agricultural-machinery/export'
    },
    publish: {
      LIST: '/cx-social-production/api/agricultural-machinery-service/list',
      CRUD: '/cx-social-production/api/agricultural-machinery-service',
      EXPORT: '/cx-social-production/api/agricultural-machinery-service/export',
      ON: '/cx-social-production/api/agricultural-machinery-service/on-shelf',
      OFF: '/cx-social-production/api/agricultural-machinery-service/off-shelf'
    },
    order: {
      LIST: '/cx-social-production/api/agricultural-machinery-order/list',
      DETAIL: '/cx-social-production/api/agricultural-machinery-order/detail',
      UPLOAD: '/cx-social-production/api/agricultural-machinery-order/contract',
      EXPORT: '/cx-social-production/api/agricultural-machinery-order/export',
      BACK_FEE: '/cx-social-production/api/agricultural-machinery-order/refund',
      FINISH: '/cx-social-production/api/agricultural-machinery-order/finished',
      REJECT: '/cx-social-production/api/agricultural-machinery-order/un-accept',
      ACCEPT: '/cx-social-production/api/agricultural-machinery-order/accept'
    },
    stock: {
      LIST: '/cx-social-production/api/agricultural-machinery-use/list',
      DETAIL: '/cx-social-production/api/agricultural-machinery-use'
    }
  },
  // 招募工人
  recruitment: {
    management: {
      LIST: '/cx-social-production/api/recruit-workers/list',
      CRUD: '/cx-social-production/api/recruit-workers',
      ON: '/cx-social-production/api/recruit-workers/on-shelf',
      OFF: '/cx-social-production/api/recruit-workers/off-shelf'
    },
    workerRegistration: {
      LIST: '/cx-social-production/api/recruit-sign-up/list',
      EXPORT: '/cx-social-production/api/recruit-sign-up/export'
    }
  },
  // 涉农主体
  agriculturalSubject: {
    management: {
      LIST: '/cx-social-production/api/principal-part/list',
      MAINTAIN: '/cx-social-production/api/principal-part/maintain',
      IMPORT: '/cx-social-production/api/principal-part/import',
      EXPORT: '/cx-social-production/api/principal-part/export',
      DELETE: '/cx-social-production/api/principal-part',
      REMOVE: '/cx-social-production/api/principal-part/dismiss'
    },
    staffing: {
      LIST: '/cx-social-production/api/auditors-config/list',
      ADD: '/cx-social-production/api/auditors-config',
      DELETE: '/cx-social-production/api/auditors-config',
      // 乡镇街道
      TOWNSHIP: '/cx-social-production/api/auditors-config/township-list'
    },
    certification: {
      LIST: '/cx-social-production/api/principal-part-certification/list',
      UN_PASS: '/cx-social-production/api/principal-part-certification/un-pass',
      PASS: '/cx-social-production/api/principal-part-certification/pass'
    }
  }
};
