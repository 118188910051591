export default {
  channel: {
    // LIST: '/hydra-user/api/v1/business/channel',
    // 渠道管理
    LIST: '/hydra-cjm-cloud-portal/api/v1/platform/open/channel/page',
    OPEN: '/hydra-user/api/v1/org/open/channel-org',
    CHOOSE_SYS: '/hydra-user/api/v1/sys/business/channel/list',
    
    // SEND_CODE_LIST: '/hydra-user/api/v1/atp-org-package-fun/handsel/page',
    // SEND_CODE: '/hydra-user/api/v1/atp-org-package-fun/handsel',
    // 渠道码管理
    GIVE_PAGE:"/hydra-cjm-cloud-portal/api/v1/platform/code/give/page",
    PRODUCT_CODE:"/hydra-cjm-cloud-portal/api/v1/platform/product/code",
    // 发放/赠送码量
    CODE_GIVE:"/hydra-cjm-cloud-portal/api/v1/platform/code/give",
    // 变更有效期
    CHANGE_TIME: '/hydra-cjm-cloud-portal/api/v1/platform/renewal/sys/org/time/validity',
    // 开通企业列表弹框
    CHANNEL_INFO:'/hydra-cjm-cloud-portal/api/v1/platform/open/channel/org/page',
    //打款验证记录
    // PLAY_VERIFY: '/hydra-user/api/v1/atp-org-auth/transfer/page',
    PLAY_VERIFY: '/hydra-cjm-cloud-portal/api/v1/platform/auth/transfer/page',
    //开通收费记录
    OPEN_CHARGE_OLD: '/hydra-user/api/v1/atp-open-org/charge/page',
    OPEN_CHARGE: '/hydra-cjm-cloud-portal/api/v1/platform/open/page',
    // 续费收费记录
    RENEWAL_PAGE:"/hydra-cjm-cloud-portal/api/v1/platform/renewal/page",
    //开通管理列表
    // OPEN_ADMIN_LIST: '/hydra-user/api/v1/atp-open-org/manager/page',
    OPEN_ADMIN_LIST: '/hydra-cjm-cloud-portal/api/v1/platform/auth/manager/page',
    // 企业付费功能收费记录分页列表，
    ORG_FUN_LIST: '/hydra-cjm-cloud-portal/api/v1/platform/sub-org/buy/page',
    // 生码量购买记录
    CODE_BUY_PAGE:"/hydra-cjm-cloud-portal/api/v1/platform/code/buy/page",
    // 开票记录
    // INVOICE_PAGE: '/hydra-user/api/v1/atp-org-invoice/page',
    INVOICE_PAGE:'/hydra-cjm-cloud-portal/api/v1/platform/invoice/page',
    // 确认开票
    // INVOICE_AGREE: '/hydra-user/api/v1/atp-org-invoice/agree',
    INVOICE_AGREE:'/hydra-cjm-cloud-portal/api/v1/platform/invoice/agree',
    // 发货
    // INVOICE_SHIP: '/hydra-user/api/v1/atp-org-invoice/ship',
    INVOICE_SHIP: '/hydra-cjm-cloud-portal/api/v1/platform/invoice/ship',
    // 试用客户分页数据
    TRIAL_PAGE:'/hydra-user/api/v1/atp-free-trial/page'
  },
  open: {
    //企业认证审核通过
    // OPEN_APPROVED: '/hydra-user/api/v1/atp-org-auth/pass'
    OPEN_APPROVED: '/hydra-cjm-cloud-portal/api/v1/platform/auth/pass'
  },
  carousel:{
    // 轮播图列表
    //  CAROUSEL_LIST:'/hydra-user/api/v1/atp-carousel/list',
     CAROUSEL_LIST:'/hydra-cjm-cloud-portal/api/v1/platform/carousel/list',
    //  发布轮播图
    // CAROUSEL_ADD:'/hydra-user/api/v1/atp-carousel/add',
    CAROUSEL_ADD:'/hydra-cjm-cloud-portal/api/v1/platform/carousel/add',
    // 轮播图上下架
    // CAROUSEL_UPDATE:'/hydra-user/api/v1/atp-carousel/update',
    CAROUSEL_UPDATE:'/hydra-cjm-cloud-portal/api/v1/platform/carousel/update',
  },
  action:{
    // 查询活动列表
    // ACTIVITY_LIST:'/hydra-user/api/v1/atp-activity/list',
    ACTIVITY_LIST:'/hydra-cjm-cloud-portal/api/v1/platform/activity/list',
    // 发布活动
    // ACTIVITY_ADD:'/hydra-user/api/v1/atp-activity/add',
    ACTIVITY_ADD:'/hydra-cjm-cloud-portal/api/v1/platform/activity/add',
    // 删除活动
    // ACTIVITY_DELETE:'/hydra-user/api/v1/atp-activity/delete',
    ACTIVITY_DELETE:'/hydra-cjm-cloud-portal/api/v1/platform/activity/delete',
    // 查询活动详情
    // ACTIVITY_QUERY:'/hydra-user/api/v1/atp-activity/queryOne',
    ACTIVITY_QUERY:'/hydra-cjm-cloud-portal/api/v1/platform/activity/detail',
    // 编辑活动
    ACTIVITY_UPDATE:"/hydra-cjm-cloud-portal/api/v1/platform/activity/update"
  },
  dropList:{
    // 下拉列表
    SELECT_LIST:'/hydra-user/api/v1/atp-package/select/list'
  },


  couponList:{
    // 企业/优惠券列表
    // COUPON_PAGE:'/hydra-user/api/v1/atp-coupon/page',
    // // 发放企业/优惠券
    // COUPON_ADD:"/hydra-user/api/v1/atp-coupon/add",
    // 修改状态
    // EDIT_STATUS:"/hydra-user/api/v1/atp-coupon/edit/status",
    // 获取对应类型的商品信息
    // GET_GOODS_LIST:"/hydra-user/api/v1/atp-goods/list",
    GET_GOODS_LIST:"/hydra-cjm-cloud-portal/api/v1/platform/product/package",
    // 标记测试组织
    ORG_TYPE:"/hydra-user/api/v1/atp-platform/edit/org-usage-type",
    // 获取平台组织列表
    LIST_PLATFORM:"/hydra-user/api/v1/org/list/platform",

    // 企业券列表
    ORG_PAGE:"/hydra-cjm-cloud-portal/api/v1/platform/coupon/org/page",
    // 折扣券列表
    DISCOUNT_PAGE:"/hydra-cjm-cloud-portal/api/v1/platform/coupon/discount/page",
    // 添加优惠券
    COUPON_ADD:"/hydra-cjm-cloud-portal/api/v1/platform/coupon/add",
     // 修改状态
    EDIT_STATUS:"/hydra-cjm-cloud-portal/api/v1/platform/coupon/edit/status",
  },

  // 列表及导出功能
  platformList:{
     // 试用客户分页数据
     TRIAL_PAGE:'/hydra-cjm-cloud-portal/api/v1/platform/free-trial/page'
  },
  // 导出功能
  export:{
    // 付费功能
    EXPORT_BUYFUN:"/hydra-user/api/v1/atp-export/buy-fun-log",
    // 试用客户
    // EXPORT_FREE_TRIAL:"/hydra-user/api/v1/atp-export/free-trial",
    EXPORT_FREE_TRIAL:"/hydra-cjm-cloud-portal/api/v1/platform/free-trial/export",
    // 开通管理
    EXPORT_OPEN:"/hydra-user/api/v1/atp-export/open-org-manager",
    // 开票列表
    // EXPORT_INVOICE:"/hydra-user/api/v1/atp-export/org-invoice",
    EXPORT_INVOICE:"/hydra-cjm-cloud-portal/api/v1/platform/invoice/export",
    // 打款验证
    // EXPORT_TRANSFER:"/hydra-user/api/v1/atp-export/org-transfer",
    EXPORT_TRANSFER:"/hydra-cjm-cloud-portal/api/v1/platform/auth/export/transfer",
    // 开通收费
    EXPORT_OPEN:"/hydra-cjm-cloud-portal/api/v1/platform/open/export",
    //开通管理
    EXPORT_AUTH:"/hydra-cjm-cloud-portal/api/v1/platform/auth/export",
    // 生码量购买记录
    EXPORT_CODE:"/hydra-cjm-cloud-portal/api/v1/platform/code/export",
    // 续费
    EXPORT_RENEWAL:"/hydra-cjm-cloud-portal/api/v1/platform/renewal/export",
    // 企业开通购买记录
    EXPORT_SUBORG:"/hydra-cjm-cloud-portal/api/v1/platform/sub-org/export",
    // 企业开通，续费收费
    EXPORT_CHARGE:"/hydra-user/api/v1/atp-export/package-charge"
  }
};