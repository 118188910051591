import dynamic from 'dva/dynamic';

export default [
  //--------------------------------
  {
    path: '/code/code/setting',
    component: dynamic({
      component: () =>
        import(/* webpackChunkName: "modules/code/views/code/setting" */ './views/code/setting'),
    }),
  },
  //---------------------------------
  {
    path: '/code/object/list',
    component: dynamic({
      component: () =>
        import(/* webpackChunkName: "modules/code/views/object/list" */ './views/object/list'),
    }),
  },
  // 申请记录
  {
    path: '/code/apply/list',
    component: dynamic({
      component: () =>
        import(/* webpackChunkName: "modules/code/views/object/list" */ './views/apply/list'),
    }),
  },
  // 审核
  {
    path: '/code/apply/audit',
    component: dynamic({
      component: () =>
        import(/* webpackChunkName: "modules/code/views/object/list" */ './views/apply/audit'),
    }),
  },
  // 码申请
  {
    path: '/code/apply/applycode',
    component: dynamic({
      component: () =>
        import(/* webpackChunkName: "modules/code/views/object/list" */ './views/apply/applycode'),
    }),
  },
  // 申请记录详情
  {
    path: '/code/apply/detail',
    component: dynamic({
      component: () =>
        import(/* webpackChunkName: "modules/code/views/object/list" */ './views/apply/detail'),
    }),
  },
  // 申请记录复制
  {
    path: '/code/apply/copy',
    component: dynamic({
      component: () =>
        import(/* webpackChunkName: "modules/code/views/object/list" */ './views/apply/copy'),
    }),
  },
  // 申请记录编辑
  {
    path: '/code/apply/edit',
    component: dynamic({
      component: () =>
        import(/* webpackChunkName: "modules/code/views/object/list" */ './views/apply/edit'),
    }),
  },
  // 防伪设置
  {
    path: '/code/apply/antiFake',
    component: dynamic({
      component: () =>
        import(/* webpackChunkName: "modules/code/views/object/list" */ './views/apply/antiFake'),
    }),
  },
  // 工厂码激活
  {
    path: '/code/apply/facactivate',
    component: dynamic({
      component: () =>
        import(/* webpackChunkName: "modules/code/views/object/list" */ './views/apply/activate/facactive'),
    }),
  },
  // 甲骨文码激活
  {
    path: '/code/apply/jgwactive',
    component: dynamic({
      component: () =>
        import(/* webpackChunkName: "modules/code/views/object/list" */ './views/apply/activate/jgwactive'),
    }),
  },
  // 签收单审核
  {
    path: '/code/identifying/signature',
    component: dynamic({
      component: () =>
        import(/* webpackChunkName: "modules/code/views/object/list" */ './views/identifying/signature'),
    }),
  },
  // 标识码作废
  {
    path: '/code/invalid/list',
    component: dynamic({
      component: () =>
        import(/* webpackChunkName: "modules/code/views/object/list" */ './views/invalid/list'),
    }),
  },
  // 标识管理--数据下载
  {
    path: '/code/identifying/download',
    component: dynamic({
      component: () =>
        import(/* webpackChunkName: "modules/code/views/object/list" */ './views/identifying/download'),
    }),
  },
  // 下载记录
  {
    path: '/code/identifying/downloadRecord',
    component: dynamic({
      component: () =>
        import(/* webpackChunkName: "modules/code/views/object/list" */ './views/identifying/downloadRecord'),
    }),
  },
  // 下載申請
  {
    path: '/code/identifying/downloadApply',
    component: dynamic({
      component: () =>
        import(/* webpackChunkName: "modules/code/views/object/list" */ './views/identifying/downloadApply'),
    }),
  },
  // 待发货
  {
    path: '/code/order/undelivered',
    component: dynamic({
      component: () =>
        import(/* webpackChunkName: "modules/code/views/object/list" */ './views/order/undelivered'),
    }),
  },
  // 工厂——已签收
  {
    path: '/code/order/signed',
    component: dynamic({
      component: () =>
        import(/* webpackChunkName: "modules/code/views/object/list" */ './views/order/signed'),
    }),
  },
  // 工厂——未签收
  {
    path: '/code/order/unsign',
    component: dynamic({
      component: () =>
        import(/* webpackChunkName: "modules/code/views/object/list" */ './views/order/unsign'),
    }),
  },
  // 标识订单已签收
  {
    path: '/code/cusorder/cusign',
    component: dynamic({
      component: () =>
        import(/* webpackChunkName: "modules/code/views/object/list" */ './views/cusorder/cusign'),
    }),
  },
  // 标识订单未签收
  {
    path: '/code/cusorder/cunsign',
    component: dynamic({
      component: () =>
        import(/* webpackChunkName: "modules/code/views/object/list" */ './views/cusorder/cunsign'),
    }),
  },
  // 签收单审核——发货件数详情
  {
    path: '/code/signature/codedetail/:id',
    component: dynamic({
      component: () =>
        import(/* webpackChunkName: "modules/code/views/object/list" */ './views/identifying/signature/codedetail/index'),
    }),
  },
  // 订单——发货详情
  {
    path: '/code/order/history',
    component: dynamic({
      component: () =>
        import(/* webpackChunkName: "modules/code/views/object/list" */ './views/order/history'),
    }),
  },
  // 标识激活列表
  {
    path: '/code/sysIdentify',
    component: dynamic({
      component: () =>
        import(/* webpackChunkName: "modules/code/views/object/list" */ './views/sysIdentify/index'),
    }),
  },
  //标识激活详情
  {
    path: '/code/sysIdentify/detail/:localBatch',
    component: dynamic({
      component: () =>
        import(/* webpackChunkName: "modules/code/views/object/list" */ './views/sysIdentify/detail'),
    }),
  },

  // 码关联记录
  {
    path: '/code/relevance',
    component: dynamic({
      component: () =>
        import(/* webpackChunkName: "modules/code/views/object/list" */ './views/relevance/index'),
    }),
  },
  // 添加码关联
  {
    path: '/code/addrelation',
    component: dynamic({
      component: () =>
        import(/* webpackChunkName: "modules/code/views/object/list" */ './views/relevance/addrelation/index'),
    }),
  },
  // 三江添加码关联
  {
    path: '/code/addrelationSJ',
    component: dynamic({
      component: () =>
        import(/* webpackChunkName: "modules/code/views/object/list" */ './views/relevance/addrelation/sj/index'),
    }),
  },
  // 码关联详情
  {
    path: '/code/relevanceDetail',
    component: dynamic({
      component: () =>
        import(/* webpackChunkName: "modules/code/views/object/list" */ './views/relevance/detail/index'),
    }),
  },
  // 分配工厂
  // {
  //   path: '/code/distrufactory',
  //   component: dynamic({
  //     component: () =>
  //       import(/* webpackChunkName: "modules/code/views/object/list" */ './views/apply/factory/distrufactory/index'),
  //   }),
  // },
  // 审核不通过
  {
    path: '/code/distrurefused',
    component: dynamic({
      component: () =>
        import(/* webpackChunkName: "modules/code/views/object/list" */ './views/apply/audit/distrurefused'),
    }),
  },
  // 派技术
  {
    path: '/code/apply/pai',
    component: dynamic({
      component: () =>
        import(/* webpackChunkName: "modules/code/views/apply/pai" */ './views/apply/pai'),
    }),
  },
  // 标识申请
  {
    path: '/code/distribution/application',
    component: dynamic({
      component: () =>
        import(/* webpackChunkName: "modules/code/views/distribution/application" */ './views/distribution/application'),
    }),
  },
  // 标识申请记录
  {
    path: '/code/distribution/applicationList',
    component: dynamic({
      component: () =>
        import(/* webpackChunkName: "modules/code/views/distribution/applicationList" */ './views/distribution/applicationList'),
    }),
  },
  // 标识分配列表
  {
    path: '/code/distribution/identificationList',
    component: dynamic({
      component: () =>
        import(/* webpackChunkName: "modules/code/views/distribution/identificationList" */ './views/distribution/identificationList'),
    }),
  },
  // 标识分配
  {
    path: '/code/distribution/identification',
    component: dynamic({
      component: () =>
        import(/* webpackChunkName: "modules/code/views/distribution/identification" */ './views/distribution/identification'),
    }),
  },
  // 分配记录
  // {
  //   path: '/code/distribution/identificationRecord/:id',
  //   component: dynamic({
  //     component: () =>
  //       import(/* webpackChunkName: "modules/code/views/distribution/identificationRecord" */ './views/distribution/identificationRecord'),
  //   }),
  // },

  /** ******************* 包装关联管理---码管理 *********************** */
  // 包装关联
  {
    path: '/code/package/pack',
    component: dynamic({ component: () => import(/* webpackChunkName: "modules/integralMarketing/views/vipIntegral/vip" */ './views/package/pack') }),
  },
  // 拆解关联
  {
    path: '/code/package/split',
    component: dynamic({ component: () => import(/* webpackChunkName: "modules/integralMarketing/views/vipIntegral/vip" */ './views/package/split') }),
  },
  // 关联查询
  {
    path: '/code/package/search',
    component: dynamic({ component: () => import(/* webpackChunkName: "modules/integralMarketing/views/vipIntegral/vip" */ './views/package/search') }),
  },
  /** *******************错误失败日志 *********************** */
  //错误失败日志
  {
    path: '/code/errorLog/list',
    component: dynamic({ component: () => import(/* webpackChunkName: "modules/integralMarketing/views/errorLog/list" */ './views/package/errorLog/list') }),
  },
  //码查询工具
  {
    path: '/code/codeSearchTool',
    component: dynamic({ component: () => import(/* webpackChunkName: "modules/integralMarketing/views/errorLog/list" */ './views/codeSearchTool') }),
  },
  /** ******************* 码生命周期区块链 *********************** */
  //
  {
    path: '/code/lifeBlock/list',
    component: dynamic({ component: () => import(/* webpackChunkName: "modules/integralMarketing/views/errorLog/list" */ './views/codeLifeBlock/list') }),
  },
  {
    path: '/code/lifeBlock/detail',
    component: dynamic({ component: () => import(/* webpackChunkName: "modules/integralMarketing/views/errorLog/list" */ './views/codeLifeBlock/detail') }),
  },
  {
    path: '/code/codeAssociated',
    component: dynamic({
      component: () =>
        import(/* webpackChunkName: "modules/code/views/distribution/identification" */ './views/codeAssociated/record'),
    }),
  },
  {
    path: '/code/codePersonalAssociated',
    component: dynamic({
      component: () =>
        import(/* webpackChunkName: "modules/code/views/distribution/identification" */ './views/codePersonalAssociated/record'),
    }),
  },
  {
    path: '/code/codeAlloccationRecord',
    component: dynamic({
      component: () =>
        import(/* webpackChunkName: "modules/code/views/distribution/identification" */ './views/codeAssociated/warehouse'),
    }),
  },
  //班组管理（万生堂）
  {
    path: '/code/wanShengTang/teamMange',
    component: dynamic({ component: () => import(/* webpackChunkName: "modules/integralMarketing/views/errorLog/list" */ './views/wanShengTang/teamMange') }),
  },
  //工单管理（万生堂）
  {
    path: '/code/wanShengTang/workOrder',
    component: dynamic({ component: () => import(/* webpackChunkName: "modules/integralMarketing/views/errorLog/list" */ './views/wanShengTang/workOrder') }),
  },
  //新增工单管理（万生堂）
  {
    path: '/code/wanShengTang/workOrderAdd',
    component: dynamic({ component: () => import(/* webpackChunkName: "modules/integralMarketing/views/errorLog/list" */ './views/wanShengTang/workOrder/add.js') }),
  },
  //编辑工单管理（万生堂）
  {
    path: '/code/wanShengTang/workOrderEdit',
    component: dynamic({ component: () => import(/* webpackChunkName: "modules/integralMarketing/views/errorLog/list" */ './views/wanShengTang/workOrder/add.js') }),
  },
  //工单管理审核（万生堂）
  {
    path: '/code/wanShengTang/workOrderReview',
    component: dynamic({ component: () => import(/* webpackChunkName: "modules/integralMarketing/views/errorLog/list" */ './views/wanShengTang/workOrder/review.js') }),
  },
  //工单管理详情（万生堂）
  {
    path: '/code/wanShengTang/workOrderDetails',
    component: dynamic({ component: () => import(/* webpackChunkName: "modules/integralMarketing/views/errorLog/list" */ './views/wanShengTang/workOrder/details.js') }),
  },
  //产线管理（万生堂）
  {
    path: '/code/wanShengTang/productionLineManage',
    component: dynamic({ component: () => import(/* webpackChunkName: "modules/integralMarketing/views/errorLog/list" */ './views/wanShengTang/productionLineManage') }),
  },
  //出库单（万生堂）
  {
    path: '/code/wanShengTang/outboundOrder',
    component: dynamic({ component: () => import(/* webpackChunkName: "modules/integralMarketing/views/errorLog/list" */ './views/wanShengTang/outboundOrder') }),
  },
  //出库单--单据详情（万生堂）
  {
    path: '/code/wanShengTang/outboundOrderDetails',
    component: dynamic({ component: () => import(/* webpackChunkName: "modules/integralMarketing/views/errorLog/list" */ './views/wanShengTang/outboundOrder/details') }),
  },
  //分布式调出单（万生堂）
  {
    path: '/code/wanShengTang/distributedCallOut',
    component: dynamic({ component: () => import(/* webpackChunkName: "modules/integralMarketing/views/errorLog/list" */ './views/wanShengTang/distributedCallOut') }),
  },
  //分布式调出单--查看（万生堂）
  {
    path: '/code/wanShengTang/distributedCallOutDetails',
    component: dynamic({ component: () => import(/* webpackChunkName: "modules/integralMarketing/views/errorLog/list" */ './views/wanShengTang/distributedCallOut/details') }),
  },
  //新增分布式调出单（万生堂）
  {
    path: '/code/wanShengTang/distributedCallOutAdd',
    component: dynamic({ component: () => import(/* webpackChunkName: "modules/integralMarketing/views/errorLog/list" */ './views/wanShengTang/distributedCallOut/add.js') }),
  },
  //分布式调入单（万生堂）
  {
    path: '/code/wanShengTang/distributedTransferOrder',
    component: dynamic({ component: () => import(/* webpackChunkName: "modules/integralMarketing/views/errorLog/list" */ './views/wanShengTang/distributedTransferOrder') }),
  },
  //分布式调入单--查看（万生堂）
  {
    path: '/code/wanShengTang/distributedTransferOrderDetails',
    component: dynamic({ component: () => import(/* webpackChunkName: "modules/integralMarketing/views/errorLog/list" */ './views/wanShengTang/distributedTransferOrder/details') }),
  },
  //新增分布式调入单（万生堂）
  {
    path: '/code/wanShengTang/distributedTransferOrderAdd',
    component: dynamic({ component: () => import(/* webpackChunkName: "modules/integralMarketing/views/errorLog/list" */ './views/wanShengTang/distributedTransferOrder/add.js') }),
  },
  //退货单（万生堂）
  {
    path: '/code/wanShengTang/returnForm',
    component: dynamic({ component: () => import(/* webpackChunkName: "modules/integralMarketing/views/errorLog/list" */ './views/wanShengTang/returnForm') }),
  },
  //退货单--查看（万生堂）
  {
    path: '/code/wanShengTang/returnFormDetails',
    component: dynamic({ component: () => import(/* webpackChunkName: "modules/integralMarketing/views/errorLog/list" */ './views/wanShengTang/returnForm/details') }),
  },
  //新增退货单（万生堂）
  {
    path: '/code/wanShengTang/returnFormAdd',
    component: dynamic({ component: () => import(/* webpackChunkName: "modules/integralMarketing/views/errorLog/list" */ './views/wanShengTang/returnForm/add.js') }),
  },
  /************************扩展码申请*************** */
  {
    path: '/code/extendedCode/applyFor',
    component: dynamic({ component: () => import(/* webpackChunkName: "modules/integralMarketing/views/" */ './views/extendedCode/applyFor') }),
  },
  {
    path: '/code/extendedCode/applyForAdd',
    component: dynamic({ component: () => import(/* webpackChunkName: "modules/integralMarketing/views/t" */ './views/extendedCode/applyFor/add') }),
  },
  {
    path: '/code/extendedCode/applyForAudit',
    component: dynamic({ component: () => import(/* webpackChunkName: "modules/integralMarketing/views/" */ './views/extendedCode/applyFor/audit') }),
  },
  {
    path: '/code/extendedCode/applyForDetail',
    component: dynamic({ component: () => import(/* webpackChunkName: "modules/integralMarketing/views/" */ './views/extendedCode/applyFor/detail') }),
  },
];
