import createApp from 'framework/createApp';
import {setConfig} from 'framework/siteConfig';
import {addClass, removeClass} from 'framework/utils/dom';
import React from 'react';
import ReactDOM from 'react-dom';
import {Spin} from 'antd';
import routeMainComponent from './routes';
import api from './config/api';
import init from './init';
import routerConfig from './routes/routerConfig';
import HeaderConfigView from 'system/layouts/HeaderConfigView';
setConfig('HeaderConfigView', HeaderConfigView);
setConfig('routeMainComponent', routeMainComponent);
setConfig('routerConfig', routerConfig);
setConfig('apiUrl', api);
setConfig('source', 'system');

ReactDOM.render(<Spin size="large"/>, document.getElementById('cjm-loading'));

const app = createApp({
  beforeStart: async (startApp) => {
    addClass(document.body, 'cjm-loading-body');
    await init();
    removeClass(document.body, 'cjm-loading-body');
    startApp();
  }
});
export default app._store;
