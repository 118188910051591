import dataOverview from './dataOverviewApi';

export default {
  dataOverview,
  // 基础信息维护
  baseInfoAssert: {
    // 获取规格列表
    GET_LIST: '/hydra-base-data/api/v1/specification/list',
    // 新增/编辑/删除规格信息
    ADD: '/hydra-base-data/api/v1/specification',
    // 获取启用的产品
    GET_PRODUCT_LIST: '/hydra-base-data/api/v1/product/enable/list',
    // 获取产品规格信息列表
    GET_PRODUCT_SPEC_LIST: '/hydra-base-data/api/v1/specification/product/list',
    // 添加产品信息
    ADD_PRODUCT_SPEC: '/hydra-base-data/api/v1/specification/product/addProductInfo',
    // 编辑产品信息
    EDIT_PRODUCT_SPEC: '/hydra-base-data/api/v1/specification/product/updateProductInfo',
    // 删除产品信息
    DEL_PRODUCT_SPEC: '/hydra-base-data/api/v1/specification/product/delete',
    //获取产品规格信息列表
    GET_PEODUCT_SIZE: '/hydra-base-data/api/v1/specification/product/dropDown',
    //获取规格信息列表
    GET_SPEC_DROP: '/hydra-base-data/api/v1/specification/select/list',
  },
  // 生产管理
  production: {
    // 当前种植
    currentPlant: {
      // 列表
      LIST: '/trace/farming/planting/batchinfo/listPlantingBatch',
      // 获取种植批次生产档案详情
      GET_PLANT_BATCH_INFO: '/trace/farming/planting/batchinfo/getPlantingBatchInfo',
      // 分页获取种植批次生产档案详情
      GET_PLANT_BATCH_LIST: '/trace/farming/planting/batchinfo/getPlantingBatchInfoList',
      // 导出列表
      EXPORT_LIST : '/trace/farming/planting/batchinfo/exportPlantingBatch',
      // 导出
      EXPORT: '/trace/farming/planting/batchinfo/exportPlantingBatchInfo',
      // 导出生产档案详情
      EXPORT_DETAIL: '/trace/farming/planting/batchinfo/exportPlantingBatchDetails',
      // 导出生产成本投入
      EXPORT_COST: '/trace/farming/planting/batchinfo/exportPlantingBatchCostNoDamaged'
    },
    // 生产档案
    productionFiles: {
      // 生产档案
      file: {
        // 列表
        LIST: '/trace/farming/planting/batchinfo/listEndBatch',
        // 获取采收批次生产档案详情
        GET_BATCH_INFO: '/trace/farming/planting/batchinfo/getEndBatchInfo',
        // 分页获取采收批次生产档案详情
        GET_BATCH_PAGE_LIST: '/trace/farming/planting/batchinfo/getEndBatchInfoList',
        // 获取生产成本投入
        GET_BATCH_COST_LIST: '/trace/farming/planting/batchinfo/getBatchCostStatistics',
        // 导出列表
        EXPORT_LIST : '/trace/farming/planting/batchinfo/exportEndBatch',
        // 导出生产档案图
        EXPORT: '/trace/farming/planting/batchinfo/exportEndBatchInfo',
        // 导出生产档案详情
        EXPORT_DETAIL: '/trace/farming/planting/batchinfo/exportEndBatchDetails',
        // 导出生产成本投入
        EXPORT_COST: '/trace/farming/planting/batchinfo/exportEndBatchCostNoDamaged',
        // 保存用工总投入信息
        SAVE_WORKING_PRICE: '/trace/farming/planting/batchinfo/saveWorkingPrice'
      },
      // 标准化生产方案
      plan: {
        // 列表
        LIST: '/gugeng/sd/produce/scheme/page',
        // 新增标准化计划方案
        ADD: '/gugeng/sd/produce/scheme/add',
        // 删除
        DELETE: '/gugeng/sd/produce/scheme/delete',
        // 方案详细信息
        SCHEME_DETAIL: '/gugeng/sd/produce/scheme/detail',
        // 标准化方案节点分页列表
        NODE_PAGE_LIST: '/gugeng/sd/produce/scheme/node/page',
        // 工作日节点详细信息
        SCHEME_NODE_DETAIL: '/gugeng/sd/produce/scheme/node/detail',
        // 修改标准化计划方案
        EDIT: '/gugeng/sd/produce/scheme/update',
        // 导出
        EXPORT: '/gugeng/sd/produce/scheme/exportList'
      },
    },
    // 生产计划
    productionPlan: {
      // 销售任务
      task: {
        LIST: '/gugeng/sale-task/department/month/list',
        remark: '/gugeng/sale-task/department/update-remark',
        EXPORT: '/gugeng/sale-task/department/export'
      },
      // 生产计划
      plan: {
        LIST: '/gugeng/produce-plan/list',
        ADD: '/gugeng/produce-plan/save',
        DETAIL: '/gugeng/produce-plan/detail',
        UPDATE: '/gugeng/produce-plan/update',
        DELETE: '/gugeng/produce-plan/delete',
        EXPORT: '/gugeng/produce-plan/exportExcel'
      },
    },
    // 产品
    product: {
      LIST: '/hydra-base-data/api/v1/product/enable/list',
    },
    // 生产技术指导
    question: {
      // 咨询专家列表
      LIST: '/gugeng/question/list',
      // 常见问题列表
      COMMON_LIST: '/gugeng/commonQuestion/list',
      // 远程指导列表
      ANSWER_LIST: '/gugeng/answer/list',
      // 新增问题
      ADD_QUESTION: '/gugeng/question/add',
      // 新增回复
      ADD_ANSWER: '/gugeng/answer/add',
    },
    // 批次
    batch: {
      // 获取历史种植批次列表
      HISTORY_BATCH_LIST: '/trace/farming/planting/batchinfo/listEndBatch',
      // 当前种植批次列表
      PLANT_BATCH_LIST: '/trace/farming/planting/batchinfo/listPlantingBatch',
      // 外采批次下拉框
      GET_OUT_DROP_LIST: '/gugeng/out/order/dropPage',
      // 获取批次(仓储)
      GET_PICK_BATCH_DROP_LIST: '/trace/farming/planting/batchinfo/listPickingBatch',
      //获取产品规格信息列表
      GET_PEODUCT_SIZE: '/hydra-base-data/api/v1/specification/product/dropDown'
    },
    // 基地，大棚
    base: {
      // 基地下拉数据
      BASE_LIST: '/hydra-base-data/api/v1/base/select/list',
      // 获取大棚种植产品信息接口
      MASS_LIST: '/trace/farming/planting/batchinfo/listMassIfInfo',
      // 获取当前登陆企业下的所有大棚数据
      ALL_MASS_LIST: '/hydra-base-data/api/v1/mass/type/enable/list',
    },
    // 育苗计划
    seedPlan: {
      // 育苗计划列表
      LIST: '/gugeng/seed-plan/list',
      // 新增育苗计划
      ADD: '/gugeng/seed-plan/save',
      // 修改育苗计划
      EDIT: '/gugeng/seed-plan/update',
      DELETE: '/gugeng/seed-plan/delete',
      EXPORT: '/gugeng/seed-plan/exportExcel'
    },
    // 采收管理
    recovery: {
      // 采收报损
      lose: {
        LIST: '/gugeng/harvest/damage/page',
        ADD: '/gugeng/harvest/damage/add',
        UPDATE: '/gugeng/harvest/damage/update',
        EXPORT: '/gugeng/harvest/damage/exportExcel',
      },
      // 采收计划
      plan: {
        LIST: '/gugeng/harvest-plan/list',
        ADD: '/gugeng/harvest-plan/save',
        UPDATE: '/gugeng/harvest-plan/update',
        DETAIL: '/gugeng/harvest-plan/detail',
        EXPORT: '/gugeng/harvest-plan/exportExcel',
        DELETE: '/gugeng/harvest-plan/delete'
      },
    },
    // 部门管理,部门列表
    department: {
      LIST: '/hydra-user/api/v1/org/dept/list',
    },
  },
  // 运营管理
  operationManagement: {
    // 来访人员管理
    visitorManagement: {
      LIST: '/hydragugeng/guGengVisitorManage/list',
      ADD: '/hydragugeng/guGengVisitorManage/save',
      ADD_V2: '/hydragugeng/guGengVisitorManage/v2/save',
      DETAIL: '/hydragugeng/guGengVisitorManage/detail',
      DETAIL_V2: '/hydragugeng/guGengVisitorManage/v2/detail',
      EDIT: '/hydragugeng/guGengVisitorManage/update',
      EDIT_V2: '/hydragugeng/guGengVisitorManage/v2/update',
      DELETE: '/hydragugeng/guGengVisitorManage/deleteById',
      EXPORT: '/hydragugeng/guGengVisitorManage/export'
    },
    // 房间类型/娱乐项目
    type: {
      LIST: '/hydragugeng/guGengCommonType/list',
      ADD: '/hydragugeng/guGengCommonType/save',
      EDIT: '/hydragugeng/guGengCommonType/update',
      DELETE: '/hydragugeng/guGengCommonType/deleteById',
      DROP_DOWN: '/hydragugeng/guGengCommonType/dropDown'
    }
  },
  // 销售
  sale: {
    // 销售任务
    goals: {
      // 获取部门销售任务列表信息
      GET_DEPARTMENT_LIST: '/gugeng/sale-task/department/list',
      // 添加部门销售任务
      ADD_DEPARTMENT_LIST: '/gugeng/sale-task/department/add',
      // 编辑部门销售任务
      EDIT_DEPARTMENT_LIST: '/gugeng/sale-task/department/update',
      // 部门年度任务
      YEARS_LIST: '/gugeng/sale-task/department/year/statistics',
      // 部门季度任务
      SEASON_LIST: '/gugeng/sale-task/department/quarter/statistics',
      // 获取个人销售任务列表信息
      GET_UNIT_LIST: '/gugeng/sale-task/personal/list',
      // 添加个人销售任务
      ADD_UNIT_LIST: '/gugeng/sale-task/personal/add',
      // 编辑个人销售任务
      EDIT_UNIT_LIST: '/gugeng/sale-task/personal/update',
      // 个人年度任务
      UNIT_YEARS_LIST: '/gugeng/sale-task/personal/year/statistics',
      // 个人季度任务
      UNIT_SEASON_LIST: '/gugeng/sale-task/personal/quarter/statistics',
      // 销售人员下拉
      SALE_EMPLOYEE_LIST: '/hydra-user/api/v1/employee/list',
      // 部门销售任务导出
      DEPARTMENT_EXPORT: '/gugeng/sale-task/department/export',
      // 部门季度销售任务导出
      DEPARTMENT_SEASON_EXPORT: '/gugeng/sale-task/department/quarter/export',
      // 部门年度销售任务导出
      DEPARTMENT_YEARS_EXPORT: '/gugeng/sale-task/department/year/export',
      // 个人销售任务导出
      UNIT_EXPORT: '/gugeng/sale-task/personal/export',
      // 个人季度销售任务导出
      UNIT_SEASON_EXPORT: '/gugeng/sale-task/personal/quarter/export',
      // 个人年度销售任务导出
      UNIT_YEARS_EXPORT: '/gugeng/sale-task/personal/year/export',

      // 月度销售任务对比
      MONTH_COMPARISON: '/gugeng/sale-task/month/sale-task-comparison',
      MONTH_COMPARISON_EXPORT: '/gugeng/sale-task/month/stc/export',
      // 季度销售任务对比
      SEASON_COMPARISON: '/gugeng/sale-task/quarter/sale-task-comparison',
      SEASON_COMPARISON_EXPORT: '/gugeng/sale-task/quarter/stc/export',
      // 年度销售任务对比
      YEAR_COMPARISON: '/gugeng/sale-task/year/sale-task-comparison',
      YEAR_COMPARISON_EXPORT: '/gugeng/sale-task/year/stc/export',
    },
    // 客户管理
    customerManager: {
      // 客户分类列表
      GET_CUSTOMER_SORT_LIST: '/gugeng/client/category/page',
      // 客户分类下拉
      CUSTOMER_SORT_LIST: '/gugeng/client/category/dropDownPage',
      // 客户名称下拉
      CUSTOMER_NAME_LIST: '/gugeng/client/dropPage',
      // 添加客户分类
      ADD_CUSTOMER_SORT_LIST: '/gugeng/client/category/add',
      // 编辑客户分类
      EDIT_CUSTOMER_SORT_LIST: '/gugeng/client/category/update',
      // 删除客户分类
      DEL_CUSTOMER_SORT_LIST: '/gugeng/client/category/delete',
      // 客户列表
      GET_CUSTOMER_LIST: '/gugeng/client/page',
      // 客户详情
      CUSTOMER_LIST: '/gugeng/client/detail',
      // 添加客户
      ADD_CUSTOMER_LIST: '/gugeng/client/add',
      // 编辑客户
      EDIT_CUSTOMER_LIST: '/gugeng/client/update',
      // 删除客户
      DEL_CUSTOMER: '/gugeng/client/delete',
      // 客户订单统计
      CUSTOMER_ORDER_RECORD: '/gugeng/sale/order/product/statistics',
      // 客户详情订单
      CUSTOMER_ORDER_DETAILS: '/gugeng/sale/order/product/page',
      // 客户导出
      CUSTOMER_EXPORT: '/gugeng/client/export',
      // 客户导入
      CUSTOMER_IMPORT: '/gugeng/client/import',
      //下载客户导入模板
      CUSTOMER_UPLOAD: '/gugeng/client/download/excel-template',
      // 客户订单导出
      CUSTOMER_ORDER_EXPORT: '/gugeng/sale/order/product/exportExcel',
      // 客户联系人下拉接口
      CONTACT_MAN_DROP_DOWN: '/gugeng/guGengContactMan/dropDown',
      // 客户维护
      customerMaintain: {
        GET_LIST: '/gugeng/guGengClientMaintain/list',
        ADD: '/gugeng/guGengClientMaintain/save',
        UPDATE: '/gugeng/guGengClientMaintain/update',
        DETAIL: '/gugeng/guGengClientMaintain/detail',
        DELETE: '/gugeng/guGengClientMaintain/deleteById',
        GET_LIST_ID: '/gugeng/guGengClientMaintain/listByClientId',
        EXPORT: '/gugeng/guGengClientMaintain/export'
      }
    },
    // 外采订单管理
    outsideOrderManage: {
      // 外采订单列表
      GET_OUTERSIDE_LIST: '/gugeng/out/order/page',
      // 新增外采订单
      ADD_OUTERSIDE_LIST: '/gugeng/out/order/add',
      // 编辑外采订单
      EDIT_OUTERSIDE_LIST: '/gugeng/out/order/update',
      // 获取采购人员
      GET_SALE_PERSON: '/hydra-user/api/v1/employee/enable/list/all',
      // 外采订单导出
      OUTERSIDE_LIST_EXPORT: '/gugeng/out/order/exportExcel',
    },
    // 销售订单管理
    saleOrderManage: {
      // 产量预测
      GET_PREDICTION_LIST: '/gugeng/harvest-plan/production-forecast/list',
      // 订单列表
      GET_SALE_ORDER_LIST: '/gugeng/sale/order/page',
      // 完成订单
      // CLOSE_ORDER: '/gugeng/sale/order/done',
      // 新增订单
      ADD_ORDER: '/gugeng/sale/order/add',
      // 编辑订单
      EDIT_ORDER: '/gugeng/sale/order/update',
      // 订单详情
      VIEW_ORDER: '/gugeng/sale/order/detail',
      // 订单导出
      ORDER_EXPORT: '/gugeng/sale/order/exportExcel',
      // 审核
      VERIFY: '/gugeng/sale/order/verify',
      // 退货
      RETURN_GOODS: '/gugeng/sale/order/returnGoods',
      // 拒收
      REJECT: '/gugeng/sale/order/reject',
      // 订单完成
      DONE: '/gugeng/sale/order/done',
      // 订单确认
      CONFIRM: '/gugeng/sale/order/confirm',
      // 订单删除
      DELETE_ORDER: '/gugeng/sale/order/deleteOrder',
      //订单导入
      ORDER_IMPORT: '/gugeng/sale/order/orderImport',
      // 导出验收单
      EXPORT_DELIVERY_ORDER_PDF_GET: '/gugeng/sale/order/exportDeliveryOrderPdf1/'
    },
    // 礼品箱管理
    giftBoxMaintenance: {
      LIST: '/gugeng/gift-bBox/list',
      ADD: '/gugeng/gift-bBox/save',
      DETAIL: '/gugeng/gift-bBox/detail',
      EDIT: '/gugeng/gift-bBox/update',
      DELETE: '/gugeng/gift-bBox'
    },
    v2Order: {
      LIST: '/gugeng/sale/order/v2/page',
      ADD: '/gugeng/sale/order/v2/add',
      DETAIL: '/gugeng/sale/order/v2/detail',
      EDIT: '/gugeng/sale/order/v2/update',
      DELETE: '/gugeng/sale/order/v2/deleteOrder',
      EXPORT: '/gugeng/sale/order/v2/exportExcel',
      IMPORT: '/gugeng/sale/order/v2/orderImport',
      EXPORT_DELIVERY_ORDER_PDF_GET: '/gugeng/sale/order/v2/exportDeliveryOrderPdf1/',
      DONE: '/gugeng/sale/order/v2/done'
    },
    orderProductAnalysis: {
      LIST: '/gugeng/sale/order/product-statistics',
      EXPORT: '/gugeng/sale/order/product-statistics/exportExcel'
    }
  },
  // 仓储管理
  storageManage: {
    // 产品称重
    productWeight: {
      LIST: '/gugeng/storagemanage/productWeight/list',
      ADD: '/gugeng/storagemanage/productWeight/save',
      EDIT: '/gugeng/storagemanage/productWeight/update',
      EXPORT: '/gugeng/storagemanage/productWeight/export'
    },
    // 分拣入库
    sortIntoStorage: {
      LIST: '/gugeng/storagemanage/productSortInstorage/list',
      // 校验码
      VALID_CODE: '/gugeng/product-code-in/valid-code-is-legal',
      EDIT: '/gugeng/storagemanage/productSortInstorage/update',
      DETAIL: '/gugeng/storagemanage/productSortInstorage/detail',
      EXPORT: '/gugeng/storagemanage/productSortInstorage/export',
      // 产品不贴码分拣入库
      ADD_CODE_LESS: '/gugeng/storagemanage/productSortInstorage/save',
      // 编辑不贴码分拣入库信息
      EDIT_CODE_LESS: '/gugeng/storagemanage/productSortInstorage/update'
    },
    // 包装出库
    packAndOutOfStock: {
      LIST: '/gugeng/outbound/list',
      // 获取入库产品信息--第一次输入码
      FIRST_CODE_IN: '/gugeng/outbound-package-message/code-in/first',
      // 获取入库产品信息--不是第一次输入码
      NOT_FIRST_CODE_IN: '/gugeng/outbound-package-message/code-in',
      // 扫码发货
      ADD: '/gugeng/outbound-package-message/save',
      // 无码包装
      ADD_NO_CODE: '/gugeng/outbound-package-message/code-less/save',
      // 保存出库发货信息
      DELIVER_WAY_SAVE: '/gugeng/uutbound-deliver-way/save',
      // 获取包装出库列表
      OUTBOUND_LIST: '/gugeng/outbound-package-message/list-by-order-id',
      // 获取发货信息详情
      DELIVER_WAY_DETAIL: '/gugeng/uutbound-deliver-way/detail',
      // 获取物流详情
      EXPRESS_INFO: '/gugeng/common/expressInfo',
      EXPORT: '/gugeng/outbound/export',
      EXTENDS_CODE: '/gugeng/outbound-package-message/extends-code',
      // 全部发货
      ALL_DELIVER: '/gugeng/outbound/allDone',
      // 校验码是否符合包装扫码
      PACK_CODE_IS_LEGAL: '/gugeng/outbound-package-message/valid-code-is-legal',
      // 编辑包装出库
      EDIT_PACK_MESSAGE: '/gugeng/outbound-package-message/edit',
      // 获取未出库发货的包装信息列表
      NOT_OUTBOUND_PACK_MSG: '/gugeng/outbound-package-message/list-with-not-outbound-msg'
    },
    // 配送信息维护
    deliveryInfo: {
      LIST: '/gugeng/deliveryInfo/list',
      ADD: '/gugeng/deliveryInfo/save',
      EDIT: '/gugeng/deliveryInfo/update',
      DELETE: '/gugeng/deliveryInfo/deleteById',
      DROPDOWN_LIST: '/gugeng/deliveryInfo/dropDown'
    },
    // 产品库存
    productStock: {
      LIST: '/gugeng/stock/list',
      DETAIL: '/gugeng/stock/detail',
      FLOW_DETAIL_LIST: '/gugeng/productionManageStockFlowDetails/list',
      EXPORT: '/gugeng/stock/export',
      // 全部库存报损
      ALL_LOSE: '/gugeng/productionManageStockLoss/total-inventory-loss',
      // 产品等级库存
      LEVEL_LIST: '/gugeng/stock/product-level-list',
      LEVEL_LIST_EXPORT: '/gugeng/stock/product-level-export',
      ADD_WARNING: '/gugeng/stock/add-or-update-warning'
    },
    // 库存流水
    productStockFlow: {
      LIST: '/gugeng/productionManageStockFlowDetails/list-all',
      EXPORT: '/gugeng/productionManageStockFlowDetails/list-export'
    },
    // 库存报损
    stockLose: {
      LIST: '/gugeng/productionManageStockLoss/list',
      ADD: '/gugeng/productionManageStockLoss/damage',
      // 无码库存报损
      ADD_NO_CODE: '/gugeng/productionManageStockLoss/code-less/damage',
      // 无码报损-报损确认
      STOCK_NO_CODE_LOSS_CONFIRM: '/gugeng/productionManageStockLoss/code-less/confirm',
      // 空余箱子处理
      EXTRA_BOX: '/gugeng/productionManageStockLoss/leftBox',
      // 损坏箱子处理
      DAMAGE_BOX: '/gugeng/productionManageStockLoss/damageBox',
      // 新箱子处理
      NEW_BOX: '/gugeng/productionManageStockLoss/newBox',
      DETAIL: '/gugeng/productionManageStockLoss/detail',
      EXPORT: '/gugeng/productionManageStockLoss/export'
    },
    // 产品报损
    productLose: {
      // 产品报损列表
      LIST: '/gugeng/product-loss/list',
      ADD: '/gugeng/product-loss',
      DETAIL: '/gugeng/product-loss/',
      // 产品报损导出
      EXPORT: '/gugeng/product-loss/export',
      // 报损确认
      CONFIRM_LOSE: '/gugeng/product-loss/'
    },
    // 种植存量
    stockOfPlanting: {
      STOCK_BATCH_LIST: '/gugeng/guGengPlantStock/list-batch',
      STOCK_BATCH_EXPORT: '/gugeng/guGengPlantStock/export-batch',
      STOCK_PRODUCT_LIST: '/gugeng/guGengPlantStock/list-product',
      STOCK_PRODUCT_EXPORT: '/gugeng/guGengPlantStock/export-product',
    }
  },
  // 产品档案
  productFile: {
    LIST: '/gugeng/product-record/list',
    DETAIL: '/gugeng/product-record/detail',
    // 销售客户列表
    SALE_OUT: '/gugeng/sale-out-record/list',
    EXPORT: '/gugeng/product-record/export',
    SORT_INTO_STORAGE_BY_BATCH_ID: '/gugeng/storagemanage/productSortInstorage/list-by-batch-id'
  },
  public: {
    // (下拉专用)获取规格信息列表
    SPECIFICATION_DROP_DOWN: '/hydra-base-data/api/v1/specification/select/list'
  },
  // 润达物料部分
  material: {
    GET_LIST: '/hydra-base-data/api/v1/material/productmanage/list',
    GET_INVENTORY_LIST: '/hydra-base-data/api/v1/material/warehouse/productmanage/list',
    GET_IN_LIST: '/hydra-base-data/api/v1/material/warehouse/productmanage/in/material/list',
    GET_BATCH_LIST: '/hydra-base-data/api/v1/material/warehouse/productmanage/in/batch',
    GET_OUT_LIST: '/hydra-base-data/api/v1/material/warehouse/productmanage/out/material/list',
    ADD_MATERIAL: '/hydra-base-data/api/v1/material/productmanage',
    GET_MATERIAL_BYID: '/hydra-base-data/api/v1/material/productmanage',
    UPDATE_MATERIAL: '/hydra-base-data/api/v1/material/productmanage',
    DISABLE_MATERIAL: '/hydra-base-data/api/v1/material/productmanage/disable',
    ENABLE_MATERIAL: '/hydra-base-data/api/v1/material/productmanage/enable',
    GET_STOCK_LIST: '/hydra-base-data/api/v1/material/warehouse/productmanage/list',
    GET_OUT_IN_CODE: '/hydra-base-data/api/v1/material/warehouse/productmanage/code',
    GET_MATERIAL_SIZE: '/hydra-base-data/api/v1/material/specification/productmanage/list',
    ADD_MATERIAL_IN: '/hydra-base-data/api/v1/material/warehouse/productmanage/batch/in',
    ADD_MATERIAL_OUT: '/hydra-base-data/api/v1/material/warehouse/productmanage/batch/out',
    GET_MATERIAL_DETAILS_BYID: '/hydra-base-data/api/v1/material/warehouse/productmanage',
    SET_WARNING: '/hydra-base-data/api/v1/material/warehouse/productmanage/warning',
    GET_LIST_BATCH: '/hydra-base-data/api/v1/material/warehouse/productmanage/signle/out/list',
    GET_LIST_TURNOVER: '/hydra-base-data/api/v1/material/warehouse/productmanage/signle/out-in/list',
    GET_FLOWING_LIST: '/hydra-base-data/api/v1/material/warehouse/productmanage/out-in/list',
    GET_VERIFY: '/hydra-base-data/api/v1/material/warehouse/productmanage/check/out',
    GET_PLATE_BATCH: '/hydra-base-data/api/v1/runda/product-batch/dropPage',
    // 物料使用统计
    STATISTIC_LIST: '/hydra-base-data/api/v1/runda/productmanage/material/materialUseStatisics/list',
    // 导出物料使用统计
    EXPORT_STATISTIC_LIST: '/hydra-base-data/api/v1/runda/productmanage/material/materialUseStatisics/export'
  },
  // 基地管理
  baseArea: {
    massif: {
      GET_LIST: '/hydra-base-data/api/v1/mass/list',
      SAVE: '/hydra-base-data/api/v1/mass',
      UPDATE: '/hydra-base-data/api/v1/mass',
      DISABLE: '/hydra-base-data/api/v1/mass/disable',
      ENABLE: '/hydra-base-data/api/v1/mass/enable',
      GET: '/hydra-base-data/api/v1/mass',
      GET_AREA: '/hydra-base-data/api/v1/partition/select/list',
      // 获取地块生产记录
      GET_RECORD: '/trace/massif/info/page',
      // 批次号获取h5溯源页数据接口
      GET_PAGE_DATA: '/trace/batch/info/h5PageData',
      // 批次号获取h5溯源页数据接口
      GET_PAGE_MASSIF_DATA: '/trace/massif/info/h5PageData',
    },
    base: {
      GET_LIST: '/hydra-base-data/api/v1/base/list',
      SAVE: '/hydra-base-data/api/v1/base',
      UPDATE: '/hydra-base-data/api/v1/base',
      DISABLE: '/hydra-base-data/api/v1/base/disable',
      ENABLE: '/hydra-base-data/api/v1/base/enable',
      GET: '/hydra-base-data/api/v1/base',
    },
    zone: {
      GET_LIST: '/hydra-base-data/api/v1/partition/list',
      SAVE: '/hydra-base-data/api/v1/partition',
      UPDATE: '/hydra-base-data/api/v1/partition',
      DISABLE: '/hydra-base-data/api/v1/partition/disable',
      ENABLE: '/hydra-base-data/api/v1/partition/enable',
      GET: '/hydra-base-data/api/v1/partition',
    }
  },
  // 基础数据
  base: {
    CONFIF_TYPE: '/hydra-base-data/api/v1/sort/type/page/list',
    AOTU_GET_LIST: '/hydra-base-data/api/v1/base/select/list',
  },
  // 数据分析
  analysis: {
    // 用户使用统计
    usageStatistic: {
      // 使用频次
      USE_TIMES: '/gugeng/productionManageFunctionUse/pageFunctionUse',
      USE_TIMES_ANALYSIS: '/gugeng/productionManageFunctionUse/selectLineChartVO',
      // 登录记录
      RECORD: '/hydra-user/api/v1/loginstatiscis/page',
      RECORD_EXPORT: '/hydra-user/api/v1/loginstatiscis/exportLoginLogList',
      // 登录数据
      LOGIN_LIST: '/hydra-user/api/v1/loginstatiscis/pageGroup',
      LOGIN_EXPORT: '/hydra-user/api/v1/loginstatiscis/exportDepartmentGroup',
      LOGIN_ANALYSIS: '/hydra-user/api/v1/loginstatiscis/selectLineChartVO',
    },
    sale: {
      // 销售额，订单数
      AMOUNT_NUM: '/gugeng/statistics/sale/order/amountAndNum',
      // 个人销售排行
      SALE_RANK: '/gugeng/statistics/sale/order/person/salerank',
      // 导出个人销售排行
      EXPORT_SALE_RANK: '/gugeng/statistics/sale/exportPersonSalerank',
      // 销售客户
      CLIENT_RANK: '/gugeng/statistics/sale/order/client/order',
      // 导出销售客户
      EXPORT_CLIENT_RANK: '/gugeng/statistics/sale/exportClientOrder',
      // 产品销售排行
      PRODUCT_RANK: '/gugeng/statistics/sale/order/product',
      // 导出产品销售排行
      EXPORT_PRODUCT_RANK: '/gugeng/statistics/sale/exportProductRank',
      // 潜在客户
      POTENTIAL_CLIENT: '/gugeng/statistics/sale/order/potential/client',
      // 潜在客户导出
      EXPORT_POTENTIAL_CLIENT: '/gugeng/statistics/sale/exportPotentialclientRankExcel',
      // 订单状态汇总
      ORDER_STATUS: '/gugeng/statistics/sale/order/status/rank',
      // 订单状态汇总导出
      EXPORT_ORDER_STATUS: '/gugeng/statistics/sale/exportOrderStatusRank',
      // 销售总数据
      // 销售总数据折线图
      TOTAL_AMOUNT_NUM: '/gugeng/statistics/salesummary/order/amountAndNum',
      // 每日销售数据列表
      SALE_ORDER_DATA_DAILY: '/gugeng/statistics/salesummary/order/listAmountAndNum',
      // 每日销售数据导出
      EXPORT_SALE_ORDER_DATA_DAILY: '/gugeng/statistics/salesummary/exportOrderAmountAndNum',
    },
    storage: {
      AMOUNT_NUM: '/gugeng/statistics/storage/order/amountAndNum'
    },
    produce: {
      PRODUCE_LOSE: '/gugeng/product-data-statistics/list',
      GREENHOUSE_LIST: '/gugeng/product-data-statistics/greenhouse-ranking/list',
      EXPORT_GREENHOUSE_LIST: '/gugeng/product-data-statistics/greenhouse-ranking/export',
      // 生产总数居
      // 产量，采收报损
      REAL_AND_LOSS_WEIGHT: '/gugeng/statistics/producesummary/product/realWeightAndLossesWeight',
      // 产量与采收报损数据列表
      REAL_AND_LOSS_WEIGHT_LIST: '/gugeng/statistics/producesummary/product/listRealWeightAndLossesWeight',
      // 导出产量与采收报损数据列表excel
      EXPORT_REAL_AND_LOSS_WEIGHT_LIST: '/gugeng/statistics/producesummary/exportRealWeightAndLossesWeight',
      // 大棚数据
      greenHouseData: {
        LIST: '/gugeng/productionManageStatisticsGreenhouse/greenhouse-list',
        EXPORT: '/gugeng/productionManageStatisticsGreenhouse/export',
        ANALYSIS: '/gugeng/productionManageStatisticsGreenhouse/list'
      },
      // 生产产量数据
      productionOutput: {
        ANALYSIS: '/gugeng/productionManageStatisticsProductionYieldData/list-by-date-interval',
        LIST: '/gugeng/productionManageStatisticsProductionYieldData/list',
        EXPORT: '/gugeng/productionManageStatisticsProductionYieldData/export',
        // 获取最先添加的产品
        NEW_PRODUCT: '/gugeng/productionManageStatisticsGreenhouse/getDefaultProduct'
      },
      // 产量对比数据
      productionComparison: {
        ANALYSIS: '/gugeng/productionManageStatisticsProductionYieldData/list-yield-comparison-data-histogram',
        LIST: '/gugeng/productionManageStatisticsProductionYieldData/list-yield-comparison-data',
        EXPORT: '/gugeng/productionManageStatisticsProductionYieldData/comparison-export',
        // 获取最新采收大棚
        NEW_GREENHOUSE: '/gugeng/productionManageStatisticsGreenhouse/getLastestGreenhouse'
      },
      // 产品产量数据
      productOutput: {
        LIST: '/gugeng/productionManageStatisticsProductProductionData/list',
        ANALYSIS: '/gugeng/productionManageStatisticsProductProductionData/histogram',
        EXPORT: '/gugeng/productionManageStatisticsProductProductionData/export'
      }
    },
    // 管理数据
    manageData: {
      // 库存报损分析
      stockLose: {
        STATISTIC_LIST: '/gugeng/statistics/stock-loss/statistics/list',
        STOCK_LOSS_LIST: '/gugeng/statistics/stock-loss/list',
        STOCK_LOSS_EXPORT: '/gugeng/statistics/stock-loss/export',
      },
      // 生产销售数据
      productionSaleData: {
        STATISTIC_LIST: '/gugeng/product/sale/statistics/lineChart',
        EXPORT_LIST: '/gugeng/product/sale/statistics/exportProductSaleStatistics',
      },
      // 批次数据
      batchData: {
        LIST: '/gugeng/plantBatch/data/statistics/listPlantBatchData',
        // 导出
        EXPORT: '/gugeng/plantBatch/data/statistics/export',
        // 获取入库批次
        GET_IN_STORAGE_BATCH_DROPDOWN:'/gugeng/storagemanage/productSortInstorage/dropDown'
      }
    },
    salesData: {
      salesPerson: {
        // 个人订单情况
        PERSON_ORDER_CONDITION: '/gugeng/statistics/sale/person/order/condition',
        // 个人订单情况导出
        EXPORT_PERSON_ORDER_CONDITION: '/gugeng/statistics/sale/exportPersonOrderconditionExcel',
        // 个人订单情况最好的销售人员信息
        PERSON_CONDITION_BEST: '/gugeng/statistics/sale/person/order/condition/best'
      },
      orderAnalysis: {
        // 整体订单情况
        WHOLE_ORDER_LIST: '/gugeng/order/data/statistics/wholeOrderCondition',
        // 整体订单情况导出
        WHOLE_ORDER_LIST_EXPORT: '/gugeng/order/data/statistics/exportWholeOrderCondition',
        // 发货类型比例接口
        PROPORTION: '/gugeng/order/data/statistics/order/deliveryType/proportion',
        // 订单发货类型排行
        RANK: '/gugeng/order/data/statistics/order/deliveryType/rank',
        // 订单发货类型排行导出
        RANK_EXPORT: '/gugeng/order/data/statistics/exportOrderdeliveryType'
      }, 
      // 销售目标
      salesGoal: {
        GOALS_ANALYSIS: '/gugeng/productionManageStatisticsSaleTargetData/histogram',
        GOALS_LIST: '/gugeng/productionManageStatisticsSaleTargetData/list',
        GOALS_LIST_EXPORT: '/gugeng/productionManageStatisticsSaleTargetData/export'
      },
      // 订单情况数据
      orderStateData: {
        // 订单数据
        ORDER_ANALYSIS: '/gugeng/orderCondition/curve',
        ORDER_LIST: '/gugeng/orderCondition/list',
        ORDER_EXPORT: '/gugeng/orderCondition/export',
        // 退货数据
        RETURN_ANALYSIS: '/gugeng/returnDataStatiscis/curve',
        RETURN_EXPORT: '/gugeng/returnDataStatiscis/export'
      },
      // 客户数据（新）
      clientData: {
        ORDER_CLIENT_ANALYSIS: '/gugeng/client/data/order/client/num',
        ORDER_CLIENT_LIST: '/gugeng/client/data/orderClientList',
        ORDER_CLIENT_EXPORT: '/gugeng/client/data/exportOrderClient',
        POTENTIAL__CLIENT_ANALYSIS: '/gugeng/client/data/order/potential/client/num',
        POTENTIAL_CLIENT_LIST: '/gugeng/client/data/potentialClientList',
        POTENTIAL_CLIENT_EXPORT: '/gugeng/client/data/exportPotentialClient',
      },
      // 订单回款
      orderReturn: {
        RETURN_LIST: '/gugeng/saleProductData/order/payback/list',
        RETURN_EXPORT: '/gugeng/saleProductData/exportPayBackList',
        RETURN_ANALYSIS: '/gugeng/saleProductData/order/payback/curve',
        UN_RETURN_LIST: '/gugeng/saleProductData/order/unPayback/list',
        UN_RETURN_EXPORT: '/gugeng/saleProductData/exportUnPaybackList',
      },
      // 销售产品数据（新）
      salesProduct: {
        ANALYSIS: '/gugeng/orderProductDataStatistics/pie',
        LIST: '/gugeng/orderProductDataStatistics/list',
        EXPORT: '/gugeng/orderProductDataStatistics/export'
      },
      // 客户分类数据
      customerClassify: {
        ANALYSIS: '/gugeng/order/clientCategory/pie',
        LIST: '/gugeng/order/clientCategory/list',
        EXPORT: '/gugeng/order/clientCategory/export'
      }
    },
    storageData: {
      storage: {
        LIST: '/gugeng/statistics/warehouse-data/statistics/list'
      },
      plantStorage: {
        // 获取种植仓储数据统计列表
        LIST: '/gugeng/statistics/plant-warehouse/list',
        // 获取种植仓储数据默认的种植批次信息
        DEFAULT_BATCH: '/gugeng/statistics/plant-warehouse/plant-batch-message/default',
        // 获取种植仓储数据种植批次列表
        BATCH_LIST: '/gugeng/statistics/plant-warehouse/plant-batch-message/list',
        EXPORT: '/gugeng/statistics/plant-warehouse/export'
      },
      inStorage: {
        LIST: '/gugeng/statistics/warehouse-data/statistics/listSortInstorageStatistics',
        EXPORT: '/gugeng/statistics/warehouse-data/statistics/exportSortInstorageStatistics'
      },
      // 仓储总数据
      totalStorageData: {
        // 出库入库，库存重量
        IN_STORAGE_AND_WEIGHT: '/gugeng/statistics/warehousesummary/stock/instorageAndOutboundWeight',
        // 出库入库与库存重量数据列表
        LIST: '/gugeng/statistics/warehousesummary/stock/listInstorageAndOutboundWeight',
        // 导出出库入库与库存重量数据列表excel
        EXPORT: '/gugeng/statistics/warehousesummary/exportInstorageAndOutboundWeight'
      },
      // 报损数据
      lossData: {
        // 库存报损
        STORAGE_LOSS_ANALYSIS: '/gugeng/statistics/stock-loss/statistics/list',
        STORAGE_LOSS_LIST: '/gugeng/statistics/stock-loss/list',
        STORAGE_LOSS_EXPORT: '/gugeng/statistics/stock-loss/export',
        // 采收报损及分拣报损数据统计
        SORT_INTO_LOSS_ANALYSIS: '/gugeng/statistics/stock-loss/hsl-line-chart',
        SORT_INTO_LOSS_LIST: '/gugeng/statistics/stock-loss/harv-sort-loss',
        SORT_INTO_LOSS_EXPORT: '/gugeng/statistics/stock-loss/export-har-and-sort',
      },
      // 发货数据
      deliverData: {
        DELIVER_ANALYSIS: '/gugeng/productionManageStatisticsShippingData/list-line-chart',
        DELIVER_LIST: '/gugeng/productionManageStatisticsShippingData/list',
        DELIVER_EXPORT: '/gugeng/productionManageStatisticsShippingData/export'
      }
    },
    // 运营统计
    visitors: {
      CLIENT_LIST: '/hydragugeng/visitor/statistic/client',
      EXPORT: '/hydragugeng/visitor/statistic/export',
      PROJECT_LIST: '/hydragugeng/visitor/statistic/project',
      MONEY_LIST: '/hydragugeng/visitor/statistic/cash'
    }
  },
  // 产品管理
  product: {
    // 获取产品分类
    PRODUCT_SORT_FIRSTS: '/hydra-base-data/api/v1/product-sort/firsts',
  },
  // 配方管理
  recipeManagement: {
    LIST: '/hydra-base-data/api/v1/formula/page/list',
    ADD: '/hydra-base-data/api/v1/formula',
    VIEW: '/hydra-base-data/api/v1/formula/one',
    DEFAULT_LIST: '/hydra-base-data/api/v1/formula/selectDefaultList'
  },
  // 首页
  index: {
    //获取设备列表
    device: {
      GET_LIST: '/iotdevice/listByOrgId'
    },
    //按月获取种植数据
    plant: {
      GET_MONTH_DATA: '/trace/farming/planting/massifinfostatistic/getPlantingInfoStatistic'
    },
    //大棚
    massif: {
      GET_LIST: '/trace/farming/planting/massifinfostatistic/getMassIfInfoStatistic',
      GET_STATISTIC:'/trace/farming/planting/massifinfostatistic/getProductionInfoStatistic',
      // 获取首页右上角的图表数据
      GET_ANALYSIS: '/gugeng/storagemanage/productWeight/list-half-year-msg',
      // 获取种植批次生产档案详情
      GET_PLANT_BATCH_INFO: '/trace/farming/planting/batchinfo/getPlantingBatchInfo',
    }
  },
  // 部门管理,部门列表
  department: {
    LIST: '/hydra-user/api/v1/org/dept/list',
  },
  // 数据屏
  dataScreen: {
    // 保存数据
    SAVE: '/gugeng/datascreen/statistics/save',
    // 获取详情
    GET: '/gugeng/datascreen/statistics/detail',
    // 获取详情
    GET_DATASCREENS_INFO: '/gugeng/datascreen/statistics/selectList',
  },
  // 用工管理
  employmentManage: {
    LIST: '/gugeng/productionManageRecruit/list',
    EXPORT: '/gugeng/productionManageRecruit/export',
    ADD: '/gugeng/productionManageRecruit/save',
    // 执行招聘
    EXECUTE_RECRUIT: '/gugeng/productionManageRecruit/execute-recruit',
    // 招聘完成
    COMPLETE_RECRUIT: '/gugeng/productionManageRecruit/completed-recruit',
    DETAIL: '/gugeng/productionManageRecruit/detail/',
    DELETE: '/gugeng/productionManageRecruit/remove/',
    EDIT: '/gugeng/productionManageRecruit/update',
    // 导出用工信息pdf
    EXPORT_PDF: '/gugeng/productionManageRecruit/export-pdf/'
  },
  // 品控管理
  qualityControlInspection: {
    LIST: '/gugeng/qualitycontrolinspection/list',
    EXPORT: '/gugeng/qualitycontrolinspection/export',
    DETAIL: '/gugeng/qualitycontrolinspection/detail',
    ADD: '/gugeng/qualitycontrolinspection/save',
    // 问题处理及品控确认
    UPDATE: '/gugeng/qualitycontrolinspection/update'
  },
  // 品控管理记录
  // 分拣车间卫生检查
  workShopInspection: {
    LIST: '/gugeng/workshopinspection/list',
    DETAIL: '/gugeng/workshopinspection/detail',
    DELETE: '/gugeng/workshopinspection/deleteOne',
    ADD: '/gugeng/workshopinspection/save',
    EDIT: '/gugeng/workshopinspection/update',
    // asgdhsgdj
    EXPORT: '/gugeng/workshopinspection/export'
  },
  // 采购管理
  purchaseManage: {
    // 采购管理
    purchase: {
      LIST: '/gugeng/productionManagePurchasingManagement/list',
      EXPORT: '/gugeng/productionManagePurchasingManagement/export',
      DELETE: '/gugeng/productionManagePurchasingManagement/remove/',
      // 采购申请
      ADD: '/gugeng/productionManagePurchasingManagement/save',
      // 确定供应商及价格
      ABSOLUTE_SUPPLIER_PRICE: '/gugeng/productionManagePurchasingManagement/save-supplier-and-price',
      // 下单日期
      UPDATE_ORDER_DATE: '/gugeng/productionManagePurchasingManagement/update-order-date',
      // 到货提醒
      UPDATE_ARRIVAL_DATE: '/gugeng/productionManagePurchasingManagement/update-except-arrival-date',
      // 确认收货
      CONFIRM_RECEIPT: '/gugeng/productionManagePurchasingManagement/confirm-receipt',
      // 获取采购管理详情信息
      DETAIL: '/gugeng/productionManagePurchasingManagement/detail',
      // 获取确定供应商及价格信息
      GET_SUPPLIER_BY_ID: '/gugeng/productionManagePurchasingManagement/get-supplier-by-id',
      // 编辑
      EDIT: '/gugeng/productionManagePurchasingManagement/update', 
      // 导出pdf
      EXPORT_PDF: '/gugeng/productionManagePurchasingManagement/export-pdf'
    },
    // 采购供应商管理
    supplier: {
      LIST: '/gugeng/productionManageProcurementSupplier/list',
      DELETE: '/gugeng/productionManageProcurementSupplier/remove/',
      ADD: '/gugeng/productionManageProcurementSupplier/save',
      EDIT: '/gugeng/productionManageProcurementSupplier/update',
      DETAIL: '/gugeng/productionManageProcurementSupplier/detail',
      DROPDOWN_LIST: '/gugeng/productionManageProcurementSupplier/get-by-name',
      EXPORT: '/gugeng/productionManageProcurementSupplier/export'
    },
    // 采购商品维护
    product: {
      LIST: '/gugeng/productionManagePurchaseProducts/list',
      DELETE: '/gugeng/productionManagePurchaseProducts/remove/',
      ADD: '/gugeng/productionManagePurchaseProducts/save',
      EDIT: '/gugeng/productionManagePurchaseProducts/update',
      EXPORT: '/gugeng/productionManagePurchaseProducts/export',
      DETAIL: '/gugeng/productionManagePurchaseProducts/detail',
      LIST_BY_SUPPLIER: '/gugeng/productionManagePurchaseProducts/list-from-supplier',
      // 供应商详情里的商品导出
      SUPPLIER_DETAIL_PRODUCT_EXPORT: '/gugeng/productionManagePurchaseProducts/export-by-supplier'
    }
  },
  // 维护管理
  maintenanceManage: {
    // 维修管理
    management: {
      LIST: '/gugeng/repairManage/list',
      DELETE: '/gugeng/repairManage/deleteOne',
      // 维修申请
      ADD: '/gugeng/repairManage/callRepair',
      // 维修指派
      APPOINT: '/gugeng/repairManage/assignRepair',
      // 执行维修
      RUN_REPAIR: '/gugeng/repairManage/runRepair',
      // 维修完成
      DONE_REPAIR: '/gugeng/repairManage/doneRepair',
      // 完成确定
      CONFIRM: '/gugeng/repairManage/doneConfirm',
      // 维修评价
      APPRAISE: '/gugeng/repairManage/commentRepair',
      EDIT: '/gugeng/repairManage/update',
      DETAIL: '/gugeng/repairManage/detail',
      EXPORT: '/gugeng/repairManage/exportExcel',
      // 导出pdf
      EXPORT_PDF: '/gugeng/repairManage/exportPdf'
    },
    // 巡检管理
    inspectionManage: {
      LIST: '/gugeng/inspectionManage/list',
      ADD: '/gugeng/inspectionManage/save',
      EDIT: '/gugeng/inspectionManage/update',
      DELETE: '/gugeng/inspectionManage/deleteOne',
      INSPECTION: '/gugeng/inspectionManage/inspection',
      DONE_INSPECTION: '/gugeng/inspectionManage/doneInspection',
      EXPORT: '/gugeng/inspectionManage/export'
    },
    // 维护材料
    materials: {
      LIST: '/gugeng/stuff/list',
      ADD: '/gugeng/stuff/save',
      EDIT: '/gugeng/stuff/update',
      DISABLE: '/gugeng/stuff/disable',
      ENABLE: '/gugeng/stuff/enable',
      DETAIL: '/gugeng/stuff/detail',
      EXPORT: '/gugeng/stuff/export',
      DROP_DOWN: '/gugeng/stuff/dropDown',
      // 规格下拉
      SPECIFICATION_DROP_DOWN: '/gugeng/stuffSpecification/dropDown',
      // 材料批次下拉
      WAREHOUSE_DROP_DOWN: '/gugeng/stuff/in/warehouse/dropDownBatch',
    },
    // 维护材料分类
    materialsClassify: {
      SORT_FIRST: '/gugeng/stuffSort/first',
      CHILDREN: '/gugeng/stuffSort/children',
      DELETE: '/gugeng/stuffSort/deleteOne',
      ADD: '/gugeng/stuffSort/save',
      EDIT: '/gugeng/stuffSort/update'
    },
    // 维护材料库存
    materialStock: {
      LIST: '/gugeng/stuff/stock/list',
      WARNING: '/gugeng/stuff/stock/warningInventory',
      EXPORT: '/gugeng/stuff/stock/export',
      DETAIL: '/gugeng/stuff/stock/detail',
      // 获取出入库编号
      BOUND_CODE: '/gugeng/stuff/stock/boundCode',
      // 入库
      SAVE: '/gugeng/stuff/in/warehouse/save',
      // 出库
      EDIT: '/gugeng/productionManageStuffOutWarehouse/save',
      // 批次详情
      BATCH_DETAIL: '/gugeng/stuff/stock/batchDetailList',
      // 流水详情
      FLOW_DETAIL: '/gugeng/stuff/stock/flowDetailList',
      // 出库库存校验
      OUTBOUND_CHECK: '/gugeng/stuff/stock/outboundCheck'
    }
  }
};
