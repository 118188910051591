/* @discription: 富川报表
 * @Author: yushunping 
 * @Date: 2021-07-27 11:32:12 
 * @Last Modified by: yushunping
 * @Last Modified time: 2021-07-27 14:48:52
 */
export default {
  echart: '/fuchuan-bigdata-screen/api/v1/reportForm/getTotal',
  plant: '/fuchuan-bigdata-screen/api/v1/reportForm/getCultivate',
  tableList: '/regulation/registration/product/gov/list'
};