export default {
  //组织情况
  org: {
    //获取关键指标数据
    GET_CRUX_QUOTA: '/hydra-user/api/v1/analysis/org/key-indicators',
    //获取图表数据
    GET_CHART_DATA: '/hydra-user/api/v1/analysis/org/chart',
    //获取企业列表
    GET_ORG_LIST: '/hydra-user/api/v1/analysis/org/page/list'
  },
  //用户情况
  user: {
    //获取关键指标数据
    GET_CRUX_QUOTA: '/hydra-user/api/v1/analysis/user/key-indicators',
    //获取图表数据
    GET_CHART_DATA: '/hydra-user/api/v1/analysis/user/chart',
    //获取用户列表
    GET_USER_LIST: '/hydra-user/api/v1/analysis/user/page/list'
  },
  //生码情况
  createCode: {
    //获取关键指标数据
    GET_CRUX_QUOTA: '/hydra-user/api/v1/analysis/code-production/key-indicators',
    //获取图表数据
    GET_CHART_DATA: '/hydra-user/api/v1/analysis/code-production/chart',
    //获取TOP20列表
    GET_TOP_LIST: '/hydra-user/api/v1/analysis/code-production/top-20/list'
  },
  //扫码情况
  scanCode: {
    //获取关键指标数据
    GET_CRUX_QUOTA: '/hydra-user/api/v1/analysis/code-scan/key-indicators',
    //获取图表数据
    GET_CHART_DATA: '/hydra-user/api/v1/analysis/code-scan/chart',
    //获取TOP20列表
    GET_TOP_LIST: '/hydra-user/api/v1/analysis/code-scan/top-20/list'
  },
  //防伪
  antiFake: {
    //防伪数据统计
    home: {
      //获取关键指标数据
      GET_CRUX_QUOTA: '/fake/operation-statistics/base',
      //获取查询方式列表
      GET_SEARCH_TYPE_LIST: '/fake/operation-statistics/inquiry-mode',
      //获取图表数据
      GET_CHART_DATA: '/fake/operation-statistics/trend-line-chart',
      //获取查询区域数据
      GET_AREA_DATA: '/fake/operation-statistics/inquiry-area',
      //获取行业分布数据
      GET_INDUSTRY_DATA: '/fake/operation-statistics/industry-pie-chart',
      //获取产品扫码TOP10
      GET_PRODUCT_TOP: '/fake/operation-statistics/product-rank',
      //获取详细数据列表
      GET_DETAIL_LIST: '/fake/operation-statistics/search-records'
    },
    //实时访问数据
    realTime: {
      //获取关键指标数据
      GET_CRUX_QUOTA: '/fake/operation-statistics/real-time/base',
      //获取图表数据
      GET_CHART_DATA: '/fake/operation-statistics/real-time/trend-line-chart',
      //获取详细数据列表
      GET_DETAIL_LIST: '/fake/operation-statistics/real-time/hour-trend-list'
    },
    //历史访问数据
    history: {
      //获取图表数据
      GET_CHART_DATA: '/fake/operation-statistics/history/trend-line-chart',
      //获取详细数据列表
      GET_DETAIL_LIST: '/fake/operation-statistics/history/detailed-trend-list'
    }
  },
  //溯源
  trace: {
    //溯源数据统计
    home: {
      //获取关键指标数据
      GET_CRUX_QUOTA: '/trace/batch/statistic/key-indicators',
      //获取图表数据
      GET_CHART_DATA: '/trace/batch/statistic/chart',
      //获取TOP10
      GET_TOP_LIST: '/trace/batch/statistic/top-10/list',
      //获取详细数据列表
      GET_DETAIL_LIST: '/trace/batch/info/page'
    },
    //实时访问数据
    realTime: {
      //获取关键指标数据
      GET_CRUX_QUOTA: '/trace/batch/real-time/key-indicators',
      //获取图表数据
      GET_CHART_DATA: '/trace/batch/real-time/chart',
      //获取详细数据列表
      GET_DETAIL_LIST: '/trace/batch/real-time/list'
    },
    //历史访问数据
    history: {
      //获取关键指标数据
      GET_CRUX_QUOTA: '/trace/batch/history/key-indicators',
      //获取图表数据
      GET_CHART_DATA: '/trace/batch/history/chart',
      //获取详细数据列表
      GET_DETAIL_LIST: '/trace/batch/history/list'
    }
  }
}