export default {
  //获取会员列表
  GET_MEMBER_LIST: '/hydra-marketing/api/v1/member/list',
  //获取导购列表
  GET_SALER_LIST: '/hydra-marketing/api/v1/saler/list',
  //获取渠道列表
  GET_CHANNEL_LIST: '/hydra-marketing/api/v1/channel/list',
  // 积分派送
  INTEGRAL_ADD: '/hydra-marketing/api/v1/integral/addIntegral',
  // 积分扣减
  INTEGRAL_REDUCE: '/hydra-marketing/api/v1/integral/reduceIntegral',
  //批量发货
  DELIVER_GOODS: '/hydra-marketing/api/v1/goods/delivery',
  //提现发放
  CASH_PERMIT: '/hydra-marketing/api/v1/cash/permit',
  //提现驳回
  CASH_REJECT: '/hydra-marketing/api/v1/cash/reject',
  //保存H5配置
  H5_SETTING_SAVE: "/hydra-marketing/api/v1/h5setting/save",
  //获取H5配置
  H5_SETTING_GET: "/hydra-marketing/api/v1/h5setting/getByType",
  // 中奖记录
  reward: {
    //活动
    activity: '/hydra-marketing/api/v1/reward/scanCode',
    //任务
    task: '/hydra-marketing/api/v1/reward/task',
    //积分兑换,
    scoreExchange: '/hydra-marketing/api/v1/reward/exchange',
    //商城活动
    shopMallActivity: '/hydra-marketing/api/v1/reward/shop',
    //------------------------------------------------------------------
    //积分记录列表
    integral: '/hydra-marketing/api/v1/integral/list',
    //红包记录列表
    redBag: '/hydra-marketing/api/v1/money/list',
    //实物列表
    goods: '/hydra-marketing/api/v1/goods/list',
    //提现记录列表
    cash: '/hydra-marketing/api/v1/cash/list',
    //拒绝订单取消申请
    rejectCancel: '/hydra-marketing/api/v1/goods/rejectAudit',
    //同意订单取消申请
    agreeCancel: '/hydra-marketing/api/v1/goods/agreeAudit'
  },
  //导出中奖记录
  rewardExport: {
    //活动
    activity: '/hydra-marketing/api/v1/reward/scanCode/export',
    //任务
    task: '/hydra-marketing/api/v1/reward/task/export',
    //积分兑换,
    scoreExchange: '/hydra-marketing/api/v1/reward/exchange/export',
    //商城活动
    shopMallActivity: '/hydra-marketing/api/v1/reward/shop/export',
    //------------------------------------------------------------------
    //积分记录列表
    integral: '/hydra-marketing/api/v1/integral/list/export',
    //红包记录列表
    redBag: '/hydra-marketing/api/v1/money/list/export',
    //实物列表
    goods: '/hydra-marketing/api/v1/goods/list/export',
    //提现记录列表
    cash: '/hydra-marketing/api/v1/cash/list/export',
  },
  //发货导入
  deliverImport: '/hydra-marketing/api/v1/goods/deliveryByExcel',
  //核销记录
  checkTicket: {
    //提货券核销记录
    pickup: {
      GET_LIST: '/hydra-marketing/api/v1/voucher/listExchangeCardAudit',
      EXPORT: '/hydra-marketing/api/v1/voucher/listExchangeCardAudit/export'
    },
    //抵扣券核销记录
    rebate: {
      GET_LIST: '/hydra-marketing/api/v1/voucher/listReductionVoucherAudit',
      EXPORT: '/hydra-marketing/api/v1/voucher/listReductionVoucherAudit/export'
    }
  },
  //福田
  futian: {
    //对账单
    cashRecord: {
      GET_LIST: '/hydra-marketing/api/v1/reconciliation/list/futian',
      EXPORT: '/hydra-marketing/api/v1/reconciliation/list/futian/export'
    }
  }
}
