import React from 'react';
import SystemSwitch from './SystemSwitch';
import OrgSwitch from './OrgSwitch';
//
export default class extends React.Component {
  render() {
    return (
      <React.Fragment>
        <SystemSwitch/>
        <OrgSwitch/>
      </React.Fragment>
    )
  }
}
