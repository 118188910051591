// 扩展码相关接口
export default {
  // 组织设置
  orgSetting: {
    LIST: '/hydra-system-docking/api/v1/organizationSetting/list',
    DETAIL: '/hydra-system-docking/api/v1/organizationSetting/get',
    ADD: '/hydra-system-docking/api/v1/organizationSetting/add',
    UPDATE: '/hydra-system-docking/api/v1/organizationSetting/update',
    UPDATE_STATUS: '/hydra-system-docking/api/v1/organizationSetting/updateSystemStatus',
    // 扩展码类型下拉
    CODETYPE_LIST: '/hydra-system-docking/api/v1/organizationSetting/extend/code/type/list',
    // 获取系统下已经设置过当前扩展码类型的组织
    ORG_LIST: '/hydra-system-docking/api/v1/organizationSetting/sync/list'
  },
  // 扩展码数量
  codeNum: {
    LIST: '/hydra-system-docking/api/v1/extend/code/list'
  },
  // 关联同步
  sync: {
    LIST: '/hydra-system-docking/api/v1/znmCodeRelationSyncLog/list',
    // 同步
    SYNC: '/hydra-system-docking/api/v1/znmCodeRelationSyncLog',
    // 用途信息
    GET_USE: '/hydra-system-docking/api/v1/znmCodeRelationSyncLog/usage'
  }
}