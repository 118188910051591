export default {
  //登录
  LOGIN: '/hydra-user/api/v1/user/web/login',
  //退出
  LOGOUT: '/hydra-user/api/v1/user/web/logout',
  //注册
  REGISTER: '/hydra-user/api/v1/user/register',
  //图片验证码校验
  IMAGE_CODE_CHECK: '/hydra-user/api/v1/code/check/abc-code',
  //短信验证码校验
  SMS_CODE_CHECK: '/hydra-user/api/v1/sms/check/phone-code',
  // 校验手机号是否已注册
  CHECK_PHONE_EXIST: '/hydra-user/api/v1/user/check/phone/',
  //重置密码
  RESET_PASSWORD: '/hydra-user/api/v1/user/forgot-password',
  //设置密码
  SET_PASSWORD: '/hydra-user/api/v1/user/password',
  //获取用户基本信息
  GET_USER_INFO: '/hydra-user/api/v1/user/account',
  //获取用户详细信息
  GET_USER_DETAIL_INFO: '/hydra-user/api/v1/user/account/info',
  //设置绑定邮箱
  SET_MAILBOX: '/hydra-user/api/v1/user/mailBox',
  //设置姓名
  SET_NICK: '/hydra-user/api/v1/user/nick',
  //新用户更新密码
  SET_NEWUSER_PASSWORD: '/hydra-user/api/v1/user/password-only',
  //申请加入组织
  JOIN_ORG: '/hydra-user/api/v1/department/employee/aud',
  //获取用户基本资料
  GET_BASE_INFO: '/hydra-user/api/v1/user/base',
  //保存用户基本资料
  SAVE_BASE_INFO: '/hydra-user/api/v1/user/base',
  //设置头像
  SET_LOGO: '/hydra-user/api/v1/user/logo',
  //获取安全设置
  GET_SAFE_SETTING: '/hydra-user/api/v1/user/account/security',
  //修改手机号
  SET_PHONE: '/hydra-user/api/v1/user/phone',
  //判断是否需要用人机验证码
  HAS_CODE: '/hydra-user/api/v1/user/web/has-code',
  //判断是否是新迁移用户
  CHECK_MOVE_USER: '/hydra-user/api/v1/user/web/isNew',
  //设置绑定平台信息
  SET_PLATFORM: '/hydra-user/api/v1/user/set/platform',
  //
  ORG_UNION: '/hydra-user/api/v1/union-org'
};
