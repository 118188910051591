export default {
  //获取溯源记录列表
  GET_LIST: '/trace/batch/info/nodeBusinessData',
  //设置显示隐藏状态
  SET_SHOW_STATE: '/trace/dynamic/node/hide',
  //添加溯源记录
  ADD_RECORD: '/trace/dynamic/node/addNodeData',
  //编辑溯源记录
  EDIT_RECORD: '/trace/dynamic/node/update',
  //删除溯源记录
  DELETE_RECORD: '/trace/dynamic/node/delete'
}
