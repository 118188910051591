import dynamic from 'dva/dynamic';

// 需要被system引用的功能路由
const routeData = [
  // 微页工具
  {
    path: '/platform/visualTool/microPage',
    component: dynamic({
      component: () => import(/* webpackChunkName: "modules/platform/index" */ './views/visualTool/microPage/index'),
    }),
  },
  //设备管理
  //设备类型
  {
    path: '/platform/equipment/type',
    component: dynamic({
      component: () => import(/* webpackChunkName: "modules/platform/index" */ './views/equipment/type'),
    }),
  },
  //设备型号
  {
    path: '/platform/equipment/model',
    component: dynamic({
      component: () => import(/* webpackChunkName: "modules/platform/index" */ './views/equipment/model'),
    }),
  },
  //设备型号
  {
    path: '/platform/equipment/detail',
    component: dynamic({
      component: () => import(/* webpackChunkName: "modules/platform/index" */ './views/equipment/model/pages/Detail'),
    }),
  },
  //设备列表
  {
    path: '/platform/equipment/equipmentList',
    component: dynamic({
      component: () => import(/* webpackChunkName: "modules/platform/index" */ './views/equipment/equipmentList'),
    }),
  },
  {
    path: '/platform/equipment/equdetail',
    component: dynamic({
      component: () => import(/* webpackChunkName: "modules/platform/index" */ './views/equipment/equipmentList/pages/equDetail'),
    }),
  },
  {
    path: '/platform/equipment/childdetail',
    component: dynamic({
      component: () => import(/* webpackChunkName: "modules/platform/index" */ './views/equipment/equipmentList/childDevice/childDetail'),
    }),
  },
  //子设备里面的查看
  {
    path: '/platform/equipment/view',
    component: dynamic({
      component: () => import(/* webpackChunkName: "modules/platform/index" */ './views/equipment/equipmentList/view/view'),
    }),
  },
  // 系统组织管理
  {
    path: '/platform/sysOrg',
    component: dynamic({
      component: () => import(/* webpackChunkName: "modules/platform/index" */ './views/sysOrg/list'),
    }),
  },
  // 系统组织管理 -- 开通
  {
    path: '/platform/orgManage/open',
    component: dynamic({
      component: () =>
        import(/* webpackChunkName: "modules/platform/views/org" */ './views/org/open'),
    }),
  },
  // 系统组织管理 -- 详情
  {
    path: '/platform/orgManage/detail',
    component: dynamic({
      component: () =>
        import(/* webpackChunkName: "modules/platform/views/org" */ './views/org/detail'),
    }),
  },
  // 系统组织管理 -- 编辑
  {
    path: '/platform/orgManage/edit',
    component: dynamic({
      component: () =>
        import(/* webpackChunkName: "modules/platform/views/org" */ './views/org/edit'),
    }),
  },
  
  // 系统着陆页配置
  {
    path: '/platform/landingpageConfig',
    component: dynamic({
      component: () => import(/* webpackChunkName: "modules/platform/views/trace" */ './views/landingpage/config'),
    }),
  },
  // 企业着陆页显示
  {
    path: '/platform/landingPage/exhibit',
    component: dynamic({
      component: () => import(/* webpackChunkName: "modules/platform/index" */ './views/landingpage/exhibit'),
    }),
  },
  //大数据屏管理
  {
    path: '/platform/dataScreen/dataScreenManage',
    component: dynamic({
      component: () =>
        import(/* webpackChunkName: "modules/platform/dataScreen/dataScreenManage" */ './views/dataScreen/dataScreenManage'),
    }),
  },
  // 数据屏配置
  {
    path: '/platform/dataScreen/dataScreenConfig',
    component: dynamic({
      component: () =>
        import(/* webpackChunkName: "modules/platform/dataScreen/dataScreenConfig" */ './views/dataScreen/dataScreenConfig'),
    }),
  },
  
  //扩展码关联同步
  {
    path: '/plaform/extendCode/relativeSync',
    component: dynamic({
      component: () =>
        import(/* webpackChunkName: "modules/platform/dataScreen/dataScreenManage" */ './views/extendCode/relativeSync'),
    }),
  },
  //扩展码数量
  {
    path: '/plaform/extendCode/codeNumber',
    component: dynamic({
      component: () =>
        import(/* webpackChunkName: "modules/platform/dataScreen/dataScreenManage" */ './views/extendCode/codeNumber'),
    }),
  },
  //报表工具
  {
    path: '/report/tool/dashboard/:code',
    component: dynamic({
      component: () =>
        import(/* webpackChunkName: "modules/platform/report/tool/dashboard/system" */ './views/report/tool/dashboard/system'),
    }),
  }
];
export default routeData;
