export default {
  //模板类别
  //获取模板类别列表
  GET_PAGELIST: '/cms/landingpage/page/list',
  //新增模板类别
  GET_ALLORG: '/hydra-user/api/v1/org/list/platform',
  //更新模板类别,
  GET_ORGCHECK: '/cms/landingpage/exist',
  //获取单一着陆页
  GET_LANDPAGECONFIG: '/cms/card/configuration/get',
  //新增着陆页
  ADD_LANDPAGE: '/cms/landingpage',
  //编辑着陆页
  EDIT_LANDPAGE: '/cms/landingpage',
  //配置着陆页
  CONFIG_LANDPAGE: '/cms/landingpage',
  //保存卡片配置
  SAVE_CARD_CONFIG: '/cms/card/configure'
};
export const landPageConfig = {
  GET_LIST: '/hydra-base-data/api/v1/user/landing/page/page/list',
  ADD: '/hydra-base-data/api/v1/user/landing/page/add',
  EDIT: '/hydra-base-data/api/v1/user/landing/page/update',
  DELETE: '/hydra-base-data/api/v1/user/landing/page/delete',
  // 获取着陆页详情
  GET_DETAIL_INFO: '/hydra-base-data/api/v1/user/landing/page/get'
};
