import dynamic from 'dva/dynamic';

export default [
  // 种植信息化首页
  {
    path: '/information/index',
    component: dynamic({
      component: () => import(/* webpackChunkName: "modules/information/index" */ './views/plantIndex'),
    }),
  },
  // 当前种植
  {
    path: '/information/productionManage/currentPlant',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/index0" */ './views/productionManage/currentPlant'
        ),
    }),
  },
  // 生产档案
  {
    path: '/information/productionManage/productionFiles/files',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/index0" */ './views/productionManage/productionFiles/files'
        ),
    }),
  },
  // 标准化生产方案
  {
    path: '/information/productionManage/productionFiles/plan',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/index0" */ './views/productionManage/productionFiles/plan'
        ),
    }),
  },
  // 销售任务
  {
    path: '/information/productionManage/productionPlan/plan',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/index0" */ './views/productionManage/productionPlan/plan'
        ),
    }),
  },
  // 生产计划
  {
    path: '/information/productionManage/productionPlan/saleTask',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/index0" */ './views/productionManage/productionPlan/saleTask'
        ),
    }),
  },
  // 育苗计划
  {
    path: '/information/productionManage/nurseryPlan',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/index1" */ './views/productionManage/nurseryPlan'
        ),
    }),
  },
  // 采收计划
  {
    path: '/information/productionManage/recoveryManage/plan',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/index1" */ './views/productionManage/recoveryManage/plan'
        ),
    }),
  },
  // 采收报损
  {
    path: '/information/productionManage/recoveryManage/lose',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/index1" */ './views/productionManage/recoveryManage/lose'
        ),
    }),
  },
  // 常见问题
  {
    path: '/information/productionManage/guidance/question',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/index1" */ './views/productionManage/guidance/question'
        ),
    }),
  },
  // 咨询专家
  {
    path: '/information/productionManage/guidance/consult',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/index1" */ './views/productionManage/guidance/consult'
        ),
    }),
  },
  // 远程指导
  {
    path: '/information/productionManage/guidance/remoteGuide',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/index1" */ './views/productionManage/guidance/remoteGuide'
        ),
    }),
  },

  // 标准种植方案
  {
    path: '/information/productionManage/plantingScheme',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/index1" */ './views/productionManage/plantingScheme'
        ),
    }),
  },
  // 农事操作提醒
  {
    path: '/information/productionManage/farmWorkReminder',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/index1" */ './views/productionManage/farmWorkReminder'
        ),
    }),
  },
  // 生产工作计划
  {
    path: '/information/productionManage/productionWorkPlan',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/index1" */ './views/productionManage/productionWorkPlan'
        ),
    }),
  },
  {
    //新增工作计划
    path: '/information/productionManage/addProductionWorkPlan',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/index1" */ './views/productionManage/productionWorkPlan/add.js'
        ),
    }),
  },

  // 产品管理
  {
    path: '/information/product/admin/list',
    component: dynamic({
      component: () =>
        import(/* webpackChunkName: "modules/information/index2" */ './views/product/admin/list'),
    }),
  },
  {
    path: '/information/product/admin/add',
    component: dynamic({
      component: () =>
        import(/* webpackChunkName: "modules/information/index2" */ './views/product/admin/edit'),
    }),
  },
  {
    path: '/information/product/admin/edit/:id',
    component: dynamic({
      component: () =>
        import(/* webpackChunkName: "modules/information/index2" */ './views/product/admin/edit'),
    }),
  },

  // 物料分类
  {
    path: '/information/storageManage/materialManage/category',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/index2" */ './views/storageManage/materialManage/category'
        ),
    }),
  },
  // 物料管理
  {
    path: '/information/storageManage/materialManage/admin',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/index2" */ './views/storageManage/materialManage/admin'
        ),
    }),
  },
  // 物料列表--监管
  {
    path: '/information/storageManage/materialManage/adminSupervision',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/index2" */ './views/storageManage/materialManage/adminSupervision'
        ),
    }),
  },
  // 物料库存
  {
    path: '/information/storageManage/materialManage/stock',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/index3" */ './views/storageManage/materialManage/stock'
        ),
    }),
  },
  // 物料流水
  {
    path: '/information/storageManage/materialManage/stream',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/index3" */ './views/storageManage/materialManage/stream'
        ),
    }),
  },
  // 使用统计
  {
    path: '/information/storageManage/materialManage/statistics',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/index3" */ './views/storageManage/materialManage/statistics'
        ),
    }),
  },
  // 产品信息维护
  {
    path: '/information/baseInfoAssert/productSize',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/index3" */ './views/baseInfoAssert/productSize'
        ),
    }),
  },

  // 订单管理
  // 销售目标
  // 部门年度任务
  {
    path: '/information/salesManage/salesGoals/departmentYearsGoals',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/index1" */ './views/salesManage/salesGoals/departmentYearsGoals'
        ),
    }),
  },
  // 部门季度任务
  {
    path: '/information/salesManage/salesGoals/departmentSeasonGoals',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/index1" */ './views/salesManage/salesGoals/departmentSeasonGoals'
        ),
    }),
  },
  // 部门销售任务
  {
    path: '/information/salesManage/salesGoals/departmentSalesGoals',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/index1" */ './views/salesManage/salesGoals/departmentSalesGoals'
        ),
    }),
  },
  // 个人年度任务
  {
    path: '/information/salesManage/salesGoals/unitYearsGoals',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/index1" */ './views/salesManage/salesGoals/unitYearsGoals'
        ),
    }),
  },
  // 个人季度任务
  {
    path: '/information/salesManage/salesGoals/unitSeasonGoals',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/index1" */ './views/salesManage/salesGoals/unitSeasonGoals'
        ),
    }),
  },
  // 个人销售任务
  {
    path: '/information/salesManage/salesGoals/unitSalesGoals',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/index1" */ './views/salesManage/salesGoals/unitSalesGoals'
        ),
    }),
  },
  // 客户管理
  {
    path: '/information/salesManage/customerManager/salesCustomerManage',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/index1" */ './views/salesManage/customerManager/salesCustomerManage'
        ),
    }),
  },
  // 潜在客户
  {
    path: '/information/salesManage/customerManager/potentialCustomerManage',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/index1" */ './views/salesManage/customerManager/potentialCustomerManage'
        ),
    }),
  },
  // 客户分类
  {
    path: '/information/salesManage/customerManager/customerClassify',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/index2" */ './views/salesManage/customerManager/customerClassify'
        ),
    }),
  },
  // 客户维护
  {
    path: '/information/salesManage/customerManager/clientMaintain',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/index2" */ './views/salesManage/customerManager/clientMaintainManage'
        ),
    }),
  },
  // 外采订单
  {
    path: '/information/salesManage/outsideOrderManage',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/index2" */ './views/salesManage/outsideOrderManage'
        ),
    }),
  },
  // 产量预测
  {
    path: '/information/salesManage/saleOrderManage/yieldPrediction',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/index2" */ './views/salesManage/saleOrderManage/yieldPrediction'
        ),
    }),
  },
  // 订单管理
  {
    path: '/information/salesManage/saleOrderManage/orderManage',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/index2" */ './views/salesManage/saleOrderManage/orderManage'
        ),
    }),
  },
  // 仓储管理
  // 产品仓储管理
  // 产品称重
  {
    path: '/information/storageManage/productStorage/productWeight',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/index2" */ './views/storageManage/productStorage/productWeight'
        ),
    }),
  },
  // 分拣入库
  {
    path: '/information/storageManage/productStorage/sortIntoStorage',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/index2" */ './views/storageManage/productStorage/sortIntoStorage'
        ),
    }),
  },
  // 加工产品入库
  {
    path: '/information/storageManage/productStorage/processProductInStorage',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/index2" */ './views/storageManage/productStorage/processProductInStorage'
        ),
    }),
  },
  // 包装出库
  {
    path: '/information/storageManage/productStorage/packAndOutOfStock',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/index2" */ './views/storageManage/productStorage/packAndOutOfStock'
        ),
    }),
  },
  // 配送信息维护
  {
    path: '/information/storageManage/productStorage/deliveryInfo',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/index2" */ './views/storageManage/productStorage/deliveryInfo'
        ),
    }),
  },
  // 产品库存
  {
    path: '/information/storageManage/productStorage/productStock',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/index2" */ './views/storageManage/productStorage/productStock'
        ),
    }),
  },
  // 种植存量
  {
    path: '/information/storageManage/productStorage/stockOfPlanting',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/index2" */ './views/storageManage/productStorage/stockOfPlanting'
        ),
    }),
  },
  // 库存流水记录
  {
    path: '/information/storageManage/productStorage/productStockFlow',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/index3" */ './views/storageManage/productStorage/productStockFlow'
        ),
    }),
  },
  // 库存报损
  {
    path: '/information/storageManage/productStorage/stockLose',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/index3" */ './views/storageManage/productStorage/stockLose'
        ),
    }),
  },
  // 产品报损
  {
    path: '/information/storageManage/productStorage/productLose',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/index3" */ './views/storageManage/productStorage/productLose'
        ),
    }),
  },
  // 农旅管理
  // 房间类型维护
  {
    path: '/information/operationManagement/roomType',
    component: dynamic({
      component: () =>
        import(
            /* webpackChunkName: "modules/information/index7" */ './views/operationManagement/roomType'
        ),
    }),
  },
  // 娱乐项目维护
  {
    path: '/information/operationManagement/entertainmentProject',
    component: dynamic({
      component: () =>
        import(
            /* webpackChunkName: "modules/information/index7" */ './views/operationManagement/entertainmentProject'
        ),
    }),
  },
  // 来访人员管理
  {
    path: '/information/operationManagement/visitorManagement',
    component: dynamic({
      component: () =>
        import(
            /* webpackChunkName: "modules/information/index7" */ './views/operationManagement/visitorManagement'
        ),
    }),
  },


  // 产品档案
  // {
  //   path: '/information/productFile',
  //   component: dynamic({
  //     component: () =>
  //       import(/* webpackChunkName: "modules/information/index3" */ './views/productFile'),
  //   }),
  // },
  // 数据分析
  // 销售数据
  {
    path: '/information/dataAnalysis/sale',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/dataAnalysis" */ './views/dataAnalysis/sale'
        ),
    }),
  },
  // 生产数据
  {
    path: '/information/dataAnalysis/produce',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/dataAnalysis" */ './views/dataAnalysis/produce'
        ),
    }),
  },
  // 仓储数据
  {
    path: '/information/dataAnalysis/storage',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/dataAnalysis" */ './views/dataAnalysis/storage'
        ),
    }),
  },
  // 数据分析->用户使用统计
  {
    path: '/information/dataAnalysis/usageStatistic',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/dataAnalysis" */ './views/dataAnalysis/usageStatistic'
        ),
    }),
  },
  // 数据分析->管理数据->销售总数据
  {
    path: '/information/dataAnalysis/manageData/totalSalesData',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/dataAnalysis" */ './views/dataAnalysis/manageData/totalSalesData'
        ),
    }),
  },
  // 数据分析->管理数据->生产销售数据
  {
    path: '/information/dataAnalysis/manageData/productionSaleData',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/dataAnalysis" */ './views/dataAnalysis/manageData/productionSaleData'
        ),
    }),
  },
  // 数据分析->管理数据->库存报损分析
  {
    path: '/information/dataAnalysis/manageData/stockLose',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/dataAnalysis" */ './views/dataAnalysis/manageData/stockLose'
        ),
    }),
  },
  // 数据分析->管理数据->批次数据
  {
    path: '/information/dataAnalysis/manageData/batchData',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/dataAnalysis" */ './views/dataAnalysis/manageData/batchData'
        ),
    }),
  },
  // 数据分析->销售数据->销售人员数据
  {
    path: '/information/dataAnalysis/salesData/salesPerson',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/dataAnalysis" */ './views/dataAnalysis/salesData/salesPerson'
        ),
    }),
  },
  // 数据分析->销售数据->客户数据
  {
    path: '/information/dataAnalysis/salesData/customerData',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/dataAnalysis" */ './views/dataAnalysis/salesData/customerData'
        ),
    }),
  },
  // 数据分析->销售数据->销售产品数据
  {
    path: '/information/dataAnalysis/salesData/saleProduct',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/dataAnalysis" */ './views/dataAnalysis/salesData/saleProduct'
        ),
    }),
  },
  // 数据分析->销售数据->订单分析数据
  {
    path: '/information/dataAnalysis/salesData/orderAnalysis',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/dataAnalysis" */ './views/dataAnalysis/salesData/orderAnalysis'
        ),
    }),
  },
  // 数据分析->仓储数据->仓储数据
  {
    path: '/information/dataAnalysis/storageData/storage',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/dataAnalysis" */ './views/dataAnalysis/storageData/storage'
        ),
    }),
  },
  // 数据分析->仓储数据->种植仓储数据
  {
    path: '/information/dataAnalysis/storageData/plantStorage',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/dataAnalysis" */ './views/dataAnalysis/storageData/plantStorage'
        ),
    }),
  },
  // 数据分析->仓储数据->分拣入库数据
  {
    path: '/information/dataAnalysis/storageData/inStorage',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/dataAnalysis" */ './views/dataAnalysis/storageData/inStorage'
        ),
    }),
  },
  // 数据分析->生产数据->生产总数据
  {
    path: '/information/dataAnalysis/produceData/totalProduceData',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/dataAnalysis" */ './views/dataAnalysis/produceData/totalProduceData'
        ),
    }),
  },
  // 数据分析->仓库数据->仓储总数据
  {
    path: '/information/dataAnalysis/storageData/totalStorageData',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/dataAnalysis" */ './views/dataAnalysis/storageData/totalStorageData'
        ),
    }),
  },
  // 数据分析->销售数据->销售目标数据
  {
    path: '/information/dataAnalysis/salesData/salesGoal',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/dataAnalysis" */ './views/dataAnalysis/salesData/salesGoal'
        ),
    }),
  },
  // 数据分析->销售数据->销售产品数据（新）
  {
    path: '/information/dataAnalysis/salesData/salesProduct',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/dataAnalysis" */ './views/dataAnalysis/salesData/salesProduct'
        ),
    }),
  },
  // 数据分析->销售数据->订单回款数据
  {
    path: '/information/dataAnalysis/salesData/orderReturn',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/dataAnalysis" */ './views/dataAnalysis/salesData/orderReturn'
        ),
    }),
  },
  // 数据分析->销售数据->客户分类数据
  {
    path: '/information/dataAnalysis/salesData/customerClassify',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/dataAnalysis" */ './views/dataAnalysis/salesData/customerClassify'
        ),
    }),
  },
  // 数据分析->销售数据->订单情况数据
  {
    path: '/information/dataAnalysis/salesData/orderStateData',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/dataAnalysis" */ './views/dataAnalysis/salesData/orderStateData'
        ),
    }),
  },
  // 数据分析->销售数据->客户数据
  {
    path: '/information/dataAnalysis/salesData/clientData',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/dataAnalysis" */ './views/dataAnalysis/salesData/clientData'
        ),
    }),
  },
  // 数据分析->来访人员数据->来访客户数据
  {
    path: '/information/dataAnalysis/visitors/client',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/dataAnalysis" */ './views/dataAnalysis/visitors/client'
        ),
    }),
  },
  // 数据分析->来访人员数据->来访项目数据
  {
    path: '/information/dataAnalysis/visitors/project',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/dataAnalysis" */ './views/dataAnalysis/visitors/project'
        ),
    }),
  },
  // 数据分析->来访人员数据->来访金额数据
  {
    path: '/information/dataAnalysis/visitors/money',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/dataAnalysis" */ './views/dataAnalysis/visitors/money'
        ),
    }),
  },
  // 数据分析->生产数据->生产产量数据
  {
    path: '/information/dataAnalysis/produceData/productionOutput',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/dataAnalysis" */ './views/dataAnalysis/produceData/productionOutput'
        ),
    }),
  },
  // 数据分析->生产数据->产量对比数据
  {
    path: '/information/dataAnalysis/produceData/productionComparison',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/dataAnalysis" */ './views/dataAnalysis/produceData/productionComparison'
        ),
    }),
  },
  // 数据分析->生产数据->产品产量数据
  {
    path: '/information/dataAnalysis/produceData/productOutput',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/dataAnalysis" */ './views/dataAnalysis/produceData/productOutput'
        ),
    }),
  },
  // 数据分析->生产数据->区域数据
  {
    path: '/information/dataAnalysis/produceData/greenHouseData',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/dataAnalysis" */ './views/dataAnalysis/produceData/greenHouseData'
        ),
    }),
  },
  // 数据分析->仓储数据->报损数据
  {
    path: '/information/dataAnalysis/storageData/lossData',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/dataAnalysis" */ './views/dataAnalysis/storageData/lossData'
        ),
    }),
  },
  // 数据分析->仓储数据->发货数据
  {
    path: '/information/dataAnalysis/storageData/deliverData',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/dataAnalysis" */ './views/dataAnalysis/storageData/deliverData'
        ),
    }),
  },
  // 数据分析->投入数据->成本投入数据
  {
    path: '/information/dataAnalysis/inject/cost',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/dataAnalysis" */ './views/dataAnalysis/inject/cost'
          ),
    }),
  },
  // 数据分析->投入数据->投入产出数据
  {
    path: '/information/dataAnalysis/inject/output',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/dataAnalysis" */ './views/dataAnalysis/inject/output'
          ),
    }),
  },

  // 生产数据概览
  {
    path: '/information/dataAnalysis/produceData/productCharts',
    component: dynamic({
      component: () =>
        import(
            /* webpackChunkName: "modules/information/dataAnalysis" */ './views/dataAnalysis/produceData/productCharts'
        ),
    }),
  },

  // 销售数据概览
  {
    path: '/information/dataAnalysis/salesData/salesCharts',
    component: dynamic({
      component: () =>
        import(
              /* webpackChunkName: "modules/information/dataAnalysis" */ './views/dataAnalysis/salesData/salesCharts'
        ),
    }),
  },

  // 仓储数据概览
  {
    path: '/information/dataAnalysis/manageData/manageCharts',
    component: dynamic({
      component: () =>
        import(
            /* webpackChunkName: "modules/information/dataAnalysis" */ './views/dataAnalysis/manageData/manageCharts'
        ),
    }),
  },

  // 采茶工管理
  // 采茶工管理
  {
    path: '/information/teaPicker/teaPicker',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/index0" */
          './views/teaPicker/teaPicker'
        ),
    }),
  },
  // 劳务公司管理
  {
    path: '/information/teaPicker/labourCompany',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/index0" */
          './views/teaPicker/labourCompany'
        ),
    }),
  },
  // 测量体温
  {
    path: '/information/teaPicker/measureTemperature',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/index0" */
          './views/teaPicker/measureTemperature'
        ),
    }),
  },
  // 用工管理
  // 竹筐管理
  {
    path: '/information/employmentManagement/basketManage',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/index0" */
          './views/employmentManagement/basketManage'
        ),
    }),
  },
  // 竹筐关联
  {
    path: '/information/employmentManagement/basketRelate',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/index0" */
          './views/employmentManagement/basketRelate'
        ),
    }),
  },
  // 用工管理
  {
    path: '/information/employmentManagement/employManage',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/index0" */
          './views/employmentManagement/employManage'
        ),
    }),
  },
  //巡检管理
  {
    //巡检记录
    path: '/information/inspectionRecord',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/views/index" */ './views/inspectionMange/inspectionRecord/index.js'
        ),
    }),
  },
  {
    //行为管理
    path: '/information/behaviorMange',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/views/index" */ './views/inspectionMange/behaviorMange/index.js'
        ),
    }),
  },
  {
    //茶农排名
    path: '/information/teaFarmersRanked',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/views/index" */ './views/inspectionMange/teaFarmersRanked/index.js'
        ),
    }),
  },
  //茶青交易数据
  {
    //实时交易数据
    path: '/information/realTimeData',
    component: dynamic({
      component: () =>
        import(
           /* webpackChunkName: "modules/information/views/index" */ './views/dataAnalysis/teaGreenTradingData/realTimeData/index.js'
        ),
    }),
  },
  {
    //历史交易数据
    path: '/information/historyData',
    component: dynamic({
      component: () =>
        import(
            /* webpackChunkName: "modules/information/views/index" */ './views/dataAnalysis/teaGreenTradingData/historyData/index.js'
        ),
    }),
  },
  {
    // 群星历史交易数据
    path: '/information/qunxing/historyData',
    component: dynamic({
      component: () =>
        import(
            /* webpackChunkName: "modules/information/views/index" */ './views/dataAnalysis/teaGreenTradingData/qunXingHistoryData/index.js'
        ),
    }),
  },
  // 系统单位设置
  {
    path: '/information/base/setUnit',
    component: dynamic({
      component: () =>
        import(
            /* webpackChunkName: "modules/information/views/index" */ './views/base/setUnit/index.js'
        ),
    }),
  },
  // 产品规格维护
  {
    path: '/information/base/productSpecifications',
    component: dynamic({
      component: () =>
        import(
            /* webpackChunkName: "modules/information/views/index" */ './views/base/productSpecifications/index.js'
        ),
    }),
  },
  // 维护材料
  {
    path: '/information/maintenanceManage/materials',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/index1" */ './views/maintenanceManage/materials'
        ),
    }),
  },
  // 维护材料分类
  {
    path: '/information/maintenanceManage/materialsClassify',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/index1" */ './views/maintenanceManage/materialsClassify'
        ),
    }),
  },
  // 维修材料库存
  {
    path: '/information/maintenanceManage/materialStock',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/index1" */ './views/maintenanceManage/materialStock'
        ),
    }),
  },
  // 维修管理
  {
    path: '/information/maintenanceManage/management',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/index1" */ './views/maintenanceManage/management'
        ),
    }),
  },
  // 巡检管理
  {
    path: '/information/maintenanceManage/inspectionManage',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/index1" */ './views/maintenanceManage/inspectionManage'
        ),
    }),
  },
  // 包材配置
  {
    path: '/information/maintenanceManage/config',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/index1" */ './views/maintenanceManage/config'
        ),
    }),
  },
  // 电子秤维护
  {
    path: '/information/maintenanceElectronicScale',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/index1" */ './views/maintenanceElectronicScale'
        ),
    }),
  },

  // 农产品收购管理
  // 合作社管理
  {
    path: '/information/agriculturalProductAcquisition/cooperativeManage',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/index1" */ './views/agriculturalProductAcquisition/cooperativeManage'
        ),
    }),
  },
  // 合作社新增
  {
    path: '/information/agriculturalProductAcquisition/addCooperativeManage',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/index1" */ './views/agriculturalProductAcquisition/cooperativeManage/add'
        ),
    }),
  },
  // 农户管理
  {
    path: '/information/agriculturalProductAcquisition/teaFarmersManage',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/index1" */ './views/agriculturalProductAcquisition/teaFarmersManage'
        ),
    }),
  },
  // 农户新增
  {
    path: '/information/agriculturalProductAcquisition/addTeaFarmersManage',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/index1" */ './views/agriculturalProductAcquisition/teaFarmersManage/add'
        ),
    }),
  },
  // 收购总重
  {
    path: '/information/agriculturalProductAcquisition/teaWeight',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/index1" */ './views/agriculturalProductAcquisition/teaWeight'
        ),
    }),
  },
  // 收购总重新增
  {
    path: '/information/agriculturalProductAcquisition/addTeaWeight',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/index1" */ './views/agriculturalProductAcquisition/teaWeight/add'
        ),
    }),
  },
  // 收购皮重
  {
    path: '/information/agriculturalProductAcquisition/teaTare',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/index1" */ './views/agriculturalProductAcquisition/teaTare'
        ),
    }),
  },
  // 收购皮称重
  {
    path: '/information/agriculturalProductAcquisition/addTeaTare',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/index1" */ './views/agriculturalProductAcquisition/teaTare/add'
        ),
    }),
  },
  // 农产品收购
  {
    path: '/information/agriculturalProductAcquisition/teaAcquisitionRecord',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/index1" */ './views/agriculturalProductAcquisition/teaAcquisitionRecord'
        ),
    }),
  },
  // 收购批次
  {
    path: '/information/agriculturalProductAcquisition/teaBatch',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/index1" */ './views/agriculturalProductAcquisition/teaBatch'
        ),
    }),
  },
  // 收购农产品等级
  {
    path: '/information/agriculturalProductAcquisition/productGrade',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/index1" */ './views/agriculturalProductAcquisition/productGrade'
        ),
    }),
  },

  // 采购管理
  // 采购管理
  {
    path: '/information/purchaseManage/purchase',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/index1" */ './views/purchaseManage/purchase'
        ),
    }),
  },
  // 采购供应商管理
  {
    path: '/information/purchaseManage/supplier',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/index1" */ './views/purchaseManage/supplier'
        ),
    }),
  },
  // 采购商品维护
  {
    path: '/information/purchaseManage/product',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/index1" */ './views/purchaseManage/product'
        ),
    }),
  },
  // 用工管理
  {
    path: '/information/employmentManage',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/index1" */ './views/employmentManage'
        ),
    }),
  },

  // 基地地图配置
  {
    path: '/information/base/mapConfig',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/index1" */ './views/base/mapConfig'
        ),
    }),
  },
  // 农户标签
  {
    path: '/information/farmer/tag',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/index1" */ './views/agriculturalProductAcquisition/farmerTag'
        ),
    }),
  },
  // 茶青收购统计
  {
    path: '/information/transStatistic',
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/information/index1" */ './views/dataAnalysis/teaGreenTradingData/transStatistic'
        ),
    }),
  },
];
