export default {
  //获取平台存储详情
  GET_STORAGE_DETAIL: '/hydra-base-data/api/v1/storage/platform',
  //组织列表
  GET_ORG_LIST: '/hydra-base-data/api/v1/video/org/config/page',
  //开通
  ORG_OPEN: '/hydra-base-data/api/v1/video/org/config/open-status',
  //关闭
  ORG_CLOSE: '/hydra-base-data/api/v1/video/org/config/open-status',
  //调整容量
  SET_SPACE: '/hydra-base-data/api/v1/video/org/config/capacity-adjustment',
  //视频列表
  GET_VIDEO_LIST: '/hydra-base-data/api/v1/video/page',
  //删除视频
  DELETE_VIDEO: '/hydra-base-data/api/v1/video'
}