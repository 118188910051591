export default {
  //数据源
  dataSource: {
    //查询列表
    GET_LIST: '/hydra-common-report/api/v1/platform/datasource/list', //get
    //新增
    ADD: '/hydra-common-report/api/v1/platform/datasource/add', //post
    //编辑
    EDIT: '/hydra-common-report/api/v1/platform/datasource/update', //put
    //删除
    DELETE: '/hydra-common-report/api/v1/platform/datasource/delete', //delete
    //获取详情(用于编辑)
    DETAIL: '/hydra-common-report/api/v1/platform/datasource/detail', //get
    //测试数据源
    CHECK: '/hydra-common-report/api/v1/platform/datasource/test', //post
  },
  //数据集
  dataGroup: {
    //查询列表
    GET_LIST: '/hydra-common-report/api/v1/platform/data/list',
    //新增
    ADD: '/hydra-common-report/api/v1/platform/data/add',
    //编辑
    EDIT: '/hydra-common-report/api/v1/platform/data/update',
    //配置(SQL)
    CONFIG_SQL: '/hydra-common-report/api/v1/platform/data/sql',
    //配置(API)
    CONFIG_API: '/hydra-common-report/api/v1/platform/data/api',
    //删除
    DELETE: '/hydra-common-report/api/v1/platform/data/delete',
    //获取详情(用于编辑)
    DETAIL: '/hydra-common-report/api/v1/platform/data/getDetail',
    //执行sql
    EXECUTE_SQL: '/hydra-common-report/api/v1/platform/data/executeSql',
    //执行Api
    EXECUTE_API: '/hydra-common-report/api/v1/platform/data/executeApi'
  },
  //api解析器
  apiParser: {
    //查询列表
    GET_LIST: '/hydra-common-report/api/v1/platform/apiconverter/list',
    //新增
    ADD: '/hydra-common-report/api/v1/platform/apiconverter/add',
    //编辑
    EDIT: '/hydra-common-report/api/v1/platform/apiconverter/update',
    //删除
    DELETE: '/hydra-common-report/api/v1/platform/apiconverter/delete',
    //获取详情(用于编辑)
    DETAIL: '/hydra-common-report/api/v1/platform/apiconverter/getById'
  },
  //字典管理
  dictionary: {
    //查询列表
    GET_LIST: '/hydra-common-report/api/v1/platform/dict/list',
    //新增
    ADD: '/hydra-common-report/api/v1/platform/dict/add',
    //编辑
    EDIT: '/hydra-common-report/api/v1/platform/dict/update',
    //删除
    DELETE: '/hydra-common-report/api/v1/platform/dict/delete',
    //获取详情(用于编辑)
    DETAIL: '/hydra-common-report/api/v1/platform/dict/detail',
    //字典配置项管理
    options: {
      //查询列表
      GET_LIST: '/hydra-common-report/api/v1/platform/dictItem/list', //get
      //新增
      ADD: '/hydra-common-report/api/v1/platform/dictItem/add', //post
      //编辑
      EDIT: '/hydra-common-report/api/v1/platform/dictItem/update', //put
      //删除
      DELETE: '/hydra-common-report/api/v1/platform/dictItem/delete', //delete
      //获取详情(用于编辑)
      DETAIL: '/hydra-common-report/api/v1/platform/dictItem/detail' //get
    }
  },
  //自定义转化器
  converter: {
    //查询列表
    GET_LIST: '/hydra-common-report/api/v1/platform/handlerglue/list',
    //新增
    ADD: '/hydra-common-report/api/v1/platform/handlerglue/add',
    //编辑
    EDIT: '/hydra-common-report/api/v1/platform/handlerglue/update',
    //删除
    DELETE: '/hydra-common-report/api/v1/platform/handlerglue/delete',
    //获取详情(用于编辑)
    DETAIL: '/hydra-common-report/api/v1/platform/handlerglue/getById'
  },
  //仪表盘
  dashboard: {
    //查询列表
    GET_LIST: '/hydra-common-report/api/v1/platform/dashboard/list',
    //新增
    ADD: '/hydra-common-report/api/v1/platform/dashboard/add',
    //编辑
    EDIT: '/hydra-common-report/api/v1/platform/dashboard/update',
    //配置
    CONFIG: '/hydra-common-report/api/v1/platform/dashboard/set',
    //删除
    DELETE: '/hydra-common-report/api/v1/platform/dashboard/delete',
    //获取详情(用于编辑)
    DETAIL: '/hydra-common-report/api/v1/platform/dashboard/detail',
    //获取查询规则列表
    GET_QUERY_RULE_LIST: '/hydra-common-report/api/v1/platform/dashboard/getQueryRuleConfig',
    //
    group: {
      //获取分组列表
      GET_LIST: '/hydra-common-report/api/v1/platform/group/list',
      //新增分组
      ADD: '/hydra-common-report/api/v1/platform/group/add',
      //修改分组
      EDIT: '/hydra-common-report/api/v1/platform/group/update',
      //删除分组
      DELETE: '/hydra-common-report/api/v1/platform/group/delete'
    },
    //
    system: {
      //系统获取仪表盘详情
      DETAIL: '/hydra-common-report/api/v1/system/dashboard/detail',
      //系统获取仪表盘数据
      GET_CHART_DATA: '/hydra-common-report/api/v1/system/dashboard/getChartData'
    }
  },
}
