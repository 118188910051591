export default {
  //产品分类
  productCategory: {
    // 获取产品分类
    PRODUCT_SORT_FIRSTS:
      "/agricultural-product-wholesale/api/v1/product-sort/list",
    // 获取产品子分类
    PRODUCT_SORT_CHILD:
      "/agricultural-product-wholesale/api/v1/product-sort/getChilds",
    // 增加产品分类
    PRODUCT_SORT: "/agricultural-product-wholesale/api/v1/product-sort/add",
    // 编辑产品分类
    EDIT_PRODUCT_SORT:
      "/agricultural-product-wholesale/api/v1/product-sort/update",
    // 删除产品分类
    DEL_PRODUCT_SORT:
      "/agricultural-product-wholesale/api/v1/product-sort/delete",
    // 获分类信息
    GET_DETAILS: "/agricultural-product-wholesale/api/v1/product-sort/get",
  },
  //产品管理
  productMange: {
    GET_LIST: "/agricultural-product-wholesale/api/v1/product/list",
    GET: "/agricultural-product-wholesale/api/v1/product/get",
    // 获取产品列表
    SAVE: "/agricultural-product-wholesale/api/v1/product/add",
    // 禁用产品
    DISABLE: "/agricultural-product-wholesale/api/v1/product/disable",
    // 启用产品
    ENABLE: "/agricultural-product-wholesale/api/v1/product/enable",
    // 更新产品
    UPDATE: "/agricultural-product-wholesale/api/v1/product/update",
    // 获取当前系统标签
    SYS_LABEL: "/hydra-user/api/v1/sys/label",
    //品牌
    GET_BRAND: "/agricultural-product-wholesale/api/v1/product-brand/list",
  },
  //单位管理
  unitMangement: {
    GET_LIST: "/agricultural-product-wholesale/api/v1/unit/list",
    SAVE: "/agricultural-product-wholesale/api/v1/unit/add",
    GET: "/agricultural-product-wholesale/api/v1/unit/get",
    UPDATE: "/agricultural-product-wholesale/api/v1/unit/update",
    DELETE: "/agricultural-product-wholesale/api/v1/unit/delete",
  },
  //评选内容管理
  selectionManagementType: {
    GET_LIST: "/agricultural-product-wholesale/api/v1/selection-category/list",
    SAVE: "/agricultural-product-wholesale/api/v1/selection-category/add",
    GET: "/agricultural-product-wholesale/api/v1/selection-category/get",
    UPDATE: "/agricultural-product-wholesale/api/v1/selection-category/update",
    DELETE: "/agricultural-product-wholesale/api/v1/selection/delete",
    DISABLE:
      "/agricultural-product-wholesale/api/v1/selection-category/disable",
    // 启用产品
    ENABLE: "/agricultural-product-wholesale/api/v1/selection-category/enable",
  },
  //评选管理
  selectionManagementSelect: {
    GET_LIST: "/agricultural-product-wholesale/api/v1/selection/list",
    SAVE: "/agricultural-product-wholesale/api/v1/selection/add",
    GET: "/agricultural-product-wholesale/api/v1/selection/get",
    UPDATE: "/agricultural-product-wholesale/api/v1/selection/update",
    ORG_LIST: "/hydra-user/api/v1/org/list/platform",
    //获取关联组织
    GET_ASSOCIATE:
      "/agricultural-product-wholesale/api/v1/selection-record/relate",
    //获取未关联组织
    GET_NO_ASSOCIATE:
      "/agricultural-product-wholesale/api/v1/selection-record/orgList",
    ADD_ASSOCIATE:
      "/agricultural-product-wholesale/api/v1/selection-record/add",
    DEl_ASSOCIATE:
      "/agricultural-product-wholesale/api/v1/selection-record/delete",
  },
  //评选记录
  selectionManagementRecord: {
    GET_LIST: "/agricultural-product-wholesale/api/v1/selection-record/list",
  },
  //电子秤列表
  electronicScaleList: {
    GET_LIST: "/agricultural-product-wholesale/api/v1/electronic-scale/list",
    SAVE: "/agricultural-product-wholesale/api/v1/electronic-scale/add",
    GET: "/agricultural-product-wholesale/api/v1/electronic-scale/get",
    UPDATE: "/agricultural-product-wholesale/api/v1/electronic-scale/update",
  },
  //电子秤管理
  electronicScaleRental: {
    GET_LIST:
      "/agricultural-product-wholesale/api/v1/electronic-scale-rend/list",
    SAVE: "/agricultural-product-wholesale/api/v1/electronic-scale-rend/add",
    GET: "/agricultural-product-wholesale/api/v1/electronic-scale-rend/get",
    UPDATE:
      "/agricultural-product-wholesale/api/v1/electronic-scale-rend/update",
    GET_ORG: "/hydra-user/api/v1/org/sys/list",
  },
  //检测批次管理
  batch: {
    GET_LIST: "/agricultural-product-wholesale/api/v1/detection-batch/list",
    SAVE: "/agricultural-product-wholesale/api/v1/detection-batch/add",
    GET: "/agricultural-product-wholesale/api/v1/detection-batch/get",
    UPDATE: "/agricultural-product-wholesale/api/v1/detection-batch/update",
  },
  //溯源
  quality: {
    checkItem: {
      GET_LIST: "/trace/producttesting/testingtype/page",
      EDIT: "/trace/producttesting/testingtype",
      ADD: "/trace/producttesting/testingtype",
      GET_ORG_LIST: "/hydra-user/api/v1/org/list/sys",
      GET_DEPARTMENT_LIST: "/hydra-user/api/v1/org/dept/listAll",
      GET_THRID_LIKE: "/hydra-open-thirdpart-service/business/open/thrid/like",
    },
    // 内部检测
    // insideCheck: {
    //   GET_LIST: "/trace/producttesting/producttesting/page",
    //   EDIT: "/trace/producttesting/producttesting",
    //   ADD: "/trace/producttesting/producttesting",
    //   DELETE: "/trace/producttesting/producttesting",
    //   GET_DETAIL: "/trace/producttesting/producttesting/getById",
    //   GET_PLATFORM_ORG: "/hydra-user/api/v1/org/list/platform",
    //   auto: {
    //     PRODUCT_LIST: "/hydra-base-data/api/v1/product/enable/list", //产品
    //     PRODUCT_BATCH_LIST:
    //       "/agricultural-product-wholesale/api/v1/detection-batch/list", //产品批次
    //     EMPLOYEE: "/hydra-user/api/v1/employee/enable/list/all", //检测人员
    //     STANDARD_LIST: "/hydra-base-data/api/v1/config/type?typeId=035", //检测标准
    //     DEVICE_LIST: "/hydra-base-data/api/v1/config/type?typeId=036", //检测设备
    //   },
    // },
    insideCheck: {
      GET_LIST: "/agricultural-product-wholesale/api/v1/product-test/list",
      EDIT: "/agricultural-product-wholesale/api/v1/product-test/update",
      ADD: "/agricultural-product-wholesale/api/v1/product-test/add",
      DELETE: "/agricultural-product-wholesale/api/v1/product-test/delete",
      GET_DETAIL: "/agricultural-product-wholesale/api/v1/product-test/get",
      GET_PLATFORM_ORG: "/hydra-user/api/v1/org/list/platform",
      auto: {
        PRODUCT_LIST: "/hydra-base-data/api/v1/product/enable/list", //产品
        PRODUCT_BATCH_LIST:
          "/agricultural-product-wholesale/api/v1/detection-batch/list", //产品批次
        EMPLOYEE: "/hydra-user/api/v1/employee/enable/list/all", //检测人员
        STANDARD_LIST: "/hydra-base-data/api/v1/config/type?typeId=035", //检测标准
        DEVICE_LIST: "/hydra-base-data/api/v1/config/type?typeId=036", //检测设备
      },
      EXPORT: "/agricultural-product-wholesale/api/v1/product-test/down",
    },
    // 检测标准
    testStandard: {
      GET_LIST: "/trace/producttesting/testingstandard/list",
      EDIT: "/trace/producttesting/testingstandard",
      ADD: "/trace/producttesting/testingstandard",
    },
  },
  // 功能
  function: {
    // 获取企业名称
    THIRD_LIKE: "/hydra-open-thirdpart-service/business/open/thrid/like",
    // 获取库存列表
    PRODUCT_STOCK:
      "/agricultural-product-wholesale/api/v1/app/product-stock/list",
    // 获取库存流水记录
    STOCK_RECORD:
      "/agricultural-product-wholesale/api/v1/app/stock-bill/getProductStockRecord",
    // 产品入库
    STOCK_BILLIN:
      "/agricultural-product-wholesale/api/v1/app/stock-bill/stockBillIn",
    // 产品出库
    STOCK_BILLOUT:
      "/agricultural-product-wholesale/api/v1/app/stock-bill/stockBillOut",
    // 库存预警
    STOCK_WARNING:
      "/agricultural-product-wholesale/api/v1/app/product-stock/setWarning",
    // 获取供应商列表
    LIST_MERCHANT:
      "/agricultural-product-wholesale/api/v1/appSupplier/listMerchant",
    //获取客户列表
    CUSTOMER_LIST: "/agricultural-product-wholesale/api/v1/customer/appList",
    // 新增供应商
    SUPPLIER_ADD: "/agricultural-product-wholesale/api/v1/appSupplier/add",
    // 禁用
    SUPPLIER_DISABLE:
      "/agricultural-product-wholesale/api/v1/appSupplier/disable",
    // 启用
    SUPPLIER_ENABLE:
      "/agricultural-product-wholesale/api/v1/appSupplier/enable",
    // 查看
    SUPPLIER_SEE: "/agricultural-product-wholesale/api/v1/appSupplier/get",
    // 编辑
    SUPPLIER_UPDATE:
      "/agricultural-product-wholesale/api/v1/appSupplier/update",
    // 获取企业信息，天眼查或系统获取
    GET_ORG_LIST_BY_ALL: "/hydra-user/api/v1/org/info-or-from-third",
  },
  // 订单
  orderInfo: {
    // 批量付款接口
    BATCHPAY: "/agricultural-product-wholesale/api/v1/app/order/batchPay",
    // 销售订单列表
    SALE_LIST: "/agricultural-product-wholesale/api/v1/app/order/list",
    // 退货订单
    RETURN_LIST: "/agricultural-product-wholesale/api/v1/app/order/list",
    // 销售订单详情
    SALE_DETAIL: "/agricultural-product-wholesale/api/v1/app/order/get",
    // 退货订单
    RETURN_GOODS:
      "/agricultural-product-wholesale/api/v1/app/order/returnedPurchase",
    // 退货详情
    BACK_DETAIL:
      "/agricultural-product-wholesale/api/v1/app/order/getChargeBack",
    // 退货列表
    BACK_LIST:
      "/agricultural-product-wholesale/api/v1/app/order/getReturnOrderList",
    // 补打小票
    ORDER_PRINT:
      "/agricultural-product-wholesale/api/v1/app/order/getTicketInfo",
    // 获取退货单信息
    GET_RETURN_ORDER:
      "/agricultural-product-wholesale/api/v1/app/order/getReturnOrder",
  },
  // 分体屏
  screen: {
    // 获取经营户信息
    SCREEN_LIST: "/agricultural-product-wholesale/api/v1/fissionScreen/getOrg",
    // 获取活动商品
    SCREEN_ACTIVITY:
      "/agricultural-product-wholesale/api/v1/fissionScreen/getFissionProduct",
    // 营业执照及检疫证上传
    SCREEN_UPLOAD:
      "/agricultural-product-wholesale/api/v1/fissionScreen/addImg",
  },
  // 投诉
  complaint: {
    //  投诉列表
    COMPLAINT_LIST:
      "/agricultural-product-wholesale/api/v1/merchant/complaint/merchantList",
    // 新增投诉
    COMPLAINT_ADD:
      "/agricultural-product-wholesale/api/v1/merchant/complaint/add",
    // 撤销投诉
    COMPLAINT_CANCEL:
      "/agricultural-product-wholesale/api/v1/merchant/complaint/cancel",
    // 获取部门名称
    COMPLAINT_NAME:
      "/agricultural-product-wholesale/api/v1/merchant/complaint/deptList",
    // 获取投诉人信息
    ACCOUNT_INFO: "/hydra-user/api/v1/user/account/info",
  },
  //  活动设置
  action: {
    // 活动列表
    ACTIVITY_LIST:
      "/agricultural-product-wholesale/api/v1/app/activity-product/list",
    // 活动删除
    ACTIVITY_DELETE:
      "/agricultural-product-wholesale/api/v1/app/activity-product/delete",
    // 活动更新
    ACTIVITY_EDIT:
      "/agricultural-product-wholesale/api/v1/app/activity-product/update",
  },
  //负库存预警
  inventoryWarning: {
    GET_LIST:
      "/agricultural-product-wholesale/api/v1/product-stock/getProductStock",
  },
  //消息通知
  notification: {
    GET_LIST: "/agricultural-product-wholesale/api/v1/message/list",
    SAVE: "/agricultural-product-wholesale/api/v1/message/add",
    GET: "/agricultural-product-wholesale/api/v1/message/get",
    UPDATE: "/agricultural-product-wholesale/api/v1/message/update",
    // 禁用
    DISABLE: "/agricultural-product-wholesale/api/v1/message/disable",
    // 启用
    ENABLE: "/agricultural-product-wholesale/api/v1/message/enable",
    NO_ASSOCIATE: "/agricultural-product-wholesale/api/v1/message/orgList",
    ASSOCIATE: "/agricultural-product-wholesale/api/v1/message/relate",
    DEL_ASSOCIATE: "/agricultural-product-wholesale/api/v1/message/deleteOrg",
  },
  //违规监管记录
  violationOfSupervisionRrecord: {
    GET_LIST: "/agricultural-product-wholesale/api/v1/supervise/list",
    SAVE: "/agricultural-product-wholesale/api/v1/supervise/add",
    UPDATE: "/agricultural-product-wholesale/api/v1/supervise/update",
    GET: "/agricultural-product-wholesale/api/v1/supervise/get",
    //监管部门
    GET_DEPAERT: "/hydra-user/api/v1/org/dept/listAll",
    //监管人员
    GET_PEOPLE: "/hydra-user/api/v1/org/dept/departmentAll",
    // 投诉对象
    COMPLAIN_PERSON:
      "/agricultural-product-wholesale/api/v1/merchant/complaint/employeeList",
    //发送短息
    DUANXIN: "/agricultural-product-wholesale/api/v1/supervise/sendMessage",
    //办结
    FINISH: "/agricultural-product-wholesale/api/v1/supervise/finish",
    //获取下级组织
    GET_NEXT_ORG: "/agricultural-product-wholesale/api/v1/merchant/getOrg",
    GET_NEXT_TWO_ORG:
      "/agricultural-product-wholesale/api/v1/merchant/getDownOrg",
    GET_DUNXIN_RECORD:
      "/agricultural-product-wholesale/api/v1/supervise/getMessage",
  },
  //投诉管理
  violationOfSupervisionComplaint: {
    GET_LIST: "/agricultural-product-wholesale/api/v1/complaint/list",
    GET: "/agricultural-product-wholesale/api/v1/complaint/get",
    FINISH: "/agricultural-product-wholesale/api/v1/complaint/finish",
  },
  agriculturalBatchCode: {
    mange: {
      GET_LIST: "/agricultural-product-wholesale/api/v1/code-batch/list",
      SAVE: "/agricultural-product-wholesale/api/v1/code-batch/createCode",
      DOWN: "/agricultural-product-wholesale/api/v1/code-batch/down",
    },
    associate: {
      GET_LIST: "/agricultural-product-wholesale/api/v1/code-batch/getCodeList",
      ASSOCIATE: "/agricultural-product-wholesale/api/v1/code-batch/codeBind",
    },
    informationManagement: {
      GET_LIST: "/agricultural-product-wholesale/api/v1/customer/list",
      GET: "/agricultural-product-wholesale/api/v1/customer/get",
      UPDATE: "/agricultural-product-wholesale/api/v1/customer/update",
    },
  },
  operator: {
    mange: {
      // 获取国家列表
      GET_COUNTRY_LIST: "/hydra-base-data/api/v1//country/list",
      GET_LIST: "/agricultural-product-wholesale/api/v1/merchant/list",
      SAVE: "/agricultural-product-wholesale/api/v1/merchant/add",
      GET: "/agricultural-product-wholesale/api/v1/merchant/get",
      UPDATE: "/agricultural-product-wholesale/api/v1/merchant/update",
      GET_ALL_ORG: "/hydra-open-thirdpart-service/business/open/thrid/like",
      GET_FILTER_ORG:
        "/agricultural-product-wholesale/api/v1/merchant/getMerchantOrg",
      // 禁用
      DISABLE: "/agricultural-product-wholesale/api/v1/merchant/disable",
      // 启用
      ENABLE: "/agricultural-product-wholesale/api/v1/merchant/enable",
    },
    supplier: {
      GET_LIST: "/agricultural-product-wholesale/api/v1/supplier/list",
      GET: "/agricultural-product-wholesale/api/v1/supplier/get",
    },
    warehousing: {
      GET_LIST:
        "/agricultural-product-wholesale/api/v1/product-stock/gerStockInRecord",
      GET:
        "/agricultural-product-wholesale/api/v1/product-stock/getStockInRecordByStockNo",
    },
    outOfStock: {
      GET_LIST: "/agricultural-product-wholesale/api/v1/order/getOrderList",
      GET: "/agricultural-product-wholesale/api/v1/order/getOrderInfo",
    },
  },
  productSettings: {
    GET_LIST: "/agricultural-product-wholesale/api/v1/product-setting/list",
    SAVE: "/agricultural-product-wholesale/api/v1/product-setting/add",
    GET: "/agricultural-product-wholesale/api/v1/product-setting/get",
    UPDATE: "/agricultural-product-wholesale/api/v1/product-setting/update",
    GET_PRODUCT:
      "/agricultural-product-wholesale/api/v1/product/productSettingList",
  },
  detectionStatistics: {
    GET_LIST:
      "/agricultural-product-wholesale/api/v1/product-test/getTestStatisticsList",
    EXPORT:
      "/agricultural-product-wholesale/api/v1/product-test/statisticsDown",
  },
  marketStatistics: {
    GET_LIST:
      "/agricultural-product-wholesale/api/v1/report_form/marketSortStatisticsList",
    EXPORT:
      "/agricultural-product-wholesale/api/v1/report_form/marketSortStatisticsDown",
  },
  tradingArea: {
    GET_LIST:
      "/agricultural-product-wholesale/api/v1/report_form/marketMerchantStatisticsList",
    EXPORT:
      "/agricultural-product-wholesale/api/v1/report_form/marketMerchantStatisticsDown",
  },
  bestseller: {
    GET_LIST:
      "/agricultural-product-wholesale/api/v1/report_form/marketProductStatisticsList",
    EXPORT:
      "/agricultural-product-wholesale/api/v1/report_form/marketProductStatisticsDown",
  },
  summary: {
    GET_LIST:
      "/agricultural-product-wholesale/api/v1/report_form/marketMerchantProductStatisticsList",
    EXPORT:
      "/agricultural-product-wholesale/api/v1/report_form/marketMerchantProductStatisticsDown",
  },
  secondaryModule: {
    GET_TOTALDATA:
      "/agricultural-product-wholesale/api/v1/report_form/getTotalData",
    GET_SALE:
      "/agricultural-product-wholesale/api/v1/report_form/getTotalSaleOrVolumeMoney",
    GET_MERCHANT:
      "/agricultural-product-wholesale/api/v1/report_form/getTotalMerchantAnalyse",
    ANaLYSE:
      "/agricultural-product-wholesale/api/v1/report_form/getMerchantSaleAnalyse",
  },
};
