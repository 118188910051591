export default {
  // 备案管理
  recordManagement: {
    // 企业备案
    enterprise: {
      LIST: '/anji-whitetea-beacon-hill/valid/api/v1/record/manage/organization/list',
      ADD: '/anji-whitetea-beacon-hill/valid/api/v1/record/manage/organization',
      EXPORT: '/anji-whitetea-beacon-hill/valid/api/v1/record/manage/organization/export',
      EDIT: '/anji-whitetea-beacon-hill/valid/api/v1/record/manage/organization/',
      EN_OR_DISABLE: '/anji-whitetea-beacon-hill/valid/api/v1/record/manage/organization/disable/',
      DETAIL: '/anji-whitetea-beacon-hill/valid/api/v1/record/manage/organization/'
    },
    // 基地备案
    base: {
      LIST: '/anji-whitetea-beacon-hill/valid/api/v1/record/manage/base/list'
    },
    // 产品备案
    product: {
      LIST: '/anji-whitetea-beacon-hill/valid/api/v1/record/manage/product/list',
      EXPORT: '/anji-whitetea-beacon-hill/valid/api/v1/record/manage/product/export'
    },
    // 产品目录管理
    productCatalog: {
      LIST: '/anji-whitetea-beacon-hill/valid/api/v1/record/manage/product/catalog/list',
      ADD: '/anji-whitetea-beacon-hill/valid/api/v1/record/manage/product/catalog',
      EDIT: '/anji-whitetea-beacon-hill/valid/api/v1/record/manage/product/catalog/',
      EN_OR_DISABLE: '/anji-whitetea-beacon-hill/valid/api/v1/record/manage/product/catalog/disable/',
      DETAIL: '/anji-whitetea-beacon-hill/valid/api/v1/record/manage/product/catalog/'
    },
    // 产品类别管理
    productCategory: {
      LIST: '/anji-whitetea-beacon-hill/valid/api/v1/record/manage/product/classification/list',
      ADD: '/anji-whitetea-beacon-hill/valid/api/v1/record/manage/product/classification',
      EDIT: '/anji-whitetea-beacon-hill/valid/api/v1/record/manage/product/classification/',
      EN_OR_DISABLE: '/anji-whitetea-beacon-hill/valid/api/v1/record/manage/product/classification/disable/',
      DETAIL: '/anji-whitetea-beacon-hill/valid/api/v1/record/manage/product/classification/'
    }
  },
  // 溯源监管
  traceSupervision: {
    // 溯源批次
    traceBatch: {
      LIST: '/anji-whitetea-beacon-hill/valid/api/v1/trace/batch/list',
      EXPORT: '/anji-whitetea-beacon-hill/valid/api/v1/trace/batch/export',
      DETAIL: '/anji-whitetea-beacon-hill/valid/api/v1/trace/batch/nodeBusinessData'
    },
    // 溯源模板
    traceTemplate: {
      LIST: '/anji-whitetea-beacon-hill/valid/api/v1/trace/template/list',
      ADD: '/anji-whitetea-beacon-hill/valid/api/v1/trace/template/add',
      EDIT: '/anji-whitetea-beacon-hill/valid/api/v1/trace/template/update',
      DETAIL: '/anji-whitetea-beacon-hill/valid/api/v1/trace/template/detail',
      // 节点字段查询
      QUERY: '/anji-whitetea-beacon-hill/valid/api/v1/trace/template/query',
      EN_OR_DISABLE: '/anji-whitetea-beacon-hill/valid/api/v1/trace/template/disable/'
    }
  },
  // 投入品监管
  inputSupervision: {
    // 投入品目录管理
    catalogue: {
      LIST: '/anji-whitetea-beacon-hill/valid/api/v1/input/catalog/manage/list',
      ADD: '/anji-whitetea-beacon-hill/valid/api/v1/input/catalog/manage',
      EDIT: '/anji-whitetea-beacon-hill/valid/api/v1/input/catalog/manage/',
      EN_OR_DISABLE: '/anji-whitetea-beacon-hill/valid/api/v1/input/catalog/manage/disable/'
    },
    // 入库信息
    warehousingInfo: {
      LIST: '/anji-whitetea-beacon-hill/valid/api/v1/input/in/info/list',
      EXPORT: '/anji-whitetea-beacon-hill/valid/api/v1/input/in/info/export'
    },
    // 出库信息
    deliveryInfo: {
      LIST: '/anji-whitetea-beacon-hill/valid/api/v1/input/out/info/list',
      EXPORT: '/anji-whitetea-beacon-hill/valid/api/v1/input/out/info/export'
    },
    // 库存信息
    stockInfo: {
      LIST: '/anji-whitetea-beacon-hill/valid/api/v1/input/stock/info/list',
      DETAIL_LIST: '/anji-whitetea-beacon-hill/valid/api/v1/input/stock/info/stock/info'
    }
  },
  // 采茶工监管
  teaPickerSupervision: {
    // 采茶工列表
    teaPicker: {
      LIST: '/anji-whitetea-beacon-hill/valid/api/v1/tea/picker/list',
      EXPORT: '/anji-whitetea-beacon-hill/valid/api/v1/tea/picker/export'
    },
    // 采茶数据列表
    teaData: {
      LIST: '/anji-whitetea-beacon-hill/valid/api/v1/tea/picker/data/list',
      EXPORT: '/anji-whitetea-beacon-hill/valid/api/v1/tea/picker/data/export'
    }
  },
  // 数据分析
  statistics: {
    // 采茶工数据分析
    teaPicker: {
      // 采茶工分析
      teaPicker: {
        // 获取采茶工统计年份
        GET_PICKER_YEAR: '/anji-whitetea-beacon-hill/valid/api/v1/statistic/tea/picker/year',
        // 关键指标
        KEY_INDICATORS: '/anji-whitetea-beacon-hill/valid/api/v1/statistic/tea/picker/key-indicators',
        // 采茶工数量
        TEA_PICKER_MOUNT: '/anji-whitetea-beacon-hill/valid/api/v1/statistic/tea/picker/chart',
        // 采茶工统计分布
        TEA_PICKER_DISTRIBUTION: '/anji-whitetea-beacon-hill/valid/api/v1/statistic/tea/picker/distribution-chart'
      },
      // 采茶量分析
      teaPickerData: {
        // 采茶工数据统计年份下拉列表
        GET_PICKER_DATA_YEAR: '/anji-whitetea-beacon-hill/valid/api/v1/statistic/tea/picker/data/year',
        // 关键指标
        KEY_INDICATORS: '/anji-whitetea-beacon-hill/valid/api/v1/statistic/tea/picker/data/key-indicators',
        // 获取排名前10
        GET_TOP_10: '/anji-whitetea-beacon-hill/valid/api/v1/statistic/tea/picker/data/top-10/list',
        // 获取采茶工数据列表
        GET_DETAIL_LIST: '/anji-whitetea-beacon-hill/valid/api/v1/statistic/tea/picker/data/list',
        // 采茶工数据统计图表
        GET_CHART_DATA: '/anji-whitetea-beacon-hill/valid/api/v1/statistic/tea/picker/data/chart'
      }
    },
    // 溯源数据分析
    trace: {
      // 实时数据
      realTime: {
        // 关键指标
        KEY_INDICATORS: '/anji-whitetea-beacon-hill/valid/api/v1/statistic/trace/realtime/data/key-indicators',
        // 数据统计图表
        GET_CHART_DATA: '/anji-whitetea-beacon-hill/valid/api/v1/statistic/trace/realtime/data/chart',
        // 数据列表
        GET_DETAIL_LIST: '/anji-whitetea-beacon-hill/valid/api/v1/statistic/trace/realtime/data/list'
      },
      // 历史数据
      historyData: {
        // 关键指标
        KEY_INDICATORS: '/anji-whitetea-beacon-hill/valid/api/v1/statistic/trace/history/data/key-indicators',
        // 数据统计图表
        GET_CHART_DATA: '/anji-whitetea-beacon-hill/valid/api/v1/statistic/trace/history/data/chart',
        // 获取数据列表
        GET_DETAIL_LIST: '/anji-whitetea-beacon-hill/valid/api/v1/statistic/trace/history/data/list'
      }
    },
    // 投入品数据分析
    inputData: {
      // 化肥数据分析
      fertilizer: {
        // 关键指标
        KEY_INDICATORS: '/anji-whitetea-beacon-hill/valid/api/v1/statistic/input/regulation/fertilizer/indicator',
        // 数据统计图表
        GET_CHART_DATA: '/anji-whitetea-beacon-hill/valid/api/v1/statistic/input/regulation/fertilizer/curve',
        // 获取排名前10
        GET_TOP_10: '/anji-whitetea-beacon-hill/valid/api/v1/statistic/input/regulation/fertilizer/top'
      },
      // 农药数据分析
      pesticide: {
        // 关键指标
        KEY_INDICATORS: '/anji-whitetea-beacon-hill/valid/api/v1/statistic/input/regulation/pesticide/indicator',
        // 数据统计图表
        GET_CHART_DATA: '/anji-whitetea-beacon-hill/valid/api/v1/statistic/input/regulation/pesticide/curve',
        // 获取排名前10
        GET_TOP_10: '/anji-whitetea-beacon-hill/valid/api/v1/statistic/input/regulation/pesticide/top'
      }
    }
  }
};
