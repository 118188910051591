import React from 'react';
import styles from './index.less';
import classNames from 'classnames';
import { Popover, Message } from 'components';
import { getOrgSysList } from 'system/services/systemSwitch';
import { setSystem } from 'framework/services/system';
import * as storage from 'framework/utils/storage';
import { setQuery } from 'src/framework/utils/url';
import { isDev } from 'src/framework/common/config';

import _ from 'lodash';
//
export default class extends React.Component {
  state = {
    sysList: []
  };
  
  async componentWillMount() {
    const orgDetail = storage.get('orgDetail');
    const organizationId = _.get(orgDetail, 'organizationId');
    if (organizationId) {
      const sysList = await getOrgSysList(organizationId);
      this.setState({ sysList });
    }
  }
  
  handleSysClick = (sys) => {
    return async () => {
      const { sysUrl, sysId, accessKey } = sys;
      const { err, res } = await setSystem(sysId, sysUrl);
      if (err) {
        Message.error(err);
      } else if (res) {
        if (isDev) {
          window.location.replace(`/?sysUrl=${encodeURIComponent(sysUrl)}`);
        } else {
          window.location.href = setQuery(sysUrl, { accessKey });
        }
      }
    };
  };
  
  createContent = () => {
    const { sysList } = this.state;
    return (
      <div className={styles.sysGroup}>
        <div className={styles.sysGroupTitle}>系统切换</div>
        <div className={styles.sysList}>
          {sysList.map((sys, index) => {
            return (
              <div key={sys.sysId} className={styles.sysItem} title={sys.sysName} onClick={this.handleSysClick(sys)}><i className={styles.sysIcon}></i>{sys.sysName}</div>
            )
          })}
        </div>
      </div>
    );
  }
  
  render() {
    const { sysList } = this.state;
    if (sysList.length === 0) {
      return null;
    }
    return (
      <Popover overlayClassName="header-system-switch-popover" placement="bottom" content={this.createContent()} trigger="hover">
        <div className={styles.systemSwitch}>
          <i className={classNames('sc-icon-switch', styles.switchIcon)}></i>
        </div>
      </Popover>
    )
  }
}
