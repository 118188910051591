/*
 * @Descripttion: 
 * @version: 
 * @Author: 
 * @Date: 2020-06-03 12:53:44
 * @LastEditors: yanglei
 * @LastEditTime: 2020-07-27 16:10:58
 */ 
export default {
  integralGet: {
    //未选的产品积分记录列表
    UN_SELECT: '/marketing/salerRuleReward/unSelectPage',
    //已选的产品积分记录列表
    SELECT: '/marketing/salerRuleReward/page',
    //批量设置产品规则
    SET_ALL_PRODUCTS: '/marketing/salerRuleReward/batchSetRule',
    //批量设置产品规则
    SET_PRODUCT: '/marketing/salerRuleReward/singleSetRule',
    //批量或单个清空规则设置产品
    EMPTY_PRODUCTS: '/marketing/salerRuleReward/emptyRule',
  },
  integralChange: {
    //列表
    LIST: '/marketing/salerRuleExchange/list',
    //详情
    DETAIL: '/marketing/salerRuleExchange/getById',
    //新增
    ADD: '/marketing/salerRuleExchange/save',
    //删除
    DELETE: '/marketing/salerRuleExchange/delete',
    //编辑
    EDIT: '/marketing/salerRuleExchange/update',
    //状态
    STATUS: '/marketing/salerRuleExchange/updateStatus',
  },
  order: {
    //列表
    LIST: '/marketing/salerOrderForm/list',
    //动态表头信息
    GET_HEADER: '/marketing/salerOrderForm/pageHeader',
    //详情
    DETAIL: '/marketing/salerOrderForm/detail',
    //设置表单
    SET_ORDER: '/marketing/salerOrderForm/save',
    ADD_ORDER: '/marketing/salerOrderForm/saveOrder',
    UPDATE_ORDER: '/marketing/salerOrderForm/updateOrder',
    //删除订单
    DEL_ORDER: '/marketing/salerOrderForm/delete',
    //发货
    SEND_ORDER: '/marketing/salerOrderForm/updateStatus',
    //导购员订单管理发货
    EXCHANGE_SEND_ORDER:'/marketing/salerOrderForm/deliver',
    //获取门店信息
    STORE_LIST: '/hydra-base-data/api/v1/customer/enable/intercept/store/page/list',
    GET_ORDER_BYID: '/marketing/salerOrderForm/detailbyId',
    //查看物流详情
    GET_LOGISTICS:'/hydra-open-thirdpart-service/express/info/expressInfo'
  },
  record: {
    //列表
    LIST: '/marketing/mutiIntegral/integralRecordSaler/list',
    //中奖发放
    SEND: '/marketing/winRecord/sendRedPackage',
  },
  all: {
    //查看按钮状态
    GET_STATUS: '/marketing/button/dispacther/getButtonStatus',
    //修改按钮状态
    EDIT_STATUS: '/marketing/button/dispacther/openCloseStatus',
  },
};
