export default {
  // 功能定制接口
  custom: {
    PAVELIST: '/trace/dynamic/fun/list',//获取自然村信息
  },
  // 创业就业服务
  entrepreneurial: {
    // 创业政策
    policy: {
      LIST: '/xinyang-future-village/api/v1/web/entrepreneurship-policy/list',
      ADD: '/xinyang-future-village/api/v1/web/entrepreneurship-policy/add',
      DEL: '/xinyang-future-village/api/v1/web/entrepreneurship-policy/delete',
      DETAIL: '/xinyang-future-village/api/v1/web/entrepreneurship-policy/get',
      EDIT: '/xinyang-future-village/api/v1/web/entrepreneurship-policy/update'
    },
    // 预约
    appointment: {
      DETAIL: '/xinyang-future-village/api/v1/web/spaceReservation/get',
      LIST: '/xinyang-future-village/api/v1/web/spaceReservation/list',
      AUDIT: '/xinyang-future-village/api/v1/web/spaceReservation/check'
    },
    // 管理
    management: {
      ADD: '/xinyang-future-village/api/v1/web/maker-space/add',
      LIST: '/xinyang-future-village/api/v1/web/maker-space/list',
      EDIT: '/xinyang-future-village/api/v1/web/maker-space/update',
      DETAIL: '/xinyang-future-village/api/v1/web/maker-space/get',
      DEL: '/xinyang-future-village/api/v1/web/maker-space/delete'
    }
  },
  // 网格员
  gridMember: {
    // 网格员管理
    management: {
      LIST: '/xinyang-future-village/api/v1/web/gridMan/list',
      ADD: '/xinyang-future-village/api/v1/web/gridMan/add',
      EDIT: '/xinyang-future-village/api/v1/web/gridMan/update',
      DEL: '/xinyang-future-village/api/v1/web/gridMan/delete',
      MANA_LIST: '/xinyang-future-village/api/v1/web/gridMan/getGridManList',
      DETAIL: '/xinyang-future-village/api/v1/web/gridMan/get',
      EXPORT: "/xinyang-future-village/api/v1/web/gridMan/down",
    },
    // 巡检任务管理
    task: {
      LIST: '/xinyang-future-village/api/v1/web/inspection-record/list',
      ADD: '/xinyang-future-village/api/v1/web/inspection-record/add',
      DETAIL: '/xinyang-future-village/api/v1/web/inspection-record/get',
      DEL: '/xinyang-future-village/api/v1/web/inspection-record/delete',
      GET_DETAIL: '/digital-village/hydra-digital-village/api/v1/poor-household/list',
      GET_USE_DETAIL: '/digital-village/hydra-digital-village/api/v1/family-information/get'
    },
    //巡检记录统计查询
    statis: {
      LIST: '/xinyang-future-village/api/v1/web/inspection-record/getInspectionRecordList',
      DETAIL: '/xinyang-future-village/api/v1/web/inspection-record/getInspectionDetailList',
      ONE_DETAIL: "/xinyang-future-village/api/v1/web/inspection-record/get",
      EXPORT: '/xinyang-future-village/api/v1/web/inspection-record/down',
      DETAIL_EXPORT: '/xinyang-future-village/api/v1/web/inspection-record/downDetail',
    }
  },
  // 乡村信息
  shgRural: {
    // 村容村貌
    // 村贤事迹
    deeds: {
      LIST: '/xinyang-future-village/api/v1/web/village-talent/list',
      ADD: '/xinyang-future-village/api/v1/web/village-talent/add',
      DEL: '/xinyang-future-village/api/v1/web/village-talent/delete',
      EDIT: '/xinyang-future-village/api/v1/web/village-talent/update',
      DETAIL: '/xinyang-future-village/api/v1/web/village-talent/get'
    },
    // 美丽庭院
    garden: {
      LIST: '/xinyang-future-village/api/v1/web/beautiful-garden/list',
      USERLIST: '/digital-village/hydra-digital-village/api/v1/family-information/list',
      ADD: '/xinyang-future-village/api/v1/web/beautiful-garden/add',
      DEL: '/xinyang-future-village/api/v1/web/beautiful-garden/delete',
      DETAIL: '/xinyang-future-village/api/v1/web/beautiful-garden/get',
      EDIT: '/xinyang-future-village/api/v1/web/beautiful-garden/update',
      GETADRESS:'/hydra-base-data/api/v1/address/all-regions',//获取地址接口
    }
  },

  // 智慧监督
  wisSup: {
    // 书记信箱
    mail: {
      LIST: '/xinyang-future-village/api/v1/web/mailbox/list',
      DETAIL: '/xinyang-future-village/api/v1/web/mailbox/get',
      REPLY: '/xinyang-future-village/api/v1/web/mailbox/reply'
    },
    //随手拍
    tapic: {
      LIST: '/xinyang-future-village/api/v1/web/snapshot/list',
      DETAIL: '/xinyang-future-village/api/v1/web/snapshot/get',
      APLY_GET: '/xinyang-future-village/api/v1/web/snapshot/acceptSubmission',
      ACCEPT: "/xinyang-future-village/api/v1/web/snapshot/accept",
    },
    // 信息上报
    reported: {
      LIST: '/xinyang-future-village/api/v1/web/message-report/list',
      ACCEPT: '/xinyang-future-village/api/v1/web/message-report/accept',
      DETAIL: '/xinyang-future-village/api/v1/web/message-report/get',
      REPLY: '/xinyang-future-village/api/v1/web/message-report/reply',
    }
  },
  //积分
  integral: {
    // 明细
    intDetail: {
      LIST: "/xinyang-future-village/api/v1/web/user-credits/list",
      LISTDETAIL: '/xinyang-future-village/api/v1/web/user-point-record/list',
      EXP: '/xinyang-future-village/api/v1/web/user-credits/exportUserCredits',
      EXPDETAIL:'/xinyang-future-village/api/v1/web/user-point-record/exportUserPointRecord'
    },
    // 商品
    goods: {
      LIST: '/xinyang-future-village/api/v1/web/integral-merchandise/list',
      ADD: '/xinyang-future-village/api/v1/web/integral-merchandise/add',
      DEL: '/xinyang-future-village/api/v1/web/integral-merchandise/delete',
      EDIT: "/xinyang-future-village/api/v1/web/integral-merchandise/update",
      DETAIL: '/xinyang-future-village/api/v1/web/integral-merchandise/get',
    },
    // 兑换记录
    exchange: {
      LIST: '/xinyang-future-village/api/v1/web/exchange-record/list',
      DETAIL: '/xinyang-future-village/api/v1/web/exchange-record/get'
    }
  },
};
