import { prefix } from 'framework/common/config'

export default {
  test: {
    GET_LIST: '/trace/tsa/batchtsa/page',
  },
  staffManager: {
    //组织模块->员工管理-> 根据组织id获取员工信息获取启用员工信息列表
    enableStaffList: '/hydra-user/api/v1/employee/enable/list/all'
  },
  // 类目管理
  categoryManager: {
    /**
     * 类目列表
     * POST
     * param:{functionId}
     * 猪只类别:13  猪只状态:14   进场类型:15   离场类型:16
     * :   :   :   :
     * :   :   :   :
     */
    list: '/hydra-shandong-pingan-pig/api/v1/healthCare/category/list'
  },
  // log


  common: {
    // 产品分类
    PRODUCT_CATEGORY: '/hydra-base-data/api/v1/product-sort/firsts',
    // 获取联盟下的企业
    UNION_COMPANY: '/hydra-user/api/v1/union-org',
    // 物料列表
    MATERIAL_LIST: '/hydra-culture-baseinfo/api/v1/culture/material/enable/list',
    // 物料库存列表
    MATERIAL_WAREHOUSE_LIST: '/hydra-culture-baseinfo/api/v1/culture/material/productmanage/enable/list',
    // 根据物料id获取该物料入库批次列表
    MATERIAL_WAREHOUSE_BATCH_LIST: '/hydra-culture-baseinfo/api/v1/culture/material/warehouse/productmanage/in/batch',
    // 根据物料Id获取批次
    BATCH_LIST: '/hydra-culture-baseinfo/api/v1/culture/material/warehouse/productmanage/in/batch',
    // 单位列表
    COMPANY_LIST: '/hydra-user/api/v1/union-org/org-or-third/select/list',
    // 操作人
    OPERATOR_LIST: '/hydra-user/api/v1/employee/enable/list',
    CONFIF_TYPE: '/hydra-base-data/api/v1/sort/type/page/list',
    // 获取基地下拉列表
    AOTU_GET_LIST: '/hydra-culture-baseinfo/api/v1/culture/base/select/list',
    // 物料分类管理
    material: {
      GET_LIST: '/hydra-base-data/api/v1/platform/sort/material/list',
      SAVE: '/hydra-shandong-pingan-pig/api/v1/healthCare/category/add',
      UPDATE: '/hydra-shandong-pingan-pig/api/v1/healthCare/category/update',
    },
    batch: {
      // 批次列表
      list: '/hydra-shandong-pingan-pig-batcher/api/v1/pigInfo/batch/list',
      // 根据批次号获取该批次下猪只数量
      count: '/hydra-shandong-pingan-pig-batcher/api/v1/pigInfo/batch/quantity',
      // 包装批次
      batchs: '/hydra-shandong-pingan-pig-batcher/api/v1/inFactory/list',
    },
    // 产品列表（下拉）
    natureProducts: '/hydra-base-data/api/v1/product/enable/like/name/list',
    // 猪只耳号列表
    identityList: '/hydra-shandong-pingan-pig-batcher/api/v1/pigInfo/pig/list',
    // 未屠宰，未无害化
    identityByBatch: '/hydra-shandong-pingan-pig-batcher/api/v1/pigInfo/listPigsByInBatch',
    pigsByNum: '/hydra-shandong-pingan-pig-batcher/api/v1/pigInfo/pig-info/list',
  },
  // 公共舍
  commonMass: {
    // 获取下拉列表
    PULL_LIST: '/hydra-culture-baseinfo/api/v1/culture/partition/select/list',
    // 获取列表
    LIST: '/hydra-culture-baseinfo/api/v1/culture/partition/list',
    // 启动舍
    ENABLE: '/hydra-culture-baseinfo/api/v1/culture/partition/enable',
    // 禁用舍
    DISABLE: '/hydra-culture-baseinfo/api/v1/culture/partition/disable',
    // 编辑舍, 添加舍, 获取单个舍
    EDIT: '/hydra-culture-baseinfo/api/v1/culture/partition',
  },
  // 系统组织管理
  nurtureSysOrg: {
    LIST: '/hydra-user/api/v1/union-org/list/sys',
    COMMON: '/hydra-user/api/v1/org/from-platform',
    COUNT: '/org/sys/invite/check/org/sys',  // 废弃
    AUD_CHECK: '/hydra-user/api/v1/org/aud/check',
    ACCEPT: '/hydra-user/api/v1/org/org/accept',
    REJECT: '/hydra-user/api/v1/org/org/reject',
    CHECK: '/hydra-user/api/v1/org/check/name/number',
    ADD: '/org/sys/invite/sys',  // 废弃
    MODIFY: '/org/sys/invite',   // 废弃
    ORG_SYS: '/hydra-user/api/v1/sys/org/list',
    THIRD_LIKE: '/hydra-open-thirdpart-service/business/open/thrid/like',
    THIRD_EXACT: '/hydra-open-thirdpart-service/business/open/thrid/exact/new',
    ROLE_COMMON: '/hydra-user/api/v1/role/select',
    // 获取系统组织列表
    GOV_LIST: '/hydra-user/api/v1/org/list/sys/government/select',
    // 开通组织
    ORG_OPEN: '/hydra-user/api/v1/org/open/pig-org',
    // SYS_LIST: '/zfjg/sys/list',
    SAVE_CROP: '/hydra-user/api/v1/org/legal-person',
    // 下拉专用获取规格信息列表
    GET_UNION_LIST: '/hydra-base-data/api/v1/org/union/specification/select/list',
    // 获取企业信息，天眼查或系统获取
    GET_ORG_LIST_BY_ALL: '/hydra-user/api/v1/org/info-or-from-third',
    // 生成企业社会统一信用代码
    GET_CREDIT: '/hydra-user/api/v1/org/credit-code-from-create',
    // 判断企业是否存在
    HAS_COMPANY: '/hydra-user/api/v1/org/exist',
    // 根据组织名称获取组织信息
    GET_ORGINFO_BY_NAME:'/hydra-user/api/v1/org/name/info',
    // 校验信用代码是否存在
    CHECK_CREDITCODE: '/hydra-user/api/v1/org/credit/exist',
    // 获取国家列表
    GET_COUNTRY_LIST: '/hydra-base-data/api/v1//country/list',
  },
  // 公共栏
  commonRoom: {

  },
  // 平安猪物料部分
  nurtureMaterial: {
    GET_LIST: '/hydra-culture-baseinfo/api/v1/culture/material/productmanage/list',
    GET_INVENTORY_LIST: '/hydra-culture-baseinfo/api/v1/culture/material/warehouse/productmanage/list',
    GET_IN_LIST: '/hydra-culture-baseinfo/api/v1/culture/material/warehouse/productmanage/in/material/list',
    GET_BATCH_LIST: '/hydra-culture-baseinfo/api/v1/culture/material/warehouse/productmanage/in/batch',
    GET_DOWN_LIST: '/hydra-culture-baseinfo/api/v1/culture/material/productmanage/enable/list',
    GET_OUT_LIST: '/hydra-culture-baseinfo/api/v1/culture/material/warehouse/productmanage/out/material/list',
    ADD_MATERIAL: '/hydra-culture-baseinfo/api/v1/culture/material/productmanage',
    GET_MATERIAL_BYID: '/hydra-culture-baseinfo/api/v1/culture/material/productmanage',
    UPDATE_MATERIAL: '/hydra-culture-baseinfo/api/v1/culture/material/productmanage',
    DISABLE_MATERIAL: '/hydra-culture-baseinfo/api/v1/culture/material/productmanage/disable',
    ENABLE_MATERIAL: '/hydra-culture-baseinfo/api/v1/culture/material/productmanage/enable',
    GET_STOCK_LIST: '/hydra-culture-baseinfo/api/v1/culture/material/warehouse/productmanage/list',
    GET_OUT_IN_CODE: '/hydra-culture-baseinfo/api/v1/culture/material/warehouse/productmanage/code',
    GET_MATERIAL_SIZE: '/hydra-culture-baseinfo/api/v1/culture/material/specification/productmanage/list',
    ADD_MATERIAL_IN: '/hydra-culture-baseinfo/api/v1/culture/material/warehouse/productmanage/batch/in',
    ADD_MATERIAL_OUT: '/hydra-culture-baseinfo/api/v1/culture/material/warehouse/productmanage/batch/out',
    GET_MATERIAL_DETAILS_BYID: '/hydra-culture-baseinfo/api/v1/culture/material/warehouse/productmanage',
    SET_WARNING: '/hydra-culture-baseinfo/api/v1/culture/material/warehouse/productmanage/warning',
    GET_LIST_BATCH: '/hydra-culture-baseinfo/api/v1/culture/material/warehouse/productmanage/signle/out/list',
    GET_LIST_TURNOVER: '/hydra-culture-baseinfo/api/v1/culture/material/warehouse/productmanage/signle/out-in/list',
    GET_FLOWING_LIST: '/hydra-culture-baseinfo/api/v1/culture/material/warehouse/productmanage/out-in/list',
    GET_VERIFY: '/hydra-culture-baseinfo/api/v1/culture/material/warehouse/productmanage/check/out',
    GET_PLATE_BATCH: '/hydra-culture-baseinfo/api/v1/culture/runda/product-batch/dropPage',
    // 物料使用统计
    STATISTIC_LIST: '/hydra-culture-baseinfo/api/v1/culture/runda/productmanage/material/materialUseStatisics/list',
    // 导出物料使用统计
    EXPORT_STATISTIC_LIST: '/hydra-culture-baseinfo/api/v1/culture/runda/productmanage/material/materialUseStatisics/export'
  },
  // 基地管理
  nurtureBaseArea: {
    massif: {
      GET_LIST: '/hydra-culture-baseinfo/api/v1/culture/mass/list',
      SAVE: '/hydra-culture-baseinfo/api/v1/culture/mass',
      UPDATE: '/hydra-culture-baseinfo/api/v1/culture/mass',
      DISABLE: '/hydra-culture-baseinfo/api/v1/culture/mass/disable',
      ENABLE: '/hydra-culture-baseinfo/api/v1/culture/mass/enable',
      GET: '/hydra-culture-baseinfo/api/v1/culture/mass',
      GET_AREA: '/hydra-culture-baseinfo/api/v1/culture/partition/select/list',
      // 获取地块生产记录
      GET_RECORD: '/trace/massif/info/page',
      // 批次号获取h5溯源页数据接口
      GET_PAGE_DATA: '/trace/batch/info/h5PageData',
      // 批次号获取h5溯源页数据接口
      GET_PAGE_MASSIF_DATA: '/trace/massif/info/h5PageData',
    },
    base: {
      GET_LIST: '/hydra-culture-baseinfo/api/v1/culture/base/list',
      SAVE: '/hydra-culture-baseinfo/api/v1/culture/base',
      UPDATE: '/hydra-culture-baseinfo/api/v1/culture/base',
      DISABLE: '/hydra-culture-baseinfo/api/v1/culture/base/disable',
      ENABLE: '/hydra-culture-baseinfo/api/v1/culture/base/enable',
      GET: '/hydra-culture-baseinfo/api/v1/culture/base',
    },
    zone: {
      GET_LIST: '/hydra-culture-baseinfo/api/v1/culture/partition/list',
      SAVE: '/hydra-culture-baseinfo/api/v1/culture/partition',
      UPDATE: '/hydra-culture-baseinfo/api/v1/culture/partition',
      DISABLE: '/hydra-culture-baseinfo/api/v1/culture/partition/disable',
      ENABLE: '/hydra-culture-baseinfo/api/v1/culture/partition/enable',
      GET: '/hydra-culture-baseinfo/api/v1/culture/partition',
    },
    // 区域相关
    area: {
      //获取栏列表
      GET_FENCE_LIST: '/hydra-culture-baseinfo/api/v1/culture/mass/type/enable/list'
    },
  },
  // 客户管理
  nurtureCustomer: {
    LIST: '/hydra-culture-baseinfo/api/v1/culture/customer/list',
    DISABLE: '/hydra-culture-baseinfo/api/v1/culture/customer/disable',
    ENABLE: '/hydra-culture-baseinfo/api/v1/culture/customer/enable',
    EDIT: '/hydra-culture-baseinfo/api/v1/culture/customer',
    ENABLE_LIST: '/hydra-culture-baseinfo/api/v1/culture/customer/superior/enable/list', // 上级列表
    THIRD_LIKE: '/hydra-open-thirdpart-service/business/open/thrid/like', // 模糊查询第三方工商库组织列表
    EXACT: '/hydra-open-thirdpart-service/business/open/thrid/exact/new', // 根据组织全名获取工商精确信息
  },
  nurture: {
    //设备列表
    iot: {
      GET_LIST: '/iot/device/listByOrgId',
      SAVE: '/iot/device/add',
      GET_DETAIL: '/iot/device/getByCode',
      UPDATE: '/iot/device/update',
      GET_CHILDDEVICE: '/iot/device/sublist',
      //添加子设备
      ADD_SUB_DEVICE: '/iot/device/addSubDevice',
      // 组织id查询该组织下的设备型号,用于添加子设备的查询
      GET_DEVICE_BY_ORG_ID: '/iot/device/subDeviceModelByOrgId',
      // 根据组织id，设备型号id查询该组织下的设备,用于添加子设备的查询
      GET_DEVICE_MODEL_BY_ORG_ID: '/iot/device/subDeviceByOrgId',
      //每日设备数据报表
      GET_DEVICE_REPORT: '/iot/device/deviceReport',
    },
    // 设备型号
    model: {
      GET_LIST: '/iot/devicemodel/list',
    },
    // 设备管理
    devices: {
      GET_LIST: '/hydra-base-data/api/v1/produce/device/list',
      // 保存
      SAVE: '/hydra-base-data/api/v1/produce/device',
      // 禁用
      DISABLE: '/hydra-base-data/api/v1/produce/device/disable',
      // 启用
      ENABLE: '/hydra-base-data/api/v1/produce/device/enable',
      // 更新
      UPDATE: '/hydra-base-data/api/v1/produce/device',
      // 获取单一
      GET: '/hydra-base-data/api/v1/produce/device',
      // 对应设备使用记录
      GET_LIST_RECORD: '/hydra-base-data/api/v1/device/usage-record',
    },
    //IOT设备
    iotFacility:{
      GET_LIST: '/hydra-shandong-pingan-pig/api/v1/device/listDevice',
      GET_DETAIL: '/hydra-shandong-pingan-pig/api/v1/device/getByCode',
      GET_CHILDDEVICE: '/hydra-shandong-pingan-pig/api/v1/device/sublist',
      //每日设备数据报表
      GET_DEVICE_REPORT: '/hydra-shandong-pingan-pig/api/v1/device/deviceReport',
      //控制开关
      CONTROL:'/hydra-shandong-pingan-pig/api/v1/device/properitesCommand'
    },
    //监控管理
    monitoringManage:{
      GET_LIST: '/hydra-shandong-pingan-pig/api/v1/monitor/listByOrgId',
      DELECT:'/hydra-shandong-pingan-pig/api/v1/monitor/delete',
      GET_STATE:'/hydra-shandong-pingan-pig/api/v1/monitor/getStatesByIds',
      //某个用户的监控设备列表
      GET_USER_MONITORLIST: '/hydra-shandong-pingan-pig/api/v1/monitor/listDevice',
      //初始化web插件
      INITIALIZE:'/hydra-shandong-pingan-pig/api/v1/monitor/init',
      //获取点击web插件实时视频参数
      GET_CLICKPARAMETER:'/hydra-shandong-pingan-pig/api/v1/monitor/preview',
      //获取点击web插件视频回放参数
      GET_BACKPARAMETER:'/hydra-shandong-pingan-pig/api/v1/monitor/playback'
    }
  },
  // 溯源管理-
  trace: {
    batch: {
      edit: '/hydra-shandong-pingan-pig-batcher/api/v1/trace/pig/config',
      editDetail: '/hydra-shandong-pingan-pig-batcher/api/v1/trace/pig/config',
      preview: '/hydra-shandong-pingan-pig-batcher/api/v1/trace/pig/config/by-batch',
    },
    pork: {
      preview: '/hydra-shandong-pingan-pig-batcher/api/v1/trace/pig/config/product/by-batch',
    },
  },
  // 标识管理
  identifying: {
    LIST: '/hydra-shandong-pingan-pig/api/v1/identification/list',
    // 出入库标识详情
    IDENTITY_DETAIL: '/hydra-shandong-pingan-pig/api/v1/identification',
    // 生成出入库编号
    GENERATE_NUM: '/hydra-shandong-pingan-pig/api/v1/identification/no',
    // 新增
    ADD: '/hydra-shandong-pingan-pig/api/v1/identification',
    // 修改
    IDENTITY_UPDATE: '/hydra-shandong-pingan-pig/api/v1/identification',
    // 仓库
    WAREHOUSE: '/hydra-base-data/api/v1/store-house/enable/page/list',
    // 存放库位
    STORE_HOUSE: '/hydra-base-data/api/v1/ware-house/enable/list',
    // 标识记录 列表
    RECORD_LIST: '/hydra-shandong-pingan-pig/api/v1/identification/useRecord',
    // 表示记录 详情
    RECORD_DETAIL: '/hydra-shandong-pingan-pig/api/v1/identification/useRecordDetail',
  },
  // 生物管理
  biology: {
    // 保健
    healthy: {
      // 列表
      LIST: '/hydra-shandong-pingan-pig/api/v1/healthCare/pages',
      // 新增
      ADD: '/hydra-shandong-pingan-pig/api/v1/healthCare/add',
      // 详情
      DETAIL: '/hydra-shandong-pingan-pig/api/v1/healthCare/detail',
      // 猪详情
      PIG_DETAIL: '/hydra-shandong-pingan-pig/api/v1/healthCare/pigDetail',
      // 编辑
      EDIT: '/hydra-shandong-pingan-pig/api/v1/healthCare/update',
    },
    // 免疫
    immune: {
      // 列表
      LIST: '/hydra-shandong-pingan-pig/api/v1/immuno/pages',
      // 新增
      ADD: '/hydra-shandong-pingan-pig/api/v1/immuno/add',
      // 详情
      DETAIL: '/hydra-shandong-pingan-pig/api/v1/immuno/detail',
      // 猪详情列表
      PIG_DETAIL: '/hydra-shandong-pingan-pig/api/v1/immuno/pigDetail',
      // 编辑
      EDIT: '/hydra-shandong-pingan-pig/api/v1/immuno/update',
    },
    // 诊疗
    diagnosis: {
      // 列表
      LIST: '/hydra-shandong-pingan-pig/api/v1/treat/pages',
      // 新增
      ADD: '/hydra-shandong-pingan-pig/api/v1/treat/add',
      // 详情
      DETAIL: '/hydra-shandong-pingan-pig/api/v1/treat/detail',
      // 猪详情列表
      PIG_DETAIL: '/hydra-shandong-pingan-pig/api/v1/treat/pigDetail',
      // 编辑
      EDIT: '/hydra-shandong-pingan-pig/api/v1/treat/update',
    },
    // 消毒
    degassing: {
      // 列表
      LIST: '/hydra-shandong-pingan-pig/api/v1/disinfect/pages',
      // 新增
      ADD: '/hydra-shandong-pingan-pig/api/v1/disinfect/add',
      // 详情
      DETAIL: '/hydra-shandong-pingan-pig/api/v1/disinfect/detail',
      // 猪详情列表
      PIG_DETAIL: '/hydra-shandong-pingan-pig/api/v1/disinfect/pigDetail',
      // 编辑
      EDIT: '/hydra-shandong-pingan-pig/api/v1/disinfect/update',
    },
    // 无害化
    harmless: {
      // 列表
      LIST: '/hydra-shandong-pingan-pig/api/v1/innocent/pages',
      // 新增
      ADD: '/hydra-shandong-pingan-pig/api/v1/innocent/add',
      // 详情
      DETAIL: '/hydra-shandong-pingan-pig/api/v1/innocent/detail',
      // 猪详情列表
      PIG_DETAIL: '/hydra-shandong-pingan-pig/api/v1/innocent/pigDetail',
      // 编辑
      EDIT: '/hydra-shandong-pingan-pig/api/v1/innocent/update',
      // 单位列表
      HARMLESS_COMPANY_LIST: '/hydra-culture-baseinfo/api/v1/culture/customer/enable/list',
      COMPANY_LIST: '/hydra-user/api/v1/union-org/org-or-third/select/list',
    },

  },
  //品种
  breed: {
    //获取品种
    GET_BREED_LIST: '/hydra-shandong-pingan-pig/api/v1/basic/getVariety',
    //获取已选品种
    GET_SELECTED_BREED_LIST: '/hydra-shandong-pingan-pig/api/v1/basic/getVarietySelected',
    //新增品种
    ADD_BREED: '/hydra-shandong-pingan-pig/api/v1/basic/addVariety',
    //获取品系
    GET_STRAIN_LIST: '/hydra-shandong-pingan-pig/api/v1/basic/getSeries',
    //根据品种获取其下属已选的品系
    GET_SELECTED_STRAIN_LIST_OF_VARIETY: '/hydra-shandong-pingan-pig/api/v1/basic/getSeriesSelected',
    //获取已选品系
    GET_SELECTED_STRAIN_LIST: '/hydra-shandong-pingan-pig/api/v1/basic/getSeriesSelected',
    //新增品系
    ADD_SERIES: '/hydra-shandong-pingan-pig/api/v1/basic/addSeries',

    //选入品种
    SELECT_SERIES: '/hydra-shandong-pingan-pig/api/v1/basic/selectIn',
    //移除品种
    REMOVE_SERIES: '/hydra-shandong-pingan-pig/api/v1/basic/remove',
  },
  // 猪只档案
  record: {
    // 获取猪只档案列表
    GET_PIG_LIST: '/hydra-shandong-pingan-pig/api/v1/pig/page/list',
    // 获取猪只溯源列表
    GET_PIG_TRACE_LIST: '/hydra-shandong-pingan-pig/api/v1/pig/union/page/list',
    // 根据栏舍id获取猪只数量
    GET_PIG_NUM: '/hydra-shandong-pingan-pig/api/v1/pig/n/by-mass-array',
    // 添加猪只
    SAVE_PIG: '/hydra-shandong-pingan-pig/api/v1/pig',
    // 获取猪只详情
    GET_PIG_DETAIL: '/hydra-shandong-pingan-pig/api/v1/pig/n',
    // 更换耳号
    UPDATE_EAR: '/hydra-shandong-pingan-pig/api/v1/pig/identity-num',
    // 获取猪只档案详情
    GET_RECORD_DEAIL: '/hydra-shandong-pingan-pig/api/v1/pig/n/details',
    // 获取档案屠宰详情
    GET_SLAUGHTER_INFO: '/hydra-shandong-pingan-pig-batcher/api/v1/pigProduct/pigRecord'
  },
  //母猪生产
  parturition: {
    // 获取母猪生产列表
    GET_PARTURITION_LIST: '/hydra-shandong-pingan-pig/api/v1/sow-production/page/list',
    // 新增配种
    SAVE_BREED: '/hydra-shandong-pingan-pig/api/v1/sow-production/breeding',
    // 获取配种详情
    GET_BREED_DETAIL: '/hydra-shandong-pingan-pig/api/v1/sow-production/no/breeding',
    // 新增娠检
    SAVE_EXAMINE: '/hydra-shandong-pingan-pig/api/v1/sow-production/pregnancy',
    // 获取娠检详情
    GET_EXAMINE_DETAIL: '/hydra-shandong-pingan-pig/api/v1/sow-production/no/pregnancy',
    // 新增分娩
    SAVE_PARTURITION: '/hydra-shandong-pingan-pig/api/v1/sow-production/childbirth',
    // 获取分娩详情
    GET_PARTURITION_DETAIL: '/hydra-shandong-pingan-pig/api/v1/sow-production/no/childbirth',
    // 新增断奶
    SAVE_WEANING: '/hydra-shandong-pingan-pig/api/v1/sow-production/weaning',
    // 获取断奶详情
    GET_WEANING_DETAIL: '/hydra-shandong-pingan-pig/api/v1/sow-production/no/weaning',
    // 删除母猪生产
    DEL: '/hydra-shandong-pingan-pig/api/v1/sow-production/no/weaning'
  },
  //养殖方案
  scheme: {
    // 获取方案应用列表
    GET_APPLY_LIST: '/hydra-shandong-pingan-pig/api/v1/plan-apply/page/list',
    // 获取标准养殖方案列表
    GET_APPLY_BREED_LIST: '/hydra-shandong-pingan-pig/api/v1/basic/getStandardBreed',
    // 添加方案应用
    SAVE_APPLY: '/hydra-shandong-pingan-pig/api/v1/plan-apply',
    // 获取应用方案详情
    GET_APPLY: '/hydra-shandong-pingan-pig/api/v1/plan-apply/no',
    // 取消方案应用
    CANCEL_APPLY: '/hydra-shandong-pingan-pig/api/v1/plan-apply/cancel',
    // 添加标准方案
    SAVE_SCHEME: '/hydra-shandong-pingan-pig/api/v1/basic/addStandardBreed',
    // 修改标准方案
    EDIT_SCHEME: '/hydra-shandong-pingan-pig/api/v1/basic/updateStandardBreed',
    // 获取标准方案详情
    GET_SCHEME: '/hydra-shandong-pingan-pig/api/v1/basic/getStandardBreedDetail',
    // 标准方案启用和禁用
    CANCEL_UPDATE_STATUS: '/hydra-shandong-pingan-pig/api/v1/basic/updateStatus'
  },
  //转栏管理
  transfer: {
    // 猪详情
    GET_PIGDETAIL_LIST: '/hydra-shandong-pingan-pig/api/v1/transfer/pigDetail',
    // 转栏数据列表 POST
    list: '/hydra-shandong-pingan-pig/api/v1/transfer/pages',
    // 新增 POST
    add: "/hydra-shandong-pingan-pig/api/v1/transfer/add",
    // 转栏详情 GET
    detail: "/hydra-shandong-pingan-pig/api/v1/transfer/detail",
  },
  // 任务
  task: {
    LIST: '/hydra-shandong-pingan-pig/api/v1/server/nurturing-task/list',
    // 任务猪只列表
    PIG_DETAIL_LIST: '/hydra-shandong-pingan-pig/api/v1/server/nurturing-task/pig',
  },
  // 检疫管理
  quarantine: {
    // 新增报告
    REPORT_ADD: '/hydra-shandong-pingan-pig/api/v1/inspection/addInspectionReport',
    // 更新报告
    REPORT_UPDATE: '/hydra-shandong-pingan-pig/api/v1/inspection/updateInspectionReport',
    // 报告详情
    REPORT_DETAIL: '/hydra-shandong-pingan-pig/api/v1/inspection/getInspectionDetail',
    // 获取报告中的文件列表
    REPORT_FILES: '/hydra-shandong-pingan-pig/api/v1/inspection/fileList',
    // 下载文件
    DOWNLOAD_FILES: `${prefix}/hydra-nurture-cattle/api/v1/inspection/download`,
    // 删除报告
    REPORT_DESTROY: '/hydra-shandong-pingan-pig/api/v1/inspection/deleteInspectionReport',
    // 获取检测项
    FETCH_CHECK_ITEM: '/hydra-shandong-pingan-pig/api/v1/inspection/getInspectionItem',
    // 新增检测项
    ADD_CHECK_ITEM: '/hydra-shandong-pingan-pig/api/v1/inspection/addInspectionItem',
    // 获取检疫报告
    REPORT_LIST: '/hydra-shandong-pingan-pig/api/v1/inspection/getInspectionReport',
    // 更新检测项
    UPDATE_CHECK_ITEM: '/hydra-shandong-pingan-pig/api/v1/inspection/updateInspectionItem',
    PIG_DETAIL: '/hydra-shandong-pingan-pig/api/v1/inspection/getInspectionReportPigs',
    // 获取检测报告中的猪只信息
    butcher: {
      // 新增报告
      REPORT_ADD: '/hydra-shandong-pingan-pig-batcher/api/v1/inspection/add',
      // 更新报告
      REPORT_UPDATE: '/hydra-shandong-pingan-pig-batcher/api/v1/inspection/update',
      // 报告详情
      REPORT_DETAIL: '/hydra-shandong-pingan-pig-batcher/api/v1/inspection/detail',
      // 获取报告中的文件列表
      REPORT_FILES: '/hydra-shandong-pingan-pig-batcher/api/v1/inspection/fileList',
      // 下载文件
      DOWNLOAD_FILES: `${prefix}/hydra-shandong-pingan-pig-batcher/api/v1/inspection/download`,
      // 删除报告
      REPORT_DESTROY: '/hydra-shandong-pingan-pig-batcher/api/v1/inspection/delete',
      // 获取检测项
      FETCH_CHECK_ITEM: '/hydra-shandong-pingan-pig/api/v1/inspection/getInspectionItem',
      // 新增检测项
      ADD_CHECK_ITEM: '/hydra-shandong-pingan-pig/api/v1/inspection/addInspectionItem',
      // 获取检疫报告
      REPORT_LIST: '/hydra-shandong-pingan-pig-batcher/api/v1/inspection/list',
      // 更新检测项
      UPDATE_CHECK_ITEM: '/hydra-shandong-pingan-pig/api/v1/inspection/updateInspectionItem',
      PIG_DETAIL: '/hydra-shandong-pingan-pig-batcher/api/v1/inspection/pigInfoList',
    }
  },
  // 新猪进出场
  pigIO: {
    pigInList: '/hydra-shandong-pingan-pig/api/v1/flow/getInFactoryInfo', //猪只入场信息列表
    //新增猪只入场
    PIG_IN_ADD: '/hydra-shandong-pingan-pig/api/v1/flow/inFactory',
    //编辑猪只入场
    PIG_IN_EDIT: '/hydra-shandong-pingan-pig/api/v1/flow/updateInFactoryInfo',
    //猪只入场详情
    PIG_IN_DETAIL: '/hydra-shandong-pingan-pig/api/v1/flow/getInFactoryDetail',
    // 获取猪只入场中的猪只信息 inFactoryId
    inInfo_pigList: '/hydra-shandong-pingan-pig/api/v1/flow/getInFactoryPigInfo',
    pigOutList: '/hydra-shandong-pingan-pig/api/v1/flow/getLeaveFactoryInfo', //猪只离场信息列表
    //新增猪只离场
    PIG_OUT_ADD: '/hydra-shandong-pingan-pig/api/v1/flow/leaveFactory',
    //编辑猪只离场
    PIG_OUT_EDIT: '/hydra-shandong-pingan-pig/api/v1/flow/updateLeaveFactoryInfo',
    //猪只离场详情
    PIG_OUT_DETAIL: '/hydra-shandong-pingan-pig/api/v1/flow/getLeaveFactoryDetail',
    // 获取猪只离场中的猪只信息 leaveFactoryId
    outInfo_pigList: '/hydra-shandong-pingan-pig/api/v1/flow/getLeaveFactoryPigInfo',
  },
  // 日常管理
  dailyManager: {
    // 喂养
    feeding: {
      // 喂养管理列表 POST
      list: "/hydra-shandong-pingan-pig/api/v1/feed/pages",
      // 新增 POST
      add: "/hydra-shandong-pingan-pig/api/v1/feed/add",
      edit: "/hydra-shandong-pingan-pig/api/v1/feed/update",
      // 饲养详情 GET
      detail: "/hydra-shandong-pingan-pig/api/v1/feed/detail",
      // 饲喂猪只详情
      pig_detail: "/hydra-shandong-pingan-pig/api/v1/feed/pigDetail",
    },
    // 称重管理
    weight: {
      // 列表 GET
      list: "/hydra-shandong-pingan-pig/api/v1/weight/pages",
      // 新增 POST
      add: "/hydra-shandong-pingan-pig/api/v1/weight/add",
      // 编辑
      edit: "/hydra-shandong-pingan-pig/api/v1/weight/update",
      // 称重详情 GET
      detail: "/hydra-shandong-pingan-pig/api/v1/weight/detail",
      // 称重猪只详情
      pig_detail: "/hydra-shandong-pingan-pig/api/v1/weight/pigDetail",
    },
    // 包装管理
    packaging: {
      // 包装列表
      list: '/hydra-shandong-pingan-pig-batcher/api/v1/packeg-weight/pages',
      // 新增包装
      add: '/hydra-shandong-pingan-pig-batcher/api/v1/packeg-weight/add',
      // 关联批次
      relation: '/hydra-shandong-pingan-pig-batcher/api/v1/packeg-weight/create-relation',
      // 清空关联
      relationEmpty: '/hydra-shandong-pingan-pig-batcher/api/v1/packeg-weight/remove-relation',
      // 编辑包装
      edit: '/hydra-shandong-pingan-pig-batcher/api/v1/packeg-weight/update',
      // 作废
      destroy: '/hydra-shandong-pingan-pig-batcher/api/v1/packeg-weight/update-status',
    },
    // 屠宰管理
    butcher: {
      // 批次下拉列表
      batchs: '/hydra-shandong-pingan-pig-batcher/api/v1/butcher/batch/pages',
      // 列表
      list: '/hydra-shandong-pingan-pig-batcher/api/v1/butcher/pages',
      update: '/hydra-shandong-pingan-pig-batcher/api/v1/butcher/update',
      // 新增屠宰
      detailAdd: '/hydra-shandong-pingan-pig-batcher/api/v1/butcher/detail/pc/add',
      // 编辑屠宰
      detailEdit: '/hydra-shandong-pingan-pig-batcher/api/v1/butcher/detail/update',
      // 屠宰详情
      detail: '/hydra-shandong-pingan-pig-batcher/api/v1/butcher/detail',
      // 详情明细列表
      detailInfo: '/hydra-shandong-pingan-pig-batcher/api/v1/butcher/detail/pages',
    },
    // 无害化管理
    harmless: {
      // 列表
      list: '/hydra-shandong-pingan-pig-batcher/api/v1/innocent/pages',
      // 新增
      add: '/hydra-shandong-pingan-pig-batcher/api/v1/innocent/add',
      // 编辑
      edit: '/hydra-shandong-pingan-pig-batcher/api/v1/innocent/update',
      // 详情
      detail: '/hydra-shandong-pingan-pig-batcher/api/v1/innocent/detail',
      // 猪详情
      pigDetail: '/hydra-shandong-pingan-pig-batcher/api/v1/innocent/pigDetail',
    },
    // 进场管理
    in: {
      // 列表
      list: '/hydra-shandong-pingan-pig-batcher/api/v1/inFactory/list',
      // 新增
      add: '/hydra-shandong-pingan-pig-batcher/api/v1/inFactory/inFactory',
      // 编辑
      edit: '/hydra-shandong-pingan-pig-batcher/api/v1/inFactory/update',
      // 详情
      detail: '/hydra-shandong-pingan-pig-batcher/api/v1/inFactory/detail',
      // 猪详情列表
      pigDetail: '/hydra-shandong-pingan-pig-batcher/api/v1/trace/pig/config/pigInfo',
    },
    // 分割管理
    separate: {
      // 列表
      list: '/hydra-shandong-pingan-pig-batcher/api/v1/separate/pages',
      // 详情
      detail: '/hydra-shandong-pingan-pig-batcher/api/v1/separate/detail',
      // 猪详情
      detailList: '/hydra-shandong-pingan-pig-batcher/api/v1/separate/detail/pages',
    },
    // 白条称重
    carcass: {
      // 列表
      list: '/hydra-shandong-pingan-pig-batcher/api/v1/white-strip/pages',
      // 详情
      detail: '/hydra-shandong-pingan-pig-batcher/api/v1/white-strip/detail',
      // 排酸前
      beforeAdd: '/hydra-shandong-pingan-pig-batcher/api/v1/white-strip/acid/detail/before-add',
      beforeDestroy: '/hydra-shandong-pingan-pig-batcher/api/v1/white-strip/acid/detail/before-delete',
      beforeList: '/hydra-shandong-pingan-pig-batcher/api/v1/white-strip/acid/detail/before-pages',
      beforeEdit: '/hydra-shandong-pingan-pig-batcher/api/v1/white-strip/acid/detail/before-update',
      // 排酸后
      afterAdd: '/hydra-shandong-pingan-pig-batcher/api/v1/white-strip/acid/detail/after-add',
      afterDestroy: '/hydra-shandong-pingan-pig-batcher/api/v1/white-strip/acid/detail/after-delete',
      afterList: '/hydra-shandong-pingan-pig-batcher/api/v1/white-strip/acid/detail/after-pages',
      afterEdit: '/hydra-shandong-pingan-pig-batcher/api/v1/white-strip/acid/detail/after-update'
    },
  },
  //下拉类目管理
  select: {
    //新增类目
    ADD: '/hydra-shandong-pingan-pig/api/v1/healthCare/category/add',
    //类目下拉列表
    GET_LIST: '/hydra-shandong-pingan-pig/api/v1/healthCare/category/list',
    //编辑类目
    UPDATE: '/hydra-shandong-pingan-pig/api/v1/healthCare/category/update',
    //根据父id获取子类
    GET_CHILD_LIST: '/hydra-shandong-pingan-pig/api/v1/healthCare/category/subType',
    // 二级类目列表
    GET_SEC_LIST: '/hydra-shandong-pingan-pig/api/v1/healthCare/category/pages'
  },
  //日志管理
  log: {
    //获取日志列表
    GET_LIST: '/hydra-shandong-pingan-pig/api/v1/operation/log/list',
    // 详情
    DETAIL: '/hydra-shandong-pingan-pig/api/v1/operation/log/detail'
  },
  data: {
    amount: '/hydra-shandong-pingan-pig/api/v1/pig/org/pig/on/list',
    fat: '/hydra-shandong-pingan-pig/api/v1/leaveFactoryData/fatPigLeaveData',
    farming: '/hydra-shandong-pingan-pig/api/v1/pig/org/mass/pig/list',
    dead: '/hydra-shandong-pingan-pig/api/v1/leaveFactoryData/deathData',
    sale: '/hydra-shandong-pingan-pig/api/v1/leaveFactoryData/soldData',
    screen: '/hydra-shandong-pingan-pig/api/v1/leaveFactoryData/dataScreenData',
  },
  home: {
    mass: '/hydra-shandong-pingan-pig/api/v1/pig/index/mass/pig/on/list',
    pig: '/hydra-shandong-pingan-pig/api/v1/pig/index/pig/on',
    sales: '/hydra-shandong-pingan-pig/api/v1/leaveFactoryData/soldAmount',
    device: {
      info: '/hydra-shandong-pingan-pig/api/v1/device/deviceInfo',
      list: '/hydra-shandong-pingan-pig/api/v1/device/listDevice',
      detail: '/hydra-shandong-pingan-pig/api/v1/device/getByCode',
    }
  },
  // 猪只产品
  produce: {
    // 猪只产品列表
    GET_LIST: '/hydra-shandong-pingan-pig-batcher/api/v1/pigProduct/list',
    // 获取详情
    GET_DETAIL: '/hydra-shandong-pingan-pig-batcher/api/v1/pigProduct/detail',
    // 产品明细
    GET_PRODUCT_DETAIL: '/hydra-shandong-pingan-pig-batcher/api/v1/pigProduct/productDetail',
    // 产品信息
    GET_PRODUCT_INFO: '/hydra-shandong-pingan-pig-batcher/api/v1/pigProduct/productList'
  },
  // 溯源称管理
  traceabilitySaid: {
    // 获取列表
    GET_LIST: '/hydra-shandong-pingan-pig-batcher/api/v1/traceScale/list',
    // 新增
    ADD: '/hydra-shandong-pingan-pig-batcher/api/v1/traceScale/add',
    // 更新
    UPDATE: '/hydra-shandong-pingan-pig-batcher/api/v1/traceScale/update',
    // 复制关联
    COPY_RELATIVE: '/hydra-shandong-pingan-pig-batcher/api/v1/traceScale/copyLink',
    // 选择设备
    SELECT: '/hydra-shandong-pingan-pig-batcher/api/v1/traceScale/deviceOptions',
    // 启用/禁用
    EBLED: '/hydra-shandong-pingan-pig-batcher/api/v1/traceScale/disable',
    // 热键列表
    GET_LIST_HOTKEY: '/hydra-shandong-pingan-pig-batcher/api/v1/traceScale/hotKeyList',
    // 关联产品
    RELATIVE_PROJECT: '/hydra-shandong-pingan-pig-batcher/api/v1/traceScale/productLink',
    // 解除绑定
    CANCEL_BIND: '/hydra-shandong-pingan-pig-batcher/api/v1/traceScale/unBind',
    // 解除关联
    CANCEL_RELATIVE: '/hydra-shandong-pingan-pig-batcher/api/v1/traceScale/unLink'
  },
  // 兽残仪管理
  residualInstrument: {
    GET_LIST: '/hydra-shandong-pingan-pig-batcher/api/v1/account/list',
    // 禁用或启用
    DISABLED: '/hydra-shandong-pingan-pig-batcher/api/v1/account/disable',
    // 生成账号和密码
    PRODUC_ACCOUNT: '/hydra-shandong-pingan-pig-batcher/api/v1/account/generate'
  },
  // 耳标统计
  identification: {
    USED: '/hydra-shandong-pingan-pig/api/v1/identification/statistics',
    RECYCLE: '/hydra-shandong-pingan-pig-batcher/api/v1/statistics/identity'
  },
  // 统计
  statistics: {
    // 溯源标识使用统计
    TRACE_USED: '/hydra-shandong-pingan-pig-batcher/api/v1/statistics/trace',
    // 猪重量统计
    PIG_WEIGHT: '/hydra-shandong-pingan-pig-batcher/api/v1/statistics/pig-meat'
  }
};
