export default {
  // 产品称重
  productWeight: {
    LIST: '/hydra-intelligent-planting/valid/api/v1/storagemanage/productWeight/list',
    ADD: '/hydra-intelligent-planting/valid/api/v1/storagemanage/productWeight/save',
    EDIT: '/hydra-intelligent-planting/valid/api/v1/storagemanage/productWeight/update',
    EXPORT: '/hydra-intelligent-planting/valid/api/v1/storagemanage/productWeight/export'
  },
  // 分拣入库
  sortIntoStorage: {
    LIST: '/hydra-intelligent-planting/valid/api/v1/storagemanage/productSortInstorage/list',
    // 校验码
    VALID_CODE: '/gugeng/product-code-in/valid-code-is-legal',
    EDIT: '/hydra-intelligent-planting/valid/api/v1/storagemanage/productSortInstorage/update',
    DETAIL: '/hydra-intelligent-planting/valid/api/v1/storagemanage/productSortInstorage/detail',
    EXPORT: '/hydra-intelligent-planting/valid/api/v1/storagemanage/productSortInstorage/export',
    // 产品不贴码分拣入库
    ADD_CODE_LESS: '/hydra-intelligent-planting/valid/api/v1/storagemanage/productSortInstorage/save',
    // 编辑不贴码分拣入库信息
    EDIT_CODE_LESS: '/hydra-intelligent-planting/valid/api/v1/storagemanage/productSortInstorage/update'
  },
  // 包装出库
  packAndOutOfStock: {
    LIST: '/hydra-intelligent-planting/valid/api/v1/outbound/list',
    // 获取入库产品信息--第一次输入码
    FIRST_CODE_IN: '/gugeng/outbound-package-message/code-in/first',
    // 获取入库产品信息--不是第一次输入码
    NOT_FIRST_CODE_IN: '/gugeng/outbound-package-message/code-in',
    // 扫码发货
    ADD: '/gugeng/outbound-package-message/save',
    // 无码包装
    ADD_NO_CODE: '/hydra-intelligent-planting/valid/api/v1/outbound-package-message/code-less/save',
    // 保存出库发货信息
    DELIVER_WAY_SAVE: '/hydra-intelligent-planting/valid/api/v1/uutbound-deliver-way/save',
    // 获取包装出库列表
    OUTBOUND_LIST: '/hydra-intelligent-planting/valid/api/v1/outbound-package-message/list-by-order-id',
    // 获取发货信息详情
    DELIVER_WAY_DETAIL: '/hydra-intelligent-planting/valid/api/v1/uutbound-deliver-way/detail',
    // 获取物流详情
    EXPRESS_INFO: '/hydra-intelligent-planting/valid/api/v1/common/expressInfo',
    EXPORT: '/hydra-intelligent-planting/valid/api/v1/outbound/export',
    EXTENDS_CODE: '/gugeng/outbound-package-message/extends-code',
    // 全部发货
    ALL_DELIVER: '/hydra-intelligent-planting/valid/api/v1/outbound/allDone',
    // 校验码是否符合包装扫码
    PACK_CODE_IS_LEGAL: '/gugeng/outbound-package-message/valid-code-is-legal',
    // 编辑包装出库
    EDIT_PACK_MESSAGE: '/hydra-intelligent-planting/valid/api/v1/outbound-package-message/edit',
    // 获取未出库发货的包装信息列表
    NOT_OUTBOUND_PACK_MSG: '/hydra-intelligent-planting/valid/api/v1/outbound-package-message/list-with-not-outbound-msg'
  },
  // 配送信息维护
  deliveryInfo: {
    LIST: '/hydra-intelligent-planting/valid/api/v1/deliveryInfo/list',
    ADD: '/hydra-intelligent-planting/valid/api/v1/deliveryInfo/save',
    EDIT: '/hydra-intelligent-planting/valid/api/v1/deliveryInfo/update',
    DELETE: '/hydra-intelligent-planting/valid/api/v1/deliveryInfo/deleteById',
    DROPDOWN_LIST: '/hydra-intelligent-planting/valid/api/v1/deliveryInfo/dropDown'
  },
  // 产品库存
  productStock: {
    LIST: '/hydra-intelligent-planting/valid/api/v1/stock/list',
    DETAIL: '/hydra-intelligent-planting/valid/api/v1/stock/detail',
    FLOW_DETAIL_LIST: '/hydra-intelligent-planting/valid/api/v1/productionManageStockFlowDetails/list',
    EXPORT: '/hydra-intelligent-planting/valid/api/v1/stock/export',
    // 全部库存报损
    ALL_LOSE: '/hydra-intelligent-planting/valid/api/v1/productionManageStockLoss/total-inventory-loss',
    // 产品等级库存
    LEVEL_LIST: '/hydra-intelligent-planting/valid/api/v1/stock/product-level-list',
    LEVEL_LIST_EXPORT: '/hydra-intelligent-planting/valid/api/v1/stock/product-level-export',
    ADD_WARNING: '/hydra-intelligent-planting/valid/api/v1/stock/add-or-update-warning'
  },
  // 库存流水
  productStockFlow: {
    LIST: '/hydra-intelligent-planting/valid/api/v1/productionManageStockFlowDetails/list-all',
    EXPORT: '/hydra-intelligent-planting/valid/api/v1/productionManageStockFlowDetails/list-export'
  },
  // 库存报损
  stockLose: {
    LIST: '/hydra-intelligent-planting/valid/api/v1/productionManageStockLoss/list',
    ADD: '/gugeng/productionManageStockLoss/damage',
    // 无码库存报损
    ADD_NO_CODE: '/hydra-intelligent-planting/valid/api/v1/productionManageStockLoss/code-less/damage',
    // 无码报损-报损确认
    STOCK_NO_CODE_LOSS_CONFIRM: '/hydra-intelligent-planting/valid/api/v1/productionManageStockLoss/code-less/confirm',
    // 空余箱子处理
    EXTRA_BOX: '/gugeng/productionManageStockLoss/leftBox',
    // 损坏箱子处理
    DAMAGE_BOX: '/gugeng/productionManageStockLoss/damageBox',
    // 新箱子处理
    NEW_BOX: '/gugeng/productionManageStockLoss/newBox',
    DETAIL: '/hydra-intelligent-planting/valid/api/v1/productionManageStockLoss/detail',
    EXPORT: '/hydra-intelligent-planting/valid/api/v1/productionManageStockLoss/export'
  },
  // 产品报损
  productLose: {
    // 产品报损列表
    LIST: '/hydra-intelligent-planting/valid/api/v1/product-loss/list',
    ADD: '/hydra-intelligent-planting/valid/api/v1/product-loss',
    DETAIL: '/hydra-intelligent-planting/valid/api/v1/product-loss/',
    // 产品报损导出
    EXPORT: '/hydra-intelligent-planting/valid/api/v1/product-loss/export',
    // 报损确认
    CONFIRM_LOSE: '/hydra-intelligent-planting/valid/api/v1/product-loss/'
  },
  // 种植存量
  stockOfPlanting: {
    STOCK_BATCH_LIST: '/hydra-intelligent-planting/valid/api/v1/guGengPlantStock/list-batch',
    STOCK_BATCH_EXPORT: '/hydra-intelligent-planting/valid/api/v1/guGengPlantStock/export-batch',
    STOCK_PRODUCT_LIST: '/hydra-intelligent-planting/valid/api/v1/guGengPlantStock/list-product',
    STOCK_PRODUCT_EXPORT: '/hydra-intelligent-planting/valid/api/v1/guGengPlantStock/export-product',
  },
  // 加工产品入库
  processProductInStorage: {
    LIST: '/hydra-intelligent-planting/valid/api/v1/process/product/in/list',
    ADD: '/hydra-intelligent-planting/valid/api/v1/process/product/in/save',
    EDIT: '/hydra-intelligent-planting/valid/api/v1/process/product/in/update',
    VIEW: '/hydra-intelligent-planting/valid/api/v1/process/product/in/detail',
    DEL: '',
    EXPORT: '/hydra-intelligent-planting/valid/api/v1/process/product/in/export',
    // 加工批次dfdfd
    PROCESS_DROP_LIST: '/hydra-trace-planting/api/v1/batch-Info/listMachiningBatch'
  }
}