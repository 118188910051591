export default {
  //下载中心
  demo: {
    LIST: '/hydra-user/api/v1/role/querylist'
  },
  credit: {
    // 评分标准
    SCORE_LIST: `/hydra-credit/credit/grading/list`,
    SCORE_ADD: `/hydra-credit/credit/grading/add`,
    SCORE_DEL: `/hydra-credit/credit/grading/del`,

    // 模板管理
    TEMPLATE_LIST: `/hydra-credit/credit/template/list`,
    TEMPLATE_ADD: `/hydra-credit/credit/template/add`,
    TEMPLATE_DEL: `/hydra-credit/credit/template/del`,
    TEMPLATE_SEE: `/hydra-credit/credit/template/detail`,
    TEMPLATE_EDIT: `/hydra-credit/credit/template/update`,
    TEMPLATE_EXPORT: `/hydra-credit/credit/template/download`,
    TEMPLATE_LOCK: `/hydra-credit/credit/template/lock`,
    TEMPLATE_UN_LOCK: `/hydra-credit/credit/template/un-lock`,
    INDICATORS_IMPORT: `/hydra-credit/credit/template/file-index-parse`,

    // 信用监管（企业）
    ENTERPRISE_REGULATORY_LIST: "/hydra-credit/credit/company/list",
    ENTERPRISE_REGULATORY_SEE: "/hydra-credit/credit/company/report",
    ENTERPRISE_REGULATORY_EXPORT: "/hydra-credit/credit/company/export",
    ENTERPRISE_REGULATORY_IMPORT: "/hydra-credit/credit/company/import",
    ENTERPRISE_REGULATORY_SCORE: `/hydra-credit/credit/company/score`,
    // 红榜
    ENTERPRISE_REGULATORY_RED:"/hydra-credit/credit/rb-list/on",
    // 黑榜
    ENTERPRISE_REGULATORY_BLACK:"/hydra-credit/credit/rb-list/on",
    // 删除
    NTERPRISE_REGULATORY_DEL:"",

    // 信用监管（农户）
    FARMER_REGULATORY_LIST: "/hydra-credit/credit/farmer/list",
    FARMER_REGULATORY_SEE: "/hydra-credit/credit/farmer/report",
    FARMER_REGULATORY_EXPORT: "/hydra-credit/credit/farmer/export",
    FARMER_REGULATORY_IMPORT: "/hydra-credit/credit/farmer/import",
    FARMER_REGULATORY_SCORE: `/hydra-credit/credit/farmer/score`,

    // 信用统计
    ENTERPRISE_STATISTIC: "/hydra-credit/credit/statistic/company",
    FARMER_REGULATORY: "/hydra-credit/credit/statistic/farmer",
    PIEAVERAGE:'/hydra-credit/score-avg/recent',
    LINEAVERAGE:'/hydra-credit/score-avg/broken-line',
    // 指标统计 公司
    COMPANY_ANSLYSIS:"/hydra-credit/credit/Index/analysis/company",
    FARMER_ANSLYSIS:"/hydra-credit/credit/Index/analysis/farmer",
    // 信用榜单
    credit_list: "/hydra-credit/credit/rb-list/list",
    credit_DEL: "/hydra-credit/credit/rb-list/remove",
    // 基础枚举数据表记录列表
     ENUMERATE_LIST:"/hydra-credit/enumerate/list",
    // 风险防范
    RISKFARMR_LIST:'/hydra-credit/risk/prevention/farmer/list' ,
    COMPANYFARMR_LIST:'/hydra-credit/risk/prevention/company/list' ,
    COMPANYFARMR_EXPORT:'/hydra-credit/risk/prevention/company/export',
    RISKFARMR__EXPORT:'/hydra-credit/risk/prevention/farmer/export',
    // 主体信用排名
    COMPANY__RANKING:"/hydra-credit/credit/ranking/new/template/company/list",
    FARNER__RANKING:"/hydra-credit/credit/ranking/new/template/farmer/list",
    //评分检查
    CHECK__RECIRD:"/hydra-credit/source/record/check",
  }
};
