import stockManage from './stockManage';
import sale from './sale';

export default {
  // 仓储管理
  stockManage,
  // 销售管理
  sale,
  common: {
    // 获取企业所有部门列表
    GET_ALL_DEPARTMENT_LIST: '/hydra-user/api/v1/org/dept/listAll',
    // 员工列表
    SALE_EMPLOYEE_LIST: '/hydra-user/api/v1/employee/list',
    // 产品列表
    PRODUCT_LIST: '/hydra-base-data/api/v1/product/enable/list',
    // 产品规格下拉列表(根据产品id)
    proSpecificationList: '/hydra-intelligent-planting/valid/api/v1/product/specification/product-id',
    // 产品规格
    PRODUCT_SPEC_LIST: '/hydra-intelligent-planting/valid/api/v1/product/specification/list/product',
    // 搜索白叶一号鲜叶产品
    SEARCH_BAI_YE_YI_HAO: '/hydra-intelligent-planting-sm/valid/api/v1/base/search/white-leaf/product',
    // 搜索安吉白茶产品
    SEARCH_AN_JI_BAI_CHA: '/hydra-intelligent-planting-sm/valid/api/v1/base/search/white-tea/product',
    role: {
      //  查询角色列表-带分页（主要用于企业中心角色设置权限）
      ROLE_LIST: '/hydra-user/api/v1/role/org/list',
    },
    dept: {
      // 获取当组织部门列表，分页
      DEPT_LIST: '/hydra-user/api/v1/org/dept/list'
    },
  },
  // 包材
  maintenance: {
    // 包材名称下拉
    PACKAGE_DROP_LIST: '/hydra-intelligent-planting/valid/api/v1/stuff/dropDown',
    // 包材规格下拉
    PACKAGE_DROP_SPECIFICATION_LIST: '/hydra-intelligent-planting/valid/api/v1/stuffSpecification/dropDown',
    // 包材配置列表
    CONFIG_LIST: '/hydra-intelligent-planting/valid/api/v1/packing/material/alloc/list'
  },
  packageMaterialConfig: {
    LIST: '/hydra-intelligent-planting/valid/api/v1/packing/material/alloc/list',
    CRUD: '/hydra-intelligent-planting/valid/api/v1/packing/material/alloc',
    // 包材（来源于智慧种植）
    // 包材名称下拉
    PACKAGE_DROP_LIST: '/hydra-intelligent-planting/valid/api/v1/stuff/dropDown',
    // 包材规格下拉
    PACKAGE_DROP_SPECIFICATION_LIST: '/hydra-intelligent-planting/valid/api/v1/stuffSpecification/dropDown',
  },
};
