//提货券
export default {
  //获取提货券列表
  GET_LIST: '/hydra-marketing/api/v1/voucher/listExchangeCard',
  //创建提货券模版
  ADD: '/hydra-marketing/api/v1/voucher/createExchangeCard',
  //编辑提货券模版
  EDIT: '/hydra-marketing/api/v1/voucher/updateExchangeCard',
  //获取提货券模版详细信息
  GET_DETAIL: '/hydra-marketing/api/v1/voucher/detail',
  //设置状态
  SET_STATUS: '/hydra-marketing/api/v1/voucher/status',
  //删除
  DELETE: '/hydra-marketing/api/v1/voucher/delete'
}