/*
 * @Author: duyaoyao
 * @Date: 2020-06-10 12:27:46
 * @LastEditors: duyaoyao
 * @LastEditTime: 2021-04-07 13:54:51
 */ 
export default {
  //检测项
  checkItem: {
    GET_LIST: '/trace/producttesting/testingtype/page',
    EDIT: '/trace/producttesting/testingtype',
    ADD: '/trace/producttesting/testingtype',
    GET_ORG_LIST: '/hydra-user/api/v1/org/list/sys',
    GET_DEPARTMENT_LIST: '/hydra-user/api/v1/org/dept/listAll',
    GET_DEPARTMENT_LIST1: '/hydra-user/api/v1/org/dept/list',
    GET_THRID_LIKE:'/hydra-open-thirdpart-service/business/open/thrid/like'
  },
  //内部检测
  insideCheck: {
    GET_LIST: '/trace/producttesting/producttesting/page',
    EDIT: '/trace/producttesting/producttesting',
    ADD: '/trace/producttesting/producttesting',
    DELETE: '/trace/producttesting/producttesting',
    GET_DETAIL: '/trace/producttesting/producttesting/getById',
    GET_PLATFORM_ORG: '/hydra-user/api/v1/org/list/platform',
    auto: {
      PRODUCT_LIST: '/hydra-base-data/api/v1/product/enable/list', //产品
      PRODUCT_BATCH_LIST: '/hydra-base-data/api/v1/product-batch/batch/list', //产品批次
      EMPLOYEE: '/hydra-user/api/v1/employee/enable/list/all', //检测人员
      STANDARD_LIST: '/hydra-base-data/api/v1/config/type?typeId=035', //检测标准
      DEVICE_LIST: '/hydra-base-data/api/v1/config/type?typeId=036' //检测设备
    }
  },
  // 检测标准
  testStandard: {
    GET_LIST: '/trace/producttesting/testingstandard/list',
    EDIT: '/trace/producttesting/testingstandard',
    ADD: '/trace/producttesting/testingstandard',
  }
}
