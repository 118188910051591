import template from "./apiModules/template"; // 溯源模版
import record from "./apiModules/record"; // 溯源记录
import productProcess from "./apiModules/productProcess";
import quality from "./apiModules/quality";
import { prefix } from "./config";
// 生产管理
export default {
  template,
  record,
  productProcess,
  quality,
  // 基础数据
  base: {
    // 基地下拉数据
    BASE_LIST: "/hydra-base-data/api/v1/base/select/list",
    // 获取下拉列表
    GET_SELECT_LIST: "/hydra-base-data/api/v1/config/type",
  },
  product: {
    GET_SORT_LIST: "/hydra-base-data/api/v1/product-sort/all",
    GET_BATCH_LIST: "/hydra-base-data/api/v1/product-batch/list",
  },
  relation: {
    // 获取列表
    GET_LIST: "/hydra-production/api/v1/query/code/relation/page/list",
    // 清空码关联
    EMPTY_DATA: "/trace/code/relation/empty",
    // 码关联
    ADD_RELATION: "/trace/code/relation",
    // 三江码关联
    ADD_RELATION_SJ: "/trace/code/relation/addRelationForSanJiang",
  },
  // 批次管理
  batch: {
    GET_TLIST: "/trace/batch/info/page",
    ADD_BATCH: "/trace/batch/info",
    // 获取产品下拉
    GET_PRODUCT_LIST: "/hydra-base-data/api/v1/product/enable/list",
    // 获取溯源模板下拉
    GET_TRACE_QUERY: "/trace/traceFunTemplateconfig/query",
    // 获取H5溯源模板
    GET_H5_LIST: "/hydra-base-data/api/v1/platform/h5/list",
    // 编辑批次
    GET_TRACE_BATCH_INFO: "/trace/batch/info",
    // 删除批次
    DELETE_TRACE_BATCH_INFO: "/trace/batch/info",
    // 批量获取批次的节点数量
    GET_BATCH_NODE_COUNT: "/trace/batch/info/listBatchNodeDataCount",
    // 获取农户下拉列表
    GET_FAMILY_LIST:
      "/digital-village/hydra-digital-village/api/v1/family-information/list",
    // 新增溯源批次
    GET_COPY: "/trace/api/v3/batch/extend/copy",
  },
  // 区块链
  block: {
    // 列表
    GET_LIST: "/trace/node/blockchain/page",
    // 校验是否上链
    CHECK_BLOCK_INFO: "/trace/node/blockchain/checkNodeBlockInfo",
    // 根据批次唯一id查找上链信息接口
    GET_NODE_BLOCK_INFO: "/trace/node/blockchain/getNodeBlockInfo",
  },
  // 区块链
  block3: {
    // 列表
    GET_LIST: "/trace/api/v3/node/blockchain/page",
    // 校验是否上链
    CHECK_BLOCK_INFO: "/trace/api/v3/node/blockchain/checkNodeBlockInfo",
    // 根据批次唯一id查找上链信息接口
    GET_NODE_BLOCK_INFO: "/trace/api/v3/node/blockchain/getNodeBlockInfo",
  },
  code: {
    ADD_CODE_RELATION: "/trace/code/relation",
    EMPTY_DATA: "/trace/code/relation/empty",
  },
  addRelation: {
    PRODUCT: "/hydra-base-data/api/v1/product/enable/like/name/list",
    SBATCH: "/hydra-base-data/api/v1/product-batch/batch/list",
  },
  // 存放地点
  storagePlace: {
    // 列表
    GET_LIST: "/trace/zaoyangpeach/storageplace/page",
    // 设置存放地点启用状态
    CHANGE_STATUS: "/trace/zaoyangpeach/storageplace/updateDisableFlag",
    // 获取分拣点
    LIST_STORAGE: "/trace/zaoyangpeach/storageplace/listSortingPlace",
    // 负责人
    EMPLOYEE_LIST: "/hydra-user/api/v1/employee/enable/list",
    // 新增存放地点
    ADD: "/trace/zaoyangpeach/storageplace",
    // 修改存放地点
    EDIT: "/trace/zaoyangpeach/storageplace",
  },
  // 合格证
  certificate: {
    listPrint: {
      // 合格证打印记录列表
      GET_LIST: "/trace/certificate/certificateinfo/listPrintInfo",
      // 获取产品合格证打印详情
      GET_BY_ID: "/trace/certificate/certificateinfo/getPrintInfoById",
    },
    printCertificate: {
      GET_LIST: "/trace/certificate/certificateinfo/list",
      // 新增产品合格证
      ADD_CERTIFICATE: "/trace/certificate/certificateinfo",
      // 修改产品合格证
      EDIT_CERTIFICATE: "/trace/certificate/certificateinfo",
      // 删除产品合格证
      DEL_CERTIFICATE: "/trace/certificate/certificateinfo",
      // 设置产品合格证启用状态
      SET_STATUS: "/trace/certificate/certificateinfo/updateDisableFlag",
      // 获取产品合格证详情
      GET_BY_ID: "/trace/certificate/certificateinfo/getById",
      // 打印产品合格证
      PRINT_CERTIFICATE: "/trace/certificate/certificateinfo/printCertificate",
      // 复制产品合格证模板
      COPY: "/trace/certificate/certificateinfo/copy",
      // 获取合格证流水号
      GET_CODE_LIST: "/trace/certificate/certificateinfo/getPrintDataById",
    },
    template: {
      // 获取合格证列表
      GET_LIST: "/trace/certificate/certificatetemplate/list",
      // 新增合格证模板
      ADD: "/trace/certificate/certificatetemplate",
      // 合格证模板详情
      GET_BY_ID: "/trace/certificate/certificatetemplate/getById",
      // 修改合格证模板
      SAVE: "/trace/certificate/certificatetemplate",
      // 复制合格证模板
      COPY: "/trace/certificate/certificatetemplate/copy",
      // 启用
      ENABLE: "/trace/certificate/certificatetemplate/enable",
      // 禁用
      DISABLED: "/trace/certificate/certificatetemplate/disable",
    },
    // 数据统计
    statistics: {
      // 打印记录
      PRINT_RECORD: "/trace/api/v3/certificate/print-info/list",
      // 使用统计
      USED: "/trace/api/v3/certificate/used-statistics/list",
      // 码数量列表
      CODE_NUM: "/trace/api/v3/certificate/code-safety-margin/selectList",
      // 合格证码申请记录
      CODE_APPLY:
        "/hydra-code-manager/code/allocation/certification/record/page/list",
      // 码数量修改
      CODE_NUM_MODIFY:
        "/trace/api/v3/certificate/code-safety-margin/setCodeSafetyMargin",
    },
  },
  // 溯源时间戳
  timestamping: {
    // 列表页面
    list: {
      // 获取批次时间戳列表
      GET_LIST: "/trace/tsa/batchtsa/page",
    },
    // 查看页面
    detail: {
      // 获取批次时间戳节点列表
      GET_LEFT: "/trace/tsa/batchtsa/getBatchTsaInfo",
      // 获取节点时间戳认证历史记录
      GET_RIGHT: "/trace/tsa/batchtsa/getNodeTsaList",
      // 获取时间戳证书url
      GET_URL: "/trace/tsa/batchtsa/getTsaPdf",
    },
  },
  // 保险理赔
  insurance: {
    claim: {
      GET_LIST: "/insurance/organization/policy/list",
      CALCULATE_CODE: "/insurance/set/calculateCodeNum",
      GET_ADD_INFO: "/insurance/organization/policy/addpage",
      GET_PRODUCT_LIST: "/insurance/organization/policy/relationActProds",
      INSURANCE_CHECK: "/insurance/organization/policy/detail",
      INSURANCE_ADD: "/insurance/organization/policy/save",
      INSURANCE_DRAFT_SAVE: "/insurance/organization/policy/savedraft",
      INSURANCE_EDIT: "/insurance/organization/policy/update",
      INSURANCE_ACTIVE: "/insurance/organization/policy/activate",
      INSURANCE_RELATE: "/insurance/organization/policy/relatecode",
      INSURANCE_RELATE_INFO: "/insurance/organization/policy/relation",
      INSURANCE_DESTROY: "/insurance/organization/policy/delete",
      INSURANCE_LIST: "/insurance/organization/policy/set/list", // 保险列表
    },
    claimRecord: {
      GET_LIST: "/insurance/organization/compensation/list",
      GET_DETAIL: "/insurance/organization/compensation/detail",
    },
    company: {
      GET_LIST: "/insurance/company/policy/list",
      INSURANCE_CHECK: "/insurance/company/policy/detail",
      INSURANCE_AUDIT: "/insurance/company/policy/audit",
      INSURANCE_COMPENSATE: "/insurance/company/policy/accept",
      INSURANCE_RELATION: "/insurance/company/policy/relation",
    },
    companyRecord: {
      GET_LIST: "/insurance/company/compensation/list",
      INSURANCE_CHECK: "/insurance/company/compensation/detail",
      INSURANCE_AUDIT: "/insurance/company/compensation/audit",
      INSURANCE_COMPENSATE: "/insurance/company/compensation/payfor",
      IMAGE_DOWNLOAD: "/insurance/company/compensation/img/batchdownload", // 批量下载图片
    },
  },
  // h5设置
  h5Setting: {
    SHARE_SAVE: "/trace/batch/h5page/saveShareConfig",
    SHARE_FETCH: "/trace/batch/h5page/getShareConfig",
    MODULE_SAVE: "/trace/batch/h5page/saveCustomModuleConfig",
    MODULE_FETCH: "/trace/batch/h5page/getCustomModuleConfig",
  },
  // 结构化字段
  struct: {
    //获取组织的 结构化字段 配置
    CHECK_ORG_STATE: "/hydra-user/api/v1/org/tracetempconf",
  },
  // nfc业务
  nfc: {
    LIST: "/hydra-production/api/v1/nfc-extend-code/page/list",
    IMPORT: "/hydra-production/api/v1/nfc-extend-code/nfc/upload/record",
    RECORD_LIST: "/trace/codeSearch/list",
    // ---导入重新对接
    FILE_UPLOAD: `${prefix}/hydra-open-third-party/api/v1/hydra-file/upload`,
    IMPORT:"/hydra-production/api/v1/nfc-extend-code/nfc/upload/record"
  },
};
