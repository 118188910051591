import { loadMenu } from 'system/services/menu';
import _ from 'lodash';

//

function getMenuFun(menuItem) {
  let menuFuns = [];
  const funList = _.get(menuItem, 'funList');
  const childMenu = _.get(menuItem, 'childMenu');
  if (Array.isArray(funList)) {
    menuFuns = menuFuns.concat(funList);
  }
  if (Array.isArray(childMenu)) {
    childMenu.forEach(sItem => {
      menuFuns = menuFuns.concat(getMenuFun(sItem));
    });
  }
  return menuFuns;
}

export const getButtonAuthCode = async function () {
  const menuData = await loadMenu();
  let funList = [];
  menuData.forEach(menuItem => {
    funList = funList.concat(getMenuFun(menuItem));
  });
  return funList.map(fun => fun.permCode).filter(Boolean);
};
