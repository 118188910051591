export default {
  growingSeason: {
    GET_LIST: "/yannan-data-system/api/v1/period/list",
    SAVE: "/yannan-data-system/api/v1/period/add",
    GET: "/yannan-data-system/api/v1/period/getById",
    UPDATE: "/yannan-data-system/api/v1/period/update",
    DELETE:"/yannan-data-system/api/v1/period/delete",
    GET_GROW:"/yannan-data-system/api/v1/enum/list"
  },
  harvestPeriod: {
    GET_LIST: "/yannan-data-system/api/v1/pick/list",
    SAVE: "/yannan-data-system/api/v1/pick/add",
    GET: "/yannan-data-system/api/v1/pick/getById",
    UPDATE: "/yannan-data-system/api/v1/pick/update",
    DELETE:"/yannan-data-system/api/v1/pick/delete"
  },
  annualOutput: {
    GET_LIST: "/yannan-data-system/api/v1/produce/list",
    SAVE: "/yannan-data-system/api/v1/produce/add",
    GET: "/yannan-data-system/api/v1/produce/getById",
    UPDATE: "/yannan-data-system/api/v1/produce/update",
    DELETE:"/yannan-data-system/api/v1/produce/delete"
  },
};
