export default {
  // 维护材料
  material: {
    LIST: '/hydra-intelligent-planting/valid/api/v1/stuff/list',
    ADD: '/hydra-intelligent-planting/valid/api/v1/stuff/save',
    EDIT: '/hydra-intelligent-planting/valid/api/v1/stuff/update',
    DISABLE: '/hydra-intelligent-planting/valid/api/v1/stuff/disable',
    ENABLE: '/hydra-intelligent-planting/valid/api/v1/stuff/enable',
    DETAIL: '/hydra-intelligent-planting/valid/api/v1/stuff/detail',
    EXPORT: '/hydra-intelligent-planting/valid/api/v1/stuff/export',
    DROP_DOWN: '/hydra-intelligent-planting/valid/api/v1/stuff/dropDown',
    // 规格下拉
    SPECIFICATION_DROP_DOWN: '/hydra-intelligent-planting/valid/api/v1/stuffSpecification/dropDown',
    // 材料批次下拉
    WAREHOUSE_DROP_DOWN: '/hydra-intelligent-planting/valid/api/v1/stuff/in/warehouse/dropDownBatch',
  },
  // 维护材料分类
  sort: {
    SORT_FIRST: '/hydra-intelligent-planting/valid/api/v1/stuffSort/first',
    CHILDREN: '/hydra-intelligent-planting/valid/api/v1/stuffSort/children',
    DELETE: '/hydra-intelligent-planting/valid/api/v1/stuffSort/deleteOne',
    ADD: '/hydra-intelligent-planting/valid/api/v1/stuffSort/save',
    EDIT: '/hydra-intelligent-planting/valid/api/v1/stuffSort/update'
  },
  // 维护材料库存
  stock: {
    LIST: '/hydra-intelligent-planting/valid/api/v1/stuff/stock/list',
    WARNING: '/hydra-intelligent-planting/valid/api/v1/stuff/stock/warningInventory',
    EXPORT: '/hydra-intelligent-planting/valid/api/v1/stuff/stock/export',
    DETAIL: '/hydra-intelligent-planting/valid/api/v1/stuff/stock/detail',
    // 获取出入库编号
    BOUND_CODE: '/hydra-intelligent-planting/valid/api/v1/stuff/stock/boundCode',
    // 入库
    SAVE: '/hydra-intelligent-planting/valid/api/v1/stuff/in/warehouse/save',
    // 出库
    EDIT: '/hydra-intelligent-planting/valid/api/v1/productionManageStuffOutWarehouse/save',
    // 批次详情
    BATCH_DETAIL: '/hydra-intelligent-planting/valid/api/v1/stuff/stock/batchDetailList',
    // 流水详情
    FLOW_DETAIL: '/hydra-intelligent-planting/valid/api/v1/stuff/stock/flowDetailList',
    // 出库库存校验
    OUTBOUND_CHECK: '/hydra-intelligent-planting/valid/api/v1/stuff/stock/outboundCheck'
  },
  // 维修管理
  managements: {
    LIST: '/hydra-intelligent-planting/valid/api/v1/repairManage/list',
    DELETE: '/hydra-intelligent-planting/valid/api/v1/repairManage/deleteOne',
    // 维修申请
    ADD: '/hydra-intelligent-planting/valid/api/v1/repairManage/callRepair',
    // 维修指派
    APPOINT: '/hydra-intelligent-planting/valid/api/v1/repairManage/assignRepair',
    // 执行维修
    RUN_REPAIR: '/hydra-intelligent-planting/valid/api/v1/repairManage/runRepair',
    // 维修完成
    DONE_REPAIR: '/hydra-intelligent-planting/valid/api/v1/repairManage/doneRepair',
    // 完成确定
    CONFIRM: '/hydra-intelligent-planting/valid/api/v1/repairManage/doneConfirm',
    // 维修评价
    APPRAISE: '/hydra-intelligent-planting/valid/api/v1/repairManage/commentRepair',
    EDIT: '/hydra-intelligent-planting/valid/api/v1/repairManage/update',
    DETAIL: '/hydra-intelligent-planting/valid/api/v1/repairManage/detail',
    EXPORT: '/hydra-intelligent-planting/valid/api/v1/repairManage/exportExcel',
    // 导出pdf
    EXPORT_PDF: '/hydra-intelligent-planting/valid/api/v1/repairManage/exportPdf'
  },
  // 巡检管理
  inspectionManages: {
    LIST: '/hydra-intelligent-planting/valid/api/v1/inspectionManage/list',
    ADD: '/hydra-intelligent-planting/valid/api/v1/inspectionManage/save',
    EDIT: '/hydra-intelligent-planting/valid/api/v1/inspectionManage/update',
    DELETE: '/hydra-intelligent-planting/valid/api/v1/inspectionManage/deleteOne',
    INSPECTION: '/hydra-intelligent-planting/valid/api/v1/inspectionManage/inspection',
    DONE_INSPECTION: '/hydra-intelligent-planting/valid/api/v1/inspectionManage/doneInspection',
    EXPORT: '/hydra-intelligent-planting/valid/api/v1/inspectionManage/export'
  },
  // 包材配置
  config: {
    LIST: '/hydra-intelligent-planting/valid/api/v1/packing/material/alloc/list',
    CRUD: '/hydra-intelligent-planting/valid/api/v1/packing/material/alloc',
  }
}
