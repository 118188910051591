export default {
  //根据组织ID获取着陆页卡片配置
  GET_CONFIG_BY_ORGID: '/cms/card/configuration/organization/get',
  //获取导航数据
  GET_NAV_CONFIG: '/cms/navigation/page/list',
  //新增导航
  ADD_NAV: '/cms/navigation',
  //编辑导航
  EDIT_NAV: '/cms/navigation',
  //切换显示隐藏状态
  TOGGLE_DISPLAY: '/cms/navigation/display',
  //导航排序上移
  NAV_UP: '/cms/navigation/up',
  //导航排序下移
  NAV_DOWN: '/cms/navigation/down',
  //删除导航
  NAV_DELETE: '/cms/navigation/delete/',
  // 获取卡片列表
  GET_CARD_LIST: '/cms/card/get/type/list',
  //--------------------------------------------------------------------------------------------------------------------
  //获取轮播图列表
  GET_CAROUSEL_IMAGE_LIST: '/cms/carousel/image/page/list',
  //新增轮播图
  ADD_CAROUSEL_IMAGE: '/cms/carousel/image',
  //删除轮播图
  DELETE_CAROUSEL_IMAGE: '/cms/carousel/image/delete',
  //编辑轮播图
  EDIT_CAROUSEL_IMAGE: '/cms/carousel/image',
  //获取轮播图详情
  GET_CAROUSEL_IMAGE_DETAIL: '/cms/carousel/image/get',
  //--------------------------------------------------------------------------------------------------------------------
  //获取新闻列表
  GET_NEWS_LIST: '/cms/news/page/list',
  //新增新闻
  ADD_NEWS: '/cms/news',
  //删除新闻
  DELETE_NEWS: '/cms/news/delete',
  //编辑新闻
  EDIT_NEWS: '/cms/news',
  //获取新闻详情
  GET_NEWS_DETAIL: '/cms/news/get',
  // 获取分类下拉
  GET_TYPE_LIST: '/cms/cardSubType/list',
  //--------------------------------------------------------------------------------------------------------------------
  //获取公示公告列表
  GET_NOTICE_LIST: '/cms/notice/page/list',
  //新增公示公告
  ADD_NOTICE: '/cms/notice',
  //删除公示公告
  DELETE_NOTICE: '/cms/notice/delete',
  //编辑公示公告
  EDIT_NOTICE: '/cms/notice',
  //获取公示公告详情
  GET_NOTICE_DETAIL: '/cms/notice/get',
  //--------------------------------------------------------------------------------------------------------------------
  //获取图片列表
  GET_IMAGE_LIST: '/cms/image/page/list',
  //新增图片
  ADD_IMAGE: '/cms/image',
  //删除图片
  DELETE_IMAGE: '/cms/image/delete',
  //编辑图片
  EDIT_IMAGE: '/cms/image',
  //获取图片详情
  GET_IMAGE_DETAIL: '/cms/image/get',
  //--------------------------------------------------------------------------------------------------------------------
  //获取信息列表
  GET_INFOMATION_LIST: '/cms/publish/information/page/list',
  //新增信息
  ADD_INFOMATION: '/cms/publish/information',
  //删除信息
  DELETE_INFOMATION: '/cms/publish/information/delete',
  //编辑信息
  EDIT_INFOMATION: '/cms/publish/information',
  //获取信息详情
  GET_INFOMATION_DETAIL: '/cms/publish/information/get',
  //--------------------------------------------------------------------------------------------------------------------
  //获取产品列表
  GET_PRODUCT_LIST: '/cms/publish/product/page/list',
  //新增产品
  ADD_PRODUCT: '/cms/publish/product',
  //删除产品
  DELETE_PRODUCT: '/cms/publish/product/delete',
  //编辑产品
  EDIT_PRODUCT: '/cms/publish/product',
  //获取产品详情
  GET_PRODUCT_DETAIL: '/cms/publish/product/get',
  //--------------------------------------------------------------------------------------------------------------------
  //获取友情链接列表
  GET_FRIENDLINK_LIST: '/cms/friendly/link/page/list',
  //新增友情链接
  ADD_FRIENDLINK: '/cms/friendly/link',
  //删除友情链接
  DELETE_FRIENDLINK: '/cms/friendly/link/delete',
  //编辑友情链接
  EDIT_FRIENDLINK: '/cms/friendly/link',
  //获取友情链接详情
  GET_FRIENDLINK_DETAIL: '/cms/friendly/link/get',
};
