/* @discription: 大屏控制api
 * @Author: yushunping 
 * @Date: 2021-04-19 16:13:55 
 * @Last Modified by: yushunping
 * @Last Modified time: 2021-04-26 17:21:54
*/

export default {
  numberDictionary: {
    list: "/hydra-data-screen/api/v1/data-dict/list",
    add: "/hydra-data-screen/api/v1/data-dict/add",
    update: "/hydra-data-screen/api/v1/data-dict/update",
  },
  rolesManageapi: {
    list: "/hydra-data-screen/api/v1/role/list",
    add: "/hydra-data-screen/api/v1/role/add",
    delete: "/hydra-data-screen/api/v1/role/delete",
    upList: "/hydra-data-screen/api/v1/data-dict/dict/options",
    update: "/hydra-data-screen/api/v1/role/update",
    upList2: "/hydra-data-screen/api/v1/role/options",
  },
  accountManage: {
    list: "/hydra-data-screen/api/v1/account/list",
    add: "/hydra-data-screen/api/v1/account/add",
    delete: "/hydra-data-screen/api/v1/account/delete",
    update: "/hydra-data-screen/api/v1/account/update",
  },
  screenManage: {
    list: "/hydra-data-screen/api/v1/display/list",
    add: "/hydra-data-screen/api/v1/display/add",
    update: "/hydra-data-screen/api/v1/display/update",
    delete: "/hydra-data-screen/api/v1/display/delete"
  },
  numberDriveManage: {
    list: "/hydra-data-screen/api/v1/visual-screen/list",
    add: "/hydra-data-screen/api/v1/visual-screen/add",
    update: "/hydra-data-screen/api/v1/visual-screen/update",
    delete: "/hydra-data-screen/api/v1/visual-screen/delete"
  },
  screenSet: {
    list: "/hydra-data-screen/api/v1/display-preset/list",
    screenList: "/hydra-data-screen/api/v1/display/options",
    driveList: "/hydra-data-screen/api/v1/visual-screen/options",
    add: "/hydra-data-screen/api/v1/display-preset/add",
    update: "/hydra-data-screen/api/v1/display-preset/update",
    delete: "/hydra-data-screen/api/v1/display-preset/delete",
    detail: "/hydra-data-screen/api/v1/display-preset/detail",
  }
};