export default {
  //下载中心
  downLoadCenter: {
    LIST: '/hydra-download-center/api/v1/task/record/get/page/list',
    UPDATE_DOWNLOAD_USER: "/hydra-download-center/api/v1/task/record/update/download/user"
  },
  // 客户管理
  customer: {
    //钓鱼台客户管理
    dyt_LIST: '/hydra-base-data/api/v1/dyt/customer/all-child',
    dyt_IMPORT: "/hydra-base-data/api/v1/dyt/customer/import-cus-file",
    dyt_EXPORT: "/hydra-base-data/api/v1/dyt/customer/export-cus",
    dyt_EDIT: "/hydra-base-data/api/v1/dyt/customer",

    // 基础客户管理
    LIST: "/hydra-base-data/api/v1/customer/all-child",
    DISABLE: "/hydra-base-data/api/v1/customer/disable",
    ENABLE: "/hydra-base-data/api/v1/customer/enable",
    EDIT: "/hydra-base-data/api/v1/customer",
    ENABLE_LIST: "/hydra-base-data/api/v1/customer/superior/enable/list", // 上级列表
    THIRD_LIKE: "/hydra-open-thirdpart-service/business/open/thrid/like", // 模糊查询第三方工商库组织列表
    EXACT: "/hydra-open-thirdpart-service/business/open/thrid/exact/new", // 根据组织全名获取工商精确信息
    // 获取当前用户客户信息
    GET_CUSTOMER: "/hydra-base-data/api/v1/customer/cur",
    // 批量导入
    IMPORT: "/hydra-base-data/api/v1/customer/import-cus-file",
    // 批量导出
    EXPORT: "/hydra-base-data/api/v1/customer/export-cus",
    // 客户管理高级搜索获取客户上级
    GET_SUPERIOR: "/hydra-base-data/api/v1/customer/customer-superior/list",
    // 获取客户列表
    GET_LIST: "/hydra-base-data/api/v1/customer/list",
    // 对应客户的子账号分页列表
    GET_Customersubuser: "/hydra-base-data/api/v1/customer/employee/page",
    // 添加对应子账号
    AddingaSubUser: "/hydra-base-data/api/v1/customer/employee/add",

    // 禁用对应子账号
    SUBUSERDISABLE: "/hydra-user/api/v1/employee/disable/status",
    // 启用对应子账号
    SUBUSERENABLE: "/hydra-user/api/v1/employee/enable/status",
    // 编辑子账号
    SUBUSEREDIT: '/hydra-base-data/api/v1/customer/employee/edit',
    // 同步第三方数据
    SYNC_DATA: '/hydra-base-data/api/v1/third/customer/sync-third',
    // 删除账号
    SUBUSERDEL: '/hydra-base-data/api/v1/customer/employee/delete'
  },
  // 子账号管理

  SubaccountManagement: {
    Information: "/hydra-base-data/api/v1/customer/employee/page-to-yourself",
    // 自身子账户列表
    AddInformation: "/hydra-base-data/api/v1/customer/employee/add-to-yourself",
    // 添加子账号
    DISUBINGSUACCOUNTS: "/hydra-user/api/v1/employee/disable/status",
    // 禁用
    ENABLINGASUBACCOUNT: "/hydra-user/api/v1/employee/enable/status",
    // 启用
  },
  // 产品管理
  product: {
    // 更新第三方数据
    SYNCHRONIZING: '/hydra-base-data/api/v1/product/data/sync-third',
    GET_LIST: "/hydra-base-data/api/v1/product/list",
    // 获取产品列表
    SAVE: "/hydra-base-data/api/v1/product",
    // 禁用产品
    PRODUCTSEXPORT: "/hydra-base-data/api/v1/product/export/product-batch-file",
    // 产品管理批次导出
    PRODUCTSEXPORTEXPORT: "/hydra-base-data/api/v1/product/export/product-file",
    // 产品导出
    DISABLE: "/hydra-base-data/api/v1/product/disable",
    // 启用产品
    ENABLE: "/hydra-base-data/api/v1/product/enable",
    // 更新产品
    UPDATE: "/hydra-base-data/api/v1/product",
    // 更新产品
    GET: "/hydra-base-data/api/v1/product/one",
    // 产品的批量导入
    BULKIMPORT: "/hydra-base-data/api/v1/product/import/product-file",
    PRODUCT_CATEGORY: "/hydra-base-data/api/v1/product-category/",
    // 产品仓储管理
    PRODUCT_WARE_HOUSE: "/hydra-base-data/api/v1/product-ware-house",
    // 获取产品分类
    PRODUCT_SORT_FIRSTS: "/hydra-base-data/api/v1/product-sort/firsts",
    // 获取产品子分类
    PRODUCT_SORT_CHILD: "/hydra-base-data/api/v1/product-sort/childs",
    // 增加编辑产品分类
    PRODUCT_SORT: "/hydra-base-data/api/v1/product-sort",
    // 添加产品下级分类
    PRODUCT_SORT_NEXT: "/hydra-base-data/api/v1/product-sort/next",
    // 获取当前系统标签
    SYS_LABEL: "/hydra-user/api/v1/sys/label",
    // 获取营销信息
    GET_MAKET: "/hydra-base-data/api/v1/product/market/no-filter",
    // 获取启用的产品,模糊搜索只有产品名称
    GET_LIST_AUTO: "/hydra-base-data/api/v1/product/enable/like/name/list",
    // 获取启用的并且已经关联了仓储的产品
    GET_LIST_WAREHOUSE:
      "/hydra-base-data/api/v1/product/enable/ware-house/list",
    // 获取tags
    FETCH_TAGS: "/hydra-base-data/api/v1/config/type",
  },
  //仓库管理
  wareHouse: {
    //获取启用的（搜索）仓库列表
    GET_WAREHOUSE_LIST: "/hydra-base-data/api/v1/ware-house/enable/list",
  },
  // 库位管理
  location: {
    // 获取启用的仓位列表
    GET_ENABLE_LIST: "/hydra-base-data/api/v1/store-house/enable/page/list",
  },
  // 供应商
  supper: {
    GET_LIST: "/hydra-base-data/api/v1/supper/list",
    // 平安猪供应商列表
    GET_LIST_PIG: "/hydra-base-data/api/v1/org/union/supper/list",
    // 保存
    SAVE: "/hydra-base-data/api/v1/supper",
    // 禁用
    DISABLE: "/hydra-base-data/api/v1/supper/disable",
    // 启用
    ENABLE: "/hydra-base-data/api/v1/supper/enable",
    // 更新
    UPDATE: "/hydra-base-data/api/v1/supper",
    // 更新
    GET: "/hydra-base-data/api/v1/supper",
    // 下拉列表使用，获取供应商列表
    AUTO_GET_LIST: "/hydra-base-data/api/v1/supper/select/list",
    // 下拉列表使用，获取供应商列表-平安猪用
    AUTO_GET_LIST_PIG: "/hydra-base-data/api/v1/org/union/select/list",
    // 同步第三方数据
    SYNC_DATA: '/hydra-base-data/api/v1/third/supplier/sync-third',
    // 供应商绑定员工
    BIND_EMPLOYEE:"/hydra-base-data/api/v1/supper/bindEmployee",
    // 获取供应商绑定的员工
    GET_EMPLOYEE:"/hydra-base-data/api/v1/supper/getEmployee",
    // 员工下拉列表
    LIST_ENABLE:"/hydra-user/api/v1/org/dept/departmentAll"
  },
  // 设备管理
  devices: {
    GET_LIST: "/hydra-base-data/api/v1/produce/device/list",
    // 保存
    SAVE: "/hydra-base-data/api/v1/produce/device",
    // 禁用
    DISABLE: "/hydra-base-data/api/v1/produce/device/disable",
    // 启用
    ENABLE: "/hydra-base-data/api/v1/produce/device/enable",
    // 更新
    UPDATE: "/hydra-base-data/api/v1/produce/device",
    // 获取单一
    GET: "/hydra-base-data/api/v1/produce/device",
    // 对应设备使用记录
    GET_LIST_RECORD: "/hydra-base-data/api/v1/device/usage-record",
  },
  // 产品批次管理
  batch: {
    GET_LIST: "/hydra-base-data/api/v1/product-batch/list",
    GET_LIST_BY_PRODUCT: "/hydra-base-data/api/v1/product-batch/batch/list", //获取产品批次，根据产品id
    // 获取产品下拉
    GET_PRODUCT_LIST: "/hydra-base-data/api/v1/product/enable/list",
    ADD: "/hydra-base-data/api/v1/product-batch",
    // 编辑批次
    GET_BATCH_INFO: "/hydra-base-data/api/v1/product-batch",
    // 删除批次
    DELETE_BATCH_INFO: "/hydra-base-data/api/v1/product-batch",
    IMPORT: "/hydra-base-data/api/v1/product/import/product-batch-file",
  },
  // 包装关联配置
  package: {
    EDIT: "/package-config",
  },
  // 基础数据
  base: {
    CONFIF_TYPE: "/hydra-base-data/api/v1/sort/type/page/list",
    AOTU_GET_LIST: "/hydra-base-data/api/v1/base/select/list",
  },
  // 基地管理
  baseArea: {
    massif: {
      GET_LIST: "/hydra-base-data/api/v1/mass/list",
      //根据地块id数组，获取地块信息列表
      GET_LIST_DETAIL: '/hydra-base-data/api/v1/mass/listBaseMassifbaseByMassIds',
      SAVE: "/hydra-base-data/api/v1/mass",
      UPDATE: "/hydra-base-data/api/v1/mass",
      DISABLE: "/hydra-base-data/api/v1/mass/disable",
      ENABLE: "/hydra-base-data/api/v1/mass/enable",
      GET: "/hydra-base-data/api/v1/mass",
      GET_AREA: "/hydra-base-data/api/v1/partition/select/list",
      // 获取地块生产记录
      GET_RECORD: "/trace/massif/info/page",
      // 批次号获取h5溯源页数据接口
      GET_PAGE_DATA: "/trace/batch/info/h5PageData",
      // 批次号获取h5溯源页数据接口
      GET_PAGE_MASSIF_DATA: "/trace/massif/info/h5PageData",
      // 导入地力评测信息
      IMPORT_SELF_INFO: "/hydra-base-data/api/v1/mass/importExcel",
    },
    base: {
      GET_LIST: "/hydra-base-data/api/v1/base/list",
      SAVE: "/hydra-base-data/api/v1/base",
      UPDATE: "/hydra-base-data/api/v1/base",
      DISABLE: "/hydra-base-data/api/v1/base/disable",
      ENABLE: "/hydra-base-data/api/v1/base/enable",
      GET: "/hydra-base-data/api/v1/base",
    },
    zone: {
      GET_LIST: "/hydra-base-data/api/v1/partition/list",
      SAVE: "/hydra-base-data/api/v1/partition",
      UPDATE: "/hydra-base-data/api/v1/partition",
      DISABLE: "/hydra-base-data/api/v1/partition/disable",
      ENABLE: "/hydra-base-data/api/v1/partition/enable",
      GET: "/hydra-base-data/api/v1/partition",
      GET_LIST_BY_BASEID: '/hydra-base-data/api/v1/base/child-greenhouse/list'
    },
  },
  // 物料管理
  matter: {
    // 下拉框使用
    AOTU_GET_LIST: "/hydra-base-data/api/v1/sort/material/list",
    GET_LIST: "/hydra-base-data/api/v1/material/list",
    // 保存
    SAVE: "/hydra-base-data/api/v1/material",
    // 禁用
    DISABLE: "/hydra-base-data/api/v1/material/disable",
    // 启用
    ENABLE: "/hydra-base-data/api/v1/material/enable",
    // 更新
    UPDATE: "/hydra-base-data/api/v1/material",
    // 获取单一
    GET: "/hydra-base-data/api/v1/material",
    // 规则信息下拉
    GET_SPEC: "/hydra-base-data/api/v1/specification/select/list",
  },
  // 物料库存
  stock: {
    // 单个物料详情
    GET_STOCK: "/hydra-base-data/api/v1/material/warehouse",
    GET_LIST: "/hydra-base-data/api/v1/material/warehouse/list",
    // 入库保存
    SAVE_PUT: "/hydra-base-data/api/v1/material/warehouse/batch/in",
    // 出库保存
    SAVE_OUT: "/hydra-base-data/api/v1/material/warehouse/batch/out",
    // 禁用
    DISABLE: "/hydra-base-data/api/v1/material/disable",
    // 启用
    ENABLE: "/hydra-base-data/api/v1/material/enable",
    // 更新
    UPDATE: "/hydra-base-data/api/v1/material",
    // 入库时获取单个物料详情
    GET_PUT: "/hydra-base-data/api/v1/material/warehouse/in/signle",
    // 批次出入库流水
    GET_LIST_BATCH:
      "/hydra-base-data/api/v1/material/warehouse/signle/out-in/list",
    // 出入库流水
    GET_LIST_TURNOVER:
      "/hydra-base-data/api/v1/material/warehouse/signle/out/list",
    // 保存预警设置
    SAVE_WARN: "/hydra-base-data/api/v1/material/warehouse/warning",
    // 入库时，获取物料列表详情
    GET_IN_LIST: "/hydra-base-data/api/v1/material/warehouse/in/material/list",
    // 入库时，获取物料列表详情
    GET_OUT_LIST:
      "/hydra-base-data/api/v1/material/warehouse/out/material/list",
    // 物料id获取该物料入库批次列表
    GET_IN_MATERIAR_LIST: "/hydra-base-data/api/v1/material/warehouse/in/batch",
    // 获取出入库编号
    GET_OUT_IN_CODE: "/hydra-base-data/api/v1/material/warehouse/code",
    // 校验物料是否符合出库条件
    VERIF_OUT: "/hydra-base-data/api/v1/material/warehouse/check/out",
  },
  warehouse: {
    GET_LIST: "/hydra-base-data/api/v1/material/warehouse/out-in/list",
    // 单个物料出入库流水
    GET_SIGNLE_LIST:
      "/hydra-base-data/api/v1/material/warehouse/signle/out-in/list",
    //获取产品包装规格配置
    GET_PROJECT_PACKAGE:
      "/hydra-base-data/api/v1/product-ware-house/getProductPackageRatios",
  },
  // 基础信息维护
  baseInfoAssert: {
    // 获取规格列表
    GET_LIST: "/hydra-base-data/api/v1/specification/list",
    // 新增/编辑/删除规格信息
    ADD: "/hydra-base-data/api/v1/specification",
    // 获取启用的产品
    GET_PRODUCT_LIST: "/hydra-base-data/api/v1/product/enable/list",
    // 获取产品规格信息列表
    GET_PRODUCT_SPEC_LIST: "/hydra-base-data/api/v1/specification/product/list",
    // 添加产品信息
    ADD_PRODUCT_SPEC:
      "/hydra-base-data/api/v1/specification/product/addProductInfo",
    // 编辑产品信息
    EDIT_PRODUCT_SPEC:
      "/hydra-base-data/api/v1/specification/product/updateProductInfo",
    // 删除产品信息
    DEL_PRODUCT_SPEC: "/hydra-base-data/api/v1/specification/product/delete",
    //获取产品规格信息列表
    GET_PEODUCT_SIZE: "/hydra-base-data/api/v1/specification/product/dropDown",
    //获取规格信息列表
    GET_SPEC_DROP: "/hydra-base-data/api/v1/specification/select/list",
  },
  //设备列表
  iot: {
    GET_LIST: "/iot/device/listByOrgId",
    SAVE: "/iot/device/add",
    GET_DETAIL: "/iot/device/getByCode",
    UPDATE: "/iot/device/update",
    GET_CHILDDEVICE: "/iot/device/sublist",
    //添加子设备
    ADD_SUB_DEVICE: "/iot/device/addSubDevice",
    // 组织id查询该组织下的设备型号,用于添加子设备的查询
    GET_DEVICE_BY_ORG_ID: "/iot/device/subDeviceModelByOrgId",
    // 根据组织id，设备型号id查询该组织下的设备,用于添加子设备的查询
    GET_DEVICE_MODEL_BY_ORG_ID: "/iot/device/subDeviceByOrgId",
    //每日设备数据报表
    GET_DEVICE_REPORT: "/iot/device/deviceReport",
  },
  // 设备型号
  model: {
    GET_LIST: "/iot/devicemodel/list",
  },
  //文件管理
  fileManage: {
    //excel下载
    excelDownLoad: "/hydra-base-data/api/v1/file/excel/download",
    // excel上传
    EXCEL_UPLOAD: "/hydra-base-data/api/v1/file/excel/upload",
  },
  message: {
    LIST: "/hydra-user-msg/api/v1/msg/mine/list",
  },
  //IOT设备
  iotFacility: {
    GET_LIST: "/iotdevice/listByOrgId",
    GET_DETAIL: "/iotdevice/getByCode",
    NO_TOKEN_DETAIL: "/iotdevice/notoken/getByCode",
    GET_CHILDDEVICE: "/iotdevice/sublist",
    //每日设备数据报表
    GET_DEVICE_REPORT: "/iotdevice/deviceReport",
    //控制开关
    CONTROL: "/iotdevice/properitesCommand",
    // 根据设备code获取设备参数（下拉）
    GET_PARAM_BY_CODE: "/iotdevice/getPropDefines",
  },
  //监控管理
  monitoringManage: {
    GET_LIST: "/iotdevice/monitor/listByOrgId",
    DELECT: "/iotdevice/monitor/delete",
    GET_STATE: "/iotdevice/monitor/getStatesByIds",
    //某个用户的监控设备列表
    GET_USER_MONITORLIST: "/iotdevice/monitor/listDevice",
    //初始化web插件
    INITIALIZE: "/iotdevice/monitor/init",
    //获取点击web插件实时视频参数
    GET_CLICKPARAMETER: "/iotdevice/monitor/preview",
    //获取点击web插件视频回放参数
    GET_BACKPARAMETER: "/iotdevice/monitor/playback",
  },
  manage: {
    band: {
      list: "/hydra-base-data/api/v1/base/brand/list",
      add: "/hydra-base-data/api/v1/base/brand/add",
      update: "/hydra-base-data/api/v1/base/brand/update",
      detail: "/hydra-base-data/api/v1/base/brand/one",
      destroy: "/hydra-base-data/api/v1/base/brand/delete",
    },
    tag: {
      list: "/hydra-base-data/api/v1/base/certification-label/list",
      add: "/hydra-base-data/api/v1/base/certification-label/add",
      update: "/hydra-base-data/api/v1/base/certification-label/update",
      detail: "/hydra-base-data/api/v1/base/certification-label/one",
      destroy: "/hydra-base-data/api/v1/base/certification-label/delete",
    },
  },
  common: {
    identityTag:
      "/hydra-base-data/api/v1/base/certification-label/getLabelTextList",
    products: "/hydra-base-data/api/v1/product/enable/list",
  },
  //沐阳物联网
  weatherStation: {
    GET: "/iotdevice/device/getDeviceByParentOrgId",
    GET_DETAIL: "/iotdevice/notoken/getListWithStatusByCodes",
    GET_DEVICE_REPORT: "/iotdevice/notoken/deviceReport", //这个要用notoken的
    CONTROL: "/iotdevice/properitesCommand",
    //获取基地和基地下的设备信息
    GET_BASE_INFORMATION: "/iotdevice/base/info",
    // 获取基地下拉列表
    GET_BASE_LIST: "/iotdevice/base/options",
    // 获取基地下拉列表
    GET_BASE_OPTIONS: "/iotdevice/base/options",
    // 根据基地获取全量详情信息（包含设备信息）
    GET_BASE_DEVICEINFO: "/iotdevice/base/device/info",
    // GET_BASE_LIST: "/iotdevice/base/options",
    // 根据基地ID获取报警统计数量
    GET_BASE_WARN: "/iotdevice/device/warning/notice/base/number",
    // 报警提醒列表
    GET_BASE_WARN_LIST: "/iotdevice/device/warning/notice/list",
  },
  equipment: {
    GET_LIST: "/iotdevice/device/warning/list",
    GET_ATTRIBUTE: "/iotdevice/getPropDefines",
    ADD_WARING: "/iotdevice/device/warning/add",
    //获取预警级别详情
    GET_WARING: "/iotdevice/device/warning/detail",
    //修改预警级别
    UPDATE_WARING: "/iotdevice/device/warning/edit",
    DELETE_WARING: "/iotdevice/device/warning/delete",
    //  //查询指定产品事件模型列表(筛选事件类型)
    GET_DEVICEEVENTS:"/iotdevice/device/product/event/getEventListByProductId",
    //  //设备虫情详情列表
    GET_INSECT_DETAILS:"/iotdevice/device/getDeviceEventDetails"
  },
  visualizationBase: {
    //获取平台列表
    GET_LIST: "/iotdevice/monitor/listByOrgList",
    //监控列表数据
    GET_USER_MONITORLIST: "/iotdevice/notoken/monitor/listDevice",
    //获取点击web插件实时视频参数
    GET_CLICKPARAMETER: "/iotdevice/monitor/preview",
    //初始化web插件
    INITIALIZE: "/iotdevice/notoken/monitor/init",
  },
  cameraCapture: {
    GET_LIST: "/iotdevice/monitor/listByMonitorPlan",
    // 下拉企业列表选项
    GET_ENTERPRISE: "/iotdevice/monitor/getOptionOrgList",
    //下拉企业监控点选项
    GET_MONITOR: "/iotdevice/monitor/getOptionOrgByDeviceList",
    // 开启
    OPEN: "/iotdevice/monitor/openMonitorPlan",
    //关闭
    CLOSE: "/iotdevice/monitor/closeMonitorPlan",
    // 验证计划有效性
    VERIFICATION_PLAN: "/iotdevice/monitor/monitorPlanCheck",
    //点击执行(平台手动抓图)
    EXECUTE: "/iotdevice/monitor/manualCapture",
    //新增监控截图计划
    ADD: "/iotdevice/monitor/addMonitorPlan",
    //截图失败记录列表
    FAIL_LIST: "/iotdevice/monitor/listByFailList",
    //截图成功记录列表
    SUCCESS_LIST: "/iotdevice/monitor/listByMonitorPlanSuccessList",
    //查看图片
    VIEW_PICTUR: "/iotdevice/monitor/getCloudImage",
  },
  videoPlan: {
    GET_LIST: "/iotdevice/monitor/monitorVideoList",
    // 下拉企业列表选项
    GET_ENTERPRISE: "/iotdevice/monitor/optionByOrgList",
    //下拉企业监控点选项
    GET_MONITOR: "/iotdevice/monitor/optionByMonitorUuidList",
    //下拉截图计划选项
    GET_SCREENSHOT: "/iotdevice/monitor/optionByPlanList",
    // 验证视频计划有效性
    VERIFICATION_VIDEO_PLAN:
      "/iotdevice/monitor/getMonitorUuidImageByTimeTotal",
    ADD: "/iotdevice/monitor/addMonitorVideoPlan",
  },
  //截图同步日志
  synchronizationLog: {
    GET_LIST: "/iotdevice/monitor/getListBySyncLog",
  },
  // gis录入工具
  gisTool: {
    LIST: "/hydra-code-egis/api/v1/businessDataManage/get-field-dict-by-layer",
    LAYERLIST: "/digital-village/hydra-digital-village/api/v1/map/orgList", //获取某组织下的图层列表
    SUBMIT: "/hydra-code-egis/api/v1/businessDataManage/add-field", // 提交字段
    GETLAYER:
      "/hydra-code-egis/api/v1/businessDataManage/get-field-dict-by-layer", //获取图层字段字典
    SETFIELD: "/hydra-code-egis/api/v1/businessDataManage/set-field-visibility", // 设置字段的可见性
    GISTIP: "/hydra-code-egis/api/v1/geoTools/get-address-input-tips", // 地址输入提示
  },
  mapInfo: {
    ORG: "/hydra-user/api/v1/org/list/platform", // 获取组织列表
    ADD: "/digital-village/hydra-digital-village/api/v1/map/add", // 新增图层
    LIST: "/digital-village/hydra-digital-village/api/v1/map/list", // 获取所有图层列表
    EDIT: "/digital-village/hydra-digital-village/api/v1/map/update", // 编辑图层
    GETINFO: "/digital-village/hydra-digital-village/api/v1/map/get", // 根据图层ID获取单个图层信息
    DEL: "/digital-village/hydra-digital-village/api/v1/map/delete", // 删除图层
    DOWN: "/digital-village/hydra-digital-village/api/v1/map/dronDownList", //下拉获取类型
  },
  mapDraw: {
    POINT: "/hydra-code-egis/api/v1/businessDataManage/add-point", // 添加点
    LINE: "/hydra-code-egis/api/v1/businessDataManage/add-line", // 添加线
    POLYGON: "/hydra-code-egis/api/v1/businessDataManage/add-polygon", //添加面
    DELPOINT: "/hydra-code-egis/api/v1/businessDataManage/delete-point", //删除点
    DELPOLYGON: "/hydra-code-egis/api/v1/businessDataManage/delete-polygon", //删除面
    DELLINE: "/hydra-code-egis/api/v1/businessDataManage/delete-line", //删除线
    EDITPOINT: "/hydra-code-egis/api/v1/businessDataManage/update-point", //编辑点
    EDITLINE: "/hydra-code-egis/api/v1/businessDataManage/update-line", //编辑线
    EDITPOLYGON: "/hydra-code-egis/api/v1/businessDataManage/update-polygon", // 编辑面
    GISORG:
      "/digital-village/hydra-digital-village/api/v1/party-organization/gisList", //获取党员信息
    GETDATATABLE:
      "/geoserver/cite/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=cite%3At_business_point&maxFeatures=500000&outputFormat=application%2Fjson&cql_filter=layer_id=", //获取数据表格
    GETSCENIC:
      "/digital-village/hydra-digital-village/api/v1/attractions/gisList", // 获取景区列表
    GETEXT:
      "/digital-village/hydra-digital-village/api/v1/family-information-ext/gisList", //获取GIS重点人员列表
    GETPARTY:
      "/digital-village/hydra-digital-village/api/v1/party-organization/gisList", // 获取gis党员信息
    DELLAT: "/hydra-code-egis/api/v1/businessDataManage/delete-layer", // gis删除图层
  },
  // 评价管理
  EVALUATION: {
    LIST: "/hydra-code-h5/api/v1/h5/page/get/page/evaluate/list",
    UPDATE: "/hydra-code-h5/api/v1/h5/page/update",
    ENABLE: "/hydra-code-h5/api/v1/h5/page/enable",
    ASSOCCIATED_PRODUCTS:
      "/hydra-code-manager/code/manager/code/apply/batch/page/list/byOrgId",
    SAVE: "/hydra-code-h5/api/v1/h5/relation/system/batch/save",
    GETTEMPLATE: "/hydra-code-h5/api/v1/h5/page/get",
    ADDTEMPLATE: "/hydra-code-h5/api/v1/h5/page/system/add",
    LIST1: "/hydra-tools/api/v1/evaluation/list",
    // 获取评价统计数据
    GET_PJ_COUNT: "/hydra-tools/api/v1/evaluation/getCount",
    CHANGETYPE: "/hydra-tools/api/v1/evaluation/changeType",
    PRODUCLIST: "/hydra-code-h5/api/v1/h5/relation/system/get/list",
  },
  RECORD: {
    RECORD_LIST: "/hydra-code-snms/api/v1/stat/regist/record/detail",
    //数值统计
    COUNT: "/hydra-code-snms/api/v1/stat/system/regist/count",
    //标识注册量条形图
    BY_MONTH: "/hydra-code-snms/api/v1/stat/system/regist/detail/by-month",
    //行业领域概况
    // DETAIL: '/hydra-code-snms/api/v1/stat/org/industry/detail',
    //企业节点数列表
    LIST: "/hydra-code-snms/api/v1/stat/system/org/node",
    //
  },
};
