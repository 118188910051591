import standardTemplate from './apiModules/standardTemplate';
import landPage, { landPageConfig } from './apiModules/landPage';
import b2b from './apiModules/b2b';
import aio from './apiModules/aio';
import runda from './apiModules/runda';
import reportTool from './apiModules/reportTool';
import microPage from './apiModules/microPage';
import platformRole from './apiModules/platformRole';
import userAdmin from './apiModules/userAdmin';
import codeTransfer from './apiModules/code';
import digitalVillage from './apiModules/digitalVillage';
import antiFake from './apiModules/antiFake';
import mapConfig from './apiModules/mapConfig';
import activist from './apiModules/activist';
import industry from './apiModules/industry';
import statistics from './apiModules/statistics';
import market from './apiModules/market';
import video from './apiModules/video';
import user from './apiModules/user';
import smartPackaging from './apiModules/smartPackaging';
import extendCode from './apiModules/extendCode';
import industrialInternet from './apiModules/industrialInternet'
import nurtureOrg from "./apiModules/nurtureOrg";
import icon from './apiModules/icon';

//
export default {
  common: {
    // 类型下拉-通用
    CONFIF_TYPE_SELECT: "/hydra-base-data/api/v1/config/type"
  },
  codeCredentialsSearch: {
    GET_INFO: "/hydra-code-h5/api/v1/client/get/code/detail/for/h5"
  },
  //工业互联网标识解析二级节点
  industrialInternet,
  // 养殖体系组织关系
  nurtureOrg,
  //防伪稽查码
  antiFake,
  // 智能包装
  smartPackaging,
  // 扩展码
  extendCode,
  //配置活动（芬姐增加by qsj）
  setActive: {
    //获取详情
    GET_DETAIL: "/hydra-base-data/api/v1/activitySetting/getPlatActivitySetting",
    //保存配置
    SAVE: "/hydra-base-data/api/v1/activitySetting/save"
  },
  sms: {
    CHECK_CODE: "/hydra-user/api/v1/sms/check/phone-code"
  },
  //一体机
  aio,
  //润达
  runda,
  //报表工具
  reportTool,
  // 一键维权
  activist,
  //微页工具
  microPage,
  //数字乡村
  digitalVillage,
  // 系统管理
  system: {
    GET_LIST: "/hydra-user/api/v1/sys/list-sys",
    SAVE: "/hydra-user/api/v1/sys/add",
    UPDATE: "/hydra-user/api/v1/sys/update",
    // 获取功能列表
    GET_POWER_LIST: "/hydra-user/api/v1/fun/querylist",
    // 获取菜单配置
    GET_MENU_SETTING: "/hydra-user/api/v1/menu/menu-all",
    // 编辑菜单配置
    SAVE_MENU_SETTING: "/hydra-user/api/v1/menu/update",
    // 获取单一该系统的信息
    GET_SYSTEM: "/hydra-user/api/v1/sys/sys/info",
    // 设置系统相关配置
    SET_SYSTEM_CONFIG: "/hydra-user/api/v1/sys/config",
    // 获取系统信息
    GET_SYSTEM_MSG: "/hydra-user/api/v1/sys",
    // 免密登录
    FREE_LOGIN: "/hydra-user/api/v1/user/login/sys",
    org: {
      //变更角色
      SET_ORG_ROLE: "/hydra-user/api/v1/role/change-for-org",
      //获取角色列表
      GET_ORG_ROLE_LIST: "/hydra-user/api/v1/role/select"
    },
    subdomain: {
      // 获取系统域名配置列表
      LIST: "/hydra-user/api/v1/sys/domain/page/list",
      SAVE: "/hydra-user/api/v1/sys/domain",
      UPDATE: "/hydra-user/api/v1/sys/domain",
      DELETE: "/hydra-user/api/v1/sys/domain",
      //智能包装
      packaging: {
        SAVE: "/hydra-user/api/v1/org/sys/sys-config/add",
        UPDATE: "/hydra-user/api/v1/sys/domain"
      }
    },
    orgList: {
      DISABLE: "/hydra-user/api/v1/sys/org/status",
      ENABLE: "/hydra-user/api/v1/sys/org/status"
    },
    //系统到期提醒
    expirySys: {
      //获取列表
      GET_LIST: "/hydra-user/api/v1/expiry-sys/page",
      //处理(批量)
      DEAL_ITEM: "/hydra-user/api/v1/expiry-sys/process"
    },
    // 系统复制
    COPY: "/hydra-user/api/v1/sys/copy",
    // 系统列表导出
    SYSTEM_EXPORT: "/hydra-user/api/v1/export/sys/list",
    // 系统组织列表导出
    SYSTEM_ORG_EXPORT: "/hydra-user/api/v1/export/org-from-sys",
    // 获取系统下角色列表
    GET_ROLE_LIST: "/hydra-user/api/v1/role/querylist"
  },
  // 组织管理
  org: {
    //获取组织关系管理列表
    GET_ORG_RELATE: "/hydra-user/api/v1/org/relation/children",
    //更新组织关系
    UPDATA_ORG_SHIP: "/hydra-user/api/v1/org/relation/update",
    // 数字乡村获取所有组织
    GET_ALL_ORG_LIST: "/hydra-user/api/v1/org/relation/filter/list",
    // 除了本组织的所有组织
    GET_ALL_ORG_NO_ME: "/hydra-user/api/v1/org/relation/type/list",
    //数字乡村组织列表
    GET_VILLAGE_LIST: "/hydra-user/api/v1/org/sys/village/list",
    //获取数字乡村信息发布菜单列表
    GET_INFO_LIST: "/hydra-user/api/v1/menu/village/info-list",
    LIST: "/hydra-user/api/v1/org/list/platform",
    // 平添组织管理
    COMMON: "/hydra-user/api/v1/org",
    orgManage: "/hydra-user/api/v1/org/from-platform",
    COUNT: "/hydra-user/api/v1/org/count",
    ACCEPT: "/hydra-user/api/v1/org/org/accept",
    REJECT: "/hydra-user/api/v1/org/org/reject",
    CHECK: "/hydra-user/api/v1/org/check/name/number",
    ADD: "/org/sys/invite/platform", // 废弃
    MODIFY: "/org/sys/invite", // 废弃
    ORG_SYS: "/hydra-user/api/v1/sys/org/list",
    THIRD_LIKE: "/hydra-open-thirdpart-service/business/open/thrid/like",
    THIRD_EXACT: "/hydra-open-thirdpart-service/business/open/thrid/exact/new",
    SETTING: "/hydra-user/api/v1/org/seniorfunflag",
    // 根据系统id获取组织列表
    GET_ORG_BY_SYS_LIST: "/hydra-user/api/v1/org/sys/list",
    PACKAGE_CONFIG: "/package-config",
    SWEEP_CONFIG: "/hydra-base-data/api/v1/base/sweep/config",
    CUSTOMER_CONFIG: "/hydra-base-data/api/v1/base/customer/config",
    // 免密登录
    FREE_LOGIN: "/hydra-base-data/api/v1/platform/login/org-sys",
    ORG_UPDATE_VALIDITY: "/hydra-user/api/v1/sys/org/time/validity",
    //iot平台配置
    IOTSAVE: "/hydra-user/api/v1/org/config/iot",
    GET_IOT_DETAIL: "/hydra-user/api/v1/org/config/iot",
    // 溯源模板字段配置
    SET_TRACE_TEMPLATE_CON: "/hydra-user/api/v1/org/tracetempconf",
    GET_TRACE_TEMPLATE_CON: "/hydra-user/api/v1/org/tracetempconf",
    //企业微信公众号相关
    OFFICIAL_CONFIG_LIST: "/hydra-base-data/api/v1/org/wechat/gzh/page/list",
    OFFICIAL_LIST: "/hydra-base-data/api/v1/config/wechat/page/list",
    OFFICIAL_ADD: "/hydra-base-data/api/v1/org/wechat/gzh",
    OFFICIAL_UPDATE: "/hydra-base-data/api/v1/org/wechat/gzh",
    OFFICIAL_DELETE: "/hydra-base-data/api/v1/org/wechat",
    JGWWEIXIN_ADD: "/hydra-base-data/api/v1/config/wechat/jgwListAddOrUpdate",
    JGWWEIXIN_LIST: "/hydra-base-data/api/v1/config/wechat/getJgwList",
    WEIXIN_LIST_ADD: "/hydra-base-data/api/v1/config/wechat/listAddOrUpdate",
    GET_WEIXIN_INFO: "/hydra-base-data/api/v1/config/wechat/get",
    // 获取兼容配置信息
    GET_COMPATIBILITY: "/hydra-transfer/userOrgCorp/get",
    // 添加兼容配置信息
    ADD_COMPATIBILITY: "/hydra-transfer/userOrgCorp/save",
    UPDATE_COMPATIBILITY: "/hydra-transfer/userOrgCorp/update",
    // 保存pda版本
    SAVE_ID: "/hydra-org/api/v1/org/app-version/download-version/selection",
    // 获取pda版本列表
    GET_PDA_LIST: "/hydra-org/api/v1/org/app-version/download-version/from-org/list",
    // 批量开通企业
    BATCH_OPEN: "/hydra-user/api/v1/org/batch",
    // 开通政府
    ORG_OPEN: "/hydra-user/api/v1/org/gov",
    // 根据组织名进行模糊查询
    GET_ORG_BY_NAME: "/hydra-user/api/v1/org/vagueOrgName",
    // 根据组织id获取组织信息
    GET_INFO_BY_ID: "/hydra-user/api/v1/org/orgInfo",
    // 获取系统类型
    GET_SYSTYPE: "/hydra-user/api/v1/sys/sys-type",
    // 批量开通系统
    SYS_OPEN: "/hydra-user/api/v1/org/batch-open",
    // 根据系统类型查询系统列表
    GET_SYS_LIST: "/hydra-user/api/v1/sys/list-from-type",
    // 更新组织上下级关系
    UPDTE_RELATION: "/hydra-user/api/v1/org/relation/batch/update",
    // 获取系统类型列表
    GET_ORG_TYPE_LIST: "/hydra-user/api/v1/org/list/type",
    // 获取组织关系列表
    GET_ORG_RELATION_LIST: "/hydra-user/api/v1/org/relation/filter/list",
    // 政府企业角色下拉
    GET_ROLE_LIST: "/hydra-user/api/v1/role/gov-enterprise",
    // 码实时下载配置获取
    GET_CODE_DOWNLOAD: "/hydra-user/api/v1/org/code/download/status",
    // 码实时下载配置保存
    SAVE_CODE_DOWNLOAD: "/hydra-user/api/v1/org/code/download/config",
    //获取环节类型
    LOAD_NODE_TYPES: "/hydra-base-data/api/v1/config/type",
    //获取主要大类
    LOAD_MAIN_TYPES: "/hydra-base-data/api/v1/config/type",
    // 一键维权获取
    GET_POWER_INFO: "/hydra-user/api/v1/org/anti-infringement",
    // 一键维权保存
    SAVE_POWER_INFO: "/hydra-user/api/v1/org/anti-infringement",
    // 获取所有行业列表
    GET_BUSINESS_ALL: "/hydra-base-data/api/v1//business/all",
    // 获取国家列表
    GET_COUNTRY_LIST: "/hydra-base-data/api/v1//country/list",
    // 获取其他他国家行政编码
    GET_OTHER_COUNTRY_ADRESS: "/hydra-base-data/api/v1//address/first-by-country",
    SEND_SMS: "/hydra-user/api/v1/sms/send/phone/code",
    // 禁用启用企业
    UPDATE_STATUS: "/hydra-user/api/v1/org/status",
    // 修改管理员
    UPDATE_USER: "/hydra-user/api/v1/org/change/manager/platform",
    // 导出组织列表
    EXPORT_ORG: "/hydra-user/api/v1/export/org",
    // 生成企业社会统一信用代码
    GET_CREDIT: "/hydra-user/api/v1/org/credit-code-from-create",
    // 判断企业是否存在
    HAS_COMPANY: "/hydra-user/api/v1/org/exist",
    // 校验是否可以免密登录
    CHECK_LOGIN: "/hydra-user/api/v1/user/login/sys/check",
    // 校验信用代码是否存在
    CHECK_CREDITCODE: "/hydra-user/api/v1/org/credit/exist",
    // 获取企业信息，天眼查或系统获取
    GET_ORG_LIST_BY_ALL: "/hydra-user/api/v1/org/info-or-from-third",
    // 根据组织名称获取组织信息
    GET_ORGINFO_BY_NAME: "/hydra-user/api/v1/org/name/info",
    // 页脚配置
    FOOTER: "/hydra-user/api/v1/org/config/footer",
    // 企业仓库产品隔离配置查询
    GET_WAREHOUSE: "/hydra-base-data/api/v1/base/config/ware-house",
    // 企业仓库产品隔离配置保存
    UPDATE_WAREHOUSE: "/hydra-base-data/api/v1/ware-house/config/isolation",
    // 获取NFC认证配置--服务配置
    NFC_SERVICE:"/hydra-user/api/v1/org/nfc-authentication",
    // 设置NFC认证配置--服务配置
    PUT_SERVICE:"/hydra-user/api/v1/org/nfc-authentication"
  },
  // 系统组织管理
  sysOrg: {
    LIST: "/hydra-user/api/v1/org/list/sys",
    COMMON: "/hydra-user/api/v1/org",
    COUNT: "/org/sys/invite/check/org/sys",
    AUD_CHECK: "/hydra-user/api/v1/org/aud/check",
    ACCEPT: "/hydra-user/api/v1/org/org/accept",
    REJECT: "/hydra-user/api/v1/org/org/reject",
    CHECK: "/hydra-user/api/v1/org/check/name/number",
    ADD: "/org/sys/invite/sys", //  作废
    MODIFY: "/org/sys/invite", //  作废
    ORG_SYS: "/hydra-user/api/v1/sys/org/list",
    THIRD_LIKE: "/hydra-open-thirdpart-service/business/open/thrid/like",
    THIRD_EXACT: "/hydra-open-thirdpart-service/business/open/thrid/exact/new",
    ROLE_COMMON: "/hydra-user/api/v1/role/select",
    // 获取系统组织列表
    GOV_LIST: "/hydra-user/api/v1/org/list/sys/government/select",
    // 开通组织
    ORG_OPEN: "/hydra-user/api/v1/org/open",
    // SYS_LIST: '/zfjg/sys/list',

    // 获取系统列表用户在该系统有权限才可
    GET_ORG_SYSTEM_LIST: "/hydra-base-data/api/v1/user/landing/page/get/sys/id",
    // 获取系统列表用户在该系统有权限才可
    SET_SYSTEM_LANDING_PAGE_ICON: "/hydra-base-data/api/v1/user/landing/page/add/sys/logo",
    // 获得组织关系列表
    GET_ORG_LIST: "/hydra-user/api/v1/org/relation/all/org",
    // 更新组织上下级关系
    ADD_HIGHER_ORG: "/hydra-user/api/v1/org/relation/update"
  },
  // 角色管理
  role: {
    LIST: "/role/platform",
    MENU: "/hydra-user/api/v1/menu/menu-final",
    DETAIL: "/hydra-user/api/v1/menu/menu-role",
    FIRST_MENU: "/menu/first-menu",
    CHILD_MENU: "/menu/child-menu",
    DISABLED: "/hydra-user/api/v1/role/update-status",
    ENABLE: "/hydra-user/api/v1/role/update-status"
  },
  // 管理员设置
  admin: {
    GET_LIST: "/hydra-user/api/v1/userAdmin/query",
    // 禁用
    DISABLE: "/hydra-user/api/v1/userAdmin/update-status",
    // 启用
    ENABLE: "/hydra-user/api/v1/userAdmin/update-status",
    SAVE: "/hydra-user/api/v1/userAdmin/add",
    UNLOCK: "/hydra-base-data/api/v1/platform/unlock/account"
  },
  // 功能管理
  power: {
    //功能列表
    GET_LIST: "/hydra-user/api/v1/fun/querylist",
    GET_POWER_LIST: "/hydra-base-data/api/v1/config/type",
    //新增功能
    POWER_ADD: "/hydra-user/api/v1/fun/add",
    //编辑功能
    POWER_EDIT: "/hydra-user/api/v1/fun/update",
    //设置功能状态
    SET_STATUS: "/hydra-user/api/v1/fun/status/update",
    //获取功能详细信息
    LOAD_DETAIL: "/hydra-user/api/v1/fun/query",
    GET_POWER: "/hydra-user/api/v1/fun/query",
    //功能导出
    EXPORT: "/hydra-user/api/v1/export/fun/list",
    //功能导入
    IMPORT: "/hydra-user/api/v1/fun/import"
  },
  //系统功能管理
  sysPower: {
    GET_LIST: "/hydra-user/api/v1/fun/querylist",
    GET_POWER_LIST: "/hydra-base-data/api/v1/config/type",
    POWER_ADD: "/hydra-user/api/v1/fun/add",
    POWER_EDIT: "/hydra-user/api/v1/fun/update",
    GET_POWER: "/hydra-user/api/v1/fun/query"
  },
  //平台菜单
  platformMenu: {
    LOAD_FINAL: "/hydra-user/api/v1/menu/menu-final",
    LOAD_DETAIL: "/hydra-user/api/v1/menu/menu-all",
    LOAD_ROLE_DETAIL: "/hydra-user/api/v1/menu/menu-role",
    SAVE: "/hydra-user/api/v1/menu/update"
  },
  // 组织菜单
  orgMenu: {
    LOAD_ORG_SYS_MENU: "/hydra-user/api/v1/menu/menu-role/allsys"
  },
  // 功能定制
  powerCustomized: {
    // 获取列表
    GET_LIST: "/hydra-user/api/v1/fun/trace/querylist",
    // 新增
    ADD: "/hydra-user/api/v1/fun/addCustomizeFun",
    // 编辑
    UPDATE: "/hydra-user/api/v1/fun/updateCustomizeFun",
    // 复制
    COPY: "/hydra-user/api/v1/fun/copyCustomizeFun",
    // 获取详情---最新的
    GET_CONFIG: "/trace/traceFieldFunConfig/queryV3",
    // 获取详情
    GET_DETAIL: "/hydra-user/api/v1/fun/trace/query",
    // 获取字段配置
    GET_FIELD_CONFIG: "/trace/traceFieldFunConfig/query",
    // 获取按钮配置
    GET_BUTTON_CONFIG: "/hydra-user/api/v1/fun/trace/service/query",
    // 获取批次关联对象列表
    GET_OBJECTASSOCIATEDTYPE_LIST: "/hydra-base-data/api/v1/config/type?typeId=130"
  },
  // 对象管理
  object: {
    GET_LIST: "/hydra-base-data/api/v1/platform/object/list",
    OBJECT_DETAIL: "/hydra-base-data/api/v1/platform/getAllByCodeTypeId"
  },
  // H5模板管理
  H5template: {
    GET_LIST: "/hydra-base-data/api/v1/platform/h5/list"
  },
  // 基础数据
  base: {
    // 供应商
    GET_LIST: "/hydra-base-data/api/v1/platform/sort/type/page/list",
    SAVE: "/hydra-base-data/api/v1/platform/sort",
    UPDATE: "/hydra-base-data/api/v1/platform/sort",
    // 物料分类下拉
    MATERIAL_SORT_TYPE: "/hydra-base-data/api/v1/config/type",
    // 物料分类
    material: {
      GET_LIST: "/hydra-base-data/api/v1/platform/sort/material/list",
      SAVE: "/hydra-base-data/api/v1/platform/sort/material",
      UPDATE: "/hydra-base-data/api/v1/platform/sort/material"
    },
    materialList: "/hydra-base-data/api/v1/platform/material/list",
    //
    massif: {
      GET_LIST: "/hydra-base-data/api/v1/platform/base/list",
      GET_LIST_MASSIF: "/hydra-base-data/api/v1/platform/mass/list"
    },
    device: {
      GET_LIST: "/hydra-base-data/api/v1/platform/produce/device/list"
    },
    supplier: {
      GET_LIST: "/hydra-base-data/api/v1/platform/supper/list"
    },
    // 基地地图配置
    mapConfig
  },
  // 组织申诉
  appeal: {
    GET_LIST: "/hydra-user/api/v1/org-appeal/list",
    GET: "/hydra-user/api/v1/org-appeal",
    SAVE: "/hydra-user/api/v1/org-appeal/verify"
  },
  material: {
    // 获取物料批次
    GET_WAREHOUSE_IN_BATCH: "/hydra-base-data/api/v1/material/warehouse/in/batch"
  },
  standardTemplate,
  landPage,
  landPageConfig,

  // 设备
  type: {
    GET_LIST: "/iot/devicetype/list",
    SAVE: "/iot/devicetype/add",
    UPDATE: "/iot/devicetype/update",
    // 获取设备详情
    GET_DETAIL: "/iot/devicetype/getById"
  },
  // 设备型号
  model: {
    GET_LIST: "/iot/devicemodel/list",
    SAVE: "/iot/devicemodel/add",
    UPDATE: "/iot/devicemodel/update",
    GET_DETAIL: "/iot/devicemodel/getById",
    // 获取协议列表
    GET_PROTOCOL: "/iot/devicemodel/getProtocol",
    // 修改设备属性参数
    UPDATEATTRPARAM: "/iot/devicemodel/updateAttrParam",
    // 修改设备基础参数
    UPDATEBASICPARAM: "/iot/devicemodel/updateBasicParam"
  },
  // 设备列表
  equipmentList: {
    GET_LIST: "/iot/device/list",
    SAVE: "/iot/device/add",
    GET_DETAIL: "/iot/device/getByCode",
    UPDATE: "/iot/device/update",
    GET_CHILDDEVICE: "/iot/device/sublist",
    // 添加子设备
    ADD_SUB_DEVICE: "/iot/device/addSubDevice",
    // 组织id查询该组织下的设备型号,用于添加子设备的查询
    GET_DEVICE_BY_ORG_ID: "/iot/device/subDeviceModelByOrgId",
    // 根据组织id，设备型号id查询该组织下的设备,用于添加子设备的查询
    GET_DEVICE_MODEL_BY_ORG_ID: "/iot/device/subDeviceByOrgId"
  },
  // 大数据管理
  dataScreen: {
    // 大数据屏配置
    config: {
      GET_LIST: "/datascreen/config/page/list",
      // 系统已有模板的分页接口
      GET_TEMPLATE_PAGE_LIST: "/datascreen/template/page/list",
      // 新增模板
      ADD_TEMPLATE: "/datascreen/template",
      // 删除模板
      DELETE_TEMPLATE: "/datascreen/template/delete"
    },
    orgConfig: {
      GET_LIST: "/datascreen/data-screen-org/page/list",
      // 批量新增监控屏配置信息
      ADD_CONFIGS: "/datascreen/data-screen-org/update",
      // 新增数据屏组
      ADD_DATASCREENS: "/datascreen/group/update",
      // 系统已有模板的分页接口
      GET: "/datascreen/data-screen-org/get",
      // 新增数据屏
      ADD: "/datascreen/data-screen-org",
      // 更新数据屏
      UPDATE: "/datascreen/data-screen-org",
      // 删除数据屏
      DELETE: "/datascreen/data-screen-org/delete"
    }
  },
  //信息管理
  message: {
    GET_LIST: "/hydra-base-data/api/v1/platform/list",
    ADD: "/hydra-user-msg/api/v1/msg/platform",
    DETAIL: "/hydra-base-data/api/v1/platform"
  },

  b2b,

  //h5工具
  h5Tool: {
    GET_LIST: "/hydra-code-h5/api/v1/h5/page/get/page/list",
    ADD: "/hydra-code-h5/api/v1/h5/page/add",
    GET: "/hydra-code-h5/api/v1/h5/page/get",
    UPDATE: "/hydra-code-h5/api/v1/h5/page/update",
    //获取平台组织列表
    GET_ORG_LIST: "/hydra-user/api/v1/org/list/platform",
    //生成码批次
    GET_CODE_BATCH: "/hydra-code-manager/code/manager/code/apply/batch/page/list/byOrgId",
    //h5关联关系添加
    ADD_RELEVANCY: "/hydra-code-h5/api/v1/h5/relation/batch/save",
    //根据id单条所有的关联信息
    GET_RELEVANCY: "/hydra-code-h5/api/v1/h5/relation/get/list",
    //启用或禁用H5工具记录状态
    UPDATE_STATE: "/hydra-code-h5/api/v1/h5/page/enable",
    //获取产品列表
    PRODUCT: "/hydra-base-data/api/v1/product/enable/list",
    // 获取模版列表
    GET_TEMPLATE_LIST: "/hydra-code-h5/api/v1/h5/template/list",
    // 删除模版
    DELETE_TEMPLATE: "/hydra-code-h5/api/v1/h5/template/delete",
    // 新增初始化模板
    ADD_INIT_TEMPLATE: "/hydra-code-h5/api/v1/h5/template/add"
  },
  insurance: {
    GET_LIST: "/insurance/set/list",
    GET_INSURANCE_COMPANIES: "/insurance/set/list/organization",
    INSURANCE_ENABLE: "/insurance/set/enable",
    INSURANCE_DISABLE: "/insurance/set/disable",
    INSURANCE_EDIT_INFO: "/insurance/set/updatepage",
    INSURANCE_EDIT: "/insurance/set/update",
    INSURANCE_ADD: "/insurance/set/save"
  },
  insuranceRules: {
    list: "/insurance/message/auditor/list",
    add: "/insurance/message/auditor/add"
  },
  code: {
    // 码查询工具获取信息
    CODE_SEARCH_INFO: "/hydra-production/api/v1/code/check/getCodeQueryToolInfo",
    // 查询某个码关联的信息
    CODE_RELATION_INFO: "/hydra-production/api/v1/code/check/getCodeRelationVoByCode",
    //生成二维码接口
    GET_QRCODE: "/trace/common/qrCode"
  },
  //全网活动
  operatingActivities: {
    //获取活动锦囊
    GET_BAGS: "/marketing/activity/platform/set/selectAll",
    //创建活动预览
    CREATE_PREVIEW: "/marketing/activity/platform/set/preView",
    //平台组织信息分页列表
    GET_ORG: "/marketing/activity/platform/set/platformOrganizationPage",
    //添加活动
    ADD_ACTIVITY: "/marketing/activity/platform/set/add",
    //编辑活动
    UPDATE_ACTIVITY: "/marketing/activity/platform/set/update",
    //启用禁用
    SET_STATUS: "/marketing/activity/platform/set/disOrEnable",
    //获取列表
    GET_LIST: "/marketing/activity/platform/set/page",
    //参与记录列表
    GET_JOIN_LIST: "/marketing/activity/platform/set/joinResultPage",
    // 根据ID获取活动信息
    GET_ACTIVITY_BY_ID: "/marketing/activity/platform/set/get",
    // 扫码活跃会员
    ACTIVE_VIP: "/marketing/activity/platform/data/scanMemberRate",
    // 会员画像
    PORTRAIT_VIP: "/marketing/activity/platform/data/memberPortrait",
    // 会员地域分布
    AREA_VIP: "/marketing/activity/platform/data/memberRegion",
    // 活动中奖率
    WIN_RATE: "/marketing/activity/platform/data/winningPrizeRate",
    // 活动参与率
    PARTICIPATION_RATE: "/marketing/activity/platform/data/activityJoinRate",
    // 扫码率
    SCAN_RATE: "/marketing/activity/platform/data/scanCodeRate",
    // 活动企业排行
    RAKING: "/marketing/activity/platform/data/activityOrganization",
    // 日活动参与量
    ONE_PARTICIPATION_RATE: "/marketing/activity/platform/data/dayActivityJoin",
    // 日扫码量
    ONE_SCAN_RATE: "/marketing/activity/platform/data/dayActivityScan",
    //导出参与记录
    JOIN_EXPORT: "/marketing/activity/platform/set/export"
  },
  //pda 设备管理
  devicesPda: {
    pda: {
      //列表
      GET_LIST: "/hydra-mobile-terminal/api/v1/device/pc/list",
      //新增设备
      SAVE: "/hydra-mobile-terminal/api/v1/device/pc/add",
      EDIT: "/hydra-mobile-terminal/api/v1/device/pc/edit",
      //审核通过
      APPROVED: "/hydra-mobile-terminal/api/v1/device/pc/auditPermit",
      //审核不通过
      FAIL: "/hydra-mobile-terminal/api/v1/device/pc/auditReject",

      //禁用启用设备
      SET_STATUS: "/hydra-mobile-terminal/api/v1/device/pc/active",
      //查询设备
      ECHO: "/hydra-mobile-terminal/api/v1/device/pc/detail",
    }
  },
  platformRole,
  userAdmin,
  codeTransfer,
  // iot
  iot: {
    // 某组织下的设备列表
    GET_DEVICE_LIST: "/iotdevice/notoken/listByOrgId",

    // 某组织下的监控平台
    GET_MONITOR_PLATFORM_LIST: "/iotdevice/notoken/monitor/listByOrgId",
    // 某个用户的监控设备列表
    GET_MONITOR_LIST: "/iotdevice/notoken/monitor/listDevice",
    // 获取某组织气象站或传感器设备的属性
    GET_HARDWARE_PROPERTIES: "/iotdevice/notoken/getPropDefines"
  },
  //网关查询
  gateway: {
    GET_LIST: "/execute-timed",
    GET_DATA_INFO: "/frequency",
    GET_TIME: "/average/time"
  },
  openInterface: {
    UPLOAD: "/hydra-open-gateway/api/v1/jar-file/upload"
  },
  //行业
  industry,
  //数据统计
  statistics,
  //营销
  market,
  //视频资源管理
  video,
  //基础用户配置
  user,
  //图标
  icon,
  // 数据屏配置
  dataScreenConfig: {
    addTemplate: "/hydra-datascreen-generator/api/v1/template/save", // 新增模板
    deleteTemplate: "/hydra-datascreen-generator/api/v1/template/delete", // 删除模板
    editTemplate: "/hydra-datascreen-generator/api/v1/template/update", // 编辑模板
    templateList: "/hydra-datascreen-generator/api/v1/template/list", // 获取模板列表
    templateDetail: "/hydra-datascreen-generator/api/v1/template/getById", // 获取模板详情
    addProject: "/hydra-datascreen-generator/api/v1/project/save", // 新增项目
    deleteProject: "/hydra-datascreen-generator/api/v1/project/delete", // 删除项目
    updateProject: "/hydra-datascreen-generator/api/v1/project/update", // 编辑项目
    projectList: "/hydra-datascreen-generator/api/v1/project/list", // 获取项目列表
    addDataScreen: "/hydra-datascreen-generator/api/v1/data-screen/save", // 新增数据屏
    dataScreenList: "/hydra-datascreen-generator/api/v1/data-screen/list", // 数据屏列表
    dataScreenEdit: "/hydra-datascreen-generator/api/v1/data-screen/update", // 数据屏编辑
    dataScreenDelete: "/hydra-datascreen-generator/api/v1/data-screen/delete", // 数据屏删除
    dataScreenSort: "/hydra-datascreen-generator/api/v1/data-screen/sort" // 数据屏排序
  },
  //公众号配置
  the_piblic: {
    DATALIST: "/hydra-supercode-oauth2/api/v1/account/config/list", // 账号列表数据
    DELETEACCOUNT: "/hydra-supercode-oauth2/api/v1/account/config/delete", // 删除账号
    LOOKACCOUNT: "/hydra-supercode-oauth2/api/v1/account/config/detail", // 查看账号详情
    MERCHANTDATA: "/hydra-supercode-oauth2/api/v1/account/config/getMerchant", // 获取商户列表
    EDITDATA: "/hydra-supercode-oauth2/api/v1/account/config/update", // 修改信息
    ADDACCOUNT: "/hydra-supercode-oauth2/api/v1/account/config/add",//添加账号
    GET_ENTERPRISE: "/hydra-user/api/v1/org/list/platform"//获取公司信息
  }
};
