const isDev = window.location.href.indexOf('localhost') >= 0;

function showError(errs) {
  console.warn.apply(window, errs);
  if (isDev) {
    const err = new Error(errs);
    throw err;
  }
}

const merge = (apis) => {
  let result = {};
  apis.forEach(api => {
    const keys = Object.keys(api);
    keys.forEach(key => {
      if (result[key]) {
        if (typeof result[key] === 'string') {
          if (result[key] !== api[key]) {
            showError(['apiUrl覆盖', key, result[key], api[key]]);
          }
          result[key] = api[key];
        } else if (typeof result[key] === 'object' && typeof api[key] === 'object') {
          result[key] = merge([result[key], api[key]]);
        } else if (typeof result[key] === 'object' && typeof api[key] === 'string') {
          if (result[key] !== api[key]) {
            showError(['apiUrl覆盖', key, result[key], api[key]]);
          }
          result[key] = api[key];
        }
      } else {
        result[key] = api[key];
      }
    });
  });
  return result;
};
export default merge;
