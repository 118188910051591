/*
 * @Author: duyaoyao
 * @Date: 2021-08-19 15:30:20
 * @LastEditors: duyaoyao
 * @LastEditTime: 2021-08-19 16:13:47
 */
import { prefix } from "framework/common/config";
import apiOld from "./apiModules/apiOld";
import setting from "./apiModules/setting";

export default {
  ...apiOld,
  nurture: {
    // 设置
    setting,
    // 猪只管理
    pigs: {
      LIST: "xx",
      EDIT: "xx",
      DETAIL: "xx",
      ADD: "xx",
      UPDATE_EAR: "xx",
    },
    // 母猪
    sow: {
      // 上传图片
      UPLOADPICTURE: "xx",
      // 后备转母猪
      reserve: {
        LIST: "xx",
        ADD: "xx",
        DETAIL: "xx",
        EDIT: "xx",
        // 获取数量
        GET_NUM: "xx",
      },
      // 发情
      rut: {
        LIST: "xx",
        ADD: "xx",
        DETAIL: "xx",
        EDIT: "xx",
        DEL: "xx",
      },
      // 配种
      hybridization: {
        LIST: "xx",
        ADD: "xx",
        DETAIL: "xx",
        EDIT: "xx",
        DEL: "xxx",
      },
      // 妊检
      inspection: {
        LIST: "xx",
        ADD: "xx",
        DETAIL: "xx",
        EDIT: "xx",
      },
      // 分娩
      parturition: {
        LIST: "xx",
        ADD: "xx",
        DETAIL: "xx",
        EDIT: "xx",
      },
      // 断奶
      weaning: {
        LIST: "xx",
        ADD: "xx",
        DETAIL: "xx",
        EDIT: "xx",
      },
      // 背膘
      backfat: {
        LIST: "xx",
        ADD: "xx",
        DETAIL: "xx",
        EDIT: "xx",
        DEL: "xx",
      },
      //
    },
  },
};
