export default {
  // 农户/经销商信息/交易市场/专业村
  gather: {
    LIST: '/yanshan-vegetable-industry/api/v1/web/User/list',
    ADD: '/yanshan-vegetable-industry/api/v1/web/User/add',
    DEL: '/yanshan-vegetable-industry/api/v1/web/User/delete',
    EDIT: '/yanshan-vegetable-industry/api/v1/web/User/update',
    DETAILS: '/yanshan-vegetable-industry/api/v1/web/User/get',
    DISABLE: "/yanshan-vegetable-industry/api/v1/web/User/disable",
    // 农户经销商导入
    IMPORT: '/yanshan-vegetable-industry/api/v1/web/User/import',
    // 市场交易专业村导入
    IMPORT1: '/yanshan-vegetable-industry/api/v1/web/User/villageAndMarketImport',
    // 农户经销商导出
    EXPORT: '/yanshan-vegetable-industry/api/v1/web/User/down',
    // 市场交易专业村导出
    EXPORT1: '/yanshan-vegetable-industry/api/v1/web/User/villageOrMarketDown',
    // 入场记录列表
    RECORD_LIST:'/yanshan-vegetable-industry/api/v1/web/scan-code-record/list',
    // 农资发放记录导入
    delivery_import:"/yanshan-vegetable-industry/api/v1/web/agricultural/delivery/import",
    // 种植记录信息导入
    plant_import:"/yanshan-vegetable-industry/api/v1/web/agricultural/plant/import",
    // 农户信息详情 地块信息
    Land_information_LIST:"/yanshan-vegetable-industry/api/v1/app/massif/list",
    // 产品品类
    productCategory_list:"/yanshan-vegetable-industry/api/v1/app/product/list",
    // 产品产量
    productProduction_list:"/yanshan-vegetable-industry/api/v1/web/product/harvest/pc/list",
    // 交易记录
    transaction_list:"/yanshan-vegetable-industry/api/v1/app/transaction/list",
    //交易记录明细列表
    transactionDetail_list:"/yanshan-vegetable-industry/api/v1/app/transaction/detailList",
    // 收购记录
    purchaseRecords_list:'/yanshan-vegetable-industry/api/v1/app/acquisition/list',
    //电子秤 
    electronicScale_list:"/yanshan-vegetable-industry/api/v1/web/electronic/list",
    // 电子秤新增关联
     addGl:'/yanshan-vegetable-industry/api/v1/web/electronic/add',
    //电子秤解除管理
    dzcDel:"/yanshan-vegetable-industry/api/v1/web/electronic/delete",
    //场内交易记录
     exchangeRecord_list:"/yanshan-vegetable-industry/api/v1/web/electronic/transcationList",
    
    // 专业村农资发放记录
    issueLecord:{
      LIST:'/yanshan-vegetable-industry/api/v1/web/agricultural/delivery/list',
      Add:"/yanshan-vegetable-industry/api/v1/web/agricultural/delivery/add",
      del:"/yanshan-vegetable-industry/api/v1/web/agricultural/delivery/delete",
      edit:'/yanshan-vegetable-industry/api/v1/web/agricultural/delivery/update',
      EXPORT:'/yanshan-vegetable-industry/api/v1/web/agricultural/delivery/down'
    },
    // 专业村种植
    planting:{
      LIST:'/yanshan-vegetable-industry/api/v1/web/agricultural/plant/list',
      Add:"/yanshan-vegetable-industry/api/v1/web/agricultural/plant/add",
      del:"/yanshan-vegetable-industry/api/v1/web/agricultural/plant/delete",
      edit:'/yanshan-vegetable-industry/api/v1/web/agricultural/plant/update',
      EXPORT:'/yanshan-vegetable-industry/api/v1/web/agricultural/plant/down'
    }
  },
  //地块分类
  classification: {
    LIST: '/yanshan-vegetable-industry/api/v1/sort/pc/list',
    ADD: '/yanshan-vegetable-industry/api/v1/sort/add',
    EDIT: '/yanshan-vegetable-industry/api/v1/sort/update',
    DEL: '/yanshan-vegetable-industry/api/v1/sort/delete'
  },
  //产品分类及亩产规则
  roductRules: {
    LIST: '/yanshan-vegetable-industry/api/v1/web/product/sort/pc/list',
    ADD: '/yanshan-vegetable-industry/api/v1/web/product/sort/add',
    EDIT: '/yanshan-vegetable-industry/api/v1/web/product/sort/update',
    DEL: '/yanshan-vegetable-industry/api/v1/web/product/sort/delete',
    disable:'/yanshan-vegetable-industry/api/v1/web/product/sort/enable'
  },
  receipt: {
    AUTO: '/hydra-intelligent-planting-sm/valid/api/v1/sale/order/auto/receipt'
  },
  // 企业信息备案
  enterpriseInfoRecord: {
    LIST: "/regulation/registration/enterprise/govandsubgov/list",
    BASE_LIST: '/hydra-base-data/api/v1/base/select/list/no',
    MASSIF_LIST: '/hydra-base-data/api/v1/mass/listBaseMassByOrgIds',
    PRODUCT_LIST: '/hydra-base-data/api/v1/product/enable/list',
    // 批次管理
    BATCH_LIST: '/trace/batch/info/page',
    // 溯源节点
    // 收购记录
    ACQUISITION_RECORD_LIST: '/hydra-intelligent-planting/valid/api/v1/tea-green-acquisition/list/org',
    // 设备
    //IOT设备
    iotFacility: {
      GET_LIST: "/iotdevice/notoken/listByOrgId",
      GET_DETAIL: "/iotdevice/getByCode",
      NO_TOKEN_DETAIL: "/iotdevice/notoken/getByCode",
      GET_CHILDDEVICE: "/iotdevice/sublist",
      //每日设备数据报表
      GET_DEVICE_REPORT: "/iotdevice/deviceReport",
      //控制开关
      CONTROL: "/iotdevice/properitesCommand",
      // 根据设备code获取设备参数（下拉）
      GET_PARAM_BY_CODE: "/iotdevice/getPropDefines",
    },
    DEVICE_LIST: '/iotdevice/notoken/listByOrgList',
    //监控管理
    monitoringManage: {
      GET_LIST: "/iotdevice/notoken/monitor/listByOrgId",
      DELECT: "/iotdevice/monitor/delete",
      GET_STATE: "/iotdevice/notoken/monitor/getStatesByIds",
      //某个用户的监控设备列表
      GET_USER_MONITORLIST: "/iotdevice/notoken/monitor/listDevice",
      //初始化web插件
      INITIALIZE: "/iotdevice/notoken/monitor/init",
      //获取点击web插件实时视频参数
      GET_CLICKPARAMETER: "/iotdevice/notoken/monitor/preview",
      //获取点击web插件视频回放参数
      GET_BACKPARAMETER: "/iotdevice/notoken/monitor/playback",
    },
  },
  //招工信息&收购信息下架管理
  administration:{
    toHire:{
      GET_LIST:"/yanshan-vegetable-industry/api/v1/web/Recruitment/list",
      GET_DETAIL:"/yanshan-vegetable-industry/api/v1/web/Recruitment/getDetail",
      GET_STAND:"/yanshan-vegetable-industry/api/v1/web/Recruitment/disable"
    }
  }
};
