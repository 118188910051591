export default {
  common:{
    GET_PRODUCT: "/hydra-base-data/api/v1/product/enable/list",
  },
  //基础数据
  //合作社管理
  cooperativeMange: {
    GET_LIST: "/hydra-fuding-white-tea/api/v1/cooperative/list",
    SAVE: "/hydra-fuding-white-tea/api/v1/cooperative",
    //批量添加合作社
    BATCH_SAVE:'/hydra-fuding-white-tea/api/v1/cooperative/batch',
    //获取合作社的信息
    GET: "/hydra-fuding-white-tea/api/v1/cooperative",
    UPDATE: "/hydra-fuding-white-tea/api/v1/cooperative",
    DISABLE: "/hydra-fuding-white-tea/api/v1/cooperative/status",
    //获取自动生成合作社编号
    GET_COOPERATIVENO: "/hydra-fuding-white-tea/api/v1/cooperative/no",
    EXPORT:'/hydra-fuding-white-tea/api/v1/cooperative/export',
    IMPORT:'/hydra-fuding-white-tea/api/v1/cooperative/import'
  },
  //茶农管理
  teaFarmersManage: {
    GET_LIST: "/hydra-fuding-white-tea/api/v1/tea-farmer/list",
    SAVE: "/hydra-fuding-white-tea/api/v1/tea-farmer",
    //批量添加茶农
    BATCH_SAVE:'/hydra-fuding-white-tea/api/v1/tea-farmer/batch',
    //获取茶农的信息
    GET: "/hydra-fuding-white-tea/api/v1/tea-farmer",
    UPDATE: "/hydra-fuding-white-tea/api/v1/tea-farmer",
    DISABLE: "/hydra-fuding-white-tea/api/v1/tea-farmer/status",
    EXPORT:'/hydra-fuding-white-tea/api/v1/tea-farmer/export',
    IMPORT:'/hydra-fuding-white-tea/api/v1/tea-farmer/import'
  },
  //交易管理
  teaAcquisitionRecord: {
    //茶青收购记录
    GET_LIST: "/hydra-fuding-white-tea/api/v1/tea-green-acquisition/list",
    //批量提交茶青收购记录
    SAVE: "/hydra-fuding-white-tea/api/v1/tea-green-acquisition/batch",
    //茶青收购实时统计
    GET_TEAGREEN_REALTIMEDATA:"/hydra-fuding-white-tea/api/v1/tea-green-acquisition/real-time-statistics",
    //茶青历史数据
    GET_HISTORY_DATE:"/hydra-fuding-white-tea/api/v1/tea-green-acquisition/statistics",
    //核销茶青收购记录
    CANCEL_RECORD:"/hydra-fuding-white-tea/api/v1/tea-green-acquisition/write-off",
    //作废茶青收购记录
    INVALID: "/hydra-fuding-white-tea/api/v1/tea-green-acquisition/disable",
    //获取启用的产品
    GET_PRODUCT: "/hydra-base-data/api/v1/product/enable/list",
    // 产品分类一级列表
    GET_CLASSIFY: "/hydra-base-data/api/v1/product-sort/firsts",
    //产品规格记录列表
    GET_SPECIFICATION:"/hydra-fuding-white-tea/api/v1/product/specification/product-id",
    EXPORT:'/hydra-fuding-white-tea/api/v1/tea-green-acquisition/export',
    //获取打印信息
    GET_PRINT_INFORMATION:'/hydra-fuding-white-tea/api/v1/tea-green-acquisition/acquisition',
    //更具产品id获取产品属性详情
    GTE_PRODUCT_PRICE:"/hydra-fuding-white-tea/api/v1/product-attributes/by-pid",
    //收购量
    GET_VOLUME:"/hydra-fuding-white-tea/api/v1/tea-green-acquisition/statistics-by-product"
  },
  maoTeaAcquisitionRecords: {
    //毛茶收购记录
    GET_LIST: "/hydra-fuding-white-tea/api/v1/hairy-tea-acquisition/list",
    //批量提交毛茶收购记录
    SAVE: "/hydra-fuding-white-tea/api/v1/hairy-tea-acquisition/batch",
    //毛茶收购实时统计
    GET_MAOTEA_REALTIMEDATA:
      "/hydra-fuding-white-tea/api/v1/hairy-tea-acquisition/real-time-statistics",
    //毛茶历史数据
    GET_MAOTEA_HISTORY_DATE:
      "/hydra-fuding-white-tea/api/v1/hairy-tea-acquisition/statistics",
    //核销毛茶收购记录
    CANCEL_RECORD:"/hydra-fuding-white-tea/api/v1/hairy-tea-acquisition/write-off",
    //作废毛茶收购记录
    INVALID: "/hydra-fuding-white-tea/api/v1/hairy-tea-acquisition/disable",
    //获取启用的产品
    GET_PRODUCT: "/hydra-base-data/api/v1/product/enable/list",
    // 产品分类一级列表
    GET_CLASSIFY: "/hydra-base-data/api/v1/product-sort/firsts",
    //产品规格记录列表
    GET_SPECIFICATION:"/hydra-fuding-white-tea/api/v1/product/specification/product-id",
    EXPORT:'/hydra-fuding-white-tea/api/v1/hairy-tea-acquisition/export'
  },
  //巡检管理
  inspectionRecord: {
    //巡检记录
    GET_LIST: "/hydra-fuding-white-tea/api/v1/daily-inspection/list",
    //获取合作社
    GET_COOPERATIVE: "/hydra-fuding-white-tea/api/v1/cooperative/list",
    //获取茶农
    GET_TEAFARMER: "/hydra-fuding-white-tea/api/v1/tea-farmer/byCooperativeId",
    //获取行为内容
    GET_CONTENT: "/hydra-fuding-white-tea/api/v1/behavioral/list",
    //添加巡检记录
    SAVE: "/hydra-fuding-white-tea/app/v1/daily-inspection",
    GET: "/hydra-fuding-white-tea/api/v1/daily-inspection",
    UPDATE: "/hydra-fuding-white-tea/api/v1/daily-inspection",
    DELECT: "/hydra-fuding-white-tea/api/v1/daily-inspection",
    EXPORT: "/hydra-fuding-white-tea/api/v1/daily-inspection/export"
  },
  behaviorMange: {
    //行为管理
    GET_LIST: "/hydra-fuding-white-tea/api/v1/behavioral/list",
    SAVE: "/hydra-fuding-white-tea/api/v1/behavioral",
    //获取行为记录信息
    GET: "/hydra-fuding-white-tea/api/v1/behavioral",
    UPDATE: "/hydra-fuding-white-tea/api/v1/behavioral",
    DELECT: "/hydra-fuding-white-tea/api/v1/behavioral/delete",
    EXPORT: "/hydra-fuding-white-tea/api/v1/daily-inspection/export"
  },
  teaFarmersRanked: {
    //茶农排名
    GET_LIST: "/hydra-fuding-white-tea/api/v1/tea-farmer/rank/list",
    EXPORT: "/hydra-fuding-white-tea/api/v1/tea-farmer/export-rank"
  },

  //产品规格
  productSpecifications: {
    GET_LIST: "/hydra-fuding-white-tea/api/v1/product/specification/list",
    SAVE: "/hydra-fuding-white-tea/api/v1/product/specification",
    // //获取产品规格的信息
    GET: "/hydra-fuding-white-tea/api/v1/product/specification",
    UPDATE: "/hydra-fuding-white-tea/api/v1/product/specification",
    DISABLE: "/hydra-fuding-white-tea/api/v1/product/specification/status",
    //获取启用的产品
    GET_PRODUCT: "/hydra-base-data/api/v1/product/enable/list"
    // //获取自动生成合作社编号
    // GET_COOPERATIVENO:'/hydra-fuding-white-tea/api/v1/cooperative/no',
  },
  //产品属性
  productProperties:{
    GET_LIST: "/hydra-fuding-white-tea/api/v1/product-attributes/list",
    SAVE: "/hydra-fuding-white-tea/api/v1/product-attributes",
    // //获取产品规格的信息
    GET: "/hydra-fuding-white-tea/api/v1/product-attributes",
    UPDATE: "/hydra-fuding-white-tea/api/v1/product-attributes",
  },
  teaGreenRisk:{
    GET_LIST: "/hydra-fuding-white-tea/api/v1/tea-green-acquisition-risk/list",
    EXPORT:"/hydra-fuding-white-tea/api/v1/tea-green-acquisition-risk/export"
  },
  commonwealthTea:{
    GET_LIST: "/hydra-fuding-white-tea/api/v1/tea-green-acquisition/statistics/cooperative",
    EXPORT:"/hydra-fuding-white-tea/api/v1/tea-green-acquisition/statistics/cooperative/export"
  },
  farmersSaleTea:{
    GET_LIST: "/hydra-fuding-white-tea/api/v1/tea-green-acquisition/statistics/farmer",
    EXPORT:"/hydra-fuding-white-tea/api/v1/tea-green-acquisition/statistics/farmer/export"
  }
};
