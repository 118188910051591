export default {
  //模板类别
  templateType: {
    //获取模板类别列表
    GET_LIST: '/trace/standardtemplate/templatetype/list',
    //新增模板类别
    ADD: '/trace/standardtemplate/templatetype',
    //更新模板类别,
    UPDATE: '/trace/standardtemplate/templatetype'
  },
  //模板列表
  templateInfo: {
    //获取模板列表
    GET_LIST: '/trace/standardtemplate/templateinfo/list',
    //获取模板详情
    GET_DETAIL: '/trace/standardtemplate/templateinfo/getById',
    //设置模版禁用(启用)状态
    SET_DISABLE_FLAG: '/trace/standardtemplate/templateinfo/updateDisableFlag',
    //更新模板
    UPDATE: '/trace/standardtemplate/templateinfo',
    //新增模板
    ADD: '/trace/standardtemplate/templateinfo'
  }
}
