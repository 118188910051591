export default {
  //获取组织微页列表
  GET_ORG_LIST: '/hydra-tools/api/v1/orgList',
  //获取平台微页列表
  GET_SYS_LIST: '/hydra-tools/api/v1/sysList',
  //启用
  SET_ENABLE: '/hydra-tools/api/v1/enable',
  //禁用
  SET_DISABLE: '/hydra-tools/api/v1/disable',
  //获取组织关联设置
  LOAD_ORG_SETTING: '/hydra-tools/api/v1/getOrgSetting',
  //获取平台关联设置
  LOAD_SYS_SETTING: '/hydra-tools/api/v1/getSysSetting',
  //保存组织关联设置
  SAVE_ORG_SETTING: '/hydra-tools/api/v1/orgSetting',
  //保存组织关联设置
  SAVE_SYS_SETTING: '/hydra-tools/api/v1/sysSetting'
};
