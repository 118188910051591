export default {
    //信息发布设置菜单
    menuList: {
        //获取已选菜单
        GET_CHECK_LIST: '/digital-village/hydra-digital-village/api/v1/info-menu/list',
        //更改菜单状态
        UPDATA: '/digital-village/hydra-digital-village/api/v1/info-menu/renew'
    },
    //信息发布设置菜单分类
    menuListType: {
        LIST: '/digital-village/hydra-digital-village/api/v1/info-type/list',
        UPDATA: '/digital-village/hydra-digital-village/api/v1/info-type/renew',
        DEL: '/digital-village/hydra-digital-village/api/v1/info-type/del',
        DELELET: '/digital-village/hydra-digital-village/api/v1/info-type/deleteByTypeId',

        GET_MENU: '/digital-village/hydra-digital-village/api/v1/info-menu/getmenuList',//20200901曾最新需求
    },
    //H5磁贴配置
    setpageH5: {
        //添加或更新功能磁贴
        ADD_EDIT: '/digital-village/hydra-digital-village/api/v1/front-tile/add-update',
        //删除某个磁贴
        DEL: '/digital-village/hydra-digital-village/api/v1/front-tile/del',
        //获取某个导航下的磁贴列表
        LIST: '/digital-village/hydra-digital-village/api/v1/front-tile/list',
        //添加组
        ADD_GROUP: '/digital-village/hydra-digital-village/api/v1/front-tile/group/add',
        //更新组
        Edit: '/digital-village/hydra-digital-village/api/v1/front-tile/group/update',
        //获取icon列表
        ICON_LIST: '/digital-village/hydra-digital-village/api/v1/icon/list/navigation'
    },
    //数字乡村H5配置导航
    setNav: {
        ALL_LIST: '/digital-village/hydra-digital-village/api/v1/navigation/base/list',
        SET_LIST: '/digital-village/hydra-digital-village/api/v1/navigation/list',
        UPDATE: '/digital-village/hydra-digital-village/api/v1/navigation/add-update'
    },
    setPicture: {
        DETAIL: '/digital-village/hydra-digital-village/api/v1/pageBanner/list',
        SAVE: '/digital-village/hydra-digital-village/api/v1/pageBanner/renew',
        // UPDATE: '/digital-village/navigation/add-update'
    },
    //数字乡村智能广播平台配置
    smartBroadcast: {
        //所有发送信息列表
        ALL_LIST: '/digital-village/hydra-digital-village/api/v1/message_send/listAll',
        //广播管理列表
        LIST: '/digital-village/hydra-digital-village/api/v1/broadcast/list',
        //禁用/启用
        DISABLED: '/digital-village/hydra-digital-village/api/v1/broadcast/disable',
        //新增广播服务
        ADD: '/digital-village/hydra-digital-village/api/v1/broadcast/add',
        //充值
        RECHARGE: '/digital-village/hydra-digital-village/api/v1/recharge-record/add',
        //充值列表
        RECHARGE_LIST: '/digital-village/hydra-digital-village/api/v1/recharge-record/list'
    },
    //设置天气
    setWeather: {
        GET_ADDRESS: '/digital-village/hydra-digital-village/api/v1/pageExt/getClientBatchByH5Ext',
        SAVE: '/digital-village/hydra-digital-village/api/v1/pageExt/batchRenew',
        //获取天气
        GET_BY_ADDRESS: '/hydra-open-thirdpart-service/weather/open/weatherConditionByAddress',//通过地区
        GET_BY_LOG: '/hydra-open-thirdpart-service/weather/open/weatherCondition',//通过经纬度

    },
    //村民积分
    villagersIntegral: {
        LIST: '/digital-village/hydra-digital-village/api/v1/assessment-template/list',
        SAVE: '/digital-village/hydra-digital-village/api/v1/assessment-template/add',
        UPDATA: '/digital-village/hydra-digital-village/api/v1/assessment-template/update',
        DEL: '/digital-village/hydra-digital-village/api/v1/assessment-template/delete',
        DETAIL: '/digital-village/hydra-digital-village/api/v1/assessment-template/get',
        COPY: '/digital-village/hydra-digital-village/api/v1/assessment-template/copyTemplate',
    },
    //H5模板配置
    setTemplate: {
        GET_LIST: '/digital-village/hydra-digital-village/api/v1/village-template/list',
        ADD: '/digital-village/hydra-digital-village/api/v1/village-template/add',
        UPDATE: '/digital-village/hydra-digital-village/api/v1/village-template/update',
        GET_DETAIL: '/digital-village/hydra-digital-village/api/v1/village-template/get',
        SYNC_TEMP: '/digital-village/hydra-digital-village/api/v1/village-template/bindingtemplate'
    },
    //算法配置
    happiness: {
        GET_LIST: '/digital-village/hydra-digital-village/api/v1/score-config/list',
        ADD: '/digital-village/hydra-digital-village/api/v1/score-config/add',
        UPDATE: '/digital-village/hydra-digital-village/api/v1/score-config/update',
        GET_DETAIL: '/digital-village/hydra-digital-village/api/v1/score-config/get',
        DEL: '/digital-village/hydra-digital-village/api/v1/score-config/delete',
        //获取权重
        GET_WEIGTH: '/digital-village/hydra-digital-village/api/v1/score-config/getweights'
    }
}