//获取图片验证码
export default {
  //字母验证码
  ABC: '/hydra-user/api/v1/code/abc/',
  //数字验证码
  NUMBER: '/hydra-user/api/v1/code/number/',
  //验证码token
  TOKEN: '/hydra-user/api/v1/code/code-token',
  //发送邮件验证码
  SEND_MAIL_SMS_CODE: '/sms/send/mail/code',
  //发送短信验证码
  SEND_PHONE_SMS_CODE: '/hydra-user/api/v1/sms/send/phone/code'
};
