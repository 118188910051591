import api from 'userCenter/common/api';
import { ajaxSync } from 'framework/utils/ajax';
import { formatDateStr, string2Date } from 'framework/utils/date';
import * as storage from 'framework/utils/storage';
import _ from 'lodash';

export default {
  async search(keyWord) {
    const { err, res } = await ajaxSync.get(api.org.SEARCH_ORG, { search: keyWord });
    if (!err && res) {
      return _.get(res, 'results.list') || [];
    }
    return [];
  }
}

function ivOverNow(target) {
  let result = false;
  try {
    let { year, month, day, hour, minutes, seconds } = formatDateStr(target);
    !hour && (hour = 23);
    !minutes && (minutes = 59);
    !seconds && (seconds = 59);
    //过期当天的23:59:59
    const time = string2Date(`${year}-${month + 1}-${day} ${hour}:${minutes}:${seconds}`) * 1;
    //当前系统时间
    const now = (new Date()) * 1;
    result = time < now;
  } catch (e) {
    console.error(e);
  }
  return result;
}

export const checkOverDate = async (orgId, sysId) => {
  // console.log(orgId, sysId);
  const { err, res } = await ajaxSync.get(api.sys.GET_SYS_VALID_DATE, { orgId, sysId });
  if (!err && res) {
    return ivOverNow(res.results.value);
  }
  return false;
};

export const getOverDate = async (orgId, sysId) => {
  const { err, res } = await ajaxSync.get(api.sys.GET_SYS_VALID_DATE, { orgId, sysId });
  return _.get(res, 'results.value');
}

export const setViewOrgId = async (orgID) => {
  storage.set('orgID', orgID);
  const { err, res } = await ajaxSync.post(`${api.org.SET_VIEW_ORG}/${orgID}`, { organizationId: orgID });
  return { err, res };
};


export const getOrgDetail = async orgId => {
  const { err, res } = await ajaxSync.get(api.org.GET_ORG_DETAIL, { orgId });
  if (!err && res && res.state === 200) {
    return res.results;
  }
  return {};
}
