/*
 * @Author: duyaoyao
 * @Date: 2020-06-10 12:27:46
 * @LastEditors: duyaoyao
 * @LastEditTime: 2020-11-19 11:37:26
 */

export default {
  //溯源模版列表
  GET_LIST: "/trace/traceFunTemplateconfig/query",
  //获取生产过程
  GET_PRODUCT_PROGRESS: "/hydra-user/api/v1/fun/list/org/admin-role",
  // 新增溯源模版
  ADD: "/trace/traceFunTemplateconfig/add",
  // 更新溯源模版
  UPDATE: "/trace/traceFunTemplateconfig/update",
  //获取模版节点
  GET_TEMPLATE_NODE_LIST: "/trace/traceFunTemplateconfig/listNodes",
  // 保存节点分类
  ADD_NODE_CATEGORY: "/trace/api/v3/template/node-category/save",
  // 获取节点分类
  GET_NODE_CATEGORY: "/trace/api/v3/template/node-category/getByTemplateId",
  //获取模版节点配置字段
  GET_TEMPLATE_NODE_FIELDS: "/trace/traceFieldFunConfig/query",
  //
  GET_TEMPLATE_NODE_FIELDS_V3: "/trace/traceFieldFunConfig/queryV3",
  //字段排序
  SORT_FIELDS: "/trace/traceFieldFunConfig/fielSort",
  //根据类目id获取标准模板详情
  GET_STANDARD_TEMPLATE: "/trace/standardtemplate/templateinfo/getByLevelId",
  // 根据模版ID 获取使用该模版的批次数量
  GET_TEMPLATE_BATCH_COUNT:
    "/trace/traceFunTemplateconfig/selectBatchCountByTemplateId",
  GET_TEMPLATE_PRODUCT_LIST: "/trace/traceFunTemplateconfig/product/list"
};
