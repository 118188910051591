export default {
  common: {
    product: {
      // 获取启用产品列表
      ENABLE_LIST: '/hydra-base-data/api/v1/product/enable/list'
    }
  },
  // 生码管理
  codeGeneration: {
    GET_LIST: '/fake/code/manage/api/getGeneratedBatchCodeList',
    GET_LIST_PARAMS: '/fake/code/manage/api/getCodeGenerateRecordLikeParams',
    GET_CODE_TYPE: '/fake/code/manage/api/getCodeAndCodeType',
    SAVE: '/fake/code/manage/api/generateBatchCode',
    EXPORT_BY_BATCH: '/fake/code/manage/api/exportSingleCodeDetailByBatchId',
  },
  // 防伪设置
  setting: {
    // 获取防伪管理列表
    LIST: '/fake/manage/api/v2/getFakeManageRecordListLikeParams',
    // 查询查询链接模板地址
    GET_URL_ALL: '/fake/pageUrl/listAll',
    // 获取生码批次
    GET_BATCH: '/fake/relationCode/selectVailableSbatchId',
    // 获取单个防伪记录
    DETAIL: '/fake/relationCode/get',
    // 防伪管理编辑接口
    UPDATE: '/fake/relationCode/update',
    // 修改生码备注
    CODE_REMARK_UPDATE: '/hydra-code-manager/code/manager/code/apply/updateCodeApplyNote'
  },
  queryResult: {
    GET_LIST: '/fake/record/search/api/getAllFakeJudgeResult',
    GET_LIST_PARAMS: '/fake/record/search/api/getFakeJudgeRecordsLikeParams',
    GET_LIST_DETAILS: '/fake/record/search/api/getSingleSearchDetailsList',
    GET_MAP_RECORD: '/fake/record/search/api/getSingleSearchDetailsListByH5',
    GET_PRODUCT_DETAILS: '/fake/record/search/api/getSingleSearchDetailsProduct',
  },
  queryRecord: {
    GET_LIST: '/fake/record/search/api/getAllSearchRecordsToEngine',
    GET_LIST_PARAMS: '/fake/record/search/api/getCodeGenerateRecordListLikeParams',
    // 防伪搜索记录区块链校验
    ANTIFAKE_VERIFY: '/fake/record/search/api/antifake-verify',
    // 获取防伪记录的区块链信息
    ANTIFAKE_DETAIL: '/fake/record/search/api/get-block-chain'
  },
  // 防伪概况分析
  antiAnalysis: {
    // 查询区域
    GET_AREA: '/fake/statistics/inquiry-area',
    // 查询总览
    GET_BASE: '/fake/statistics/inquiry-base',
    // 查询方式
    GET_MODE: '/fake/statistics/inquiry-mode',
    // 查询总数
    GET_POPPROTION: '/fake/statistics/inquiry-proportion',
    // 查询总量
    GET_TOTAL: '/fake/statistics/inquiry-total'
  },
  // 一键维权
  // 电子存证
  certificate: {
    GET_LIST: '/fake/rights-protection/list'
  },
};
