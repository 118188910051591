/*
 * @Descripttion:
 * @Author: ymf
 * @Date: 2021-08-17 09:31:57
 * @LastEditors: ymf
 * @LastEditTime: 2021-09-24 16:57:01
 */

export default {
  // 组织数据
  orgRelation: {
    LIST: "/hydra-user/api/v1/org/relation/children/orgRelation"
  },
  // 码关联数据
  Code: {
    ADDCODE: "/xiangshan-beauty-orange/api/v1/code/addInitialCode", //生成母本园/扩繁园初码
    CODELIST: "/xiangshan-beauty-orange/api/v1/code/codingList", // 赋码审核列表
    CODEINFO: "/xiangshan-beauty-orange/api/v1/code/detailCode", // 获取码详细信息
    UPDATECODE: "/xiangshan-beauty-orange/api/v1/code/updateCode", // 更改/作废
    CODECOUNT: "/xiangshan-beauty-orange/api/v1/code/getCodeCount", // 首页赋码量统计信息
    CODEINFOLIST: "/xiangshan-beauty-orange/api/v1/code/getCodeCountDetailList", // 赋码量详情码列表
    ADDRESS: "/xiangshan-beauty-orange/api/v1/code/getCodeByAreaCode", // 按地区统计码数量
    UNIT: "/xiangshan-beauty-orange/api/v1/code/getCodeByUnit", // 按单位统计码数量
    WARNINGCODE:
      "/xiangshan-beauty-orange/api/v1/code/getEarlyWarningCodeCount", //获取预警码数量
    VOID: "/xiangshan-beauty-orange/api/v1/code/wasteCodingList", //废码列表
    MONTHER: "/xiangshan-beauty-orange/api/v1/code/getCodeMotherPlantList", // 母株列表
    EDITORIGIN: "/xiangshan-beauty-orange/api/v1/code/editMotherGardenCode", //编辑母本园信息
    ORGCODE: "/xiangshan-beauty-orange/api/v1/code/detailOrgCode", // 获取企业码详情
    EDITEXPAND:
      "/xiangshan-beauty-orange/api/v1/code/editPropagationGardenCode", // 编辑扩繁园信息
    PLANTCODE: "/xiangshan-beauty-orange/api/v1/code/addMotherPlantCode", // 添加母株码信息
    EDITPLATN: "/xiangshan-beauty-orange/api/v1/code/editMotherPlantCode", // 编辑母株码信息
    EDITSEED: "/xiangshan-beauty-orange/api/v1/code/editGrowSeedlingsCode", // 编辑育苗码信息
    PICKLIST: "/xiangshan-beauty-orange/api/v1/ear-picking/pickingMangerList", // 枝条采摘管理列表
    SALECOUNT: "/xiangshan-beauty-orange/api/v1/sale/getSaleCount", // 获取销售数量
    ADDRESSCODENEW: "/xiangshan-beauty-orange/api/v1/sale/saleStatistics", //销售量按地区统计
    ADDRESSINFO: "/xiangshan-beauty-orange/api/v1/sale/getSaleCountDetail", // 获取销售数量详情
    SALEDETAIL: "/xiangshan-beauty-orange/api/v1/sale/detail", // 获取销售纪录详情
    CODEINFOTOTAL: "/xiangshan-beauty-orange/api/v1/code/scanInfo", // 扫码统计
    CODERELATION: "/xiangshan-beauty-orange/api/v1/code/codeAssociation", // 码关联
    COMPANY: "/xiangshan-beauty-orange/api/v1/code/detailOrgCode" // 获取企业码详细信息
  },
  // 品种操作
  Variet: {
    LIST: "/xiangshan-beauty-orange/api/v1/varieties/list", // 该企业所含品种
    ALLLIST: "/xiangshan-beauty-orange/api/v1/varieties/allList" // 所有企业所含品种
  },
  //打印
  Print: {
    BATCH_NUMBER: "/xiangshan-beauty-orange/api/v1/batch/list",
    LIST: "/xiangshan-beauty-orange/api/v1/code/print"
  },
  //销售记录
  Sale: {
    LIST: "/xiangshan-beauty-orange/api/v1/sale/list",//未删除列表数据
    BUYDATA: "/xiangshan-beauty-orange/api/v1/sale/buy",//未删除购买列表
    DELETE: "/xiangshan-beauty-orange/api/v1/sale/delete",//删除
    DETAIL: "/xiangshan-beauty-orange/api/v1/sale/detail",//详情
    SORT: "/xiangshan-beauty-orange/api/v1/code/org/classification",//分类企业
    KFYDATA: "/xiangshan-beauty-orange/api/v1/varieties/page/kfy",//扩繁园下拉品种列表
    GARDEN: "/xiangshan-beauty-orange/api/v1/varieties/page/garden",//母本园下拉品种列表
    ADDPICKING:"/xiangshan-beauty-orange/api/v1/code/addPickingCode",//采穗
    VOIDCARD:"/xiangshan-beauty-orange/api/v1/code/updateCode"//作废
  }
};
