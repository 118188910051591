export default {
    //知识产权
    intellectual: {
        GET_LIST: '/fake/intellectual-property/list',
        UPDATE: '/fake/intellectual-property/deal'
    },
    // 一键维权
    protection: {
        // 本月存证数
        GET_MONTH: '/fake/rights-protection/certificate-month',
        // 今日存证数
        GET_DAY: '/fake/rights-protection/certificate-today',
        // 存证数总数
        GET_TOTAL: '/fake/rights-protection/certificate-totals',
        // 组织排名接口
        GET_RANK: '/fake/rights-protection/certificate-org-rank',
        // 存证量
        GET_BAR: '/fake/rights-protection/certificate'
    },
    // 电子存证
    certificate: {
        GET_LIST: '/fake/rights-protection/list'
    },
    // 权属信息
    owner: {
        GET_LIST: '/hydra-user/api/v1/org/record-ownership-information/list',
        UPDATA: '/hydra-user/api/v1/org/record-ownership-information',
        // DETAIL: '/hydra-user/api/v1/org/record-ownership-information'
    }
};