export default {
  // 客户管理(客户编号)
  client: {
    LIST: '/hydra-intelligent-planting-erp/valid/api/v1/client/page/clients',
    PERMISSION_LIST: '/hydra-intelligent-planting-erp/valid/api/v1/client/page/clients/no-role',
    ADD: '/hydra-intelligent-planting-erp/valid/api/v1/client/addEmployee',
    EDIT: '/hydra-intelligent-planting-erp/valid/api/v1/client/updateEmployee',
    DELETE: '/hydra-intelligent-planting-erp/valid/api/v1/client/delete',
    DETAIL: '/hydra-intelligent-planting-erp/valid/api/v1/client/detail',
    EXPORT: '/hydra-intelligent-planting-erp/valid/api/v1/client/clientNo/export',
    IMPORT: '/hydra-intelligent-planting-erp/valid/api/v1/client/clientNo/import',
    CLIENT_TAG: '/hydra-intelligent-planting-erp/valid/api/v1/clientTag/list',
    EDIT_TAG: '/hydra-intelligent-planting-erp/valid/api/v1/clientTag/updateClientTags',
    // 联系人下拉
    CONTACT_DROPDOWN_LIST: '/hydra-intelligent-planting-erp/valid/api/v1/guGengContactMan/dropDown',
  },
  order: {
    LIST: '/hydra-intelligent-planting-erp/valid/api/v1/sale/order/page/clientNo',
    NO_PERMISSION_LIST: '/hydra-intelligent-planting-erp/valid/api/v1/sale/order/page/clientNo/no-role',
    ADD: '/hydra-intelligent-planting-erp/valid/api/v1/sale/order/add/clientNo',
    EDIT: '/hydra-intelligent-planting-erp/valid/api/v1/sale/order/update/clientNo',
    DETAIL: '/hydra-intelligent-planting-erp/valid/api/v1/sale/order/detail/clientNo',
    DELETE: '/hydra-intelligent-planting-erp/valid/api/v1/sale/order/deleteOrder',
    COMPLETE: '/hydra-intelligent-planting-erp/valid/api/v1/sale/order/done',
    EXPORT: '/hydra-intelligent-planting-erp/valid/api/v1/sale/order/exportExcel/clientNo',
    EXPORT_PDF: '/hydra-intelligent-planting-erp/valid/api/v1/sale/order/exportDeliveryOrderPdf',
    // 销售人员下拉
    SALE_EMPLOYEE_LIST: '/hydra-user/api/v1/employee/list',
    // 产品规格下拉列表(根据产品id)
    proSpecificationList: '/hydra-intelligent-planting/valid/api/v1/product/specification/product-id',
    // 码列表
    CODE_LIST: '/hydra-intelligent-planting-erp/valid/api/v1/storehouse/outbound/code/list',
    // 获取物流信息
    EXPRESS_INFO: '/hydra-intelligent-planting-erp/valid/api/v1/common/expressInfo',
    // 待收货消息提醒
    MESSAGE_SHOW: '/hydra-intelligent-planting-erp/valid/api/v1/sale/order/order/message/show',
    // 关闭消息提醒
    CLOSE_MESSAGE: '/hydra-intelligent-planting-erp/valid/api/v1/sale/order/order/message/close'
  },
  // 客户标签
  clientTag: {
    LIST: '/hydra-intelligent-planting-erp/valid/api/v1/clientTag/list',
    ADD: '/hydra-intelligent-planting-erp/valid/api/v1/clientTag/add',
    EDIT: '/hydra-intelligent-planting-erp/valid/api/v1/clientTag/update',
    DEL: '/hydra-intelligent-planting-erp/valid/api/v1/clientTag',
  },
};
