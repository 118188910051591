export default {
  // 销售任务
  goals: {
    // 获取部门销售任务列表信息
    GET_DEPARTMENT_LIST: '/hydra-intelligent-planting/valid/api/v1/sale-task/department/list',
    // 添加部门销售任务
    ADD_DEPARTMENT_LIST: '/hydra-intelligent-planting/valid/api/v1/sale-task/department/add',
    // 编辑部门销售任务
    EDIT_DEPARTMENT_LIST: '/hydra-intelligent-planting/valid/api/v1/sale-task/department/update',
    // 部门年度任务
    YEARS_LIST: '/hydra-intelligent-planting/valid/api/v1/sale-task/department/year/statistics',
    // 部门季度任务
    SEASON_LIST: '/hydra-intelligent-planting/valid/api/v1/sale-task/department/quarter/statistics',
    // 获取个人销售任务列表信息
    GET_UNIT_LIST: '/hydra-intelligent-planting/valid/api/v1/sale-task/personal/list',
    // 添加个人销售任务
    ADD_UNIT_LIST: '/hydra-intelligent-planting/valid/api/v1/sale-task/personal/add',
    // 编辑个人销售任务
    EDIT_UNIT_LIST: '/hydra-intelligent-planting/valid/api/v1/sale-task/personal/update',
    // 个人年度任务
    UNIT_YEARS_LIST: '/hydra-intelligent-planting/valid/api/v1/sale-task/personal/year/statistics',
    // 个人季度任务
    UNIT_SEASON_LIST: '/hydra-intelligent-planting/valid/api/v1/sale-task/personal/quarter/statistics',
    // 销售人员下拉
    SALE_EMPLOYEE_LIST: '/hydra-user/api/v1/employee/list',
    // 部门销售任务导出
    DEPARTMENT_EXPORT: '/hydra-intelligent-planting/valid/api/v1/sale-task/department/export',
    // 部门季度销售任务导出
    DEPARTMENT_SEASON_EXPORT: '/hydra-intelligent-planting/valid/api/v1/sale-task/department/quarter/export',
    // 部门年度销售任务导出
    DEPARTMENT_YEARS_EXPORT: '/hydra-intelligent-planting/valid/api/v1/sale-task/department/year/export',
    // 个人销售任务导出
    UNIT_EXPORT: '/hydra-intelligent-planting/valid/api/v1/sale-task/personal/export',
    // 个人季度销售任务导出
    UNIT_SEASON_EXPORT: '/hydra-intelligent-planting/valid/api/v1/sale-task/personal/quarter/export',
    // 个人年度销售任务导出
    UNIT_YEARS_EXPORT: '/hydra-intelligent-planting/valid/api/v1/sale-task/personal/year/export',

    // 月度销售任务对比
    MONTH_COMPARISON: '/hydra-intelligent-planting/valid/api/v1/sale-task/month/sale-task-comparison',
    MONTH_COMPARISON_EXPORT: '/hydra-intelligent-planting/valid/api/v1/sale-task/month/stc/export',
    // 季度销售任务对比
    SEASON_COMPARISON: '/hydra-intelligent-planting/valid/api/v1/sale-task/quarter/sale-task-comparison',
    SEASON_COMPARISON_EXPORT: '/hydra-intelligent-planting/valid/api/v1/sale-task/quarter/stc/export',
    // 年度销售任务对比
    YEAR_COMPARISON: '/hydra-intelligent-planting/valid/api/v1/sale-task/year/sale-task-comparison',
    YEAR_COMPARISON_EXPORT: '/hydra-intelligent-planting/valid/api/v1/sale-task/year/stc/export',
  },
  // 客户管理
  customerManager: {
    // 客户分类列表
    GET_CUSTOMER_SORT_LIST: '/hydra-intelligent-planting/valid/api/v1/client/category/page',
    // 客户分类下拉
    CUSTOMER_SORT_LIST: '/hydra-intelligent-planting/valid/api/v1/client/category/dropDownPage',
    // 客户名称下拉
    CUSTOMER_NAME_LIST: '/hydra-intelligent-planting/valid/api/v1/client/dropPage',
    // 添加客户分类
    ADD_CUSTOMER_SORT_LIST: '/hydra-intelligent-planting/valid/api/v1/client/category/add',
    // 编辑客户分类
    EDIT_CUSTOMER_SORT_LIST: '/hydra-intelligent-planting/valid/api/v1/client/category/update',
    // 删除客户分类
    DEL_CUSTOMER_SORT_LIST: '/hydra-intelligent-planting/valid/api/v1/client/category/delete',
    // 客户列表
    GET_CUSTOMER_LIST: '/hydra-intelligent-planting/valid/api/v1/client/page',
    // 客户详情
    CUSTOMER_LIST: '/hydra-intelligent-planting/valid/api/v1/client/detail',
    // 添加客户
    ADD_CUSTOMER_LIST: '/hydra-intelligent-planting/valid/api/v1/client/add',
    // 编辑客户
    EDIT_CUSTOMER_LIST: '/hydra-intelligent-planting/valid/api/v1/client/update',
    // 删除客户
    DEL_CUSTOMER: '/hydra-intelligent-planting/valid/api/v1/client/delete',
    // 客户订单统计
    CUSTOMER_ORDER_RECORD: '/hydra-intelligent-planting/valid/api/v1/sale/order/product/statistics',
    // 客户详情订单
    CUSTOMER_ORDER_DETAILS: '/hydra-intelligent-planting/valid/api/v1/sale/order/product/page',
    // 客户导出
    CUSTOMER_EXPORT: '/hydra-intelligent-planting/valid/api/v1/client/export',
    // 客户导入
    CUSTOMER_IMPORT: '/hydra-intelligent-planting/valid/api/v1/client/import',
    //下载客户导入模板
    CUSTOMER_UPLOAD: '/gugeng/client/download/excel-template',
    // 客户订单导出
    CUSTOMER_ORDER_EXPORT: '/hydra-intelligent-planting/valid/api/v1/sale/order/product/exportExcel',
    // 客户联系人下拉接口
    CONTACT_MAN_DROP_DOWN: '/hydra-intelligent-planting/valid/api/v1/guGengContactMan/dropDown',
    // 客户维护
    customerMaintain: {
      GET_LIST: '/hydra-intelligent-planting/valid/api/v1/guGengClientMaintain/list',
      ADD: '/hydra-intelligent-planting/valid/api/v1/guGengClientMaintain/save',
      UPDATE: '/hydra-intelligent-planting/valid/api/v1/guGengClientMaintain/update',
      DETAIL: '/hydra-intelligent-planting/valid/api/v1/guGengClientMaintain/detail',
      DELETE: '/hydra-intelligent-planting/valid/api/v1/guGengClientMaintain/deleteById',
      GET_LIST_ID: '/hydra-intelligent-planting/valid/api/v1/guGengClientMaintain/listByClientId',
      EXPORT: '/hydra-intelligent-planting/valid/api/v1/guGengClientMaintain/export'
    }
  },
  // 外采订单管理
  outsideOrderManage: {
    // 外采订单列表
    GET_OUTERSIDE_LIST: '/hydra-intelligent-planting/valid/api/v1/out/order/page',
    // 新增外采订单
    ADD_OUTERSIDE_LIST: '/hydra-intelligent-planting/valid/api/v1/out/order/add',
    // 编辑外采订单
    EDIT_OUTERSIDE_LIST: '/hydra-intelligent-planting/valid/api/v1/out/order/update',
    // 获取采购人员
    GET_SALE_PERSON: '/hydra-user/api/v1/employee/enable/list/all',
    // 外采订单导出
    OUTERSIDE_LIST_EXPORT: '/hydra-intelligent-planting/valid/api/v1/out/order/exportExcel',
  },
  // 销售订单管理
  saleOrderManage: {
    // 产量预测
    GET_PREDICTION_LIST: '/hydra-intelligent-planting/valid/api/v1/harvest-plan/production-forecast/list',
    // 订单列表
    GET_SALE_ORDER_LIST: '/hydra-intelligent-planting/valid/api/v1/sale/order/page',
    // 完成订单
    // CLOSE_ORDER: '/gugeng/sale/order/done',
    // 新增订单
    ADD_ORDER: '/hydra-intelligent-planting/valid/api/v1/sale/order/add',
    // 编辑订单
    EDIT_ORDER: '/hydra-intelligent-planting/valid/api/v1/sale/order/update',
    // 订单详情
    VIEW_ORDER: '/hydra-intelligent-planting/valid/api/v1/sale/order/detail',
    // 订单导出
    ORDER_EXPORT: '/hydra-intelligent-planting/valid/api/v1/sale/order/exportExcel',
    // 审核
    VERIFY: '/gugeng/sale/order/verify',
    // 退货
    RETURN_GOODS: '/gugeng/sale/order/returnGoods',
    // 拒收
    REJECT: '/gugeng/sale/order/reject',
    // 订单完成
    DONE: '/hydra-intelligent-planting/valid/api/v1/sale/order/done',
    // 订单确认
    CONFIRM: '/hydra-intelligent-planting/valid/api/v1/sale/order/confirm',
    // 订单删除
    DELETE_ORDER: '/hydra-intelligent-planting/valid/api/v1/sale/order/deleteOrder',
    //订单导入
    ORDER_IMPORT: '/hydra-intelligent-planting/valid/api/v1/sale/order/orderImport',
    // 导出验收单
    EXPORT_DELIVERY_ORDER_PDF_GET: '/hydra-intelligent-planting/valid/api/v1/sale/order/exportDeliveryOrderPdf/'
  },
}
