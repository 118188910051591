export default {
  //根据溯源码获取产品信息
  GET_PRODUCT_INFO_BY_TRACT_CODE: '/hydra-base-data/api/v1/shipper/order/product',
  //寄快递,保存
  SEND_ORDER: '/hydra-base-data/api/v1/shipper/order',
  //获取电子面单详情
  GET_ORDER_DETAIL: '/hydra-base-data/api/v1/shipper/order',
  //快递服务商
  service: {
    //获取服务商列表
    GET_LIST: '/hydra-base-data/api/v1/shipper/service-provider/list',
    //添加服务商
    ADD: '/hydra-base-data/api/v1/shipper/service-provider',
    //编辑服务商
    EDIT: '/hydra-base-data/api/v1/shipper/service-provider',
    //获取服务商详情
    GET_DETAIL: '/hydra-base-data/api/v1/shipper/service-provider'
  },
  //电子面单
  order: {
    //获取电子面单列表
    GET_LIST: '/hydra-base-data/api/v1/shipper/order/list',
    //取消订单
    CANCEL: '/hydra-base-data/api/v1/shipper/order/cancel',
    //删除订单
    DELETE: '/hydra-base-data/api/v1/shipper/order',
    //获取运单轨迹
    GET_TRAVEL: '/hydra-base-data/api/v1/shipper/order/listLogisticsTraces',
    //获取电子面单详情
    GET_DETAIL: '/hydra-base-data/api/v1/shipper/order',
    //打单发货
    SEND_OUT: '/hydra-base-data/api/v1/shipper/order/placeOrder',
    //编辑电子面单
    EDIT: '/hydra-base-data/api/v1/shipper/order'
  }
};
