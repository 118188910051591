export default {
  // 当前种植
  currentPlant: {
    // 列表
    LIST: '/hydra-trace-planting/api/v1/batch-Info/listPlantingBatch',
    // 获取种植批次生产档案详情
    GET_PLANT_BATCH_INFO: '/hydra-trace-planting/api/v1/batch-Info/getPlantingBatchInfo',
    // 分页获取种植批次生产档案详情
    GET_PLANT_BATCH_LIST: '/hydra-trace-planting/api/v1/batch-Info/getPlantingBatchInfoList',
    // 导出列表
    EXPORT_LIST : '/hydra-trace-planting/api/v1/batch-Info/exportPlantingBatch',
    // 导出
    EXPORT: '/hydra-trace-planting/api/v1/batch-Info/exportPlantingBatchInfo',
    // 导出生产档案详情
    EXPORT_DETAIL: '/hydra-trace-planting/api/v1/batch-Info/exportPlantingBatchDetails',
    // 导出生产成本投入
    EXPORT_COST: '/hydra-trace-planting/api/v1/batch-Info/exportPlantingBatchCost'
  },
  // 生产档案
  productionFiles: {
    // 生产档案
    file: {
      // 列表
      LIST: '/hydra-trace-planting/api/v1/batch-Info/listEndBatch',
      // 获取采收批次生产档案详情
      GET_BATCH_INFO: '/hydra-trace-planting/api/v1/batch-Info/getEndBatchInfo',
      // 分页获取采收批次生产档案详情
      GET_BATCH_PAGE_LIST: '/hydra-trace-planting/api/v1/batch-Info/getEndBatchInfoList',
      // 获取生产成本投入
      GET_BATCH_COST_LIST: '/hydra-trace-planting/api/v1/batch-Info/getBatchCostStatistics',
      // 导出列表
      EXPORT_LIST : '/hydra-trace-planting/api/v1/batch-Info/exportEndBatch',
      // 导出生产档案图
      EXPORT: '/hydra-trace-planting/api/v1/batch-Info/exportEndBatchInfo',
      // 导出生产档案详情
      EXPORT_DETAIL: '/hydra-trace-planting/api/v1/batch-Info/exportEndBatchDetails',
      // 导出生产成本投入
      EXPORT_COST: '/hydra-trace-planting/api/v1/batch-Info/exportEndBatchCost',
      // 保存用工总投入信息
      SAVE_WORKING_PRICE: '/hydra-trace-planting/api/v1/batch-Info/saveWorkingPrice'
    },
    // 标准化生产方案
    plan: {
      // 列表
      LIST: '/hydra-intelligent-planting/valid/api/v1/sd/produce/scheme/page',
      // 新增标准化计划方案
      ADD: '/hydra-intelligent-planting/valid/api/v1/sd/produce/scheme/add',
      // 删除
      DELETE: '/hydra-intelligent-planting/valid/api/v1/sd/produce/scheme/delete',
      // 方案详细信息
      SCHEME_DETAIL: '/hydra-intelligent-planting/valid/api/v1/sd/produce/scheme/detail',
      // 标准化方案节点分页列表
      NODE_PAGE_LIST: '/hydra-intelligent-planting/valid/api/v1/sd/produce/scheme/node/page',
      // 工作日节点详细信息
      SCHEME_NODE_DETAIL: '/hydra-intelligent-planting/valid/api/v1/sd/produce/scheme/node/detail',
      // 修改标准化计划方案
      EDIT: '/hydra-intelligent-planting/valid/api/v1/sd/produce/scheme/update',
      // 导出
      EXPORT: '/hydra-intelligent-planting/valid/api/v1/sd/produce/scheme/exportExcel'
    },
  },
  // 生产计划
  productionPlan: {
    // 销售任务
    task: {
      LIST: '/hydra-intelligent-planting/valid/api/v1/sale-task/department/month/list',
      remark: '/hydra-intelligent-planting/valid/api/v1/sale-task/department/update-remark',
      EXPORT: '/hydra-intelligent-planting/valid/api/v1/sale-task/department/export'
    },
    // 生产计划
    plan: {
      LIST: '/hydra-intelligent-planting/valid/api/v1/produce-plan/list',
      ADD: '/hydra-intelligent-planting/valid/api/v1/produce-plan/save',
      DETAIL: '/hydra-intelligent-planting/valid/api/v1/produce-plan/detail',
      UPDATE: '/hydra-intelligent-planting/valid/api/v1/produce-plan/update',
      DELETE: '/hydra-intelligent-planting/valid/api/v1/produce-plan/delete',
      EXPORT: '/hydra-intelligent-planting/valid/api/v1/produce-plan/exportExcel'
    },
  },
  // 产品
  product: {
    LIST: '/hydra-base-data/api/v1/product/enable/list',
  },
  // 生产技术指导
  question: {
    // 咨询专家列表
    LIST: '/hydra-intelligent-planting/valid/api/v1/question/list',
    // 常见问题列表
    COMMON_LIST: '/hydra-intelligent-planting/valid/api/v1/commonQuestion/list',
    // 远程指导列表
    ANSWER_LIST: '/hydra-intelligent-planting/valid/api/v1/answer/list',
    // 新增问题
    ADD_QUESTION: '/hydra-intelligent-planting/valid/api/v1/question/add',
    // 新增回复
    ADD_ANSWER: '/hydra-intelligent-planting/valid/api/v1/answer/add',
  },
  // 批次
  batch: {
    // 获取历史种植批次列表
    HISTORY_BATCH_LIST: '/hydra-trace-planting/api/v1/batch-Info/listEndBatch',
    // 当前种植批次列表
    PLANT_BATCH_LIST: '/hydra-trace-planting/api/v1/batch-Info/listPlantingBatch',
    // 外采批次下拉框
    GET_OUT_DROP_LIST: '/hydra-intelligent-planting/valid/api/v1/out/order/dropPage',
    // 获取批次(仓储)
    GET_PICK_BATCH_DROP_LIST: '/hydra-trace-planting/api/v1/batch-Info/listPickingBatch',
    //获取产品规格信息列表
    GET_PEODUCT_SIZE: '/hydra-base-data/api/v1/specification/product/dropDown'
  },
  // 基地，大棚
  base: {
    // 基地下拉数据
    BASE_LIST: '/hydra-base-data/api/v1/base/select/list',
    // 获取大棚种植产品信息接口
    MASS_LIST: '/hydra-trace-planting/api/v1/batch-Info/listMassIfInfo',
    // 获取当前登陆企业下的所有大棚数据
    ALL_MASS_LIST: '/hydra-base-data/api/v1/mass/type/enable/list',
  },
  // 育苗计划
  seedPlan: {
    // 育苗计划列表
    LIST: '/hydra-intelligent-planting/valid/api/v1/seed-plan/list',
    // 新增育苗计划
    ADD: '/hydra-intelligent-planting/valid/api/v1/seed-plan/save',
    // 修改育苗计划
    EDIT: '/hydra-intelligent-planting/valid/api/v1/seed-plan/update',
    DELETE: '/hydra-intelligent-planting/valid/api/v1/seed-plan/delete',
    EXPORT: '/hydra-intelligent-planting/valid/api/v1/seed-plan/exportExcel'
  },
  // 采收管理
  recovery: {
    // 采收报损
    lose: {
      LIST: '/productmanage/harvest/damage/page',
      ADD: '/productmanage/harvest/damage/add',
      UPDATE: '/productmanage/harvest/damage/update',
      EXPORT: '/productmanage/harvest/damage/exportExcel',
    },
    // 采收计划
    plan: {
      LIST: '/hydra-intelligent-planting/valid/api/v1/harvest-plan/list',
      ADD: '/hydra-intelligent-planting/valid/api/v1/harvest-plan/save',
      UPDATE: '/hydra-intelligent-planting/valid/api/v1/harvest-plan/update',
      DETAIL: '/hydra-intelligent-planting/valid/api/v1/harvest-plan/detail',
      EXPORT: '/hydra-intelligent-planting/valid/api/v1/harvest-plan/exportExcel',
      DELETE: '/hydra-intelligent-planting/valid/api/v1/harvest-plan/delete'
    },
  },
  // 部门管理,部门列表
  department: {
    LIST: '/hydra-user/api/v1/org/dept/list',
  },
  // 标准种植方案
  plantingScheme: {
    LIST: '/hydra-intelligent-planting/valid/api/v1/planting/scheme/list',
    ADD: '/hydra-intelligent-planting/valid/api/v1/planting/scheme/add',
    EDIT: '/hydra-intelligent-planting/valid/api/v1/planting/scheme/update',
    DETAIL: '/hydra-intelligent-planting/valid/api/v1/planting/scheme/detail'
  },
  // 农事操作提醒
  farmWorkReminder: {
    LIST: '/hydra-intelligent-planting/valid/api/v1/planting/work/remind/list',
    SET: '/hydra-intelligent-planting/valid/api/v1/planting/work/remind/set-status',
    // 根据批次id获取农事操作提醒
    GET_BY_BATCH_ID: '/hydra-intelligent-planting/valid/api/v1/planting/work/remind/get-by-batch-id'
  },
  //生产工作计划
  productionWorkPlan: {
    LIST: '/hydra-intelligent-planting/valid/api/v1/produce-plan-task/list',
    //获取产品
    GET_PRODUCT: '/hydra-base-data/api/v1/product/enable/list',
    //获取生产地块
    GET_AREA: '/hydra-base-data/api/v1/mass/enable/list',
    SAVE: '/hydra-intelligent-planting/valid/api/v1/produce-plan-task/save',
    UPDATE: '/hydra-intelligent-planting/valid/api/v1/produce-plan-task/update',
    DETAIL: "/hydra-intelligent-planting/valid/api/v1/produce-plan-task/detail",
    //获取部门
    GET_DEPART: "/hydra-user/api/v1/org/dept/list",
    //获取个人
    GET_PERSONAL: "/hydra-user/api/v1/employee/enable/list",
    //获取物料名称
    GET_MATERIALS: "/hydra-intelligent-planting/valid/api/v1/material/inventory/in/material/list",
    //获取物料规格
    GET_SPECIFICATION: '/hydra-intelligent-planting/valid/api/v1/material/specification/list'
  },
}
