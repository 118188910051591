import systemApiUrl from './systemApi' ;
import orgApiurl from 'org/config/api';
import platformApiurl from 'platform/config/api';
import antiFakeApiUrl from 'antiFake/config/api';
import traceApiUrl from 'trace/config/api';
import codeApiUrl from 'code/config/api';
import logisticsUrl from 'logistics/config/api';
import baseUrl from 'base/config/api';
import integralMarketingUrl from 'integralMarketing/config/api';
import govUrl from 'gov/config/api';
import informationUrl from 'information/config/api';
import rundaUrl from 'runda/config/api';
import nurtureApiUrl from 'nurture/config/api';
import povertyAlleviationApiUrl from 'povertyAlleviation/config/api';
import otherApiUrl from 'other/config/api';
import creditApiUrl from 'credit/config/api';
import teaErpApiUrl from 'teaErp/config/api';
// import pinpinxiangApiUrl from 'other/config/modules/pinpinxiang/api.js';
// import yunNanGuGengApiUrl from 'other/config/modules/yunnangugeng/api.js';
import merge from './apiMerge';
//
//
const apiConfig = merge([
  systemApiUrl, orgApiurl, platformApiurl, antiFakeApiUrl, traceApiUrl, codeApiUrl,
  logisticsUrl, baseUrl, integralMarketingUrl, govUrl, informationUrl, rundaUrl,
  nurtureApiUrl, povertyAlleviationApiUrl,otherApiUrl,creditApiUrl,teaErpApiUrl
]);
//console.log(apiConfig);
export default apiConfig;
