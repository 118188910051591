import { prefix } from 'framework/common/config';

export default {
  common: {
    // 获取启用的产品，可通过产品名称模糊搜索
    GET_PRODUCT_LIST: '/hydra-base-data/api/v1/product/enable/like/name/list',
    // 搜索产品批次
    GET_BATCH_LIST: '/hydra-base-data/api/v1/product-batch/list',
    // 获取员工信息列表
    GET_EMPLOYEE_LIST: '/hydra-user/api/v1/employee/enable/list',
    // 根据运单号查询物流信息
    GET_LOGISTICS_INFO: '/hydra-open-thirdpart-service/express/info/expressInfo'
  },
  code: {
    GET_CODE_SEARCH: '/hydra-code-manager/code/query/codeApply',
    GET_LIST: '/hydra-code-manager/code/manager/code/apply/record/page/list',
    CODE_APPLY: '/hydra-code-manager/code/manager/code/apply/getCodeApplyDetail',
    // 组织、企业
    ORG_LIST: '/hydra-user/api/v1/org/list/platform',
    // 技术特征
    GET_TEC_LIST: '/hydra-code-manager/unicode/technologyCharacterList',
    // 数据下载列表
    DOWNLOAD_LIST: '/hydra-code-manager/code/download/page/list',
    // 数据下载申请
    DOWNLOAD_APPLY_LIST: '/hydra-code-manager/code/download/apply/download/page/list',
    // 下载
    DOWNLOAD_CODE: `${prefix}/hydra-code-manager/code/download/exportCodeData`,
    // 审核数据下载申请
    AUDIT_DOWNLOAD_APPLY: '/hydra-code-manager/code/download/apply/download/audit',
    // 作废列表
    INVALID_LIST: '/hydra-code-manager/code/invalid/page/list',
    // 码作废--添加
    INVALID_ADD: '/hydra-code-manager/code/invalid/add',
    // 获取系统列表
    SYS_LIST: '/hydra-user/api/v1/sys/org/v2/list',
    // 发送验证码
    SEND_CODE: '/hydra-code-manager/phone/code/send',
    // 校验验证码是否正确
    CHECK_CODE: '/hydra-code-manager/phone/code/check',
    // 校验验证码是否失效
    CHECK_CODE_FAILURE: '/hydra-code-manager/phone/code/checkPhoneCodeInvalid',
    // 签收单审核
    ORDER_AUDIT: '/hydra-code-manager/code/manager/delivery/waitAudit/page/list',
    // 激活列表
    // ACTIVE_LIST: '/hydra-code-manager/code/activation/page/list',
    ACTIVE_LIST: '/hydra-code-manager/code/activation/page/list/mini/program',

    // 码激活
    ACTIVE_SAVE: '/hydra-code-manager/code/activation/add',
    // 码激活详情
    // ACTIVE_DETAIL:'/hydra-code-manager/code/activation/detail',
    ACTIVE_DETAIL:'/hydra-code-manager/code/activation/page/list',

    // 待发货
    CODE_ORDER_DELIVE: '/hydra-code-manager/code/manager/order/page/list',
    // 工厂订单列表——已发货（已签收）（未签收）
    FACTORY_ORDER_LIST: '/hydra-code-manager/code/manager/delivery/factory/page/list',
    // 客户订单已发货，已签收、未签收
    CLIENT_ORDER_LIST: '/hydra-code-manager/code/manager/delivery/customer/page/list',
    // 发货详情
    DELIVER_DETAIL: '/hydra-code-manager/code/manager/delivery/getDeliveryVoById',
    // 历史发货记录列表
    OEDER_HISTORY_LIST: '/hydra-code-manager/code/manager/delivery/web/getDeliveryVoList',
    // 查询快递-物流公司
    GET_COURIER: '/hydra-code-manager/unicode/deliveryCompanyList',
    // 发货
    SEND_GOODS: '/hydra-code-manager/code/manager/order/delivery',
    // 审核
    AUDIT: '/hydra-code-manager/code/manager/delivery/auditOrder',
    // 码申请
    CODE_APPLY_SAVE: '/hydra-code-manager/code/manager/code/apply/save',
    // 修改码申请
    UPDATA_CODE: '/hydra-code-manager/code/manager/code/apply/updateCodeApply',
    // 导出测试码
    EXPORT_CODE: `${prefix}/hydra-code-manager/code/download/exportTestCode`,
    // 删除申请记录
    APPLY_DEL: '/hydra-code-manager/code/manager/code/apply/deleteCodeApply',
    // 标识批次
    SBATCH_LIST: '/hydra-code-manager/code/activation/batch/page/list',
    // 标识分配列表
    CODEAPPLY_LIST: '/hydra-code-manager/code/allocation/page/list',
    // 撤销分配
    CANCEL_RECORD: '/hydra-code-manager/code/allocation/revocation/allocation',
    // 添加标识分配申请
    CODEAPPLY_SAVE: '/hydra-code-manager/code/apply/allocation/add',
    // 标识申请驳回
    CODEAPPLY_REFUSE: '/hydra-code-manager/code/apply/allocation/reject/apply',
    // 标识分配列表搜索
    APPLY_ALLOCATION: '/hydra-code-manager/code/apply/allocation/page/list',
    // 添加标识校验码是否正确
    CODE_HAS: '/hydra-code-manager/code/allocation/check/codeAllocation',
    // 重新分配前校验数据是否正确
    CODE_HAS_AGAIN: '/hydra-code-manager/code/allocation/check/afresh/allocation',
    // 添加标识分配
    ALLOCATION_ADD: '/hydra-code-manager/code/allocation/add',
    // 重新标识分配
    ALLOCATION_AGAIN: '/hydra-code-manager/code/allocation/afresh/allocation',
    // 获取当前组织机器下级组织列表
    GOV_LIST: '/regulation/registration/enterprise/gov/all/list',
    // 获取当前组织的上级组织
    GOV_SUPERIOR: '/regulation/registration/enterprise/get/gov/enterprise/count',
    // 关联批次
    SBATCH_RELATION: '/hydra-code-manager/code/manager/code/apply/batch/page/list/forRelation',
    // 码关联记录
    RELATION_RECORD: '/hydra-production/api/v1/query/code/relation/page/list',
    // 码关联
    ADD_RELATION: '/hydra-production/api/v1/code/relation/add',
    // 三江码关联
    ADD_RELATION_SJ: '/hydra-production/api/v1/code/relation/add/sanJiang',
    // 分配工厂
    FACTORY_LIST: '/hydra-code-manager/unicode/factoryList',
    // 审核码申请
    AUDIT_APPLY: '/hydra-code-manager/code/manager/code/apply/audit/codeApply',
    // 签收
    SIGN_CODE: '/hydra-code-manager/code/manager/delivery/signOrder',
    // 重新签收
    RESIGN_ORDER: '/hydra-code-manager/code/manager/delivery/reSignOrder',
    // 获取当前组织机器下级组织列表--平安用
    GOV_LIST_PINGAN: '/hydra-base-data/api/v1/org/union/org/page/list',
    // 获取当前组织的直属下级组织
    GOV_LIST_SUB: '/hydra-user/api/v1/org/relation/current/sub/org',
    IS_CODE: '/hydra-code-manager/code/check/outerCode',  // 验证码是否存在，并验证该码是否属于该组织
    // 获取纯数据类型的企业批次
    GET_BATCH_CODE: '/hydra-code-manager/code/manager/code/apply/localBatch/page/list',
    // 通过组织Id, 获取组织的生码批次
    GET_BATCH_LIST_BY_ORGID: '/hydra-code-manager/code/manager/code/apply/batch/page/list/byOrgId',
    // π接口
    pai: {
      // 获取π技术相关参数
      GET_PI_DATA: '/hydra-code-manager/code/manager/code/apply/getPiData',
      // 保存π数据
      SAVE: '/hydra-code-manager/code/manager/code/apply/configPiData',
      // 获取码制
      GET_CODE_TYPE_OF_CODE_APPLY: '/hydra-code-manager/codeTypeConfig/getCodeTypeByCodeApplyId',
      // 复制pai
      COPY: '/hydra-code-manager/code/manager/code/apply/copyPiData',
    },
    relation: {
      // 清空码关联
      EMPTY_DATA: '/hydra-production/api/v1/code/relation/empty',
      //获取码关联数量详情
      GET_COUNT_DETAIL: '/hydra-production/api/v1/query/code/relation/select/singleCode'
    },
    identifying: {
      //再次申请下载
      SAVE_AGAIN_APPLY: '/hydra-code-manager/code/download/apply',
      DOWNLOAD_LIST: '/hydra-code-manager/code/download/page/list',
    },
    // //剩余码数量
    // LAST_CODENUM: '/platform-codemanager/code/last/codenum',
    //配置防伪技术参数
    SAVE_FAKE: '/hydra-code-manager/code/manager/code/apply/configFakeData',
    //获取码查询工具详情
    GET_CODE_DETAIL: '/hydra-production/api/v1/code/check/checkCode/getProduct',
    // 包装关联查询(装箱)
    pack: {
      LIST: '/hydra-production/api/v1/code/package/flow/page/list',
      EDIT: '/hydra-production/api/v1/code/package/create/package',
      // 检验子码是否正确
      GET_PACK_CODE_INFO: '/hydra-production/api/v1/code/package/check/package/sonCode',
      // 检验父码是否正确
      GET_PACK_PARSEINT: '/hydra-production/api/v1/code/package/check/package/parentCode',
      //包装关联导出
      // ERROR_EXPORT: '/hydra-production/api/v1/code/package/flow/export',
      ERROR_EXPORT: '/hydra-production/api/v1/code/package/flow/new/export',
      // 获取产线：
      PRODUCT_LINE: '/hydra-production/api/v1/production/line/get/page/list',
      CHECK_ALL_CODE: '/hydra-production/api/v1/code/package/checkAllCode'
    },
    // 包装关联查询(拆箱)
    split: {
      LIST: '/hydra-production/api/v1/code/package/flow/page/list',
      EDIT: '/hydra-production/api/v1/split/package/split',
      //拆解关联导出
      ERROR_EXPORT: '/hydra-production/api/v1/code/package/flow/export',
      IS_CODE: '/hydra-production/api/v1/split/package/check/code'
    },
    // 关联查询
    search: {
      SEARCH: '/hydra-production/api/v1/query/code/package/query/packageInfoTree',
      // GET_SON: '/platform-codemanager/code/search/sonCode', // 根据父码查询所有子码信息
    },
    //错误日志
    errorLog: {
      GET_LIST: '/hydra-production/api/v1/code/package/flow/page/list',
      ERROR_EXPORT: '/hydra-production/api/v1/code/package/flow/export'
    },
    // 码全流程区块链
    lifeBlock: {
      LIST: '/hydra-code-manager/code/lifeCycle/blockChain/page/list',
      BLOCKCHAN_RESULT: '/hydra-code-manager/code/lifeCycle/blockChain/page/list/forResult',
      BLOCKCHAN_DETAIL: '/hydra-code-manager/code/lifeCycle/blockChain/getFlowList',
    }
  },
  addRelation: {
    PRODUCT: '/hydra-base-data/api/v1/product/enable/like/name/list',
    SBATCH: '/hydra-base-data/api/v1/product-batch/batch/list'
  },
  //营销码分配接口
  codeDistribution: {
    //获取列表(所有)
    GET_LIST: '/hydra-marketing/api/v1/warehouse/list',
    //获取列表(数据隔离)
    GET_LIMIT_LIST: '/hydra-marketing/api/v1/warehouse/listByLoginUser',
    //获取中奖记录
    GET_REWARD_RECORD_LIST: '/hydra-marketing/api/v1/reconciliation/list/futian',
    //获取中奖记录(数据隔离)
    GET_REWARD_RECORD_LIMIT_LIST: '/hydra-marketing/api/v1/reconciliation/listByLoginUser/futian',
    //码分配数量大到小排列
    GET_LISTBWAREHOUS: '/hydra-marketing/api/v1/warehouse/listByWareHouse',
    //添加码关联仓库
    ADD_WAREHOUSE: '/hydra-marketing/api/v1/warehouse/add',
    //批量添加码关联仓库
    ADD_MANY_WAREHOUSE: '/hydra-marketing/api/v1/warehouse/batchAdd',
    //修改马关联
    MODIFY_WAREHOUSE: '/hydra-marketing/api/v1/warehouse/update',
    //马分配总数
    COUNT_WAREHOUSE: '/hydra-marketing/api/v1/warehouse/countByWareHouse',
    //获取下拉列表
    GET_DROP_DOWN: '/hydra-base-data/api/v1/ware-house/enable/list',
    //导出
    EXPORT_WAREHOUSE: '/hydra-marketing/api/v1/warehouse/list/export',
    //修改状态
    REST_STATUS_WAREHOUSE: '/hydra-marketing/api/v1/warehouse/status',
  },
  //万生堂
  //班组管理
  teamMange: {
    GET_LIST: '/hydra-production/api/v1/work/team/get/page/list',
    ADD: "/hydra-production/api/v1/work/team/add",
    GET: "/hydra-production/api/v1/work/team",
    UPDATE: "/hydra-production/api/v1/work/team/update",
    DELETE: "/hydra-production/api/v1/work/team/delete",
    DISABLE: "/hydra-production/api/v1/work/team/disable",
    ENABLE: '/hydra-production/api/v1/work/team/enable',
  },
  // 工单管理
  workOrder: {
    GET_LIST: '/hydra-production/api/v1/work/order/get/page/list',
    //获取产线
    GET_PRODUCTION_LINE: '/hydra-production/api/v1/production/line/get/list',
    //获取班组
    GET_TEAM: '/hydra-production/api/v1/work/team/get/list',
    //获取产品名称
    GET_PRODUCE: '/hydra-base-data/api/v1/product/enable/list',
    //获取包装规格
    GET_FORMAT: "/hydra-base-data/api/v1/product-ware-house",
    //获取产品批次
    GET_BATCH: '/hydra-base-data/api/v1/product-batch/batch/list',
    ADD: "/hydra-production/api/v1/work/order/add",
    UPDATE: "/hydra-production/api/v1/work/order/update",
    DELECT: "/hydra-production/api/v1/work/order/delete",
    //工单分析
    GET_ANLYSIS: '/hydra-production/api/v1/work/order/analysis',
    //获取详情
    GET_DETAILS: "/hydra-production/api/v1/work/order/detail",
    //审核通过
    SUCCESS: "/hydra-production/api/v1/work/order/enable",
    //审核不通过
    FAIL: "/hydra-production/api/v1/work/order/disable",
    //更新产线状态
    SET_STATUS: '/hydra-production/api/v1/work/order/update/production/status'
  },
  //产线管理
  productionLineManage: {
    GET_LIST: '/hydra-production/api/v1/production/line/get/page/list',
    //产线用户
    GET_LINE: "/hydra-user/api/v1/employee/enable/list",
    ADD: "/hydra-production/api/v1/production/line/add",
    UPDATE: "/hydra-production/api/v1/production/line/update",
    GET: '/hydra-production/api/v1/production/line',
    DISABLE: "/hydra-production/api/v1/production/line/disable",
    ENABLE: '/hydra-production/api/v1/production/line/enable',
  },
  //出库单
  outboundOrder: {
    GET_LIST: '/logistics/ware-house-out/list',
    //获取出库单信息
    GET_OUT_DETAILS: "/logistics/ware-house-out",
    DELECT: '/logistics/okamoto/ware-house-out',
    //获取客户列表
    GET_CLIENT: '/hydra-base-data/api/v1/customer/enable/list',
    IMPORT: "/logistics/okamoto/ware-house-out/import-sweep-house"
  },
  //分布式调出单
  distributedCallOut: {
    GET_LIST: '/logistics/relocate/list',
    ADD: '/logistics/relocate',
    GET: "/logistics/relocate",
    UPDATE: "/logistics/relocate",
    DELECT: "/logistics/relocate",
    SWEEP_PRODUCT_INFO: '/hydra-base-data/api/v1/product/code', // 获取单一产品(不拦截，不需要权限，不需要登录)
    ENABLE_LIST: '/hydra-base-data/api/v1/ware-house/enable/list',//调货 收货仓库
    PRODUCT_ENABLE_LIST: '/hydra-base-data/api/v1/product/enable/ware-house/list', // 获取关联了仓储信息的产品
    ALL_CUSTOMER: '/hydra-base-data/api/v1/customer/all-enable-child',//一个客户下的所有子客户
    GET_UNIT: "/hydra-base-data/api/v1/product-ware-house/product/unitcode",//获取产品详细及仓储信息
  },
  //退货单
  returnForm: {
    GET_LIST: '/logistics/return/list',
    ADD: '/logistics/return',
    GET: "/logistics/return",
    UPDATE: "/logistics/return",
    DELECT: "/logistics/return",

  },
  // 扩展码
  extendCode: {
    LIST: '/hydra-code-manager/extend/code/apply/record/page/list',
    DETAIL: '/hydra-code-manager/extend/code/apply/get/detail',
    ADD: '/hydra-code-manager/extend/code/apply/edit',
    AUDIT: '/hydra-code-manager/extend/code/apply/audit',
    // 获取扩展码类型
    TYPE: '/hydra-system-docking/api/v1/organizationSetting/extend/code/type/list',
    // 扩展码应用名称
    APPLICATIONNAME: '/hydra-system-docking/api/v1/organizationSetting/client/info/list'
  }
}