import healthyPig from "./modules/healthyPig";
import pinpinXiang from "./modules/pinpinxiang/api";
import yunNanGuGeng from "./modules/yunnangugeng/api"; // 云南古耕
import hongjiang from "./modules/hongjiang/api"; // 洪江
import anjibaicha from "./modules/anjibaicha/api"; // 安吉白茶
import sanjiang from "./modules/sanjiang/api"; // 安吉白茶
import zheNongMa from "./modules/sanjiang/api"; // 浙农码
import kaiHua from "./modules/kaihua/api"; // 开化清水鱼
import bijiashan from "./modules/bijiashan/api"; // 笔架山
import suoqi from "./modules/suoqi/api";
import peixian from "./modules/peixian/api"; //沛县湖西农场
import changxingscys from "./modules/changxingscys/api"; //长兴县社会化生产要素平台
import controlscreen from "./modules/controlscreen/api"; // 大屏控制
import haiyan from "./modules/haiyan/api";//海盐
import zangyun from "./modules/zangyun/api";//藏云生物科技
import fuchuan from './modules/fuchuan/api'; // 富川报表
import zishahu from './modules/zishahu/api'; // 紫砂壶
import tongshanshao from './modules/tongshanshao/api'; // 紫砂壶
import yanshan from './modules/yanshan/api'; // 砚山
import yannan from './modules/yannan/api'; // 盐南水稻
import xiangshan from './modules/xiangshan/api';//象山
import shihegang from './modules/shihegang/api';//浉河港
import manborui from './modules/manborui/api';//曼博瑞
export default {
  pinpinXiang,
  yunNanGuGeng,
  hongjiang,
  anjibaicha,
  sanjiang,
  zheNongMa,
  kaiHua,
  bijiashan,
  suoqi,
  peixian,
  changxingscys,
  controlscreen,
  haiyan,
  zangyun,
  fuchuan,
  zishahu,
  tongshanshao,
  yanshan,
  yannan,
  xiangshan,
  shihegang,
  manborui,
};
