export default {
  //获取字段配置
  GET_COLUMNS: '/trace/traceFieldFunConfig/queryV3',
  //获取列表
  GET_LIST: '/trace/dynamic/fun/list',
  //添加数据
  ADD_NODE: '/trace/dynamic/fun/addFunData',
  //编辑数据
  EDIT_NODE: '/trace/dynamic/fun/update',
  //删除数据
  DELETE_NODE: '/trace/dynamic/fun/delete',
  //获取按钮权限
  GET_BUTTON_POWER: '/hydra-user/api/v1/fun/list/service',
  //获取数据详情
  GET_DETAIL: '/trace/dynamic/fun/getById',
  //导出excel
  EXPORT: '/trace/dynamic/fun/exportExcel',
  //导入excel
  IMPORT: '/trace/dynamic/fun/importExcel'
}
