import React from 'react';
import styles from './index.less';
import classNames from 'classnames';
import { Popover, Message } from 'components';
import { getOrgListBySysId, getTokenBySwitchOrg } from 'system/services/organizationSwitch';
import * as storage from 'framework/utils/storage';
import { isDev } from 'src/framework/common/config';
import orgSwitch from 'system/assets/images/header-org-icon.png'
import { setQuery } from 'src/framework/utils/url';

import _ from 'lodash';
//
export default class extends React.Component {
  state = {
    orgList: []
  };
  
  async componentWillMount() {
    const orgDetail = storage.get('orgDetail');
    const sysCache = _.get(orgDetail, 'sysCache');
    const sysId = _.get(sysCache, 'sysId');
    if (sysId) {
      const orgList = await getOrgListBySysId(sysId);
      this.setState({ orgList });
    }
  }
  
  handleOrgClick = (org) => {
    return async () => {
      const orgDetail = storage.get('orgDetail');
      const sysCache = _.get(orgDetail, 'sysCache');
      const sysId = _.get(sysCache, 'sysId');
      const sysUrl = _.get(sysCache, 'sysUrl');
      const res = await getTokenBySwitchOrg({sysId, orgId: org.organizationId})
      if (!res) {
        Message.error(err);
      } else if (res) {
        storage.set('login-token', res && res.token)
        if (isDev) {
          window.location.replace(`/?sysUrl=${encodeURIComponent(sysUrl)}`);
        } else {
          // window.location.replace(`/?sysUrl=${encodeURIComponent(sysUrl)}`);
          window.location.href = setQuery(sysUrl, { accessKey: null });
        }
      }
    };
  };
  
  createContent = () => {
    const { orgList } = this.state;
    return (
      <div className={styles.sysGroup}>
        <div className={styles.sysGroupTitle}>企业切换</div>
        <div className={styles.orgList}>
          {orgList.map((org, index) => {
            return (
              <div key={org.organizationId} className={styles.sysItem} title={org.organizationName} onClick={this.handleOrgClick(org)}><i className={styles.sysIcon}></i>{org.organizationName}</div>
            )
          })}
        </div>
      </div>
    );
  }
  
  render() {
    const { orgList } = this.state;
    if (orgList.length === 0) {
      return null;
    }
    return (
      <Popover overlayClassName="header-system-switch-popover" placement="bottom" content={this.createContent()} trigger="hover">
        <div className={styles.orgSwitch}>
          <img className={classNames(styles.switchIcon)} src={orgSwitch} alt="" />
          {/* <i className={classNames('sc-icon-switch', styles.switchIcon)}></i> */}
        </div>
      </Popover>
    )
  }
}
