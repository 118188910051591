const api = {
  // 获取系统信息
  GET_SYSTEM_DETAIL: '/hydra-user/api/v1/sys/sys/url',
  // 获取系统菜单配置
  GET_MENU: '/hydra-user/api/v1/menu/menu-final',
  // 设置当前选择的系统
  SET_VIEW_SYS: '/hydra-user/api/v1/user/set/sys',
  // 获取按钮授权码
  GET_BUTTON_AUTH_CODE: '/fun/button/authcode',
  // 根据url获取系统信息
  GET_MSG_FROM_URL: '/hydra-user/api/v1/sys/sys/url',
  GET_SYS_INFO_BY_URL: '/hydra-user/api/v1/sys/sys/url',
  // 根据系统ID获取系统信息
  GET_SYS_INFO_BY_ID: '/hydra-user/api/v1/sys/sys/info',
  //获取组织下的系统
  GET_ORG_SYSTEM_LIST: '/hydra-user/api/v1/sys/org/auth/list',
  //用户绑定手机号
  USER_BIND_PHONE: '/hydra-user/api/v1/user/web/bindPhone',
  // 获取系统下的组织
  GET_ORG_BY_SYSTEM_ID: '/hydra-user/api/v1/org/sys/filter-user',
  // 切换组织，重新获取token
  GET_TOKEN_BY_SWITCH_ORG: '/hydra-user/api/v1/user/login/orgs/check'
}
export default api;