export default {
  GET_SHOP_LIST: '/hydra-base-data/api/v1/customer/queryByLocation',
  //获取组织模版列表
  GET_TEMPLATE_LIST: '/hydra-marketing/api/v1/activity/listTemplateByType',
  //获取模版详情
  GET_TEMPLATE_DETAIL: '/hydra-marketing/api/v1/activity/getTemplateById',
  //获取活动列表
  GET_ACTIVITY_LIST: '/hydra-marketing/api/v1/activity/listByType',
  //获取积分兑换列表
  GET_EXCHANGE_ACTIVITY_LIST: '/hydra-marketing/api/v1/activity/exchange',
  //获取积分兑换列表
  GET_SCORE_EXCHANGE_LIST: '/hydra-marketing/api/v1/activity/exchange',
  //修改活动状态
  SET_ACTIVITY_STATUS: '/hydra-marketing/api/v1/activity/status',
  //批量修改活动状态
  BATCH_SET_ACTIVITY_STATUS: '/hydra-marketing/api/v1/activity/batchStatus',
  //编辑活动
  EDIT_ACTIVITY: '/hydra-marketing/api/v1/activity/update',
  //设置活动排序
  SET_ACTIVITY_ORDER: '/hydra-marketing/api/v1/activity/setOrder',
  //获取活动详情
  GET_ACTIVITY_DETAIL: '/hydra-marketing/api/v1/activity/detail',
  //删除活动
  DELETE_ACTIVITY: '/hydra-marketing/api/v1/activity/delete',
  //新增活动
  ADD_ACTIVITY: '/hydra-marketing/api/v1/activity/add',
  //扫码活动中奖纪录列表
  GET_SCANCODE_LIST: '/hydra-marketing/api/v1/reward/scanCode',
  //索契积分兑换
  GET_SUOQI_SCOREEXCHANGE: '/hydra-marketing/api/v1/reward/sq/exchange',
  //索契扫码记录
  GET_SCANCODE_LIST_SUOQI: '/hydra-marketing/api/v1/reward/sq/scanCode',
  CHANNEL_EXPORT: '/hydra-marketing/api/v1/channel/export',
  USER_EXPORT: '/hydra-marketing/api/v1/member/export',
  GUIDE_EXPORT: '/hydra-marketing/api/v1/saler/export',
  //问卷接口
  question: {
    GET_QUESTION_LIST: '/hydra-marketing/api/v1/question/list',
    ADD_QUESTION: '/hydra-marketing/api/v1/question/add',
    SET_QUESTION: '/hydra-marketing/api/v1/question/set',
    GET_QUESTION: '/hydra-marketing/api/v1/question/getQuestions',
    GET_ANSWERINFOLIST: '/hydra-marketing/api/v1/question/listAnswers',
    GET_USER_ANSWERINFO: '/hydra-marketing/api/v1/question/listUserAnswers',
    GET_QUESTION_DETAIL: '/hydra-marketing/api/v1/question/getPaperById',
    //设置问卷状态
    SET_STATUS: '/hydra-marketing/api/v1/question/status',
    //更新问卷
    EDIT_QUESTION: '/hydra-marketing/api/v1/question/update'
  },
  channelManager: {
    GET_CHANNEL_LIST: '/hydra-marketing/api/v1/area/list',
    EDIT_REGION: '/hydra-marketing/api/v1/area/update',
    ADD_REGION: '/hydra-marketing/api/v1/area/add',
    DEL_REGION: '/hydra-marketing/api/v1/area/delete',
    GET_CHANNELLISTBYADMIN: '/hydra-marketing/api/v1/area/listByChannel'
  },
  racerank: {
    GET_DEFAULT_LIST: '/hydra-marketing/api/v1/race/listByActivityCode'
  },
  
}