export default {
  // 采茶工管理
  teaPicker: {
    // 采茶工管理
    teaPicker: {
      LIST: '/hydra-smart-planting/api/v1/teaPicker/list',
      ADD: '/hydra-smart-planting/api/v1/teaPicker',
      DETAIL: '/hydra-smart-planting/api/v1/teaPicker/detail/',
      EDIT: '/hydra-smart-planting/api/v1/teaPicker/update',
      DELETE: '/hydra-smart-planting/api/v1/teaPicker/',
      EXPORT: '/hydra-smart-planting/api/v1/teaPicker/export'
    },
    // 劳务公司管理
    labourCompany: {
      LIST: '/hydra-smart-planting/api/v1/labourCompany/list',
      ADD: '/hydra-smart-planting/api/v1/labourCompany',
      EDIT: '/hydra-smart-planting/api/v1/labourCompany/update',
      DELETE: '/hydra-smart-planting/api/v1/labourCompany/'
    },
    // 测量体温
    measureTemperature: {
      LIST: '/hydra-smart-planting/api/v1/bodyTemperature/list',
      ADD: '/hydra-smart-planting/api/v1/bodyTemperature',
      EDIT: '/hydra-smart-planting/api/v1/bodyTemperature/update',
      DELETE: '/hydra-smart-planting/api/v1/bodyTemperature/',
      EXPORT: '/hydra-smart-planting/api/v1/bodyTemperature/export'
    },
  },
  // 用工管理
  employmentManagement: {
    // 竹筐管理
    basketManage: {
      LIST: '/hydra-smart-planting/api/v1/bamboo-basket-manage/list',
      ADD: '/hydra-smart-planting/api/v1/bamboo-basket-manage',
      ENABLE: '/hydra-smart-planting/api/v1/bamboo-basket-manage/setUseStatus',
      EDIT: '/hydra-smart-planting/api/v1/bamboo-basket-manage',
      EXPORT: '/hydra-smart-planting/api/v1/bamboo-basket-manage/export'
    },
    // 竹筐关联
    basketRelate: {
      LIST: '/hydra-smart-planting/api/v1/bamboo-basket-associate/list',
      ADD: '/hydra-smart-planting/api/v1/bamboo-basket-associate',
      EDIT: '/hydra-smart-planting/api/v1/bamboo-basket-associate',
      // 解除关联
      RELIEVE: '/hydra-smart-planting/api/v1/bamboo-basket-associate',
      DROP_DOWN: '/hydra-smart-planting/api/v1/bamboo-basket-associate/pull'
    }, 
    // 用工管理
    employManage: {
      LIST: '/hydra-smart-planting/api/v1/labor-manage/list',
      ADD: '/hydra-smart-planting/api/v1/labor-manage',
      DETAIL: '/hydra-smart-planting/api/v1/labor-manage/get-by-id',
      EDIT: '/hydra-smart-planting/api/v1/labor-manage',
      DELETE: '/hydra-smart-planting/api/v1/labor-manage',
      EXPORT: '/hydra-smart-planting/api/v1/labor-manage/export'
    }
  }
};
