export default {
  // 配送信息维护下拉接口
  deliveryInfo: {
    DROPDOWN: '/hydra-intelligent-planting/valid/api/v1/deliveryInfo/dropDown'
  },
  // 茶青批次列表
  batch: {
    // 2q3
    LIST: '/hydra-intelligent-planting-erp/valid/api/v1/tea-green-batch/list/semi'
  },
  // 产品等级维护
  productLevel: {
    LIST: '/hydra-intelligent-planting-erp/valid/api/v1/product/level/maintain/list',
    EDIT: '/hydra-intelligent-planting-erp/valid/api/v1/product/level/maintain/',
    ADD: '/hydra-intelligent-planting-erp/valid/api/v1/product/level/maintain'
  },
  // 半成品
  semiFinished: {
    // 入库，入库确认
    in: {
      LIST: '/hydra-intelligent-planting-erp/valid/api/v1/storehouse-semi-in-storage/list',
      ADD: '/hydra-intelligent-planting-erp/valid/api/v1/storehouse-semi-in-storage/save',
      EDIT: '/hydra-intelligent-planting-erp/valid/api/v1/storehouse-semi-in-storage/update',
      EXPORT: '/hydra-intelligent-planting-erp/valid/api/v1/storehouse-semi-in-storage/export',
      DETAIL: '/hydra-intelligent-planting-erp/valid/api/v1/storehouse-semi-in-storage/getById',
      CONFIRM: '/hydra-intelligent-planting-erp/valid/api/v1/storehouse-semi-in-storage/confirm'
    },
    // 出库
    out: {
      LIST: '/hydra-intelligent-planting-erp/valid/api/v1/storehouse-semi-outbound/list',
      LIST_DATE: '/hydra-intelligent-planting-erp/valid/api/v1/storehouse-semi-outbound/list/batch-date',
      ADD: '/hydra-intelligent-planting-erp/valid/api/v1/storehouse-semi-outbound/save',
      EXPORT: '/hydra-intelligent-planting-erp/valid/api/v1/storehouse-semi-outbound/export',
      ADD_DATE: "/hydra-intelligent-planting-erp/valid/api/v1/storehouse-semi-outbound/save/batch-date"
    },
    // 回库
    back: {
      LIST: '/hydra-intelligent-planting-erp/valid/api/v1/storehouse-semi-in-storage/back-list',
      LIST_DATE: '/hydra-intelligent-planting-erp/valid/api/v1/storehouse-semi-in-storage/return/list/batch-date',
      ADD: '/hydra-intelligent-planting-erp/valid/api/v1/storehouse-semi-in-storage/save-back',
      ADD_DATE: '/hydra-intelligent-planting-erp/valid/api/v1/storehouse-semi-in-storage/save-back/batch-date',
      // 获取库存列表-批次日期-去重（获取产品信息）
      OUT_DATE_LIST_PRODUCT: '/hydra-intelligent-planting-erp/valid/api/v1/storehouse-semi-stock/list/product',
      // 获取库存列表-批次日期
      OUT_DATE_LIST: '/hydra-intelligent-planting-erp/valid/api/v1/storehouse-semi-stock/list/batch-date',
      EXPORT: '/hydra-intelligent-planting-erp/valid/api/v1/storehouse-semi-in-storage/export/batch-date',
    },
    // 库存
    stock: {
      LIST: '/hydra-intelligent-planting-erp/valid/api/v1/storehouse-semi-stock/list',
      EXPORT: '/hydra-intelligent-planting-erp/valid/api/v1/storehouse-semi-stock/export',
      // 半成品入库列表
      SEMI_FINISHED_LIST: '/hydra-intelligent-planting-erp/valid/api/v1/storehouse/semi/stock/flow/detail/list/batch-date'
    },
    // 库存流水
    flow: {
      LIST: '/hydra-intelligent-planting-erp/valid/api/v1/storehouse/semi/stock/flow/list'
    },
    // 盘点
    check: {
      LIST: '/hydra-intelligent-planting-erp/valid/api/v1/storehouse/semi/stock/check/list',
      ADD: '/hydra-intelligent-planting-erp/valid/api/v1/storehouse/semi/stock/check',
      EXPORT: '/hydra-intelligent-planting-erp/valid/api/v1/storehouse/semi/stock/check/export',
      CHECK: '/hydra-intelligent-planting-erp/valid/api/v1/storehouse/semi/stock/check/',
      CHECK_ALL: '/hydra-intelligent-planting-erp/valid/api/v1/storehouse/semi/stock/check/done/',
      DETAIL: '/hydra-intelligent-planting-erp/valid/api/v1/storehouse/semi/stock/check/',
      BATCH_CHECK: '/hydra-intelligent-planting-erp/valid/api/v1/storehouse/semi/stock/check/batch/check',
      BATCH_DATE_LIST: '/hydra-intelligent-planting-erp/valid/api/v1/storehouse-semi-stock/select-batch-date'
    }
  },
  // 箱码规格
  boxSpec: {
    LIST: '/hydra-intelligent-planting-erp/valid/api/v1/storehouse/box/specification/list',
    ADD: '/hydra-intelligent-planting-erp/valid/api/v1/storehouse/box/specification',
    EDIT: '/hydra-intelligent-planting-erp/valid/api/v1/storehouse/box/specification/',
    ENABLE_OR_DISABLE: '/hydra-intelligent-planting-erp/valid/api/v1/storehouse/box/specification/enableOrDis/',
    DROP_DOWN_LIST: '/hydra-intelligent-planting-erp/valid/api/v1/storehouse/box/specification/dropDown'
  },
  // 成品
  finishedGoods: {
    // 入库
    in: {
      LIST: '/hydra-intelligent-planting-erp/valid/api/v1/storehouse/finished/stock/in/list',
      // 批次号是日期
      LIST_DATE: '/hydra-intelligent-planting-erp/valid/api/v1/storehouse/finished/stock/in/list/batch-date',
      ADD: '/hydra-intelligent-planting-erp/valid/api/v1/storehouse/finished/stock/in/in/storage',
      ADD_DATE: '/hydra-intelligent-planting-erp/valid/api/v1/storehouse/finished/stock/in/in/storage/batch-date',
      DETAIL: '/hydra-intelligent-planting-erp/valid/api/v1/storehouse/finished/stock/in/detail/',
      EXPORT: '/hydra-intelligent-planting-erp/valid/api/v1/storehouse/finished/stock/in/export/batch-date',
      // 校验码
      VALID_CODE_IS_LEGAL: '/hydra-intelligent-planting-erp/valid/api/v1/storehouse/finished/stock/in/valid-code-is-legal',
      // 获取大于0的库存批次日期列表
      SEMI_FINISHED_BATCH_LIST: "/hydra-intelligent-planting-erp/valid/api/v1/storehouse-semi-stock/select-batch-date/zero",
      // 获取半成品规格列表
      STORE_HOUSE_SEMI_STOCK: "/hydra-intelligent-planting-erp/valid/api/v1/storehouse-semi-stock/list"

    },
    // 库存
    stock: {
      LIST: '/hydra-intelligent-planting-erp/valid/api/v1/storehouse/finished/stock/list',
      EXPORT: '/hydra-intelligent-planting-erp/valid/api/v1/storehouse/finished/stock/export'
    },
    // 入库确认
    confirm: {
      LIST: '/hydra-intelligent-planting-erp/valid/api/v1/storehouse/finished/stock/in/check/list',
      EXPORT: '/hydra-intelligent-planting-erp/valid/api/v1/storehouse/finished/stock/in/check/export',
      ADD: '/hydra-intelligent-planting-erp/valid/api/v1/storehouse/finished/stock/in/check/confirm',
      GET_MSG: '/hydra-intelligent-planting-erp/valid/api/v1/storehouse/finished/stock/in/check/codeId/',
      // 身份码未确认
      VALID: '/hydra-intelligent-planting-erp/valid/api/v1/storehouse/finished/stock/in/check/valid-code-is-legal',
      // 身份码已确认（出库发货用）
      OUTBOUND_VALID: "/hydra-intelligent-planting-erp/valid/api/v1/storehouse/finished/stock/in/check/valid-code-is-legal/check"
    },
    // 库存流水
    flow: {
      LIST: '/hydra-intelligent-planting-erp/valid/api/v1/storehouse/finished/stock/flow/list'
    },
    // 盘点
    check: {
      LIST: '/hydra-intelligent-planting-erp/valid/api/v1/storehouse/finished/stock/check/list',
      ADD: '/hydra-intelligent-planting-erp/valid/api/v1/storehouse/finished/stock/check',
      EXPORT: '/hydra-intelligent-planting-erp/valid/api/v1/storehouse/finished/stock/check/export',
      CHECK: '/hydra-intelligent-planting-erp/valid/api/v1/storehouse/finished/stock/check/',
      CHECK_ALL: '/hydra-intelligent-planting-erp/valid/api/v1/storehouse/finished/stock/check/done/',
      DETAIL: '/hydra-intelligent-planting-erp/valid/api/v1/storehouse/finished/stock/check/',
      VALID: '/hydra-intelligent-planting-erp/valid/api/v1/storehouse/finished/stock/check/outCode/check'
    },
    back: {
      LIST: '/hydra-intelligent-planting-erp/valid/api/v1/storehouseFinishedProductReturnStorage/list',
      ADD: '/hydra-intelligent-planting-erp/valid/api/v1/storehouseFinishedProductReturnStorage',
      EXPORT: '/hydra-intelligent-planting-erp/valid/api/v1/storehouseFinishedProductReturnStorage/export',
      DETAIL: '/hydra-intelligent-planting-erp/valid/api/v1/storehouseFinishedProductReturnStorage',
      CHECK: '/hydra-intelligent-planting-erp/valid/api/v1/storehouseFinishedProductReturnStorage/check'
    }
  },
  // 出库发货
  outbound: {
    LIST: '/hydra-intelligent-planting-erp/valid/api/v1/storehouse/outbound/list',
    ADD: '/hydra-intelligent-planting-erp/valid/api/v1/storehouse/outbound/message',
    // 获取未发货的出库信息
    GET_NO_DELIVERY_OUTBOUND_MSG: '/hydra-intelligent-planting-erp/valid/api/v1/storehouse/outbound/message/select/un-outbound',
    // 全部发货
    ALL_DELIVERY: '/hydra-intelligent-planting-erp/valid/api/v1/storehouse/outbound/allDone',
    // 发货
    DELIVERY: '/hydra-intelligent-planting-erp/valid/api/v1/uutbound-deliver-way/save',
    // 导出
    EXPORT: '/hydra-intelligent-planting-erp/valid/api/v1/storehouse/outbound/export',
    // 出库发货校验
    DELIVERY_CHECK: '/hydra-intelligent-planting-erp/valid/api/v1/uutbound-deliver-way/check',
    // 出库发货列表
    DELIVERY_LIST: '/hydra-intelligent-planting-erp/valid/api/v1/uutbound-deliver-way/delivery/list'
  },
  // 电子秤
  electronicScale: {
    LIST: '/hydra-intelligent-planting/valid/api/v1/electronic-scale/maintain/list'
  },
  // 箱码，盒码关联
  boxCodeAssociation: {
    LIST: '/hydra-intelligent-planting-erp/valid/api/v1/storehouse/codeCorrelation/list',
    ADD: '/hydra-intelligent-planting-erp/valid/api/v1/storehouse/codeCorrelation',
    VIEW: '/hydra-intelligent-planting-erp/valid/api/v1/storehouse/codeCorrelation/',
    DISASSOCIATE: '/hydra-intelligent-planting-erp/valid/api/v1/storehouse/codeCorrelation/relieve/',
    CHECK_CODE: '/hydra-intelligent-planting-erp/valid/api/v1/storehouse/codeCorrelation/check',
  },
  // 根据码获取防窜信息
  ANTI_FLEEING: '/hydra-intelligent-planting-erp/valid/api/v1/tracing-goods',
  // 成品出库的码列表
};
