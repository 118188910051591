import React from 'react';
import {connect} from 'dva';
import {Switch, Route, Redirect} from 'dva/router';
import {getConfig} from 'framework/siteConfig';

let routeData = require('./routeData.js').default;
let isMounted = false;
//
@connect()
export default class extends React.Component {

  state = {
    hotKey: Date.now()
  };

  componentDidMount() {
    isMounted = true;
    if (process.env.NODE_ENV === 'development' && module.hot) {
      module.hot.accept('./routeData.js', () => {
        if (isMounted) {
          routeData = require('./routeData.js').default;
          this.setState({hotKey: Date.now()});
        }
      });
    }
  }

  componentWillUnmount() {
    isMounted = false;
  }

  getDefaultPath = (menuData = []) => {
    let path = '';
    menuData.forEach(item => {
      if (path) {
        return;
      }
      if (item.path) {
        path = item.path;
      } else if (item.children) {
        path = this.getDefaultPath(item.children);
      }
    });
    return path ? path : '/index';
  };

  render() {
    const menuData = getConfig('menuData');
    const defaultPath = this.getDefaultPath(menuData);
    const {hotKey} = this.state;

    return (
      <Switch key={hotKey}>
        {routeData.map((route, index) => <Route key={index} path={route.path} component={route.component}/>)}
        <Redirect exact from="/" to={defaultPath}/>
      </Switch>
    )
  }
}
