/*
 * @Descripttion: 
 * @version: 
 * @Author: yinzi
 * @Date: 2021-09-23 10:17:21
 * @LastEditors: yinzi
 * @LastEditTime: 2021-09-27 17:24:13
 */
// 互联网标识解析二级节点相关接口
export default {
  // 标识字典
  identificationDictionary: {
    LIST: '/hydra-code-snms/api/v1/identification/get/list',
    DELETE: '/hydra-code-snms/api/v1/identification/delete',
    // ADD: '/hydra-code-snms/api/v1/identification/add',
    UPDATE: '/hydra-code-snms/api/v1/identification/update',
  },
  // 行业领域字典
  industryDictionary: {
    LIST: '/hydra-user/api/v1/org/industry/all',
    ENABLE: '/hydra-user/api/v1/org/industry/enable',
    ADD: '/hydra-user/api/v1/org/industry/add',
    UPDATE: '/hydra-user/api/v1/org/industry/update',
    SELECT_LIST: '/hydra-user/api/v1/org/industry/name-list',
  },
  // 组织配置标识
  config: {
    GET: '/hydra-code-snms/api/v1/organization/config/get',
    UPDATE: '/hydra-code-snms/api/v1/organization/config/update',
  },
  // 标识记录
  record: {
    RECORD_LIST: '/hydra-code-snms/api/v1/stat/regist/record/detail',
    //数值统计
    COUNT: '/hydra-code-snms/api/v1/stat/regist/count',
    //标识注册量条形图
    BY_MONTH: '/hydra-code-snms/api/v1/stat/regist/detail/by-month',
    //行业领域概况
    DETAIL: '/hydra-code-snms/api/v1/stat/org/industry/detail',
    //企业节点数列表
    LIST: '/hydra-code-snms/api/v1/stat/org/node/list'
    //
  }
}