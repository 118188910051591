export default {
  // 产品
  base: {
    product: {
      LIST: '/hydra-base-data/api/v1/product/enable/list',
      PRODUCT_SORT_LIST: '/hydra-base-data/api/v1/product-category/'
    },
    productBatch: {
      LIST: '/hydra-base-data/api/v1/product-batch/batch/list'
    }
  },
  // 组织
  org: {
    LIST: '/hydra-user/api/v1/org/user/id',
    // 获取当前组织机器下级组织列表
    GOV_LIST: '/regulation/registration/enterprise/gov/all/list',
  },
  user: {
    USER_LIST: '/hydra-user/api/v1/employee/list',
  },
  // 检测任务管理
  testTaskManagement: {
    // 年度检测方案
    annualTestPlan: {
      LIST: '/hydra-hongjiang-supervision/api/v1/supervision-plan/pages',
      ADD: '/hydra-hongjiang-supervision/api/v1/supervision-plan/add',
      DETAIL: '/hydra-hongjiang-supervision/api/v1/supervision-plan/detail-info',
      EDIT: '/hydra-hongjiang-supervision/api/v1/supervision-plan/update',
      CONTENT_LIST: '/hydra-hongjiang-supervision/api/v1/supervision-plan/details/pages',
      ADD_DETAIL: '/hydra-hongjiang-supervision/api/v1/supervision-plan/detail/add',
      EDIT_DETAIL: '/hydra-hongjiang-supervision/api/v1/supervision-plan/detail/update',
      DELETE: '/hydra-hongjiang-supervision/api/v1/supervision-plan/detail/delete'
    },
    // 检测计划管理
    testPlan: {
      LIST: '/hydra-hongjiang-supervision/api/v1/supervision-scheme/pages',
      ADD: '/hydra-hongjiang-supervision/api/v1/supervision-scheme/add',
      DETAIL: '/hydra-hongjiang-supervision/api/v1/supervision-scheme/detail-info',
      EDIT: '/hydra-hongjiang-supervision/api/v1/supervision-scheme/update',
      CONTENT_LIST: '/hydra-hongjiang-supervision/api/v1/supervision-scheme/details/pages',
      ADD_DETAIL: '/hydra-hongjiang-supervision/api/v1/supervision-scheme/detail/add',
      EDIT_DETAIL: '/hydra-hongjiang-supervision/api/v1/supervision-scheme/detail/update',
      DELETE: '/hydra-hongjiang-supervision/api/v1/supervision-scheme/detail/delete',
      // 根据方案id获取方案下的产品类型集合
      GET_PRODUCT_CATEGORY_LIST: '/hydra-hongjiang-supervision/api/v1/supervision-scheme/category/list'
    },
    // 检测任务管理
    testTask: {
      LIST: '/hydra-hongjiang-supervision/api/v1/supervision-task/pages',
      ADD: '/hydra-hongjiang-supervision/api/v1/supervision-task/add',
      DETAIL: '/hydra-hongjiang-supervision/api/v1/supervision-task/detail-info',
      EDIT: '/hydra-hongjiang-supervision/api/v1/supervision-task/update',
      CONTENT_LIST: '/hydra-hongjiang-supervision/api/v1/supervision-task/details/pages',
      ADD_DETAIL: '/hydra-hongjiang-supervision/api/v1/supervision-task/detail/add',
      EDIT_DETAIL: '/hydra-hongjiang-supervision/api/v1/supervision-task/detail/update',
      DELETE: '/hydra-hongjiang-supervision/api/v1/supervision-task/detail/delete',
      // 根据计划id获取计划下的产品类型集合
      GET_PRODUCT_CATEGORY_LIST: '/hydra-hongjiang-supervision/api/v1/supervision-task/category/list'
    },
    // 我的检测任务
    mineTest: {
      LIST: '/hydra-hongjiang-supervision/api/v1/supervision-task-center/pages',
      // 获取农残数据
      PESTICIDE: '/hydra-hongjiang-supervision/api/v1/supervision-task-center/supervision/data',
      ADD: '/hydra-hongjiang-supervision/api/v1/supervision-task-center/supervision/record/add',
      DETAIL_LIST: '/hydra-hongjiang-supervision/api/v1/supervision-task-center/supervision/record/pages',
      DETAIL_MSG: '/hydra-hongjiang-supervision/api/v1/supervision-task-center/supervision/record/detail',
      EDIT: '/hydra-hongjiang-supervision/api/v1/supervision-task-center/supervision/record/update',
      // 获取任务明细相关信息
      GET_TEST_DETAIL: '/hydra-hongjiang-supervision/api/v1/supervision-task-center/supervision/detail/info'
    },
    // 检测报告管理
    testReport: {
      LIST: '/hydra-hongjiang-supervision/api/v1/supervision-report-form/pages',
      VIEW_LIST: '/hydra-hongjiang-supervision/api/v1/supervision-report-form/details/pages'
    }
  },
  // 考核管理
  assessmentManagement: {
    // 考核指标管理
    assessIndex: {
      LIST: '/hydra-hongjiang-supervision/api/v1/appraisal-indication/list',
      ADD: '/hydra-hongjiang-supervision/api/v1/appraisal-indication/add',
      EDIT: '/hydra-hongjiang-supervision/api/v1/appraisal-indication/update'
    },
    // 考核任务管理
    assessTask: {
      LIST: '/hydra-hongjiang-supervision/api/v1/appraisal-task/list',
      ADD: '/hydra-hongjiang-supervision/api/v1/appraisal-task/add',
      EDIT: '/hydra-hongjiang-supervision/api/v1/appraisal-task/update',
      DETAIL: '/hydra-hongjiang-supervision/api/v1/appraisal-task/detail',
      ASSESS_RES_LIST: '/hydra-hongjiang-supervision/api/v1/appraisal-task/appraisal/result',
      ASSESS_RES_MSG: '/hydra-hongjiang-supervision/api/v1/appraisal-task/appraisal/result/detail'
    },
    // 考核评分管理
    assessScore: {
      // 自评
      SELF_LIST: '/hydra-hongjiang-supervision/api/v1/score/self/appraisal',
      // 上级评分
      SUPER_LIST: '/hydra-hongjiang-supervision/api/v1/score/superiors/appraisal',
      // 监督科评分
      HIGHER_LIST: '/hydra-hongjiang-supervision/api/v1/score/supervision/appraisal',
      // 获取考核内容
      GET_TEST_CONTENT: '/hydra-hongjiang-supervision/api/v1/score/appraisal/content',
      // 评分填写
      ADD: '/hydra-hongjiang-supervision/api/v1/score/grade'
    }
  },
  //设备管理 
  equipmentManagement: {
    consumablesMangement: {//耗材管理
      LIST: '/hydra-hongjiang-supervision/api/v1/consumable-material/list',
      ADD: '/hydra-hongjiang-supervision/api/v1/consumable-material/add',
      EDIT: '/hydra-hongjiang-supervision/api/v1/consumable-material/update',
      DETAIL: '/hydra-hongjiang-supervision/api/v1/consumable-material/detail',
    },
    consumptionMangement: {//消耗管理
      LIST: '/hydra-hongjiang-supervision/api/v1/consume-record/list',
      ADD: '/hydra-hongjiang-supervision/api/v1/consume-record/add',
      EDIT: '/hydra-hongjiang-supervision/api/v1/consume-record/update',
      DETAIL: '/hydra-hongjiang-supervision/api/v1/consume-record/detail',
    },
    materialStatistics: {//耗材统计
      GET_LIST: '/hydra-hongjiang-supervision/api/v1/consumable-material-statistics/statistics'
    },
    pesticideTestingEquipment: {//农残检测设备管理
      LIST: '/hydra-hongjiang-supervision/api/v1/equipment/list',
      DISABLE: '/hydra-hongjiang-supervision/api/v1/equipment/disable',
      GET_PASSWORD: '/hydra-hongjiang-supervision/api/v1/equipment/generate'
    }
  },
  equipmentManagement: {
    consumablesMangement: {//耗材管理
      LIST: '/hydra-hongjiang-supervision/api/v1/consumable-material/list',
      ADD: '/hydra-hongjiang-supervision/api/v1/consumable-material/add',
      EDIT: '/hydra-hongjiang-supervision/api/v1/consumable-material/update',
      DETAIL: '/hydra-hongjiang-supervision/api/v1/consumable-material/detail',
    },
    consumptionMangement: {//消耗管理
      LIST: '/hydra-hongjiang-supervision/api/v1/consume-record/list',
      ADD: '/hydra-hongjiang-supervision/api/v1/consume-record/add',
      EDIT: '/hydra-hongjiang-supervision/api/v1/consume-record/update',
      DETAIL: '/hydra-hongjiang-supervision/api/v1/consume-record/detail',
    },
    materialStatistics: {//耗材统计
      GET_LIST: '/hydra-hongjiang-supervision/api/v1/consumable-material-statistics/statistics'
    },
    pesticideTestingEquipment: {//农残检测设备管理
      LIST: '/hydra-hongjiang-supervision/api/v1/equipment/list',
      DISABLE: '/hydra-hongjiang-supervision/api/v1/equipment/disable',
      GET_PASSWORD: '/hydra-hongjiang-supervision/api/v1/equipment/generate'
    }
  },
  // 组织关系管理
  organization: {
    // 科室管理
    departmentManagement: {
      LIST: '/hydra-hongjiang-supervision/api/v1/supervision-branch/pages',
      ADD: '/hydra-hongjiang-supervision/api/v1/supervision-branch/add',
      DELETE: '/hydra-hongjiang-supervision/api/v1/supervision-branch/delete',
      EDIT: '/hydra-hongjiang-supervision/api/v1/supervision-branch/update',
      DETAIL: '/hydra-hongjiang-supervision/api/v1/supervision-branch/detail',
      // 获取检测站列表
      GET_TEST_STATION: '/hydra-hongjiang-supervision/api/v1/supervision-branch/branch/supervision/pages',
      // 获取科室人员列表
      GET_BRANCH_PAGE: '/hydra-hongjiang-supervision/api/v1/supervision-branch/branch/user/pages'
    }
  },
  //分配检测任务
  assignments:{
    LIST: '/hydra-hongjiang-supervision/api/v2/supervision-task/pages',
    ADD: '/hydra-hongjiang-supervision/api/v2/supervision-task/add',
    DETAIL: '/hydra-hongjiang-supervision/api/v2/supervision-task/detail',
    EDIT: '/hydra-hongjiang-supervision/api/v2/supervision-task/update',
    //详细情况
    DETAILS:'/hydra-hongjiang-supervision/api/v2/supervision-task/detail/list',
    // 获取当前组织机器下级组织列表
    GOV_LIST: '/hydra-open-thirdpart-service/business/open/thrid/like',
  },
  //我的检测任务（新）
  myTask:{
    LIST: '/hydra-hongjiang-supervision/api/v2/supervision-task-center/detail/list',
    DETAIL: '/hydra-hongjiang-supervision/api/v2/supervision-task-center/supervision/record/detail',
    EDIT: '/hydra-hongjiang-supervision/api/v2/supervision-task-center/supervision/record/add',
  }
};
