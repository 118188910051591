export default {
  purchaser: {
    LIST: '/jgwproductInfo/b2b/purchaser/listPurchaser',
    IS_DISBALE: '/jgwproductInfo/b2b/purchaser/disable'
  },
  product: {
    LIST: '/jgwproductInfo/b2bproduct/list',
    ADD: '/jgwproductInfo/b2bproduct/save',
    DETAIL: '/jgwproductInfo/b2bproduct/detail',
    UPDATA: '/jgwproductInfo/b2bproduct/update',
    COPY: '/jgwproductInfo/b2bproduct/copy',
    STATUS: '/jgwproductInfo/b2bproduct/upAndDownCarriage',
    EXPORT: '/jgwproductInfo/b2bproduct/export',
  },
  productList: {
    LIST: '/hydra-choose-product-library/api/v1/server/recommend-product/listProduct',
    ADD: '/hydra-choose-product-library/api/v1/server/recommend-product/add',
    DETAIL: '/hydra-choose-product-library/api/v1/server/recommend-product/getInfo',
    DELETE: '/hydra-choose-product-library/api/v1/server/recommend-product/deleteById',
    UPDATA: '/hydra-choose-product-library/api/v1/server/recommend-product/update',
    STATUS: '/hydra-choose-product-library/api/v1/server/recommend-product/updateShelves',
    EXPORT: '/hydra-choose-product-library/api/v1/server/recommend-product/export',
    MAXMIN_WEIGHT: '/hydra-choose-product-library/api/v1/server/recommend-product/getWeight',
    TRACE_H5_URL: '/hydra-choose-product-library/api/v1/server/choose-product-library-v3/getTraceUrl'
  },
  productSelection: {
    LIST_2_0: '/hydra-choose-product-library/api/v1/server/choose-product-library-v2/listProduct',
    DETAIL_2: '/hydra-choose-product-library/api/v1/server/choose-product-library-v2/getInfo',
    UPDATA_2: '/hydra-choose-product-library/api/v1/server/choose-product-library-v2/getInfo',
    LIST_3_0: '/hydra-choose-product-library/api/v1/server/choose-product-library-v3/listProduct',
    DETAIL_3: '/hydra-choose-product-library/api/v1/server/choose-product-library-v3/getInfo',
    UPDATA_3: '/hydra-choose-product-library/api/v1/server/choose-product-library-v3/getInfo',
    GET_PRODUCTINFO_2: '/hydra-choose-product-library/api/v1/server/choose-product-library-v2/getLastestInfo',
    GET_PRODUCTINFO_3: '/hydra-choose-product-library/api/v1/server/choose-product-library-v3/getLastestInfo',
  },
  // 产品标签
  productLabel: {
    LIST: '/hydra-choose-product-library/api/v1/server/product-label/listLabel',
    DROP_LIST: '/hydra-choose-product-library/api/v1/server/product-label/listLabelNoDisable',
    ADD: '/hydra-choose-product-library/api/v1/server/product-label/add',
    UPDATA: '/hydra-choose-product-library/api/v1/server/product-label/update',
    ENABLE: '/hydra-choose-product-library/api/v1/server/product-label/updateDisableFlag',
    DELETE: '/hydra-choose-product-library/api/v1/server/product-label/deleteById',
    DETAIL: '/hydra-choose-product-library/api/v1/server/product-label/getById',
  },
  productCategory: {
    LIST: '/hydra-choose-product-library/api/v1/server/product-classification/listClassification',
    ADD: '/hydra-choose-product-library/api/v1/server/product-classification/add',
    DELETE: '/hydra-choose-product-library/api/v1/server/product-classification/deleteById',
  },
  category: {
    LIST: '/jgwproductInfo/productCategory/get',
    ADD: '/jgwproductInfo/productCategory/save',
    DEL: '/jgwproductInfo/productCategory/delete',
    EDIT: '/jgwproductInfo/productCategory/update',
  },
  area: {
    LIST: '/jgwproductInfo/productionPlace/list',
    ADD: '/jgwproductInfo/productionPlace/save',
    DETAIL: '/jgwproductInfo/productionPlace/detail',
    UPDATA: '/jgwproductInfo/productionPlace/update',
  },
  base: {
    LIST: '/jgwproductInfo/basePlace/list',
    PRODUCT_BASE_LIST: '/jgwproductInfo/basePlace/listwithDisable',
    ADD: '/jgwproductInfo/basePlace/save',
    EDIT: '/jgwproductInfo/basePlace/update',
    DETAIL: '/jgwproductInfo/basePlace/detail',
    IS_DISBALE: '/jgwproductInfo/basePlace/disableFlagOrNot',
  },
  publishment: {
    LIST: '/jgwproductInfo/supplyBack/list',
    // 删除发布的供采信息
    DELETE: '/jgwproductInfo/supplyBack/delete'
  }
};