import api from './api';
import { ajaxSync } from 'framework/utils/ajax';
import MenuItem from 'framework/common/MenuItem';
import { loadMenu } from 'system/services/menu';
import _ from 'lodash';

function menuItemFormat(data) {
  const icon = '';
  const name = _.get(data, 'menuName') || '';
  const path = _.get(data, 'funUri') || '';
  const children = (_.get(data, 'childMenu') || []).map(menuItemFormat).filter(Boolean);
  let type = _.get(data, 'menuType');
  const url = _.get(data, 'url') || '';
  if (type == 2) {
    type = 1;
  } else {
    type = '';
  }
  const menuItem = new MenuItem(name);
  menuItem.set({ icon, path, children, type, url });
  if (!(url || path) && children.length === 0) {
    return null;
  }
  return menuItem;
}

// 区分相同path的菜单
function sameMenuDistinguish(menuData) {
  let menuItems = [];
  menuData.forEach(item => {
    const childs = item.deepChildren();
    menuItems = menuItems.concat(childs);
  });
  const isPathExist = menuItem => menuItems.filter(item => item && item !== menuItem && item.path === menuItem.path).length > 0
  menuItems.forEach((item, index) => {
    if (item && item.path && isPathExist(item)) {
      const newPath = `${item.path}/index-${index}`;
      item.set({ path: newPath });
    }
  });
  return menuData;
}

export default async () => {
  let menuData = await loadMenu();
  menuData = menuData.map(item => {
    return menuItemFormat(item);
  }).filter(Boolean);
  menuData = sameMenuDistinguish(menuData);
  return menuData;
}
