export default {
  // 合作社管理
  cooperativeMange: {
    GET_LIST: "/hydra-intelligent-planting/valid/api/v1/cooperative/list",
    SAVE: "/hydra-intelligent-planting/valid/api/v1/cooperative",
    //批量添加合作社
    BATCH_SAVE: "/hydra-intelligent-planting/valid/api/v1/cooperative/batch",
    //获取合作社的信息
    GET: "/hydra-intelligent-planting/valid/api/v1/cooperative",
    UPDATE: "/hydra-intelligent-planting/valid/api/v1/cooperative",
    DISABLE: "/hydra-intelligent-planting/valid/api/v1/cooperative/status",
    //获取自动生成合作社编号
    GET_COOPERATIVENO:
      "/hydra-intelligent-planting/valid/api/v1/cooperative/no",
    EXPORT: "/hydra-intelligent-planting/valid/api/v1/cooperative/export",
    IMPORT: "/hydra-intelligent-planting/valid/api/v1/cooperative/import",
  },
  //茶农管理
  teaFarmersManage: {
    GET_LIST: "/hydra-intelligent-planting/valid/api/v1/tea-farmer/list",
    SAVE: "/hydra-intelligent-planting/valid/api/v1/tea-farmer",
    //批量添加茶农
    BATCH_SAVE: "/hydra-intelligent-planting/valid/api/v1/tea-farmer/batch",
    //获取茶农的信息
    GET: "/hydra-intelligent-planting/valid/api/v1/tea-farmer",
    //获取基地信息
    // GET_BASE: '/base/select/list',(去除)
    UPDATE: "/hydra-intelligent-planting/valid/api/v1/tea-farmer",
    DISABLE: "/hydra-intelligent-planting/valid/api/v1/tea-farmer/status",
    EXPORT: "/hydra-intelligent-planting/valid/api/v1/tea-farmer/export",
    IMPORT: "/hydra-intelligent-planting/valid/api/v1/tea-farmer/import",
    UPDATE_TAG: '/hydra-intelligent-planting/valid/api/v1/farmerTag/updateFarmerTags'
  },
  //茶青总重
  teaWeight: {
    GET_LIST: "/hydra-intelligent-planting/valid/api/v1/tea-green-acquisition/list",
    SAVE: '/hydra-intelligent-planting/valid/api/v1/tea-green-acquisition/batch',
    EXPORT: '/hydra-intelligent-planting/valid/api/v1/tea-green-acquisition/export/quantity',
    //作废茶青总重
    INVALID: "/hydra-intelligent-planting/valid/api/v1/tea-green-acquisition/disable",
    //获取启用的产品
    GET_PRODUCT: "/hydra-base-data/api/v1/product/enable/list",
  },
  //皮称重
  teaTare: {
    GET_LIST: "/hydra-intelligent-planting/valid/api/v1/tea-green-acquisition/bareWeight/list",
    SAVE: '/hydra-intelligent-planting/valid/api/v1/tea-green-acquisition/save/bareWeight',
    EXPORT: '/hydra-intelligent-planting/valid/api/v1/tea-green-acquisition/export/bareWeight',
    //获取启用的产品
    GET_PRODUCT: "/hydra-base-data/api/v1/product/enable/list",
    //获取皮称重详情
    GET_TARE: '/hydra-intelligent-planting/valid/api/v1/tea-green-acquisition/acquisition',
    //皮称重
    TARE: '/hydra-intelligent-planting/valid/api/v1/tea-green-acquisition/save/bareWeight'
  },
  //茶青收购记录
  teaAcquisitionRecord: {
    GET_LIST: "/hydra-intelligent-planting/valid/api/v1/tea-green-acquisition/list",
    //核销茶青收购记录
    CANCEL_RECORD: "/hydra-intelligent-planting/valid/api/v1/tea-green-acquisition/write-off",
    //作废茶青收购记录
    INVALID: "/hydra-intelligent-planting/valid/api/v1/tea-green-acquisition/disable",
    //获取启用的产品
    GET_PRODUCT: "/hydra-base-data/api/v1/product/enable/list",
    EXPORT: '/hydra-intelligent-planting/valid/api/v1/tea-green-acquisition/export',
    //获取打印信息
    GET_PRINT_INFORMATION: '/hydra-intelligent-planting/valid/api/v1/tea-green-acquisition/acquisition',
    //茶青收购实时统计
    GET_TEAGREEN_REALTIMEDATA:"/hydra-intelligent-planting/valid/api/v1/tea-green-acquisition/real-time-statistics",
    //茶青历史数据
    GET_HISTORY_DATE:"/hydra-intelligent-planting/valid/api/v1/tea-green-acquisition/statistics",
  },
  //茶青批次
  teaBatch: {
    GET_LIST: "/hydra-intelligent-planting/valid/api/v1/tea-green-batch/list",
    EXPORT: '/hydra-intelligent-planting/valid/api/v1/tea-green-batch/export',
    // 茶青批次产品等级列表
    TEA_GREEN_BATCH_LEVEL_LIST: '/hydra-intelligent-planting/valid/api/v1/tea-green-batch/product-level/list',
    // 生成茶青批次
    GET_GREEN_TEA_BATCH: '/hydra-intelligent-planting/valid/api/v1/tea-green-batch/save'
  },
  // 收购农产品等级
  productGrade:{
    LIST: '/hydra-intelligent-planting/valid/api/v1/teaGreenProductLevel/list',
    ADD: '/hydra-intelligent-planting/valid/api/v1/teaGreenProductLevel',
    EDIT: '/hydra-intelligent-planting/valid/api/v1/teaGreenProductLevel/',
    DEL: '/hydra-intelligent-planting/valid/api/v1/teaGreenProductLevel/',
    DROP_LIST: '/hydra-intelligent-planting/valid/api/v1/teaGreenProductLevel/select',
  },
  // 农户标签
  farmerTag: {
    LIST: '/hydra-intelligent-planting/valid/api/v1/farmerTag/list',
    ADD: '/hydra-intelligent-planting/valid/api/v1/farmerTag/add',
    EDIT: '/hydra-intelligent-planting/valid/api/v1/farmerTag/update',
    DEL: '/hydra-intelligent-planting/valid/api/v1/farmerTag',
  },
};