export default {
    GET_LIST: '/fake/ai-audit/list',
    GET_AREA: '/fake/ai-audit/inquire-area',
    GET_TREND: '/fake/ai-audit/inquire-trend',
    GET_ORG_RANK: '/fake/ai-audit/inquire-org-rank',
    // 提示信息管理
    tip: {
        // 提示列表
        LIST: '/fake/tips/list',
        // 修改单条默认值
        UPDATE_DEFAULT_VALUE: '/fake/tips/restores-default-values',
        // 更新
        UPDATE: '/fake/tips/save-or-update'
    }
};