import dynamic from 'dva/dynamic';

export default [
  //下载中心
  {
    path: '/base/downLoadCenter',
    component: dynamic({
      component: () => import(/* webpackChunkName: "modules/base/index0" */ './views/downLoadCenter'),
    }),
  },
  // 客户管理
  {
    path: '/base/customer/admin',
    component: dynamic({
      component: () => import(/* webpackChunkName: "modules/base/index0" */ './views/customer/admin'),
    }),
  },
  // 钓鱼台客户管理
  {
    path: '/base/dytCustomer/admin',
    component: dynamic({
      component: () => import(/* webpackChunkName: "modules/base/index0" */ './views/dytCustomer/admin'),
    }),
  },
  // 子账号管理
  {
    path: '/base/management/admin',
    component: dynamic({
      component: () => import(/* webpackChunkName: "modules/base/index0" */ './views/management/admin'),
    }),
  },
  // 包装关联配置
  {
    path: '/base/package/config',
    component: dynamic({
      component: () => import(/* webpackChunkName: "modules/base/index0" */ './views/package/config'),
    }),
  },
  // 供应商管理
  {
    path: '/base/supplier/admin',
    component: dynamic({
      component: () => import(/* webpackChunkName: "modules/base/index0" */ './views/supplier/admin'),
    }),
  },
  // 供应商管理--平安猪用，列表有联盟端数据
  {
    path: '/base/supplier/admin2/list2',
    component: dynamic({
      component: () => import(/* webpackChunkName: "modules/base/views/supplier" */ './views/supplier/admin/list2'),
    }),
  },
  {
    path: '/base/supplier/type',
    component: dynamic({
      component: () => import(/* webpackChunkName: "modules/base/index0" */ './views/supplier/type'),
    }),
  },
  // 设备管理
  {
    path: '/base/device/product',
    component: dynamic({
      component: () => import(/* webpackChunkName: "modules/base/index0" */ './views/device/product'),
    }),
  },
  // 生产设备使用记录
  {
    path: '/base/device/product/recordList',
    component: dynamic({
      component: () => import(/* webpackChunkName: "modules/base/index0" */ './views/device/product/RecordList'),
    }),
  },
  {
    path: '/base/device/iot',
    component: dynamic({
      component: () => import(/* webpackChunkName: "modules/base/index0" */ './views/device/iot'),
    }),
  },
  // //气象站
  // {
  //   path: '/base/device/stations',
  //   component: dynamic({
  //     component: () =>
  //       import(/* webpackChunkName: "modules/base/index0" */ './views/device/iot/components/DeviceDetail/index'),
  //   }),
  // },
  // 物料管理
  {
    path: '/base/matter/admin',
    component: dynamic({
      component: () => import(/* webpackChunkName: "modules/base/index0" */ './views/matter/admin'),
    }),
  },
  {
    path: '/base/matter/stock',
    component: dynamic({
      component: () => import(/* webpackChunkName: "modules/base/index0" */ './views/matter/stock'),
    }),
  },
  {
    path: '/base/matter/stream',
    component: dynamic({
      component: () => import(/* webpackChunkName: "modules/base/index0" */ './views/matter/stream'),
    }),
  },
  // 产品管理
  {
    path: '/base/production/admin/list',
    component: dynamic({
      component: () => import(/* webpackChunkName: "modules/base/index1" */ './views/production/admin/list'),
    }),
  },
  // 产品管理-添加
  {
    path: '/base/production/admin/add',
    component: dynamic({
      component: () => import(/* webpackChunkName: "modules/base/index1" */ './views/production/admin/edit'),
    }),
  },
  // 产品管理 编辑
  {
    path: '/base/production/admin/edit/:id',
    component: dynamic({
      component: () => import(/* webpackChunkName: "modules/base/index1" */ './views/production/admin/edit'),
    }),
  },
  {
    path: '/base/production/type',
    component: dynamic({
      component: () => import(/* webpackChunkName: "modules/base/index1" */ './views/production/type'),
    }),
  },
  {
    path: '/base/production/batch',
    component: dynamic({
      component: () => import(/* webpackChunkName: "modules/base/index1" */ './views/production/batch'),
    }),
  },
  // 基地管理
  {
    path: '/base/baseArea/admin',
    component: dynamic({
      component: () => import(/* webpackChunkName: "modules/base/index1" */ './views/baseArea/admin'),
    }),
  },
  {
    path: '/base/baseArea/zone',
    component: dynamic({
      component: () => import(/* webpackChunkName: "modules/base/index1" */ './views/baseArea/zone'),
    }),
  },
  {
    path: '/base/baseArea/massif',
    component: dynamic({
      component: () => import(/* webpackChunkName: "modules/base/index1" */ './views/baseArea/massif'),
    }),
  },
  // 基地监管GIS地图
  {
    path: '/base/baseArea/gis',
    component: dynamic({
      component: () => import(/* webpackChunkName: "modules/base/index1" */ './views/baseArea/gis'),
    }),
  },
  // 基础信息维护
  {
    path: '/base/baseInfoAssert/packSize',
    component: dynamic({
      component: () => import(/* webpackChunkName: "modules/base/index1" */ './views/baseInfoAssert/packSize'),
    }),
  },
  {
    path: '/base/baseInfoAssert/packWay',
    component: dynamic({
      component: () => import(/* webpackChunkName: "modules/base/index1" */ './views/baseInfoAssert/packWay'),
    }),
  },
  {
    path: '/base/baseInfoAssert/productSize',
    component: dynamic({
      component: () => import(/* webpackChunkName: "modules/base/index2" */ './views/baseInfoAssert/productSize'),
    }),
  },
  {
    path: '/base/baseInfoAssert/materialSize',
    component: dynamic({
      component: () => import(/* webpackChunkName: "modules/base/index2" */ './views/baseInfoAssert/materialSize'),
    }),
  },
  //设备列表
  // {
  //   path: '/base/device/iot',
  //   component: dynamic({
  //     component: () =>
  //       import(
  //        /* webpackChunkName: "modules/base/index2" */ './views/device/iot'
  //       ),
  //   }),
  // },
  {
    path: '/base/device/equdetail',
    component: dynamic({
      component: () => import(/* webpackChunkName: "modules/base/index2" */ './views/device/iot/pages/equDetail'),
    }),
  },
  {
    path: '/base/device/childdetail',
    component: dynamic({
      component: () => import(/* webpackChunkName: "modules/base/index2" */ './views/device/iot/childDevice/childDetail'),
    }),
  },
  //子设备里面的查看
  {
    path: '/base/device/view',
    component: dynamic({
      component: () => import(/* webpackChunkName: "modules/base/index2" */ './views/device/iot/view/view'),
    }),
  },

  //消息管理
  {
    path: '/message',
    component: dynamic({
      component: () => import(/* webpackChunkName: "modules/base/index2" */ './views/message/index'),
    }),
  },
  //IOT设备
  {
    path: '/base/iotDevices',
    component: dynamic({
      component: () => import(/* webpackChunkName: "modules/base/index2" */ './views/iotDevices/index'),
    }),
  },
  //IOT设备详情
  {
    path: '/base/eqDetails',
    component: dynamic({
      component: () => import(/* webpackChunkName: "modules/base/index2" */ './views/iotDevices/eqDetails/index'),
    }),
  },
  //IOT子设备
  {
    path: '/base/childDetail',
    component: dynamic({
      component: () => import(/* webpackChunkName: "modules/base/index2" */ './views/iotDevices/childDetail/index'),
    }),
  },
  //监控管理
  {
    //监控列表
    path: '/base/monitorList',
    component: dynamic({
      component: () => import(/* webpackChunkName: "modules/base/index3" */ './views/monitoringRegulate/monitorList/index'),
    }),
  },
  {
    //监控设备列表
    path: '/base/monitoringRegulate/facilityList',
    component: dynamic({
      component: () => import(/* webpackChunkName: "modules/base/index3" */ './views/monitoringRegulate/monitorList/facilityList'),
    }),
  },
  {
    //实时视频
    path: '/base/realTimeVideo',
    component: dynamic({
      component: () => import(/* webpackChunkName: "modules/base/index3" */ './views/monitoringRegulate/realTimeVideo/index'),
    }),
  },
  {
    //历史回放
    path: '/base/videoPlayback',
    component: dynamic({
      component: () => import(/* webpackChunkName: "modules/base/index3" */ './views/monitoringRegulate/videoPlayback/index'),
    }),
  },
  {
    //品牌管理
    path: '/base/manage/band/list',
    component: dynamic({
      component: () => import(/* webpackChunkName: "modules/base/index3" */ './views/manage/band'),
    }),
  },
  {
    //品牌管理
    path: '/base/manage/bandEdit',
    component: dynamic({
      component: () => import(/* webpackChunkName: "modules/base/index3" */ './views/manage/band/edit'),
    }),
  },
  {
    //认证标签管理
    path: '/base/manage/tag/list',
    component: dynamic({
      component: () => import(/* webpackChunkName: "modules/base/index3" */ './views/manage/tag'),
    }),
  },
  // 沐阳物联网
  //首页()
  {
    path: '/base/internetOfThings/home',
    component: dynamic({
      component: () => import(/* webpackChunkName: "modules/base/index3" */ './views/internetOfThings/home/index'),
    }),
  },
  //可视化基地
  {
    path: '/base/internetOfThings/visualizationBase',
    component: dynamic({
      component: () => import(/* webpackChunkName: "modules/base/index3" */ './views/internetOfThings/visualizationBase/index'),
    }),
  },
  //气象站
  {
    path: '/base/internetOfThings/weatherStation',
    component: dynamic({
      component: () => import(/* webpackChunkName: "modules/base/index3" */ './views/internetOfThings/weatherStation/index'),
    }),
  },
  //气象站详情
  {
    path: '/base/internetOfThings/weatherStationDetails',
    component: dynamic({
      component: () => import(/* webpackChunkName: "modules/base/index3" */ './views/internetOfThings/weatherStation/details'),
    }),
  },
  //传感器
  {
    path: '/base/internetOfThings/sensor',
    component: dynamic({
      component: () => import(/* webpackChunkName: "modules/base/index3" */ './views/internetOfThings/sensor/index'),
    }),
  },
  //传感器详情
  {
    path: '/base/internetOfThings/sensorDetails',
    component: dynamic({
      component: () => import(/* webpackChunkName: "modules/base/index3" */ './views/internetOfThings/sensor/details'),
    }),
  },
  //沛县客流详情
  {
    path: '/base/internetOfThings/peixianDetails',
    component: dynamic({
      component: () => import(/* webpackChunkName: "modules/base/index3" */ './views/internetOfThings/peixian/details'),
    }),
  },
  //水肥一体机
  {
    path: '/base/internetOfThings/waterFertilizer',
    component: dynamic({
      component: () => import(/* webpackChunkName: "modules/base/index3" */ './views/internetOfThings/waterFertilizer/index'),
    }),
  },
  //  //水肥一体机详情
  {
    path: '/base/internetOfThings/waterFertilizerDetails',
    component: dynamic({
      component: () => import(/* webpackChunkName: "modules/base/index3" */ './views/internetOfThings/waterFertilizer/details'),
    }),
  },
  //智能控制柜
  {
    path: '/base/internetOfThings/controlCabinet',
    component: dynamic({
      component: () => import(/* webpackChunkName: "modules/base/index3" */ './views/internetOfThings/controlCabinet/index'),
    }),
  },
  //  //智能控制柜详情
  {
    path: '/base/internetOfThings/controlCabinetDetails',
    component: dynamic({
      component: () => import(/* webpackChunkName: "modules/base/index3" */ './views/internetOfThings/controlCabinet/details'),
    }),
  },
  //佩戴传感器
  {
    path: '/base/internetOfThings/wearChuanGanQi',
    component: dynamic({
      component: () => import(/* webpackChunkName: "modules/base/index3" */ './views/internetOfThings/wearChuanGanQi/index'),
    }),
  },
  //虫情传感器
  {
    path: '/base/internetOfThings/pestSensing',
    component: dynamic({
      component: () => import(/* webpackChunkName: "modules/base/index3" */ './views/internetOfThings/pestSensing/index'),
    }),
  },
  //虫情传感器详情
  {
    path: '/base/internetOfThings/pestSensingDetails',
    component: dynamic({
      component: () => import(/* webpackChunkName: "modules/base/index3" */ './views/internetOfThings/pestSensing/details'),
    }),
  },
  //设备管理
  {
    path: '/base/iot/devices',
    component: dynamic({
      component: () => import(/* webpackChunkName: "modules/base/index3" */ './views/internetOfThings/equipment/index'),
    }),
  },
  {
    path: '/base/equipmentManage/warningSet',
    component: dynamic({
      component: () => import(/* webpackChunkName: "modules/base/index3" */ './views/internetOfThings/equipment/warningSet/index'),
    }),
  },
  //相机截图
  {
    path: '/base/internetOfThings/cameraCapture',
    component: dynamic({
      component: () => import(/* webpackChunkName: "modules/base/index3" */ './views/internetOfThings/cameraCapture/index'),
    }),
  },
  {
    //新增相机截图
    path: '/base/internetOfThings/addScreenshots',
    component: dynamic({
      component: () => import(/* webpackChunkName: "modules/platform/views/equipment" */ './views/internetOfThings/cameraCapture/addScreenshots'),
    }),
  },
  {
    //相机失败详情
    path: '/base/internetOfThings/failureDetails',
    component: dynamic({
      component: () => import(/* webpackChunkName: "modules/platform/views/equipment" */ './views/internetOfThings/cameraCapture/failureDetails'),
    }),
  },
  {
    //抓图详情
    path: '/base/internetOfThings/screenshotDetails',
    component: dynamic({
      component: () => import(/* webpackChunkName: "modules/platform/views/equipment" */ './views/internetOfThings/cameraCapture/screenshotDetails'),
    }),
  },
  {
    //视频计划
    path: '/base/internetOfThings/videoPlan',
    component: dynamic({
      component: () => import(/* webpackChunkName: "modules/platform/views/equipment" */ './views/internetOfThings/videoPlan'),
    }),
  },
  {
    //新增视频计划
    path: '/base/internetOfThings/addVideoPlan',
    component: dynamic({
      component: () => import(/* webpackChunkName: "modules/platform/views/equipment" */ './views/internetOfThings/videoPlan/add/addVideoPlan'),
    }),
  },
  {
    //视频失败详情
    path: '/base/internetOfThings/videoFailureDetails',
    component: dynamic({
      component: () => import(/* webpackChunkName: "modules/platform/views/equipment" */ './views/internetOfThings/videoPlan/videoFailureDetails'),
    }),
  },
  {
    //视频计划播放页面
    path: '/base/internetOfThings/videoPlanPlay',
    component: dynamic({
      component: () => import(/* webpackChunkName: "modules/platform/views/equipment" */ './views/internetOfThings/videoPlanPlay'),
    }),
  },
  {
    //错误日志
    path: '/base/internetOfThings/errorLog',
    component: dynamic({
      component: () => import(/* webpackChunkName: "modules/platform/views/equipment" */ './views/internetOfThings/errorLog'),
    }),
  },
  {
    //截图同步日志
    path: '/base/internetOfThings/synchronizationLog',
    component: dynamic({
      component: () => import(/* webpackChunkName: "modules/platform/views/equipment" */ './views/internetOfThings/synchronizationLog'),
    }),
  },
  {
    // 图层管理
    path: '/base/gisTool/mapInformationManagementLayer',
    component: dynamic({
      component: () => import('./views/gisTool/views/layerManagement/index'),
    }),
  },
  {
    //地图绘制
    path: '/base/gisTool/mapInformationManagementLayerDraw',
    component: dynamic({
      component: () => import('./views/gisTool/views/mapDraw/index'),
    }),
  },
  {
    // 地图信息管理平台
    path: '/base/gisTool/mapManagement',
    component: dynamic({
      component: () => import('./views/gisTool/views/mapManagement/index'),
    }),
  },
  // 评价管理
  {
    path: '/base/evaluation/evaluationTemplateManagement',
    component: dynamic({
      component: () => import('./views/evaluation/evaluationTemplateManagement/index'),
    }),
  },
  {
    path: '/base/evaluation/evaluationContentManagement',
    component: dynamic({
      component: () => import('./views/evaluation/evaluationContentManagement/index'),
    }),
  },
  // 平台层
  // {
  //   path: '/platform/visualTool/newh5Tool',
  //   component: dynamic({
  //     component: () => import('../../platform/routes/views/newVisualTool/h5Tool/index'),
  //   }),
  // },
  // {
  //   path: '/platform/visualTool/newh5ToolTemplate',
  //   component: dynamic({
  //     component: () => import('../../platform/routes/views/newVisualTool/h5ToolTemplate/index'),
  //   }),
  // },

  // {
  //   //地图信息管理平台
  //   path: "/base/gisTool/mapInformationManagement",
  //   component: dynamic({
  //     component: () =>
  //       import("./views/gisTool/views/mapInformationManagement/index")
  //   })
  // }
  //工业互联网标识解析---标识记录
  {
    path: "/base/industrialInternet/identificationRecord",
    component: dynamic({
      component: () =>
        import(
          "./views/industrialInternet/identificationRecord"
        )
    })
  },
  //工业互联网标识解析---标识注册概况
  {
    path: "/base/industrialInternet/registrationOverview",
    component: dynamic({
      component: () =>
        import(
          "./views/industrialInternet/registrationOverview"
        )
    })
  },
  // 功能介绍
  {
    path: "/base/intro",
    component: dynamic({
      component: () =>
        import(
          "./views/intro"
        )
    })
  },
  {
    path: "/base/intro/detail",
    component: dynamic({
      component: () =>
        import(
          "./views/intro/detail"
        )
    })
  },
];
