import quality from "./apiModules/quality";
export default {
  // 溯源检测
  quality,
  // 基础信息
  base: {
    // 地块
    landManagement: {
      LIST: '/manbury-bio-technology/api/v1/massif/list',
      ADD: '/manbury-bio-technology/api/v1/massif/save',
      EDIT: '/manbury-bio-technology/api/v1/massif/update',
      DEL: '/manbury-bio-technology/api/v1/massif/delete'
    },
    // 库存
    inventory: {
      LIST: '/manbury-bio-technology/api/v1/storageStock/list',
      DETAIL_LIST: '/manbury-bio-technology/api/v1/storageStock'
    },
    // 产品管理
    product: {
      LIST: '/manbury-bio-technology/api/v1/base-product/list',
      EDIT: '/manbury-bio-technology/api/v1/base-product',
      ADD: '/manbury-bio-technology/api/v1/base-product',
      DISABLE: '/manbury-bio-technology/api/v1/base-product/disable',
      ENABLE: '/manbury-bio-technology/api/v1/base-product/enable'

    },
    material: {
      // 物料分类
      TYPE: '/hydra-intelligent-planting/valid/api/v1/material/sort/list',
      // 物料列表
      LIST: '/hydra-intelligent-planting/valid/api/v1/cultivate-manage/material/list'
    },
    customer: {
      LIST: '/manbury-bio-technology/api/v1/customerManage/list',
      ADD: '/manbury-bio-technology/api/v1/customerManage',
      EDIT: '/manbury-bio-technology/api/v1/customerManage',
      DISABLE: '/manbury-bio-technology/api/v1/customerManage/staus',
      DETAIL: '/manbury-bio-technology/api/v1/customerManage'
    },
    customerClassify: {
      LIST: '/hydra-base-data/api/v1/config/type'
    },
    // 仓库管理
    warehouse: {
      LIST: '/hydra-base-data/api/v1/ware-house/list',
      EDIT: '/hydra-base-data/api/v1/ware-house', // 更新PUT, 删除DELETE, 添加POST
      DISABLE: '/hydra-base-data/api/v1/ware-house/disable',
      ENABLE: '/hydra-base-data/api/v1/ware-house/enable',
      EMPLOYEE: '/hydra-user/api/v1/employee/enable/list',
      CROP_EMPLOYEE: '/hydra-user/api/v1/employee/enable/list/all',
      ENABLE_LIST: '/hydra-base-data/api/v1/ware-house/enable/list',
      // 关联产品
      RELATIVE_PPRODUCE: '/hydra-base-data/api/v1/ware-house/product/ref',
      // 同步第三方数据
      SYNC_DATA: '/hydra-base-data/api/v1/third/ware-house/sync-third'
    },
    // 仓库仓位管理
    storehouse: {
      LIST: '/hydra-base-data/api/v1/store-house/list',
      EDIT: '/hydra-base-data/api/v1/store-house',
      ENABLE: '/hydra-base-data/api/v1/store-house/enable',
      DISABLE: '/hydra-base-data/api/v1/store-house/disable',
      ENABLE_LIST: '/hydra-base-data/api/v1/store-house/enable/list',//可存放库位
    },
  },
  // 田间管理
  fieldManagement: {
    // 种植
    plant: {
      LIST: '/manbury-bio-technology/api/v1/plant-manage/list',
      ADD: '/manbury-bio-technology/api/v1/plant-manage',
      EDIT: '/manbury-bio-technology/api/v1/plant-manage',
      DEL: '/manbury-bio-technology/api/v1/plant-manage',
      DETAIL: '/manbury-bio-technology/api/v1/plant-manage',
      // 同步
      SYNCHRONOUS: '/manbury-bio-technology/api/v1/plant-manage/sync',
      PLANT_LIST: "/manbury-bio-technology/api/v1/plant-manage/massif/list"
    },
    // 管培管理
    cultivate: {
      LIST: "/manbury-bio-technology/api/v1/cultivate-manage/list",
      DEL: '/manbury-bio-technology/api/v1/cultivate-manage',
      CULTIVATE_LIST: '/manbury-bio-technology/api/v1/cultivate-manage/massif/list',
      SYNCHRONOUS: '/manbury-bio-technology/api/v1/cultivate-manage/sync',
      EDIT: '/manbury-bio-technology/api/v1/cultivate-manage',
      ADD: '/manbury-bio-technology/api/v1/cultivate-manage',
      DETAIL: '/manbury-bio-technology/api/v1/cultivate-manage'
    },
    // 采收管理
    harvest: {
      LIST: "/manbury-bio-technology/api/v1/pick-manage/list",
      DEL: '/manbury-bio-technology/api/v1/pick-manage',
      HARVEST_LIST: '/manbury-bio-technology/api/v1/pick-manage/massif/list',
      SYNCHRONOUS: '/manbury-bio-technology/api/v1/pick-manage/sync',
      EDIT: '/manbury-bio-technology/api/v1/pick-manage',
      ADD: '/manbury-bio-technology/api/v1/pick-manage',
      DETAIL: '/manbury-bio-technology/api/v1/pick-manage'
    },
    // 鲜叶入库
    freshLeaves: {
      LIST: '/manbury-bio-technology/api/v1/fresh-tea-leaf/in-storage/list',
      ADD: '/manbury-bio-technology/api/v1/fresh-tea-leaf/in-storage',
      EDIT: '/manbury-bio-technology/api/v1/fresh-tea-leaf/in-storage',
      DETAIL: '/manbury-bio-technology/api/v1/fresh-tea-leaf/in-storage/',
      DEL: '/manbury-bio-technology/api/v1/fresh-tea-leaf/in-storage/',
      // 下拉列表
      DOWN_LOAD:'/manbury-bio-technology/api/v1/fresh-tea-leaf/in-storage/down-load',
      // 下载PDF
      DOWNLOAD:'/manbury-bio-technology/api/v1/fresh-tea-leaf/in-storage/download'

    },
    // 鲜叶出库
    outboundOrder: {
      LIST: '/manbury-bio-technology/api/v1/fresh-tea-leaf/outbound/list',
    }
  },
  // 生产管理
  productionManagement: {
    // 加工管理
    processing: {
      LIST: '/manbury-bio-technology/api/v1/process/list',
      EDIT: '/manbury-bio-technology/api/v1/process',
      ADD: '/manbury-bio-technology/api/v1/process',
      DEL: '/manbury-bio-technology/api/v1/process/'
    },
    // 成品入库
    finishingWarehousing: {
      ADD: '/manbury-bio-technology/api/v1/finished-product/warehousing',
      EDIT: '/manbury-bio-technology/api/v1/finished-product/warehousing',
      LIST: '/manbury-bio-technology/api/v1/finished-product/warehousing/list',
      DEL: "/manbury-bio-technology/api/v1/finished-product/warehousing/"
    },
    // 成品出库单
    finishedProductOutbound: {
      LIST: '/manbury-bio-technology/api/v1/finished-product/outbound/list',
      ADD: '/manbury-bio-technology/api/v1/finished-product/outbound/save',
      DETAIL: '/manbury-bio-technology/api/v1/finished-product/outbound/getById',
      EDIT: '/manbury-bio-technology/api/v1/finished-product/outbound/update'
    }
  },
  // 销售管理
  salesManagement: {
    LIST: '/manbury-bio-technology/api/v1/order/list',
    ADD: '/manbury-bio-technology/api/v1/order/save',
    EDIT: '/manbury-bio-technology/api/v1/order/update',
    DEL: '/manbury-bio-technology/api/v1/order/delete',
    // 下载PDF
    DOWNLOAD:'/manbury-bio-technology/api/v1/order/download'
  },
  // 码校验
  CHECK_CODE: "/manbury-bio-technology/api/v1/finished-product/outbound/check",
}
