import getCustomerConfig from 'public/customerConfig/index.js';
//
const customerConfig = getCustomerConfig();
export const headerHeight = 60;
export const prefix = '/apiInterface/interface';
export const downloadPrefix = '/apiInterface/interface/trace/common/download';
export const amapkey = '12fea8c7f7f0ec1a0a851b89feaf84ea'; //高德地图密钥
export const device = (() => {
  const result = {};
  const ua = window.navigator.userAgent.toLocaleLowerCase();
  result.isIE = ua.indexOf('trident') >= 0; // 是否是IE浏览器
  result.isIE11 = ua.indexOf('trident') >= 0 && ua.indexOf("rv:11.0") >= 0;
  result.isChrome = ua.indexOf('chrome') >= 0; // 是否是谷歌浏览器
  result.isFireFox = ua.indexOf('firefox') >= 0; // 是否是火狐浏览器
  result.isSafari = result.isChrome ? false : ua.indexOf('safari') >= 0; // 是否是Safari浏览器
  result.isWeiXin = ua.indexOf('micromessenger') >= 0; // 是否是微信内置浏览器
  result.isMobile = ua.indexOf('mobile') >= 0; // 是否是移动端浏览器
  return result;
})();
export const themes = ['#3296fa', '#13c2c2', '#fa8c16'];
// 浙农码码链接前缀
export const znmCodeDomain = 'http://h5.znm.zjagri.cn/u/14/';

//
// 是否是本地开发环境
export const isDev =
  location.href.indexOf('http://localhost') === 0 ||
  location.href.indexOf('http://192.168') === 0 ||
  location.href.indexOf('http://127.0.0.1') === 0;
// 是否是远程开发环境
export const isRemoteDev = location.href.indexOf('.kf315.net') >= 0 && location.href.indexOf('dev-') >= 0;
// 是否是测试环境
export const isTesting = !isRemoteDev && location.href.indexOf('.kf315.net') >= 0;
// 是否是浙农码环境
export const isZnmTesting = location.href.indexOf('znm.zjagri.cn') >= 0;
// 是否是生产环境
export const isProduction = !(isDev || isRemoteDev || isTesting || isZnmTesting);
//-------------------------------------------------------------------------------------
// // 本地开发设置
// const devConfig = require('./configModules/dev.js').default;
// // 远程开发环境设置
// const remoteDevConfig = require('./configModules/remoteDev.js').default;
// // 测试环境设置
// const testConfig = require('./configModules/test.js').default;
// // 测试环境设置
// const testznmConfig = require('./configModules/znmTest.js').default;
// // 生产环境设置
// const productionConfig = require('./configModules/production.js').default;
//-------------------------------------------------------------------------------------
let config = {};
// if (isDev) {
//   config = devConfig;
// } else if (isRemoteDev) {
//   config = remoteDevConfig;
// } else if (isTesting) {
//   config = testConfig;
// } else if (isZnmTesting) {
//   config = testznmConfig;
// } else if (isProduction) {
//   config = productionConfig;
// }
export const smSocketUrl = `${location.href.split('//')[1].split('/')[0]}${prefix}/hydra-intelligent-planting/websocket`;
const finalConfig = { ...config, ...customerConfig };
export default finalConfig;
