import {getCookie, removeCookie} from 'framework/utils/cookie';

function setLoginHref() {
  top.location.hash = '#/user/login';
}

export default async (logout, storage, cookie) => {
  const loginFrom = getCookie('loginFrom');
  removeCookie('loginFrom');
  await logout();
  storage.clear();
  cookie.clear();
  setLoginHref(loginFrom);
}
