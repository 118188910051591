export default {
  //基础数据
  userManagement: {
    LIST: '/peixian-huxi-farm/api/v1/server/user-management/list'
  },
  //景点管理
  attractionsManagement: {
    LIST: '/peixian-huxi-farm/api/v1/server/attractions-management/list',
    ADD: '/peixian-huxi-farm/api/v1/server/attractions-management/add',
    EDIT: '/peixian-huxi-farm/api/v1/server/attractions-management/update',
    GET: '/peixian-huxi-farm/api/v1/server/attractions-management/getById',
    DEL: '/peixian-huxi-farm/api/v1/server/attractions-management/delete' 
  }
};
