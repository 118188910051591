export default {
  //获取会员等级列表
  GET_LEVEL_LIST: '/hydra-marketing/api/v1/member/level/list',
  //新增会员等级
  ADD_LEVEL: '/hydra-marketing/api/v1/member/level/add',
  //编辑会员等级
  EDIT_LEVEL: '/hydra-marketing/api/v1/member/level/update',
  //删除会员等级
  DELETE_LEVEL: '/hydra-marketing/api/v1/member/level/delete',
  //获取等级详情
  GET_LEVEL_DETAIL: '/hydra-marketing/api/v1/member/level/detail',
}