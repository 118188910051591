export default {
  // 基地地图配置
  // 获取列表
  LIST: '/hydra-base-data/api/v1/base/map/base-map/list',
  // 添加
  ADD: '/hydra-base-data/api/v1/base/map/base-map',
  // 编辑
  UPDATE: '/hydra-base-data/api/v1/base/map/base-map/update',
  // 详情
  DETAIL: '/hydra-base-data/api/v1/base/map/base-map/detail',
  // 删除
  DELETE: '/hydra-base-data/api/v1/base/map/base-map/',
  // 获取平台组织列表
  GET_ORG_LIST: '/hydra-user/api/v1/org/list/platform',
  // gis地图搜索
  SEARCH_GIS: '/hydra-code-egis/api/v1/geoTools/poi-search-autonavi',
  // 获取组织下的设备列表
  GET_DEVICE_LIST: '/iotdevice/notoken/listByOrgId',
  // 获取平台列表
  GET_IOT_PLATFORM_LIST: '/iotdevice/notoken/monitor/listByOrgId',
  // 获取监控点
  GET_MONITOR_POINTER_LIST: '/iotdevice/notoken/monitor/listDevice'
}
