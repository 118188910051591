import api from 'system/config/systemApi';
import { ajaxSync } from 'framework/utils/ajax';
import _ from 'lodash';

let menuSave = [];

export const loadMenu = async function () {
  if (menuSave.length > 0) {
    return JSON.parse(JSON.stringify(menuSave));
  }
  const { res } = await ajaxSync.post(api.GET_MENU);
  menuSave = _.get(res, 'results.list') || [];
  return menuSave;
};
