import dynamic from "dva/dynamic";

const routeData = [
  // 对象管理----------------------------------------------------
  // 人员管理
  {
    path: "/trace/target/member",
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/trace/views/target" */ "./views/target/member"
        )
    })
  },
  // 产品管理
  {
    path: "/trace/target/product",
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/trace/views/target" */ "./views/target/product"
        )
    })
  },
  // 批次管理
  {
    path: "/trace/batch",
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/trace/views/batch" */ "./views/batch"
        )
    })
  },
  {
    path: "/trace/batchH5",
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/trace/views/batch" */ "./views/batch/H5"
        )
    })
  },
  // 码关联
  {
    path: "/trace/relevance",
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/trace/views/relevance" */ "./views/relevance/list"
        )
    })
  },
  // 码关联
  {
    path: "/trace/addRelationSJ",
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/trace/views/relevance" */ "./views/relevance/addrelation/SJ"
        )
    })
  },
  // 三江码关联
  {
    path: "/trace/addRelation",
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/trace/views/relevance" */ "./views/relevance/addrelation"
        )
    })
  },
  // 地块批次管理
  {
    path: "/trace/massifbatch",
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/trace/views/batch" */ "./views/massifbatch"
        )
    })
  },
  // 溯源记录
  {
    path: "/trace/record",
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/trace/views/record" */ "./views/record"
        )
    })
  },
  // 溯源模版
  {
    path: "/trace/template",
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/trace/views/template" */ "./views/template"
        )
    })
  },
  // 生产管理
  {
    path: "/trace/product/process/:id",
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/trace/views/product/process" */ "./views/product/process"
        )
    })
  },
  // 区块链
  {
    path: "/trace/block/list",
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/trace/views/block" */ "./views/block/list"
        )
    })
  },
  // 区块链详情
  {
    path: "/trace/block/detail/:id",
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/trace/views/block" */ "./views/block/detail"
        )
    })
  },
  // 区块链 3.0
  {
    path: "/trace/block3/list",
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/trace/views/block" */ "./views/block3/list"
        )
    })
  },
  // 区块链详情 3.0
  {
    path: "/trace/block3/detail/:id",
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/trace/views/block" */ "./views/block3/detail"
        )
    })
  },
  // 品质监管
  // 检测项管理
  {
    path: "/trace/checkItems",
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/trace/views/quality" */ "./views/quality/checkItems"
        )
    })
  },
  // 检测标准管理
  {
    path: "/trace/testStandard",
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/trace/views/quality" */ "./views/quality/testStandard"
        )
    })
  },
  // 内部检测
  {
    path: "/trace/insideCheck",
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/trace/views/quality" */ "./views/quality/insideCheck"
        )
    })
  },
  // 第三方检测
  {
    path: "/trace/thirdPartyCheck",
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/trace/views/quality" */ "./views/quality/thirdPartyCheck"
        )
    })
  },
  // 存放点管理
  {
    path: "/trace/storagePlace",
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/trace/views/storagePlace/list" */ "./views/storagePlace/list"
        )
    })
  },
  // 合格证打印记录
  {
    path: "/trace/listPrint",
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/trace/views/certificate" */ "./views/certificate/listPrint"
        )
    })
  },
  // 产品合格证
  {
    path: "/trace/printCertificate",
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/trace/views/certificate" */ "./views/certificate/printCertificate"
        )
    })
  },
  // 新增产品合格证
  {
    path: "/trace/certificateAdd",
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/trace/views/certificate" */ "./views/certificate/printCertificate/pages/add"
        )
    })
  },
  // 编辑产品合格证
  {
    path: "/trace/certificateEdit",
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/trace/views/certificate" */ "./views/certificate/printCertificate/pages/edit"
        )
    })
  },
  // 合格证模板
  {
    path: "/trace/certificateTemplate",
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/trace/views/certificate" */ "./views/certificate/template/list"
        )
    })
  },
  // 新增合格证模板
  {
    path: "/trace/certificate/addTemplate",
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/trace/views/certificate" */ "./views/certificate/template/addTemplate"
        )
    })
  },
  // 合格证打印记录
  {
    path: "/trace/certificate/statistics/printRecord",
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/trace/views/certificate" */ "./views/certificate/statistics/printRecord"
        )
    })
  },
  // 合格证使用记录
  {
    path: "/trace/certificate/statistics/used",
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/trace/views/certificate" */ "./views/certificate/statistics/used"
        )
    })
  },
  // 合格证码数量
  {
    path: "/trace/certificate/statistics/codeNum",
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/trace/views/certificate" */ "./views/certificate/statistics/codeNum"
        )
    })
  },
  // 合格证申请记录
  {
    path: "/trace/certificate/statistics/applyRecord",
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/trace/views/certificate" */ "./views/certificate/statistics/applyRecord"
        )
    })
  },
  // 可信溯源时间戳
  {
    path: "/trace/trusted/timestamping",
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/trace/views/trusted" */ "./views/trusted/timestamping"
        )
    })
  },
  // 可信溯源时间戳 -> 查看
  {
    path: "/trace/trusted/timestampingDetail",
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/trace/views/trusted" */ "./views/trusted/timestamping/detail"
        )
    })
  },
  // 保险理赔相关
  {
    path: "/trace/insurance/claim",
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/trace/views/trusted" */ "./views/insurance/claim"
        )
    })
  },
  {
    path: "/trace/insurance/edit",
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/trace/views/trusted" */ "./views/insurance/edit"
        )
    })
  },
  {
    path: "/trace/insurance/claimDetail",
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/trace/views/trusted" */ "./views/insurance/claim/detail"
        )
    })
  },
  {
    path: "/trace/insurance/claimRecord",
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/trace/views/trusted" */ "./views/insurance/claim/record"
        )
    })
  },
  {
    path: "/trace/insurance/claimRecords/detail",
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/trace/views/trusted" */ "./views/insurance/claim/record/detail"
        )
    })
  },
  {
    path: "/trace/insurance/company",
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/trace/views/trusted" */ "./views/insurance/company"
        )
    })
  },
  {
    path: "/trace/insurance/companyRecord",
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/trace/views/trusted" */ "./views/insurance/company/record"
        )
    })
  },
  {
    path: "/trace/insurance/record",
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/trace/views/trusted" */ "./views/insurance/record"
        )
    })
  },
  {
    path: "/trace/skftrace",
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/trace/views/trusted" */ "./views/skf"
        )
    })
  },
  //平邑县溯源统计
  {
    path: "/trace/pyxtrace",
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/trace/views/trusted" */ "./views/pyx"
        )
    })
  },
  //包装关联-NFC
  {
    path: "/trace/packageConnect",
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/trace/views/trusted" */ "./views/packageConnect"
        )
    })
  },
  //溯源查询记录
  {
    path: "/trace/seeRecord",
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/trace/views/trusted" */ "./views/seeRecord"
        )
    })
  },

];
export default routeData;
