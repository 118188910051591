export default {
  //管理员设置
  adminSet: {
    GET_LIST:"/hydra-tongshanyaki/api/admin/list",
    SAVE:"/hydra-tongshanyaki/api/admin",
    GET:"/hydra-tongshanyaki/api/admin",
    UPDATE:"/hydra-tongshanyaki/api/admin",
    DISABLE:"/hydra-tongshanyaki/api/admin/operation/disableflag",
    ENABLE:"/hydra-tongshanyaki/api/admin/operation/disableflag"
  },
  auditRecords:{
    GET_LIST:"/hydra-tongshanyaki/api/user/materials/audit/record/list",
  }
};
