import dynamic from 'dva/dynamic';

const routeData = [
  // 防伪查询结果
  {
    path: '/antiFake/queryResult/list',
    component: dynamic({ component: () => import(/* webpackChunkName: "modules/antiFake/queryResult" */ './views/queryResult/list') }),
  },
  // 获取当前扫码记录详情
  {
    path: '/antiFake/queryResult/details/:id',
    component: dynamic({ component: () => import(/* webpackChunkName: "modules/antiFake/queryResult/list/details" */ './views/queryResult/list/details') }),
  },
  // 所有扫码记录
  {
    path: '/antiFake/queryRecord',
    component: dynamic({ component: () => import(/* webpackChunkName: "modules/antiFake/queryRecord" */ './views/queryRecord/list') }),
  },
  // 防伪设置
  {
    path: '/antiFake/codeSecurity',
    component: dynamic({ component: () => import(/* webpackChunkName: "modules/antiFake/setting" */ './views/setting') }),
    exact: true
  },
  //防伪配置--编辑
  {
    path: '/antiFake/setting/edit/:id',
    component: dynamic({ component: () => import(/* webpackChunkName: "modules/antiFake/setting" */ './views/setting/edit') }),
  },
  // 生码管理
  {
    path: '/antiFake/codeGeneration',
    component: dynamic({ component: () => import(/* webpackChunkName: "modules/antiFake/codeGeneration" */ './views/codeGeneration/list') }),
  },
  // 数据分析
  {
    path: '/antiFake/analysis',
    component: dynamic({ component: () => import(/* webpackChunkName: "modules/antiFake/codeGeneration" */ './views/analysis') }),
  },
  // 数据大屏
  {
    path: '/antiFake/dataScreen',
    component: dynamic({ component: () => import(/* webpackChunkName: "modules/antiFake/codeGeneration" */ './views/dataScreen') }),
  },
  // 电子存在
  {
    path: '/antiFake/certificate',
    component: dynamic({ component: () => import(/* webpackChunkName: "modules/antiFake/codeGeneration" */ './views/certificate') }),
  },
];
export default routeData;
