export default {
  // 站点端
  // 站点端首页
  SiteHomePage:{
    //获取累计数据
    GET_ACCMULATE:'/hydra-longshan-report/hydra-longshan-report/api/v1/data-chart/combined',
    //获取代买代卖的数据
    GET_BUG:'/hydra-longshan-report/hydra-longshan-report/api/v1/data-chart/order/buy/sale',
    //获取订单分析数据
    GET_ORDERDATA:'/hydra-longshan-report/hydra-longshan-report/api/v1/data-chart/order/analysis',
    //代买商品TOP10
    GET_BUGTOP:'/hydra-longshan-report/hydra-longshan-report/api/v1/data-chart/order/buy-rank',
    //代卖商品TOP10
    GET_SELLTOP:'/hydra-longshan-report/hydra-longshan-report/api/v1/data-chart/order/sale-rank',
  },
  // 订单管理
  order: {
    LIST: '/hydra-longshan-report/hydra-longshan-report/api/v1/order/list',
    ADD: '/hydra-longshan-report/hydra-longshan-report/api/v1/order/add',
    DETAIL: '/hydra-longshan-report/hydra-longshan-report/api/v1/order/detail',
    EDIT: '/hydra-longshan-report/hydra-longshan-report/api/v1/order/update',
    DELETE: '/hydra-longshan-report/hydra-longshan-report/api/v1/order/delete',
    EXPORT: '/hydra-longshan-report/hydra-longshan-report/api/v1/order/export',
  },
  // 产品分类
  productSort: {
    FIRST_SORT: '/hydra-base-data/api/v1/product-sort/firsts',
    CHILD_SORT: '/hydra-base-data/api/v1/product-sort/childs',
    // 获取一级和二级类目总和
    GET_ONE_TWO_CATEGORY: '/hydra-longshan-report/hydra-longshan-report/api/v1/category/all'
  },
  // 数据上报
  siteDataReport: {
    GET: '/hydra-longshan-report/hydra-longshan-report/api/v1/site-data-report/data',
    REPORT: '/hydra-longshan-report/hydra-longshan-report/api/v1/site-data-report/report'
  },
  // 数据统计
  dataAnalysis: {
    GET: '/hydra-longshan-report/hydra-longshan-report/api/v1/data-chart/data/reported'
  },
  // 管理端
  homePage:{
    //获取累计数据
    GET_ACCMULATE:'/hydra-longshan-report/hydra-longshan-report/api/v1/manager/statistics/total',
    //昨日统计
    GET_TOTALTODAY:'/hydra-longshan-report/hydra-longshan-report/api/v1/manager/statistics/yesterday',
    //获取代买代卖的数据
    GET_BUG:'/hydra-longshan-report/hydra-longshan-report/api/v1/manager/statistics/proportion',
    //获取订单分析数据
    GET_ORDERDATA:'/hydra-longshan-report/hydra-longshan-report/api/v1/manager/statistics/order-analysis',
    //代买商品TOP10
    GET_BUGTOP:'/hydra-longshan-report/hydra-longshan-report/api/v1/manager/statistics/buy-rank',
    //代卖商品TOP10
    GET_SELLTOP:'/hydra-longshan-report/hydra-longshan-report/api/v1/manager/statistics/sell-rank',
     //代买商品站点TOP10
    GET_BUGSITETOP:'/hydra-longshan-report/hydra-longshan-report/api/v1/manager/statistics/site-buy-rank',
     //代卖商品站点TOP10
    GET_SELLSITETOP:'/hydra-longshan-report/hydra-longshan-report/api/v1/manager/statistics/site-sell-rank'
  },
  // 数据上报
  manageDataReport: {
    GET: '/hydra-longshan-report/hydra-longshan-report/api/v1/manager/client/data',
    REPORT: '/hydra-longshan-report/hydra-longshan-report/api/v1/manager/client/report'
  },
  //站点管理
  siteManagement:{
    LIST: '/hydra-longshan-report/hydra-longshan-report/api/v1/site/pages',
    ADD: '/hydra-longshan-report/hydra-longshan-report/api/v1/site/add',
    DETAIL: '/hydra-longshan-report/hydra-longshan-report/api/v1/site/detail',
    UPDATE:'/hydra-longshan-report/hydra-longshan-report/api/v1/site/update',
    IMPORT:'/hydra-longshan-report/hydra-longshan-report/api/v1/site/import',
    EXPORT:'/hydra-longshan-report/hydra-longshan-report/api/v1/site/export',
    DISABLED:'/hydra-longshan-report/hydra-longshan-report/api/v1/site/enable',
    //获取员工账号
    GET_USER:'/hydra-user/api/v1/employee/list',
    //账号关联
    ASSOICATED:'/hydra-longshan-report/hydra-longshan-report/api/v1/site/bind-user',
     //账号关联详情
    ASSOICATED_DETAIL:'/hydra-longshan-report/hydra-longshan-report/api/v1/site/bind-user/detail',
    // 判断用户是否关联站点或站点是否被禁用
    CHECK_IF_SITE: '/hydra-longshan-report/hydra-longshan-report/api/v1/user/site-info/check',
    // 站点删除
    DELETE: '/hydra-longshan-report/hydra-longshan-report/api/v1/site/delete'
  },
  // 日报查询
  dailyReportQuery: {
    GET: '/hydra-longshan-report/hydra-longshan-report/api/v1/manager/client/query'
  },
  // 站点数据
  siteData: {
    LIST: '/hydra-longshan-report/hydra-longshan-report/api/v1/manager/client/site-data/pages',
    DETAIL: '/hydra-longshan-report/hydra-longshan-report/api/v1/manager/client/detail/pages',
    EXPORT: '/hydra-longshan-report/hydra-longshan-report/api/v1/manager/list/export',
    DETAIL_EXPORT: '/hydra-longshan-report/hydra-longshan-report/api/v1/report/detail/export'
  }
}
