/*
 * @Author: duyaoyao
 * @Date: 2021-09-26 09:42:14
 * @LastEditors: duyaoyao
 * @LastEditTime: 2021-09-26 10:39:11
 */
export default {
  //获取组织关系树,带搜索
  GET_ORG_TREE: "/hydra-user/api/v1/org/breed/relation/tree",
  ADD_RELATION: "/hydra-user/api/v1/org/breed/relation",
  EDIT_RELATION: "/hydra-user/api/v1/org/breed/relation",
  DEL_RELATION: "/hydra-user/api/v1/org/breed/relation"
};
