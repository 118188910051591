export default {
  //贫困户管理
  povertyFamily: {
    GET_LIST: '/hydra-poverty-alleviation/api/v1/pooHouseholdManagement/list',
    GET_SINGLE: '/hydra-poverty-alleviation/api/v1/pooHouseholdManagement',
    IMPORT: '/hydra-poverty-alleviation/api/v1/pooHouseholdManagement/import',
    EXPORT: '/hydra-poverty-alleviation/api/v1/pooHouseholdManagement/downGuiDunCun',
    SET_LIST: '/hydra-poverty-alleviation/api/v1/pooHouseholdManagement/setPovertyAlleviation',
    SYNC_ALL: '/hydra-poverty-alleviation/api/v1/pooHouseholdManagement/synchronizedPovertyAlleviation',
    GUIDUN_IMPORT: '/hydra-poverty-alleviation/api/v1/pooHouseholdManagement/importGuiDunCun',
  },
  //扶贫记录
  povertyRecord: {
    GET_LIST: '/hydra-poverty-alleviation/api/v1/povertyAlleviation/list',
    GET_SINGLE: '/hydra-poverty-alleviation/api/v1/povertyAlleviation',
    IMPORT: '/hydra-poverty-alleviation/api/v1/povertyAlleviation/importPovertyAlleviation',
    EXPORT: '/hydra-poverty-alleviation/api/v1/povertyAlleviation/down'
    // ADD:'/hydra-poverty-alleviation/api/v1/povertyAlleviation',

  },
  //劳动岗位
  laborpost: {
    GET_LIST: '/hydra-poverty-alleviation/api/v1/laborpost/list',
    GET_SINGLE: '/hydra-poverty-alleviation/api/v1/laborpost',
    DISABLE: '/hydra-poverty-alleviation/api/v1/laborpost/disable',
  },
  //劳动收入
  povertyIncome: {
    GET_LIST: '/hydra-poverty-alleviation/api/v1/povertyIncome/list',
    GET_SINGLE: '/hydra-poverty-alleviation/api/v1/povertyIncome',
    IMPORT: '/hydra-poverty-alleviation/api/v1/povertyIncome/importPovertyIncome',
    EXPORT: '/hydra-poverty-alleviation/api/v1/povertyIncome/down'
  },
  //获取组织列表
  povertyOrg: {
    // LIST: '/regulation/registration/enterprise/gov/all/list',
    LIST: '/regulation/registration/enterprise/org/list',
    EXPORT: '/hydra-poverty-alleviation/api/v1/povertyAlleviation/exportEnterprises'

  },
  //获取追溯产品列表
  product: {
    LIST: '/hydra-base-data/api/v1/product/enable/list',
    // LIST: '/hydra-base-data/api/v1/product/list',
    BATCH_LIST: '/hydra-base-data/api/v1/product-batch/batch/list',
    GET_ONE: '/hydra-base-data/api/v1/product-batch/getByBatchId'
  },
  //产业介绍
  industryIntroduce: {
    GET_LIST: '/hydra-poverty-alleviation/api/v1/industryIntroduce/list',
    GET_SINGLE: '/hydra-poverty-alleviation/api/v1/industryIntroduce',
  },
  //乡镇设置
  townShip: {
    GET_LIST: '/hydra-poverty-alleviation/api/v1/townShip/list',
    GET_SINGLE: '/hydra-poverty-alleviation/api/v1/townShip',
    DISABLE: '/hydra-poverty-alleviation/api/v1/townShip/disable',
  },
  //脱贫情况设置
  outOfPoverty: {
    GET_LIST: '/hydra-poverty-alleviation/api/v1/shakePovertySetting',
  },
  //普通帮扶
  generalPoverty: {
    LIST: '/hydra-poverty-alleviation/api/v1/help-record/list',
    ADD: '/hydra-poverty-alleviation/api/v1/help-record/add',
    EDIT: '/hydra-poverty-alleviation/api/v1/help-record/update',
    DEL: '/hydra-poverty-alleviation/api/v1/help-record/delete',
    DETAIL: '/hydra-poverty-alleviation/api/v1/help-record/get',
    IMPORT: '/hydra-poverty-alleviation/api/v1/help-record/import',
    EXPORT: '/hydra-poverty-alleviation/api/v1/help-record/down',
    GET_DROP_LIST: '/hydra-poverty-alleviation/api/v1/drop-down/list',
  }
};
