export default {
  USER_CHECK_PLATFORM: '/hydra-user/api/v1/userAdmin/checkadmit',
  GET_LIST: '/hydra-user/api/v1/user/list', //获取用户管理列表
  DISABLED: '/hydra-user/api/v1/user/account/info/update-status',
  ENABLE: '/hydra-user/api/v1/user/account/info/update-status',
  // 通过用户编号获取用户账户详细信息
  GET_ACCOUNT_DETAIL: '/hydra-user/api/v1/user/account/info/byuserid',
  // 获取用户基本信息
  GET_USER_BASE_INFO: '/hydra-user/api/v1/user/base',
  // 修改用户信息
  UPDATE: '/hydra-user/api/v1/user/base/platform',
  //短信模板
  sms: {
    GET_LIST: '/hydra-open-thirdpart-service/hydra-open-third-party/api/v1/sms-template/page',
    SAVE: '/hydra-open-thirdpart-service/hydra-open-third-party/api/v1/sms-template',
    UPDATE: '/hydra-open-thirdpart-service/hydra-open-third-party/api/v1/sms-template',
    DELETE: '/hydra-open-thirdpart-service/hydra-open-third-party/api/v1/sms-template'
  },
  token: {
    GET_LIST: '/hydra-user/api/v1/filter/auth/list',
    SAVE: '/hydra-user/api/v1/filter/auth/apply/long-token',
    GET_SYS_LIST: '/hydra-user/api/v1/sys/org/v2/list',
    ENABLE_STATUS: '/hydra-user/api/v1/filter/auth/status'
  }
};
