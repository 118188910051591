import fastMail from "./fastMail";

export default {
  fastMail,
  common: {
    CODE: "/logistics/common/outer/code", // 验证码是否存在，并验证该码是否属于该组织
    CUSTOMER: "/hydra-base-data/api/v1/customer/list", // 客户列表， 收货方
    BACKCUSTOMER: "/hydra-base-data/api/v1/customer/next-child",
    ALL_CUSTOMER: "/hydra-base-data/api/v1/customer/all-enable-child", //一个客户下的所有子客户
    PRODUCT_WARE_HOUSE: "/hydra-base-data/api/v1/product-ware-house/product", // 获取选中产品是否有仓储信息
    PRODUCT_SORT_FIRSTS: "/hydra-base-data/api/v1/product-sort/firsts", // 获取产品分类
    PRODUCT_SORT_CHILD: "/hydra-base-data/api/v1/product-sort/childs", // 获取产品子分类
    PRODUCT_ENABLE_LIST:
      "/hydra-base-data/api/v1/product/enable/ware-house/list", // 获取关联了仓储信息的产品
    PRODUCT_SORT_LIST: "/hydra-base-data/api/v1/product/sort/list", // 根据产品分类，获取产品列表,可做下拉
    PRODUCT_BATCH_LIST: "/hydra-base-data/api/v1/product-batch/batch/list", // 通过产品id获取产品批次
    // 获取启用的产品，可通过产品名称模糊搜索
    GET_PRODUCT_LIST: "/hydra-base-data/api/v1/product/enable/like/name/list",
    // 搜索产品批次
    GET_BATCH_LIST: "/hydra-base-data/api/v1/product-batch/list",
    WARE_HOUSE_LIST: "/hydra-base-data/api/v1/ware-house/enable/list", // 获取启用的（搜索）仓库列表
    STORE_HOUSE_LIST: "/hydra-base-data/api/v1/store-house/enable/list", // 通过仓库id获取仓位列表
    SWEEP_PRODUCT_INFO: "/hydra-base-data/api/v1/product/code", // 获取单一产品(不拦截，不需要权限，不需要登录)
    CHECK_CODE: "/logistics/purchasesale/checkOuterCodeId", //检查码属不属于客户

    GET_COMPANY: "/hydra-base-data/api/v1/shipper/company/list", //获取物流公司
    GET_COMPANY2: "/hydra-base-data/api/v1/shipper/company/list", //获取物流公司--扫码出库
    GET_SCANCODE_CONFIG: "/logistics/sweep/config", //获取出入库扫码配置
    // 获取员工信息列表
    GET_EMPLOYEE_LIST: "/hydra-user/api/v1/employee/enable/list",
    // 获取产品分类
    GET_PRODUCT_SORT: "/hydra-base-data/api/v1/product-sort/all",
    // 获取启用的仓位列表
    GET_STOREHOUSE_LIST: "/hydra-base-data/api/v1/store-house/enable/page/list",
    // 获取供应商列表
    GET_SUPPER_LIST: "/hydra-base-data/api/v1/supper/select/list",
    // 获取产品仓储信息
    GET_PRODUCT_WHEARHOUSE: "/hydra-base-data/api/v1/product-ware-house",
    // 获取客户的所有子客户
    GET_CUSTOMER_CHILDREN: "/hydra-base-data/api/v1/customer/all-enable-child",
  },
  // 仓库管理
  warehouse: {
    LIST: "/hydra-base-data/api/v1/ware-house/list",
    EDIT: "/hydra-base-data/api/v1/ware-house", // 更新PUT, 删除DELETE, 添加POST
    DISABLE: "/hydra-base-data/api/v1/ware-house/disable",
    ENABLE: "/hydra-base-data/api/v1/ware-house/enable",
    EMPLOYEE: "/hydra-user/api/v1/employee/enable/list",
    CROP_EMPLOYEE: "/hydra-user/api/v1/employee/enable/list/all",
    ENABLE_LIST: "/hydra-base-data/api/v1/ware-house/enable/list",
    // 关联产品
    RELATIVE_PPRODUCE: "/hydra-base-data/api/v1/ware-house/product/ref",
    // 同步第三方数据
    SYNC_DATA: "/hydra-base-data/api/v1/third/ware-house/sync-third",
  },
  // 仓库仓位管理
  storehouse: {
    LIST: "/hydra-base-data/api/v1/store-house/list",
    EDIT: "/hydra-base-data/api/v1/store-house",
    ENABLE: "/hydra-base-data/api/v1/store-house/enable",
    DISABLE: "/hydra-base-data/api/v1/store-house/disable",
    ENABLE_LIST: "/hydra-base-data/api/v1/store-house/enable/list", //可存放库位
  },
  // 包装关联查询(装箱)
  pack: {
    GET_CONFIG: "/package-config", // 包装关联配置
    LIST: "/logistics/package/search",
    EDIT: "/logistics/package",
  },
  // 包装关联查询(拆箱)
  split: {
    LIST: "/logistics/split/search",
    EDIT: "/logistics/split",
  },
  // 关联查询
  search: {
    SEARCH: "/logistics/relation/search",
    GET_SON: "/logistics/relation/search/son", // 根据父码查询所有子码信息
  },
  // 入库管理
  put: {
    /** **** 入库单 ***** */
    LIST: "/logistics/ware-house-in/list", // 获取入库单列表
    FAIL: "/logistics/ware-house-in/fail", // 作废
    FAIL_DEL: "/logistics/qnz/doc/ware-house-in/fail",
    PASS: "/logistics/ware-house-in/pass", // 通过审核
    UNPASS: "/logistics/ware-house-in/unpass", // 不通过审核
    WARE_HOUSE_IN: "/logistics/ware-house-in", // 获取入库单信息
    // 同步单据
    SYNC_INVOICES: "/logistics/third/party/data/sync/ware-house-in",

    /** **** 扫码入库 ***** */
    SWEEP: "/logistics/sweep-in/house/sweep-not-package", // 扫码入库
    PACK_SWEEP: "/logistics/sweep-in/house/sweep-package", // 扫码入库,先装后扫
    SWEEP_SEARCH: "/logistics/sweep-in/house/search", // 扫码入库信息查询
    SWEEP_CODE: "/logistics/sweep-in/house/code/list", // 根据扫码入库产品唯一序列号查询所有的码信息
    WARE_HOUSE_IN_PRODUCT: "/logistics/ware-house-in/product", // 根据入库单号与产品id获取扫码入库产品的信息
    LIST_EXPORT: "/logistics/sweep-in/house/export", //扫码入库信息导出

    /** **** 入库统计 ***** */
    TOTAL: "/logistics/sweep-in/statis", // 入库统计列表
    TOTAL_ONE: "/logistics/sweep-in/statis/product", // 单个产品入库明细
    TOTAL_EXPORT: "/logistics/sweep-in/statis/export",

    /** **** 验证扫码入库的码是否有误 ***** */
    CODE: "/logistics/sweep/in/check",

    // 入库明细
    DETAIL_LIST: "/logistics/sweep-in/house/details/list",
    // 入库明细导出
    DETAIL_EXPORT: "/logistics/sweep-in/house/details/export",
    // 千年舟详情
    DETAIL_QNZ: "/logistics/qnz/doc/ware-house-in",
    // 千年舟导出
    QNZ_EXPORT: "/logistics/qnz/doc/ware-house-in/export",
  },

  // 出库管理
  out: {
    /** **** 出库单 ***** */
    LIST: "/logistics/ware-house-out/list", // 获取出库单列表
    FAIL: "/logistics/ware-house-out/fail", // 作废
    PASS: "/logistics/ware-house-out/pass", // 通过审核
    UNPASS: "/logistics/ware-house-out/unpass", // 不通过审核
    WARE_HOUSE_OUT: "/logistics/ware-house-out", // 获取出库单信息
    SYNC_INVOICES: "/logistics/third/party/data/sync/ware-house-out",
    IMPORT: "/logistics/ware-house-out/import-sweep-house",
    TOTAL_EXPORT: "/logistics/sweep-out/statis/export",
    // 标识检错列表
    IDENTY_RECOVERY_LIST: "/hydra-production/api/v1/label/correction/page/list",
    //标识检错提交
    CODE_CORRECT: "/logistics/correct/code/correct",
    // 出库单-千年舟
    invoices: {
      DETAIL: "/logistics/qnz/doc/out",
      LIST: "/logistics/qnz/doc/out/list",
      INVALID: "/logistics/qnz/doc/out/fail",
      EXPORT: "/logistics/qnz/doc/out/export",
    },

    /** **** 扫码出库 ***** */
    SWEEP: "/logistics/sweep-out/house/code", // 扫码出库
    SWEEP_OLD: "/logistics/sweep/out", // 扫码出库-老接口
    SWEEP_DIRECT: "/logistics/sweep-out/house/product-direct-out", // 扫码出库--直接出库
    SWEEP_DIRECT_NEW: "/logistics/sweep-out/house/no-check-product", // 扫码出库--直接出库---不校验产品
    SWEEP_SEARCH: "/logistics/sweep-out/house/list", // 扫码出库单列表--新接口
    SWEEP_SEARCH2: "/logistics/sweep/out/search", // 扫码出库产品信息查询列表
    SWEEP_CODE: "/logistics/sweep-out/house/detail", // 根据扫码出库产品唯一序列号查询所有的码信息
    SWEEP_CODE2: "/logistics/sweep/out/code/list", // 根据扫码出库产品唯一序列号查询所有的码信息
    WARE_HOUSE_OUT_PRODUCT: "/logistics/ware-house-out/product", // 根据入库单号与产品id获取扫码出库产品的信息
    LIST_EXPORT: "/logistics/sweep-out/house/export", //导出
    LIST_EXPORT2: "/logistics/sweep/out/export", //导出--直接出库
    GET_CODE_LIST: "/logistics/sweep-out/house/code/list", //
    GET_CODE_PROJECT: "/logistics/sweep-out/house/code-product", //根据码获获取产品信息

    /** **** 出库统计 ***** */
    TOTAL: "/logistics/sweep-out/statis", // 出库统计列表
    TOTAL_ONE: "/logistics/sweep-out/statis/product", // 单个产品出库明细
    TOTAL_EXPORT: "/logistics/sweep-out/statis/export",
    /** **** 验证扫码出库的码是否有误 ***** */
    CODE: "/logistics/sweep/out/check",

    // 出库明细
    DETAIL_LIST: "/logistics/sweep-out/house/details/list",
    // 出库明细导出
    DETAIL_EXPORT: "/logistics/sweep-out/house/details/export",
    // 出库修改
    DETAIL_UPDATE:"/logistics/qnz/doc/out/update/product"
  },
  // 库存明细
  inventory: {
    // 库存汇总
    LIST: "/logistics/product-current",
    ONE: "/logistics/product-current/product",
    CODE_ONE: "/logistics/product-current/code",
    // 导出
    EXPORT: "/logistics/product-current/export",
    // 库存汇总详情导出
    ONE_EXPORT: "/logistics/product-current/product/export",

    // 库存明细新
    DETAIL_LIST: "/logistics/code-current",
    // 库存明细新-导出
    DETAIL_EXPORT: "/logistics/code-current/export",
  },
  // 流向查询
  flow: {
    QUERY: "/logistics/flow/query/v2030",
  },
  // 流向查询
  flowtask: {
    LIST: "/logistics/house-list-code-flow-task/list",
    REDO: "/logistics/house-list-code-flow-task/redo",
  },
  //退货管理
  back: {
    BACK: "/logistics/goods/return",
    BACK_LIST: "/logistics/goods/return/flow",
    EXPORT: "/logistics/goods/return/export",
  },
  // 调货管理
  cargo: {
    CARGO: "/logistics/goods/out",
    CARGO_LIST: "/logistics/goods/out/flow",
    EXPORT: "/logistics/goods/out/export",
  },
  // 调仓管理
  house: {
    HOUSE: "/logistics/house/out",
    HOUSE_LIST: "/logistics/house/out/flow",
    EXPORT: "/logistics/house/out/export",
    UPDATE:"/logistics/qnz/doc/house-out/update/product"
  },
  // 窜货
  fleeGoods: {
    RULES: "/goods/regulation/getRuleDetail", //获取规则
    SET_RULES: "/goods/regulation/setRule", //设置规则
    QUERY: "/goods/query/v2030", //窜货查询
    RECORD: "/goods/record/getFleeGoodRecords", //窜货记录
    SET: "/goods/query/setSuspectFleeGood", //疑似窜货

    //分析
    DEALERS: "/goods/analysis/getRecordOrder", //经销商排名
    BASIC_DATA: "/goods/analysis/getBasicAnalysis", //基础数据
    RISK: "/goods/analysis/getRiskAssessment", //风险评估
    FLEE_MAP: "/goods/analysis/getMapData", //全国窜货
    DETAIL_MAP: "/goods/analysis/getMapDataDetail", //某一省窜货数据详细信息
    TREND: "/goods/analysis/getPeriodicAnalysis", //周期窜货
  },
  // 错误日志
  errorLog: {
    GET_LIST: "/logistics/logger/error",
    ERROR_EXPORT: "/logistics/logger/error/export",
    // 获取错误日志类型
    GET_TYPE_LIST: "/logistics/logger/type",
    // 千年舟
    LIST_QNZ: "/logistics/callback/list",
    EXPORT_QNZ: "/logistics/callback/export",
    REDO_QNZ: "/logistics/callback/redo",
  },
  // 在库关联拆解
  dismantle: {
    // 条件分页查询在库拆解流水信息列表
    GET_LIST: "/logistics/disassemblybox/split-code/list",
    // 在库拆解导出
    EXPORT: "/logistics/disassemblybox/split-code/export",
    // 拆解关联
    ADD: "/logistics/disassemblybox/split-code",
    // 装箱入库列表-在库关联
    GET_PACK_LIST: "/logistics/disassemblybox/package-code/list",
    // 导出装箱入库
    EXPORT_PACK: "/logistics/disassemblybox/package-code/export",
    // 装箱入库添加
    PACKIN_ADD: "/logistics/disassemblybox/package-code",
    // 补码入箱列表
    GET_DISASSEMBLYBOX_LIST: "/logistics/disassemblybox/repair-code/list",
    // 补码入箱导出
    GET_DISASSEMBLYBOX_EXPORT: "/logistics/disassemblybox/repair-code/export",
    // 补码入箱添加
    SUPPLEMENTS_ADD: "/logistics/disassemblybox/repair-code",
    // 根据码获取对应的产品和仓储信息
    GET_PRODUCT_WARE: "/logistics/disassemblybox/get-product-ware",
    // 校验父码是否正确
    GET_PACK_PARSEINT:
      "/hydra-production/api/v1/code/package/check/package/parentCode",
    // 校验码列表是否正确
    VALID_CODE_LIST:
      "/hydra-production/api/v1/code/package/check/package/sonCodeList",
  },
  //调货单
  transferOrder: {
    GET_LIST: "/logistics/goods/out/order-list",
    //调出客户
    GET_OUTCUSTOMERS: "/hydra-base-data/api/v1/pda/base/customer/next-child",
    //选择产品
    GET_PRODUCT: "/hydra-base-data/api/v1/product/enable/ware-house/list",
    //新增调货单
    ADD: "/logistics/goods/out/order",
    //作废调货单
    VOID: "/logistics/goods/out/order-unable",
    //获取详情
    DETAIL: "/logistics/goods/out/order-detail",
    //删除
    DELECT: "/logistics/goods/out/order-product-del",
    //下拉单位
    UNIT: "/hydra-base-data/api/v1/product-ware-house/product/unitcode",
    //下拉单据编号
    GET_DOCUMENTS: "/logistics/goods/out/order-house-list",
  },
  // 扫码调货
  scanCode: {
    // 码校验
    CHENCK_CODE: "/logistics/goods/out/order-code-check/v2030",
    // 上传
    UPLOAD: "/logistics/goods/out/order-product-sweep/v2030",
  },
  // 盘点任务
  stockCount: {
    // 获取盘点任务列表
    TASK_LIST: "/logistics/inventory/task/list",
    // 添加/修改/详情/删除 盘点任务
    TASK: "/logistics/inventory/task",
    // 开始盘点
    START_TASK: "/logistics/inventory/task/start",
    // 取消任务
    CANCEL_TASK: "/logistics/inventory/task/cancel",
    // 结束盘点
    END_TASK: "/logistics/inventory/task/end",
    // 盘点产品操作 增删改查
    PRDUCT: "/logistics/inventory/task/product",
    // 盘点产品列表
    PRODUCT_LIST: "/logistics/inventory/task/product/list",
    // 盘点产品列表-导出
    PRODUCT_LIST_EXPORT: "/logistics/inventory/task/product/export",
    // 盘点产品明细
    PRODUCT_DETAIl: "/logistics/inventory/task/product/flow/list",
    // 盘点产品明细-导出
    PRODUCT_DETAIl_EXPORT: "/logistics/inventory/task/product/flow/export",
  },
  // 花色管理
  stylor: {
    LIST: "/logistics/attribute/list",
    // 详情，添加，修改
    ACTION: "/logistics/attribute",
    // 启用
    ENABLE: "/logistics/attribute/enable",
    // 禁用
    DISABLED: "/logistics/attribute/disable",
    // 同步第三方数据
    SYNC_DATA: "/hydra-base-data/api/v1/third/def-doc/sync-third",
  },
  // 调仓单
  exchangeInvoices: {
    LIST: "/logistics/relocate/list",
    ADD: "/logistics/relocate",
    // 作废
    INVALID: "/logistics/relocate/invalid",
    // 导出
    EXPORT: "/logistics/relocate/export",
    // 单据详情
    DETAIL: "/logistics/qnz/doc/house-out",
  },
  customer: {
    //客户出库统计列表
    STOCK_OUT_STATISTIC_LIST: "/logistics/customer-data/statistics",
    //客户出库统计列表导出
    STOCK_OUT_STATISTIC_LIST_EXPORT:
      "/logistics/customer-data/statistics/export",
    //客户出库明细列表
    STOCK_OUT_DETAIL_LIST: "/logistics/customer-data/details/list",
    //客户出库明细列表导出
    STOCK_OUT_DETAIL_LIST_EXPORT: "/logistics/customer-data/details/export",
    //客户库存明细列表
    INVENTORY_DETAIL_LIST: "/logistics/customer-data/code-current",
    //客户库存明细列表导出
    INVENTORY_DETAIL_LIST_EXPORT:
      "/logistics/customer-data/code-current/export",
    //客户库存汇总列表
    INVENTORY_STATISTIC_LIST: "/logistics/customer-data/product-current",
    //客户库存汇总列表
    INVENTORY_STATISTIC_LIST_EXPORT:
      "/logistics/customer-data/product-current/export",
    //客户调货明细
    TRANSFER_ORDER_LIST: "/logistics/customer-data/transfer-goods",
    //客户调货明细导出
    TRANSFER_ORDER_LIST_EXPORT:
      "/logistics/customer-data/transfer-goods/export",
    //客户退货明细
    CANCLE_ORDER_LIST: "/logistics/customer-data/return-goods",
    //客户退货明细导出
    CANCLE_ORDER_LIST_EXPORT: "/logistics/customer-data/return-goods/export",
  },
  // 订单版块
  orders: {
    // 订单列表
    LIST: "/logistics/sales-order/list",
    // 详情、添加、修改
    ORDERTYPE: "/logistics/sales-order",
    // 通过审核
    PASS: "/logistics/sales-order/pass",
    // 不通过审核
    UNPASS: "/logistics/sales-order/un_pass",
    // 基础模块获取申请方和上级发货方
    CUSTOMER: "/hydra-base-data/api/v1/customer/cur",
    // 销售订单列表
    SALES_LIST: "/logistics/sales-order/audit-list",
    // 删除
    DEL: "/logistics/sales-order/delete",
    // 确认销售订单
    CONFIRM: "/logistics/sales-order/confirm",
    // 构建出库单单据信息
    HOUSE_INFO: "/logistics/sales-order/build-out-house-info",
    //
    CUSTOMER_NEW: "/hydra-base-data/api/v1/customer",

    // 物料列表
    ENABLE_LIST: "/hydra-base-data/api/v1/material/enable/list",
    // 采购订单列表
    ORDER_LIST: "/logistics/purchase/order/list",
    // 供应商列表
    SUPPER_LIST: "/logistics/purchase/order/supper-list",
    // 添加采购订单,修改，详情
    ORDER_COMM: "/logistics/purchase/order",
    // 订单删除
    ORDER_DEL: "/logistics/purchase/order/delete",
    // 采购确认订单
    ORDER_CONFIRM: "/logistics/purchase/order/confirm",
    // 采购通过审核
    ORDER_PASS: "/logistics/purchase/order/pass",
    // 采购不通过审核
    ORDER_UNPASS: "/logistics/purchase/order/un_pass",
    // 订单发货
    ORDER_SEND: "/logistics/purchase/order/send",
    THIRD_LIKE: "/hydra-base-data/api/v1/supper/select/list", // 模糊查询第三方工商库组织列表
  },
};
