import landPage from './apiModules/landPage';

export default {
  // 部门与员工
  member: {
    // 组织架构
    department: {
      // 获取顶级部门
      GET_TOP: '/hydra-user/api/v1/org/dept/top',
      // 获取下级部门
      GET_CHILDREN: '/hydra-user/api/v1/org/dept/parent',
      // 获取所有下级部门(包括下级的下级)
      GET_ALL_CHILDREN: '/hydra-user/api/v1/org/dept/listAll',
      // 新建部门
      ADD: '/hydra-user/api/v1/org/dept/add',
      // 删除部门
      REMOVE: '/hydra-user/api/v1/org/dept/delete',
      // 更新部门信息
      UPDATE: '/hydra-user/api/v1/org/dept/update',
      // 获取部门下面的所有员工
      GET_MEMBER: '/hydra-user/api/v1/org/dept/departmentAll',
      // 获取禁用了的员工
      GET_DISABLED_MEMBER: '/hydra-user/api/v1/employee/enable/list/Prohibit',
      // 发送邀请短信
      // SMS_SENT: '/sms/send/phone/message',
    },
    // 申请审核
    audit: {
      // 获取申请列表
      GET_LIST: '/department/employee/aud/list',
      // 通过申请
      PASS: '/department/employee/aud/accept',
      // 拒绝申请
      REFUSE: '/department/employee/aud/reject',
    },
  },
  // 权限管理
  power: {
    role: {
      // 获取角色列表
      GET_LIST: '/hydra-user/api/v1/role/org/list',
      // 获取系统和系统的菜单配置
      GET_SYSTEM_MENU: '/hydra-user/api/v1/menu/menu-role/allsys',
      // 校验当前组织是否关联系统
      CHECK_SYS: '/hydra-user/api/v1/org/sys/checksys',
      // 新增角色
      ADD_ROLE: '/hydra-user/api/v1/role/add',
      // 编辑角色
      EDIT_ROLE: '/hydra-user/api/v1/role/add',
      // 获取角色详情
      GET_DETAIL: '/hydra-user/api/v1/menu/menu-role/allsys',
      //获取数据权限列表
      LOAD_ROLE_AUTH_LIST: '/hydra-user/api/v1/role/data/auth'
    },
  },
  // 产品管理
  product: {
    // 获取产品列表
    GET_LIST: '/hydra-base-data/api/v1/product/list',
    // 获取产品列表
    SAVE: '/hydra-base-data/api/v1/product',
    // 禁用产品
    DISABLE: '/hydra-base-data/api/v1/product/disable',
    // 启用产品
    ENABLE: '/hydra-base-data/api/v1/product/enable',
    // 更新产品
    UPDATE: '/hydra-base-data/api/v1/product',
    // 更新产品
    GET: '/hydra-base-data/api/v1/product/one',
    PRODUCT_CATEGORY: '/hydra-base-data/api/v1/product-category/',
  },
  // 企业管理
  org: {
    CHECK: '/hydra-user/api/v1/org/check/name/number',
    SMS_CHECK: '/hydra-user/api/v1/sms/check/phone-code',
    SMS_SEND: '/hydra-user/api/v1/sms/send/phone/code',
    ADD_ORG: '/hydra-user/api/v1/org',
    CONFIRM_ORG: '/org/sys/invite/confirm',  // 废弃
    ORG_CENTER: '/hydra-user/api/v1/org/org/center/index',
    THIRD_LIKE: '/hydra-open-thirdpart-service/business/open/thrid/like',
    THIRD_EXACT: '/hydra-open-thirdpart-service/business/open/thrid/exact/new',
    INVITE_ONE: '/org/sys/invite/one',   // 废弃
    GET_DETAIL: '/hydra-user/api/v1/org',
    UPDATE: '/hydra-user/api/v1/org/update',
    CHECK_ORG_STATUS: '/hydra-user/api/v1/org/status',
    CLAIM_ORG: '/hydra-user/api/v1/org/claim', // 认领企业
    APPAL_ORG: '/hydra-user/api/v1/org/appeal', // 申诉企业
    AUTHENTICATE_ORG: '/hydra-user/api/v1/org/legalize', // 认证企业
    // 获取企业中心首页数据
    GET_ORG_INFO: '/hydra-user/api/v1/org/org/center/index',
    // 实时获取组织中心企业名称
    GET_ACCOUNT: '/hydra-user/api/v1/user/account'
  },
  // 员工管理
  employee: {
    // 获取员工列表
    GET_LIST: '/hydra-user/api/v1/employee/list',
    // 获取已启用的员工列表
    GET_ENABLE_LIST: '/hydra-user/api/v1/employee/enable/list',
    // 获取详细信息
    GET_DETAIL: '/hydra-user/api/v1/employee/getByEmpId',
    // 保存员工
    SAVE: '/hydra-user/api/v1/employee/add',
    // 禁用员工
    DISABLE: '/hydra-user/api/v1/employee/disable/status',
    // 启用员工
    ENABLE: '/hydra-user/api/v1/employee/enable/status',
    // 更新员工
    UPDATE: '/hydra-user/api/v1/employee/update',
    // 获取单个信息
    GET: '/hydra-user/api/v1/employee/add',
    aud: {
      // 获取员工申请列表
      GET_LIST: '/department/employee/aud/list',
      UPDATE: '/department/employee/aud/reject',
      SAVE: '/department/employee/aud/accept',
    },
    role: {
      // 返回角色列表
      GET: '/role/user/org',
    },
  },
  role: {
    GET_LIST_AUTOCOMPLETE: '/role/common',
  },
  // 系统管理
  sys: {
    LIST: '/hydra-user/api/v1/sys/org/list',
  },
  // 系统角色管理
  sysRole: {
    LIST: '/hydra-user/api/v1/role/querylist',
    MENU: '/hydra-user/api/v1/menu/menu-role',
    ORG_ROLE_DETAIL: '/hydra-user/api/v1/role/org/query',
    DETAIL: '/hydra-user/api/v1/menu/menu-role',
    FIRST_MENU: '/sysuser/first-menu',
    CHILD_MENU: '/sysuser/child-menu',
    COMMON: '/hydra-user/api/v1/sysuser/role',
    DISABLED: '/hydra-user/api/v1/role/update-status',
    ENABLE: '/hydra-user/api/v1/role/update-status',
  },
  admin: {
    CHANGE_MANAGER: '/hydra-user/api/v1/org/change/manager',
  },
  landPage,
  informationClassifying: {
    GET_LIST: '/cms/cardSubType/listForBackground',
    SAVE: '/cms/cardSubType/batchAddCardSub'
  },
  //设置子管理员
  subAdmin: {
    //获取子管理员列表
    GET_LIST: '/hydra-user/api/v1/org/sub-admin/page',
    //添加子管理员
    ADD: '/hydra-user/api/v1/org/sub-admin',
    //删除子管理员
    DELETE: '/hydra-user/api/v1/org/sub-admin',
    //启用子管理员
    ENABLE: '/hydra-user/api/v1/org/sub-admin/enable',
    //禁用子管理员
    DISABLE: '/hydra-user/api/v1/org/sub-admin/disable',
    //获取子管理员详细信息
    LOAD_DETAIL: '/hydra-user/api/v1/org/sub-admin',
    //添加子管理员时获取非子管理员的员工
    LOAD_USER_LIST_NOT_ADMIN: '/hydra-user/api/v1/org/sub-admin/employee/list'
  }
};
