export default {
  GET_LIST: '/hydra-base-data/api/v1/material/productmanage/list',
  GET_INVENTORY_LIST: '/hydra-base-data/api/v1/material/warehouse/productmanage/list',
  GET_IN_LIST: '/hydra-base-data/api/v1/material/warehouse/productmanage/in/material/list',
  GET_BATCH_LIST: '/hydra-base-data/api/v1/material/warehouse/productmanage/in/batch',
  GET_OUT_LIST: '/hydra-base-data/api/v1/material/warehouse/productmanage/out/material/list',
  ADD_MATERIAL: '/hydra-base-data/api/v1/material/productmanage',
  GET_MATERIAL_BYID: '/hydra-base-data/api/v1/material/productmanage',
  UPDATE_MATERIAL: '/hydra-base-data/api/v1/material/productmanage',
  DISABLE_MATERIAL: '/hydra-base-data/api/v1/material/productmanage/disable',
  ENABLE_MATERIAL: '/hydra-base-data/api/v1/material/productmanage/enable',
  GET_STOCK_LIST: '/hydra-base-data/api/v1/material/warehouse/productmanage/list',
  GET_OUT_IN_CODE: '/hydra-base-data/api/v1/material/warehouse/productmanage/code',
  GET_MATERIAL_SIZE: '/hydra-base-data/api/v1/material/specification/productmanage/list',
  ADD_MATERIAL_IN: '/hydra-base-data/api/v1/material/warehouse/productmanage/batch/in',
  ADD_MATERIAL_OUT: '/hydra-base-data/api/v1/material/warehouse/productmanage/batch/out',
  GET_MATERIAL_DETAILS_BYID: '/hydra-base-data/api/v1/material/warehouse/productmanage',
  SET_WARNING: '/hydra-base-data/api/v1/material/warehouse/productmanage/warning',
  GET_LIST_BATCH: '/hydra-base-data/api/v1/material/warehouse/productmanage/signle/out/list',
  GET_LIST_TURNOVER: '/hydra-base-data/api/v1/material/warehouse/productmanage/signle/out-in/list',
  GET_FLOWING_LIST: '/hydra-base-data/api/v1/material/warehouse/productmanage/out-in/list',
  GET_VERIFY: '/hydra-base-data/api/v1/material/warehouse/productmanage/check/out',
  // GET_PLATE_BATCH: '/hydra-base-data/api/v1/runda/product-batch/dropPage',
  // 物料使用统计
  // STATISTIC_LIST: '/hydra-base-data/api/v1/runda/productmanage/material/materialUseStatisics/list',
  // 导出物料使用统计
  // EXPORT_STATISTIC_LIST: '/hydra-base-data/api/v1/runda/productmanage/material/materialUseStatisics/export'
}