import { setConfig } from 'framework/siteConfig';
import menuCreate from './config/menu';
import system from './config/system';
import { loadDetail, saveDetail, logout as userLogout } from 'framework/services/user';
import { getOverDate } from 'userCenter/services/org';
import { Modal } from 'src/framework/components';
import logout from './config/logout';
import * as storage from 'framework/utils/storage';
import cookie from 'framework/utils/cookie';
import { string2Date } from 'framework/utils/date';
import { parse } from 'framework/utils/url';
import { getButtonAuthCode } from 'system/services/buttonAuthCode';
import _ from 'lodash';

//
function isAdmin() {
  const browserLocation = parse(window.location.href);
  const query = _.get(browserLocation, 'query', {});
  if (query.isAdmin === '1' || query.isadmin === '1') {
    return '1';
  }
  return '';
}

//
async function getPowerConfig() {
  const powerConfig = await getButtonAuthCode();
  //console.log(powerConfig);
  return powerConfig;
}

function taskLoop(tasks, success, fail) {
  //console.log(tasks.length, tasks.length > 0);
  if (tasks && tasks.length > 0) {
    const next = () => {
      taskLoop(tasks.filter((task, index) => index > 0), success, fail);
    };
    tasks[0](next, fail);
  } else {
    success();
  }
}

//如果30天内到期,就提示用户
async function checkValidDate(userDetail) {
  const checked = storage.get('checked');
  if (checked === '1') {
    return;
  }
  const orgId = _.get(userDetail, 'organizationCache.organizationId');
  const sysId = _.get(userDetail, 'organizationCache.sysCache.sysId');
  if (orgId && sysId) {
    let overDate = await getOverDate(orgId, sysId);
    // overDate = string2Date(overDate);
    // overDate.setHours(23);
    // overDate.setMinutes(59);
    // overDate.setSeconds(59);
    // overDate.setMilliseconds(999);
    // overDate = overDate * 1;
    // const now = Date.now();
    var setTime = new Date(overDate.replace(/\-/g, "/"));//结束时间
    var nowTime = new Date(); //当前时间
    const sTime = setTime.getTime() - nowTime.getTime();//结束时间与当前时间差
    const dayMs = 1000 * 60 * 60 * 24; //一天的毫秒数
    const hours = parseInt((sTime / (60 * 60 * 1000)) % 24);//小时
    const sDay = parseInt(sTime / dayMs);
    let msgContent = null;
    if (sTime < 0) {
      msgContent = (<div>您的系统已经到期<br />延期请拨打电话：4006-822-110</div>);
    } else if (sDay <= 30) {
      msgContent = (<div>您的系统{sDay}天{hours}小时后到期<br />延期请拨打电话：4006-822-110</div>);
    }
    if (msgContent) {
      // Modal
      Modal.warning({
        okText: '关闭',
        title: '提示',
        content: msgContent
      });
      storage.set('checked', '1');
    }
  }
}

//
export default () => {
  const tasks = [];
  let userDetail = null;
  let systemDetail = null;
  //
  tasks.push((next) => {
    setConfig('logout', logout);
    next();
  });
  //
  tasks.push(async (next, fail) => {
    userDetail = await loadDetail();
    if (userDetail) {
      next();
    } else {
      fail();
    }
  });
  //
  tasks.push((next, fail) => {
    const { organizationCache } = userDetail;
    if (!isAdmin() && !organizationCache) { //不是管理员而且没有选择组织
      fail();
    } else {
      next();
    }
  });
  //
  tasks.push(async (next, fail) => {
    systemDetail = await system.loadDetail();
    if (systemDetail) {
      next();
    } else {
      fail();
    }
  });
  //
  tasks.push(async (next, fail) => {
    const title = systemDetail.title || systemDetail.sysName;
    title && (document.title = title);
    setConfig('logo', title);
    setConfig('systemDetail', systemDetail);
    const setSuccess = await system.setLocalSys(systemDetail.sysId);
    if (setSuccess) {
      next();
    } else {
      fail();
    }
  });
  //
  tasks.push(async (next, fail) => {
    const browserLocation = parse(window.location.href);
    const query = _.get(browserLocation, 'query', {});
    if (query.autoMenu !== '0') {
      const menuData = await menuCreate();
      if (menuData.length > 0) {
        setConfig('menuData', menuData);
        next();
      } else { //获取系统菜单配置失败
        fail();
      }
    } else {
      next();
    }
  });
  //
  tasks.push(async (next, fail) => {
    const powerConfig = await getPowerConfig();
    setConfig('powerConfig', powerConfig);
    next();
  });
  
  //-------------------------
  const saveHashInfo = () => {
    const logoutHash = window.location.hash;
    if (logoutHash.indexOf('#/user') < 0) {
      sessionStorage.setItem('logoutHash', window.location.hash);
    }
  };
  //---------------------------
  tasks.push(async (next, fail) => {
    checkValidDate(userDetail); //如果30天内到期,就提示用户
    next();
  });
  //---------------------------
  
  return new Promise(resolve => {
    taskLoop(tasks, resolve, () => {
      saveHashInfo();
      logout(userLogout, storage, cookie);
      resolve();
    });
  });
};
