// 设置
export default {
  // 生产提醒
  productionRemind: {
    LIST: 'xx',
    EDIT: 'xxx',
    ENABLE: 'xxx',
    DISABLE: 'xxx'
  },
  // 种猪淘汰
  swineEliminated: {
    LIST: 'xx',
    EDIT: 'xxx',
    ENABLE: 'xxx',
    DISABLE: 'xxx'
  },
  // 生产周期
  productionCycle: {
    LIST: 'xx',
    EDIT: 'xxx',
  }
}