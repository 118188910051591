export default {
  // 用户使用统计
  usageStatistic: {
    // 使用频次
    USE_TIMES: '/hydra-intelligent-planting/valid/api/v1/productionManageFunctionUse/pageFunctionUse',
    USE_TIMES_ANALYSIS: '/hydra-intelligent-planting/valid/api/v1/productionManageFunctionUse/selectLineChartVO',
    // 登录记录
    RECORD: '/hydra-user/api/v1/loginstatiscis/page',
    RECORD_EXPORT: '/hydra-user/api/v1/loginstatiscis/exportLoginLogList',
    // 登录数据
    LOGIN_LIST: '/hydra-user/api/v1/loginstatiscis/pageGroup',
    LOGIN_EXPORT: '/hydra-user/api/v1/loginstatiscis/exportDepartmentGroup',
    LOGIN_ANALYSIS: '/hydra-user/api/v1/loginstatiscis/selectLineChartVO',
  },
  sale: {
    // 销售额，订单数
    AMOUNT_NUM: '/gugeng/statistics/sale/order/amountAndNum',
    // 个人销售排行
    SALE_RANK: '/gugeng/statistics/sale/order/person/salerank',
    // 导出个人销售排行
    EXPORT_SALE_RANK: '/gugeng/statistics/sale/exportPersonSalerank',
    // 销售客户
    CLIENT_RANK: '/gugeng/statistics/sale/order/client/order',
    // 导出销售客户
    EXPORT_CLIENT_RANK: '/gugeng/statistics/sale/exportClientOrder',
    // 产品销售排行
    PRODUCT_RANK: '/gugeng/statistics/sale/order/product',
    // 导出产品销售排行
    EXPORT_PRODUCT_RANK: '/gugeng/statistics/sale/exportProductRank',
    // 潜在客户
    POTENTIAL_CLIENT: '/gugeng/statistics/sale/order/potential/client',
    // 潜在客户导出
    EXPORT_POTENTIAL_CLIENT: '/gugeng/statistics/sale/exportPotentialclientRankExcel',
    // 订单状态汇总
    ORDER_STATUS: '/gugeng/statistics/sale/order/status/rank',
    // 订单状态汇总导出
    EXPORT_ORDER_STATUS: '/gugeng/statistics/sale/exportOrderStatusRank',
    // 销售总数据
    // 销售总数据折线图
    TOTAL_AMOUNT_NUM: '/hydra-intelligent-planting/valid/api/v1/statistics/salesummary/order/amountAndNum',
    // 每日销售数据列表
    SALE_ORDER_DATA_DAILY: '/hydra-intelligent-planting/valid/api/v1/statistics/salesummary/order/listAmountAndNum',
    // 每日销售数据导出
    EXPORT_SALE_ORDER_DATA_DAILY: '/hydra-intelligent-planting/valid/api/v1/statistics/salesummary/exportOrderAmountAndNum',
  },
  storage: {
    AMOUNT_NUM: '/gugeng/statistics/storage/order/amountAndNum'
  },
  produce: {
    PRODUCE_LOSE: '/gugeng/product-data-statistics/list',
    GREENHOUSE_LIST: '/gugeng/product-data-statistics/greenhouse-ranking/list',
    EXPORT_GREENHOUSE_LIST: '/gugeng/product-data-statistics/greenhouse-ranking/export',
    // 生产总数居
    // 产量，采收报损
    REAL_AND_LOSS_WEIGHT: '/hydra-intelligent-planting/valid/api/v1/statistics/producesummary/product/realWeightAndLossesWeight',
    // 产量与采收报损数据列表
    REAL_AND_LOSS_WEIGHT_LIST: '/hydra-intelligent-planting/valid/api/v1/statistics/producesummary/product/listRealWeightAndLossesWeight',
    // 导出产量与采收报损数据列表excel
    EXPORT_REAL_AND_LOSS_WEIGHT_LIST: '/hydra-intelligent-planting/valid/api/v1/statistics/producesummary/exportRealWeightAndLossesWeight',
    // 大棚数据
    greenHouseData: {
      LIST: '/hydra-intelligent-planting/valid/api/v1/productionManageStatisticsGreenhouse/greenhouse-list',
      EXPORT: '/hydra-intelligent-planting/valid/api/v1/productionManageStatisticsGreenhouse/export',
      ANALYSIS: '/hydra-intelligent-planting/valid/api/v1/productionManageStatisticsGreenhouse/list'
    },
    // 生产产量数据
    productionOutput: {
      ANALYSIS: '/hydra-intelligent-planting/valid/api/v1/productionManageStatisticsProductionYieldData/list-by-date-interval',
      LIST: '/hydra-intelligent-planting/valid/api/v1/productionManageStatisticsProductionYieldData/list',
      EXPORT: '/hydra-intelligent-planting/valid/api/v1/productionManageStatisticsProductionYieldData/export',
      // 获取最先添加的产品
      NEW_PRODUCT: '/hydra-intelligent-planting/valid/api/v1/productionManageStatisticsGreenhouse/getDefaultProduct'
    },
    // 产量对比数据
    productionComparison: {
      ANALYSIS: '/hydra-intelligent-planting/valid/api/v1/productionManageStatisticsProductionYieldData/list-yield-comparison-data-histogram',
      LIST: '/hydra-intelligent-planting/valid/api/v1/productionManageStatisticsProductionYieldData/list-yield-comparison-data',
      EXPORT: '/hydra-intelligent-planting/valid/api/v1/productionManageStatisticsProductionYieldData/comparison-export',
      // 获取最新采收大棚
      NEW_GREENHOUSE: '/hydra-intelligent-planting/valid/api/v1/productionManageStatisticsGreenhouse/getLastestGreenhouse'
    },
    // 产品产量数据
    productOutput: {
      LIST: '/hydra-intelligent-planting/valid/api/v1/productionManageStatisticsProductProductionData/list',
      ANALYSIS: '/hydra-intelligent-planting/valid/api/v1/productionManageStatisticsProductProductionData/histogram',
      EXPORT: '/hydra-intelligent-planting/valid/api/v1/productionManageStatisticsProductProductionData/export'
    }
  },
  // 管理数据
  manageData: {
    // 库存报损分析
    stockLose: {
      STATISTIC_LIST: '/gugeng/statistics/stock-loss/statistics/list',
      STOCK_LOSS_LIST: '/hydra-intelligent-planting/valid/api/v1/statistics/stock-loss/list',
      STOCK_LOSS_EXPORT: '/gugeng/statistics/stock-loss/export',
    },
    // 生产销售数据
    productionSaleData: {
      STATISTIC_LIST: '/gugeng/product/sale/statistics/lineChart',
      EXPORT_LIST: '/gugeng/product/sale/statistics/exportProductSaleStatistics',
    },
    // 批次数据
    batchData: {
      LIST: '/gugeng/plantBatch/data/statistics/listPlantBatchData',
      // 导出
      EXPORT: '/gugeng/plantBatch/data/statistics/export',
      // 获取入库批次
      GET_IN_STORAGE_BATCH_DROPDOWN: '/gugeng/storagemanage/productSortInstorage/dropDown'
    }
  },
  salesData: {
    salesPerson: {
      // 个人订单情况
      PERSON_ORDER_CONDITION: '/gugeng/statistics/sale/person/order/condition',
      // 个人订单情况导出
      EXPORT_PERSON_ORDER_CONDITION: '/gugeng/statistics/sale/exportPersonOrderconditionExcel',
      // 个人订单情况最好的销售人员信息
      PERSON_CONDITION_BEST: '/gugeng/statistics/sale/person/order/condition/best'
    },
    orderAnalysis: {
      // 整体订单情况
      WHOLE_ORDER_LIST: '/gugeng/order/data/statistics/wholeOrderCondition',
      // 整体订单情况导出
      WHOLE_ORDER_LIST_EXPORT: '/gugeng/order/data/statistics/exportWholeOrderCondition',
      // 发货类型比例接口
      PROPORTION: '/gugeng/order/data/statistics/order/deliveryType/proportion',
      // 订单发货类型排行
      RANK: '/gugeng/order/data/statistics/order/deliveryType/rank',
      // 订单发货类型排行导出
      RANK_EXPORT: '/gugeng/order/data/statistics/exportOrderdeliveryType'
    },
    // 销售目标
    salesGoal: {
      GOALS_ANALYSIS: '/hydra-intelligent-planting/valid/api/v1/productionManageStatisticsSaleTargetData/histogram',
      GOALS_LIST: '/hydra-intelligent-planting/valid/api/v1/productionManageStatisticsSaleTargetData/list',
      GOALS_LIST_EXPORT: '/hydra-intelligent-planting/valid/api/v1/productionManageStatisticsSaleTargetData/export'
    },
    // 订单情况数据
    orderStateData: {
      // 订单数据
      ORDER_ANALYSIS: '/hydra-intelligent-planting/valid/api/v1/orderCondition/curve',
      ORDER_LIST: '/hydra-intelligent-planting/valid/api/v1/orderCondition/list',
      ORDER_EXPORT: '/hydra-intelligent-planting/valid/api/v1/orderCondition/export',
      // 退货数据
      RETURN_ANALYSIS: '/gugeng/returnDataStatiscis/curve',
      RETURN_EXPORT: '/gugeng/returnDataStatiscis/export'
    },
    // 客户数据（新）
    clientData: {
      ORDER_CLIENT_ANALYSIS: '/hydra-intelligent-planting/valid/api/v1/client/data/order/client/num',
      ORDER_CLIENT_LIST: '/hydra-intelligent-planting/valid/api/v1/client/data/orderClientList',
      ORDER_CLIENT_EXPORT: '/hydra-intelligent-planting/valid/api/v1/client/data/exportOrderClient',
      POTENTIAL__CLIENT_ANALYSIS: '/hydra-intelligent-planting/valid/api/v1/client/data/order/potential/client/num',
      POTENTIAL_CLIENT_LIST: '/hydra-intelligent-planting/valid/api/v1/client/data/potentialClientList',
      POTENTIAL_CLIENT_EXPORT: '/hydra-intelligent-planting/valid/api/v1/client/data/exportPotentialClient',
    },
    // 订单回款
    orderReturn: {
      RETURN_LIST: '/gugeng/saleProductData/order/payback/list',
      RETURN_EXPORT: '/gugeng/saleProductData/exportPayBackList',
      RETURN_ANALYSIS: '/gugeng/saleProductData/order/payback/curve',
      UN_RETURN_LIST: '/gugeng/saleProductData/order/unPayback/list',
      UN_RETURN_EXPORT: '/gugeng/saleProductData/exportUnPaybackList',
    },
    // 销售产品数据（新）
    salesProduct: {
      ANALYSIS: '/hydra-intelligent-planting/valid/api/v1/orderProductDataStatistics/pie',
      LIST: '/hydra-intelligent-planting/valid/api/v1/orderProductDataStatistics/list',
      EXPORT: '/hydra-intelligent-planting/valid/api/v1/orderProductDataStatistics/export'
    },
    // 客户分类数据
    customerClassify: {
      ANALYSIS: '/hydra-intelligent-planting/valid/api/v1/order/clientCategory/pie',
      LIST: '/hydra-intelligent-planting/valid/api/v1/order/clientCategory/list',
      EXPORT: '/hydra-intelligent-planting/valid/api/v1/order/clientCategory/export'
    }
  },
  storageData: {
    storage: {
      LIST: '/gugeng/statistics/warehouse-data/statistics/list'
    },
    plantStorage: {
      // 获取种植仓储数据统计列表
      LIST: '/gugeng/statistics/plant-warehouse/list',
      // 获取种植仓储数据默认的种植批次信息
      DEFAULT_BATCH: '/gugeng/statistics/plant-warehouse/plant-batch-message/default',
      // 获取种植仓储数据种植批次列表
      BATCH_LIST: '/gugeng/statistics/plant-warehouse/plant-batch-message/list',
      EXPORT: '/gugeng/statistics/plant-warehouse/export'
    },
    inStorage: {
      LIST: '/gugeng/statistics/warehouse-data/statistics/listSortInstorageStatistics',
      EXPORT: '/gugeng/statistics/warehouse-data/statistics/exportSortInstorageStatistics'
    },
    // 仓储总数据
    totalStorageData: {
      // 出库入库，库存重量
      IN_STORAGE_AND_WEIGHT: '/hydra-intelligent-planting/valid/api/v1/statistics/warehousesummary/stock/instorageAndOutboundWeight',
      // 出库入库与库存重量数据列表
      LIST: '/hydra-intelligent-planting/valid/api/v1/statistics/warehousesummary/stock/listInstorageAndOutboundWeight',
      // 导出出库入库与库存重量数据列表excel
      EXPORT: '/hydra-intelligent-planting/valid/api/v1/statistics/warehousesummary/exportInstorageAndOutboundWeight'
    },
    // 报损数据
    lossData: {
      // 库存报损
      STORAGE_LOSS_ANALYSIS: '/hydra-intelligent-planting/valid/api/v1/statistics/stock-loss/statistics/list',
      STORAGE_LOSS_LIST: '/hydra-intelligent-planting/valid/api/v1/statistics/stock-loss/list',
      STORAGE_LOSS_EXPORT: '/hydra-intelligent-planting/valid/api/v1/statistics/stock-loss/export',
      // 采收报损及分拣报损数据统计
      SORT_INTO_LOSS_ANALYSIS: '/gugeng/statistics/stock-loss/hsl-line-chart',
      SORT_INTO_LOSS_LIST: '/gugeng/statistics/stock-loss/harv-sort-loss',
      SORT_INTO_LOSS_EXPORT: '/gugeng/statistics/stock-loss/export-har-and-sort',
    },
    // 发货数据
    deliverData: {
      DELIVER_ANALYSIS: '/hydra-intelligent-planting/valid/api/v1/productionManageStatisticsShippingData/list-line-chart',
      DELIVER_LIST: '/hydra-intelligent-planting/valid/api/v1/productionManageStatisticsShippingData/list',
      DELIVER_EXPORT: '/hydra-intelligent-planting/valid/api/v1/productionManageStatisticsShippingData/export'
    }
  },
  // 运营统计
  visitors: {
    CLIENT_LIST: '/hydra-intelligent-planting/valid/api/v1/visitor/statistic/client',
    EXPORT: '/hydra-intelligent-planting/valid/api/v1/visitor/statistic/export',
    PROJECT_LIST: '/hydra-intelligent-planting/valid/api/v1/visitor/statistic/project',
    MONEY_LIST: '/hydra-intelligent-planting/valid/api/v1/visitor/statistic/cash'
  },
  //销售数据概览
  salesOverview: {
    //销售额实收额（订单数据）
    GET_ORDER_DATA: '/hydra-intelligent-planting/valid/api/v1/statistics/saleoverview/amountAndNum',
    //客户数据
    GET_CUSTOM_DATA: '/hydra-intelligent-planting/valid/api/v1/statistics/saleoverview/selectCustomerNumList',
    //订单情况
    GET_ORDER_DETAIL: '/hydra-intelligent-planting/valid/api/v1/statistics/saleoverview/selectSaleOrderList',
    //销售产品
    GET_SALES_PRODUCTION: '/hydra-intelligent-planting/valid/api/v1/statistics/saleoverview/selectSaleProduct',
    //销售目标
    GET_SALES_TARGET: '/hydra-intelligent-planting/valid/api/v1/statistics/saleoverview/histogram',
    //产品分类
    GET_PEODUCT_SORT: '/hydra-base-data/api/v1/product-sort/firsts'
  },
  //仓储数据概览
  manageOverview: {
    //库存数据
    GET_INVENTORY_DATA: '/hydra-intelligent-planting/valid/api/v1/statistics/warehouseoverview/stock/instorageAndOutboundWeight',
    //库存报损数据
    GET_INVENTORY_DESTORY: '/hydra-intelligent-planting/valid/api/v1/statistics/warehouseoverview/hsl-line-chart',
    //发货数据
    GET_DELIVERY_DATA: '/hydra-intelligent-planting/valid/api/v1/statistics/warehouseoverview/list-line-chart',
  },
  //生产数据概览
  productOverview: {
    //生产数据
    GET_PRODUCT_DATA: '/hydra-intelligent-planting/valid/api/v1/statistics/produceoverview/product/realWeightAndLossesWeight',
    //产量数据
    GET_PRODUCTION_DATA: '/hydra-intelligent-planting/valid/api/v1/statistics/produceoverview/list-by-date-interval',
    //产量对比
    GET_YIELD_DATA: '/hydra-intelligent-planting/valid/api/v1/statistics/produceoverview/list-yield-comparison-data-histogram',
    //产品产量
    GET_PRODUCT_PRODUCTION: '/hydra-intelligent-planting/valid/api/v1/statistics/produceoverview/histogram'
  },
  // 投入数据
  inject: {
    // 投入产出图表
    OUTPUT_CHART: '/hydra-intelligent-planting/valid/api/v1/productmanage/statistics/input/output/yield',
    // 投入产出统计列表
    OUTPUT_LIST: '/hydra-intelligent-planting/valid/api/v1/productmanage/statistics/input/output/list',
    // 投入产出统计导出
    OUTPUT_EXPORT: '/hydra-intelligent-planting/valid/api/v1/productmanage/statistics/input/output/export',
    // 成本投入-物料
    COST_MATERIAL: '/hydra-intelligent-planting/valid/api/v1/productmanage/statistics/cost/input/material',
    // 成本投入-营养液
    COST_NUTRIENT_SOLUTION: '/hydra-intelligent-planting/valid/api/v1/productmanage/statistics/cost/input/sustenance',
    // 成本投入-人工
    COST_LABOUR: '/hydra-intelligent-planting/valid/api/v1/productmanage/statistics/cost/input/working',
    // 成本投入-图表
    COST_CHART: '/hydra-intelligent-planting/valid/api/v1/productmanage/statistics/cost/input/yield',
    // 成本投入-物料导出
    COST_MATERIAL_EXPORT: '/hydra-intelligent-planting/valid/api/v1/productmanage/statistics/cost/input/export/material',
    // 成本投入-营养液导出
    COST_NUTRIENT_SOLUTION_EXPORT: '/hydra-intelligent-planting/valid/api/v1/productmanage/statistics/cost/input/export/sustenance',
    // 成本投入-人工导出
    COST_LABOUR_EXPORT: '/hydra-intelligent-planting/valid/api/v1/productmanage/statistics/cost/input/export/working',
  },
  teaGreenTradingData: {
    // 茶青交易统计（erp）
    teaHistoryStatistic: '/hydra-intelligent-planting/valid/api/v1/tea-green-acquisition/statistics/history',
    // 群星--茶青交易历史数据
    qunXingHistoryData: {
      // 茶青收购历史数据统计
      STATISTIC_HISTORY: '/hydra-intelligent-planting/valid/api/v1/tea-green-acquisition/summary/statistics/history/v2',
      // 茶青收购合作社历史数据统计
      COOPERATIVE_STATISTIC: '/hydra-intelligent-planting/valid/api/v1/tea-green-acquisition/summary/statistics/cooperative',
      // 茶青收购总重历史数据统计
      WEIGHT_STATISTIC: '/hydra-intelligent-planting/valid/api/v1/tea-green-acquisition/summary/statistics/weight',
      // 茶青收购销售历史数据统计
      SALE_STATISTIC: '/hydra-intelligent-planting/valid/api/v1/tea-green-acquisition/summary/statistics/sale'
    }
  }
}