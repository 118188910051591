import dynamic from 'dva/dynamic';

// 需要被system引用的功能路由
const routeData = [
  {
    path: '/org/system/mySystem',
    component: dynamic({component: () => import(/* webpackChunkName: "modules/org/index" */ './views/system/mySystem')}),
  },
  // 系统角色管理列表
  {
    path: '/platform/system/sysRole/list',
    component: dynamic({component: () => import(/* webpackChunkName: "modules/org/index" */ './views/system/sysRole/list')}),
  },
  // 添加系统角色
  {
    path: '/platform/system/sysRole/setting',
    component: dynamic({component: () => import(/* webpackChunkName: "modules/org/index" */ './views/system/sysRole/setting')}),
  },
  // 导入文件模板demo
  {
    path: '/demo/importFileDemo',
    component: dynamic({component: () => import(/* webpackChunkName: "modules/demo/importFileDemo" */ './views/base/demo/importFileDemo')})
  }
];
export default routeData;
