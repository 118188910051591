import dataReportApi from "./dataReportApi";

export default {
  // 企业接入平台
  company: {
    // 接入申请
    APPLY: "/regulation/registration/enterprise/apply",
    // 政府下企业列表
    LIST: "/regulation/registration/enterprise/gov/all/list",
    // 获致接入地址
    GETURL: "/regulation/registration/enterprise/geturl",
    // 企业审核通过
    PASS: "/regulation/registration/enterprise/audit/pass",
    // 企业审核不通过
    NOPASS: "/regulation/registration/enterprise/audit/notpass",
  },
  // 系统组织管理
  sysOrg: {
    // 开通组织_政府
    ORG_OPEN2: "/zfjg/open/regulation",
    GET_ORG_DETAIL: "/hydra-user/api/v1/org/government",
    GET_ORG_DETAIL2: "/hydra-user/api/v1/org",
    UPDATE: "/hydra-user/api/v1/org/government",
    GET_GOV_LIST: "/hydra-user/api/v1/org/list/sys/government",
    // 根据组织名进行模糊查询
    GET_ORG_LIST_BY_NAME: "/hydra-user/api/v1/org/vagueOrgName",
    // 获取系统组织列表
    GOV_LIST: "/hydra-user/api/v1/org/list/sys/government/select",
    // 开通组织
    // SYS_LIST: '/zfjg/sys/list',
    // 获取系统类型
    GET_SYSTYPE: "/hydra-user/api/v1/sys/sys-type",
    // 根据系统类型查询系统列表
    GET_LIST_BY_TYPE: "/hydra-user/api/v1/sys/list-from-type",
    // 开通政府组织
    SAVE_GOV_ORG: "/hydra-user/api/v1/org/open/gov",
    // 根据组织id获取单条组织信息
    GET_ORG_INFO: "/hydra-user/api/v1/org/orgInfo",
    // 获取不包括自己的组织列表
    GET_ORG_OTHER_LIST: "/hydra-user/api/v1/org/relation/filter/list",
  },
  // 系统管理员
  sys: {
    // 业务定制
    made: {
      // 列表
      GET_LIST: "/regulation/node/listNodes",
      // 删除
      DELETE: "/regulation/node/delete",
      // 新增
      ADD: "/regulation/node/add",
      // 修改
      UPDATE: "/regulation/node/update",
      // 复制
      COPY: "/regulation/node/copy",
      // 详情
      DETAIL: "/regulation/node/getFiledsByNodeId",
      // 编辑节点设置字段信息
      CUSTOMNODE: "/regulation/node/customNode",
      // 根据政府id获取节点列表
      NODES_BY_ORG_ID: "/regulation/node/listNodesByOrgId",
      // 获取下拉节点字段信息,用于下拉列表
      PAGE_NODES_BY_ORG_ID: "/regulation/node/pageNodesByOrgId",
      // 获取下拉节点字段信息,用于下拉列表
      PAGE_FILEDS: "/regulation/node/pageFiledsByNodeId",
    },
    // 备案模板管理
    template: {
      // 列表
      GET_LIST: "/regulation/template/list",
      // 新增
      ADD: "/regulation/template/add",
      // 修改
      UPDATE: "/regulation/template/update",
      // 复制
      COPY: "/regulation/template/copy",
      // 校验模板是否被使用
      CHECK_TEMPLATE: "/regulation/template/check",
      // 启用模板
      ENABLE_TEMPLATE: "/regulation/template/enable",
      // 根据templateId获取模板信息
      GET_DETAIL: "/regulation/template/get",
      // 定制模板
      MADE_TEMPLATE: "/regulation/template/Custom",
      // 根据模板id获取节点字段信息
      GET_NODES: "/regulation/template/getNodes",
    },
  },
  // 产品备案
  product: {
    filingManagement: {
      GET_LIST: "/regulation/registration/product/gov/list",
      // 保存
      SAVE: "/regulation/registration/product",
      // 更新
      UPDATE: "/regulation/registration/product",
      // 获取单一
      GET: "/regulation/registration/product/get",
      // 政府获取产品备案接口
      GET_LIST_GOV: "/regulation/registration/product/reg/list",
    },
    directoryManagement: {
      GET_LIST: "/regulation/registration/product/list",
      // 政府企业产品备案管理
      GET_LIST_GOV: "/regulation/registration/product/gov/reg/list",
    },
    approval: {
      // 备案审批
      GET_LIST: "/regulation/registration/product/audit/list",
      // 产品审核通过
      PASS: "/regulation/registration/product/audit/pass",
      // 产品审核不通过
      NOTPASS: "/regulation/registration/product/audit/notpass",
    },
  },
  // 获取系统政府组织列表
  government: {
    GET_LIST: "/hydra-user/api/v1/org/relation/gov/list",
    GET_GOV_LIST: "/hydra-user/api/v1/org/list/sys/government/select",
    GET_GOV_LIST2: "/hydra-user/api/v1/org/relation/gov/all/list",
  },
  // 政府及下级政府下企业列表
  traceSubject: {
    enterprise: {
      GET_LIST: "/regulation/registration/enterprise/govandsubgov/list",
    },
    traceInfo: {
      GET_LIST: "/regulation/registration/enterprise/gov/list",
    },
  },
  enterprise: {
    // 获取企业基础信息
    GET: "/regulation/registration/enterprise/get",
    // 企业列菜单列表
    GET_MENU: "/regulation/registration/enterprise/menu/list",
    // 获取传输统计列表
    GET_HISTORY_LIST: "/regulation/nodedata/history",
    // 政府获取单一企业
    GET_GOV: "/regulation/registration/enterprise/gov/get",
    //企业获取接口文档信息
    GET_API: "/regulation/registration/enterprise/get/apidocinfo",
    // 获取列表
    GET_LIST: "/regulation/trace/field/list",
  },
  // 数据中心
  dataCenter: {
    // 企业备案数量
    COM_RECORD_NUM: "/regulation/govdata/enterprise/recordnum",
    // 企业月报传送数量
    MONTH_LOG: "/regulation/govdata/monthlog",
    // 备案产品统计
    PRO_RECORD_NUM: "/regulation/govdata/product/recordnum",
    // 产品品种统计
    PRO_VARIETY_NUM: "/regulation/govdata/product/varietynum",
    // 下级政府企业备案数量
    SUB_RECORD_NUM: "/regulation/govdata/subgov/enterprise/recordnum",
    // 追溯数据质量排行
    TRANSMIT_RANK: "/regulation/govdata/transmitRank",
    // 已上传数据量统计
    TRANSMIT_NUM: "/regulation/govdata/transmitnum",
  },
  traceNode: {
    GET: "/regulation/trace/get",
    // 获取列表
    GET_LIST: "/regulation/trace/list",
    // 政府看某企业某节点数据
    GET_LIST_GOV: "/regulation/trace/listByOrganizationId",
  },

  //监管电子地图
  regularMap: {
    //获取当前政府地区信息
    GET_AREA_MSG: "/regulation/map/govArea",
    //获取产品列表
    GET_PRODUCT_LIST: "/regulation/map/productList",
    //获取基地经纬度信息
    SEARCH: "/regulation/map/baseLngLat",
    //环节类型
    TACHE_TYPE: "/hydra-base-data/api/v1/config/type",
    //获取基地经纬度
    GET_BASE_LIST: "/regulation/map/baseLngLat",
    //获取基地详细信息
    BASE_DETAIL: "/regulation/map/baseInfo",
  },

  //一品一码基础数据
  ncBase: {
    //农产品追溯数据
    TRACR_LIST: "/regulation/oneProductCode/agricultureTraceData",
    //农产品追溯数据导出
    EXPORT_TRACE: "/regulation/oneProductCode/agricultureTraceData/export",
    //农产品基础数据
    BASE_LIST: "/regulation/oneProductCode/agricultureProductData",
    //导出农产品基础数据
    EXPORT_BASE: "/regulation/oneProductCode/agricultureProductData/export",
    //导出农产品生产数据
    EXPORT_PRODUCT:
      "/regulation/oneProductCode/agricultureProductDataList/export",
  },
  //证书接口
  businessCertification: {
    //获取列表
    GET_LIST: "/regulation/businessCertification/list",
    //注销证书
    CANCEL: "/regulation/businessCertification/cancel",
    //获取详情
    DETAILS: "/regulation/businessCertification/getBusinessCertification",
    //通过审核
    AUDITING: "/regulation/businessCertification/auditing",
    //不通过审核
    NOT_AUDITING: "/regulation/businessCertification/notAuditing",
    //保存证书信息
    SAVE: "/regulation/businessCertification/save",
    //证书变更
    UPDATE: "/regulation/businessCertification/update",
    //证书图片
    IMG: "/regulation/businessCertification/disponse/cert",
    //获取企业的证书详情
    COMPANY_DETAILS: "/regulation/businessCertification/certificationId",
  },
  //一品一码
  oneProductCode: {
    //检测数据列表
    TEST_DATA: "/regulation/oneProductCode/testData",
    //检测数据导出
    TD_EXPORT: "/regulation/oneProductCode/testData/export",
    //企业溯源档案导出
    ER_EXPORT: "/regulation/oneProductCode/agricultureProductTraceData/export",
    //实时监控
    MONITORING: "/regulation/oneProductCode/monitorData",
    //实时监控导出
    MR_EXPORT: "/regulation/oneProductCode/monitorData/export",
  },
  //传感器汇总
  device:{
    DEVICE_LIST: "/hydra-trace-individual-micro/api/v1/kh/device/list",
    EXPORT: "/hydra-trace-individual-micro/api/v1/kh/device/export",
    NO_TOKEN_DETAIL: '/iotdevice/notoken/getByCode',
  },
  // 数据上报
  dataReportApi,
};
