export default {
  ADD: '/hydra-user/api/v1/server/icon/add',
  GET_LIST: '/hydra-user/api/v1/server/icon/list',
  UPDATE: '/hydra-user/api/v1/server/icon/update',
  DELTET: '/hydra-user/api/v1/server/icon/delete',
  category: {
    ADD: '/hydra-user/api/v1/server/icon-category/add',
    GET_LIST: '/hydra-user/api/v1/server/icon-category/list',
    UPDATE: '/hydra-user/api/v1/server/icon-category/update'
  }
}
