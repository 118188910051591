export default {
  //提交审核保存个人信息
  APPUSER_SAVE:"/zishahu-antifake-trace/api/v1/server/appuser/save",
  // 获取个人信息
  APPUSER_GET:"/zishahu-antifake-trace/api/v1/server/appuser/get",
  // 获取个人信息列表
  INFO_LIST:"/zishahu-antifake-trace/api/v1/server/appuser/getList",
  // 通过id获取个人信息
  APPUSER_GETID:"/zishahu-antifake-trace/api/v1/server/appuser/getById",
  // 添加产品并且添加仓库
  PRODUCT_ADD_HOUSE:"/hydra-base-data/api/v1/product/add-with-ware-house",
  // 当前登入人的分页产品数据
  PRODUCT_YOURSELF:"/hydra-base-data/api/v1/product/page/yourself",
  // 审核
  APPUSER_AUDIT:"/zishahu-antifake-trace/api/v1/server/appuser/audit",
  // 交易列表
  GET_HOLDLIST:"/zishahu-antifake-trace/api/v1/server/transaction/getList",
  // 交易统计
  GET_TRADE:"/zishahu-antifake-trace/api/v1/server/transaction/getTradeStatistic",
  // 获取区块链信息
  GET_BLOCK:"/zishahu-antifake-trace/api/v1/server/transaction/getBlockRecord",
  // 审核不通过原因
  GET_REASON:"/zishahu-antifake-trace/api/v1/server/appuser/getReason",
  // 获取机构名称
  GET_USER:"/interface/hydra-user/api/v1/user/account/info",
  // 获取当前用户组织下指定部门信息
  GET_ONDEP:"/hydra-user/api/v1/org/dept/org/getOnDep"
}