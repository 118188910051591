import saleManModal from "./apiModules/saleMan";
import newActivity from "./apiModules/newActivity";
import record from "./apiModules/record";
import member from "./apiModules/member";
import pickupTicket from "./apiModules/pickupTicket";
import lancui from "./apiModules/lancui";
import shop from './apiModules/shop';
import statistics from './apiModules/statistics';
import codeLevel from './apiModules/codeLevel';

export default {
  vip: {
    //会员画像设置已选字段
    GET_SELECTED_FIELDS: "/marketing/portrait/getSelectedPor",
    //会员画像设置未选字段
    GET_UN_SELECTED_FIELDS: "/marketing/portrait/getUnselectedPor",
    //保存设置
    SAVE_CONFIG: "/marketing/portrait/addOrgPor",
    //获取会员列表
    GET_LIST: "/marketing/members/list",
    //设置禁用
    SET_DISABLED: "/marketing/members/disable/status",
    //设置启用
    SET_ENABLE: "/marketing/members/enable/status",
    //编辑会员
    UPDATE: "/marketing/members/update",
    //新增会员
    CREATE_VIP: "/marketing/members/backMemberRegister",
    //获取详细信息
    GET_DETAIL: "/marketing/members/getMenberById",
    //导出会员信息
    EXPORT_USER_INFO: "/marketing/export/exportMemberInfo",
    //获取会员类型
    GET_PLATFORM_TYPE: "/hydra-transfer/userOrgCorp/get",
    //会员等级列表
    VIP_GRADE_LIST: "/marketing/member-level-config/list",
    //添加、编辑、删除会员等级
    EDIT_VIP_GRADE: "/marketing/member-level-config",
    //获取会员等级配置
    GET_VIP_GRADE: "/marketing/member-level-config",
    //会员列表
    GET_VIP_LIST: "/marketing/members/list/v1"
  },

  activity: {
    //获取活动锦囊
    GET_BAGS: "/marketing/activity/selectAll",
    //获取活动列表
    GET_LIST: "/marketing/activity/page",
    //获取参与记录
    GET_RECORD_LIST: "/marketing/winRecord/page/v1",
    //获取产品列表
    GET_PRODUCTS: "/marketing/activity/set/relationActProds",
    //获取产品列表（新增的产品都能看到）
    GET_PRODUCTS_ALL: "/hydra-base-data/api/v1/product/list",
    //获取产品列表（新增的产品都能看到，过滤无批次）
    GET_PRODUCTS_ALL_NO_BATCH: "/hydra-base-data/api/v1/product-batch/v2/list",
    //获取产品下的所有批次
    GET_PRODUCT_BATCH: "/hydra-base-data/api/v1/product-batch/batch/list",
    //模糊搜索产品批次
    SEARCH_PRODUCT_BATCH: "/hydra-base-data/api/v1/product-batch/list",
    //设置停用启用状态
    SET_STATUS: "/marketing/activity/set/disOrEnable",
    //导出参与记录
    EXPORT_RECORD: "/marketing/winRecord/export/v1",
    // 创建活动
    ADD: "/marketing/activity/set/add",
    // 编辑活动
    UPDATE: "/marketing/activity/set/add",
    //更新领取页和中奖页
    UPDATE_WXPAGE: "/marketing/activity/set/updatePage",
    //获取活动基础配置信息
    GET_BASE_CONFIG: "/marketing/activity/set/getBaseInfo",
    //获取活动领取页中奖页配置信息,
    GET_WXPAGE_CONFIG: "/marketing/activity/set/getPageInfo",
    //获取活动产品配置信息
    GET_PRODUCT_CONFIG: "/marketing/activity/product/getProductInfo",
    //获取渠道配置信息
    GET_CHANNEL_CONFIG: "/marketing/activity/channel/getchannelInfo",
    //获取活动奖品配置信息
    GET_PRIZE_CONFIG: "/marketing/activity/prize/getPrizeInfo",
    //创建活动预览
    CREATE_PREVIEW: "/marketing/activity/set/preview",
    //更新活动
    UPDATE_ACTIVITY: "/marketing/activity/set/update",
    //获取核销设置详情
    GET_REBATE: "/marketing/activity/coupon/detail",
    //获取核销记录
    GET_VERIFY_LIST: "/marketing/coupon/verify/list",
    //核销导出
    EXPORT_REBAE: "/marketing/coupon/verify/export",
    REBATE_SAVE: "/marketing/activity/coupon/add",
    REBATE_COPY: "/marketing/activity/coupon/copy",
    REBATE_EDIT: "/marketing/activity/coupon/update",
    //大转盘
    ADD_WHEEL: "/marketing/prizeWheels/add",
    UPDATE_WHEEL: "/marketing/prizeWheels/update",
    GET_WHEEL_DETAILS: "/marketing/prizeWheels/detail",
    //设置停用启用状态
    SET_WHEEL_STATUS: "/marketing/prizeWheels/changeStatus",
    //获取参与记录
    GET_WHEEL_RECORD_LIST: "/marketing/prizeWheels/record",
    //大转盘导出
    EXPORT_BIGWHEEL: "/marketing/prizeWheels/export",
    //大转盘预览添加
    ADD_WHEEL_PREV: "/marketing/front/prizeWheels/preview/add",
    //下载cdk模板
    DOWNLOAD_CDK: "/marketing/prizeWheels/cdktemplate/download",
    //获取非自卖品
    GET_OTHER_PRODUCT: "/marketing/mutiIntegral/exchange/allUnsaleProduct",
    //获取会员发放红包列表
    GET_VIP_REDBAG: "/marketing/red-packet/member/list",
    //会员红包发放审核导出
    exportRedBagReviewList: "/marketing/red-packet/member/export",
    //红包审核
    CHECK_REDBAG: "/marketing/red-packet/audit",
    //添加提货券
    ADD_REDEMPTION: "/marketing/activity/set/enter/delivery/ticket/add",
    //编辑
    EDIT_REDEMPTION: "/marketing/activity/set/enter/delivery/ticket/update",
    //获取详情
    GET_REDEMPTION: "/marketing/activity/set/enter/delivery/ticket/get",
    //提货券记录
    GET_REDEMPTION_LIST: "/marketing/delivery/ticket/record/list",
    //抵货券记录导出
    EXPORT_REDEMPTION_LIST: "/marketing/delivery/ticket/record/export",
    //抵货券发货
    DELIVERY_REDEMPTION: "/marketing/delivery/ticket/record/delivery",
    //岚萃添加奖券
    createLoterry: "/marketing/activity/set/enter/lottery",
    //岚萃奖券记录
    lotteryTicketRecord: "/marketing/lottery/record",
    //导出岚萃奖券记录
    lotteryTicketRecordExport: "/marketing/lottery/record/export",
    //结束奖券记录
    lotteryTicketEnd: "/marketing/lottery/record/end",
    //获取奖券详情
    getLotteryTicketDetail: "/marketing/activity/set/enter/lottery",
    //标注中奖
    // markAward: "/marketing/lottery/record/rewards",
    markAward: "/marketing/lottery/record/rewards",
    //导入获奖信息
    importAward: "/marketing/lottery/record/import"
  },
  //公众号绑定
  subscription: {
    //上传证书
    UPLOAD: "/marketing/snBind/upload",
    GET_DETAIL: "/marketing/snBind/get",
    SET_DETAIL: "/marketing/snBind/bind"
  },
  //积分设置，兑换
  integral: {
    //积分记录
    GET_LIST: "/marketing/mutiIntegral/integralRecord/list",
    //积分记录导出
    EXPORT_INTEGRALRECORD: "/marketing/mutiIntegral/integralRecord/export",
    //通用积分规则
    SET_INTEGRAL: "/marketing/integral/rule/edit",
    //批量设置产品规则
    SET_ALL_RULE: "/marketing/integral/ruleProduct/batchSetRule",
    //通用积分奖励列表
    GET_COMMON_LIST: "/marketing/mutiIntegral/integralRule/commonList",
    //通用积分奖励详情
    GET_COMMON_DETAIL: "/marketing/mutiIntegral/integralRule/detail",
    //批量清空产品规则
    DEL_ALL_RULE: "/marketing/integral/ruleProduct/emptyRule",
    //个性化列表
    GET_CHECK_LIST: "/marketing/mutiIntegral/integralRule/listData",
    //单个设置积分
    SET_OEN_PRODUCT: "/marketing/integral/ruleProduct/singleSetRule",
    //积分兑换列表
    GET_EXCHANGE_LIST: "/marketing/mutiIntegral/exchange/list",
    //添加积分兑换获取产品
    GET_PRODUCT_LIST: "/marketing/exchange/getProduct",
    //产品上下架
    SET_SHELEVAS: "/marketing/mutiIntegral/exchange/updateStatus",
    //删除积分设置
    DELETE_INT: "/marketing/mutiIntegral/exchange/deleteById",
    //获取自卖产品，非自卖产品（积分兑换页面）
    GET_ALL_PRODUCT: "/marketing/exchange/getProduct",
    // 新增上架产品
    ADD_SHELEVAS: "/marketing/mutiIntegral/exchange/save",
    //兑换详情
    GET_DETAILS: "/marketing/mutiIntegral/exchange/detail",
    //更新积分兑换
    EDIT_SHELEVAS: "/marketing/mutiIntegral/exchange/update",
    //获取积分信息
    GET_RULES: "/marketing/integral/rule/get",
    //积分配送列表
    GET_INTEGRALDELIVERY_LIST:
      "/marketing/mutiIntegral/productSendIntegral/list",
    //积分配送导出
    EXPORT_INTEGRALDELIVERY:
      "/marketing/mutiIntegral/productSendIntegralRecord/export",
    //积分配送
    INTEGRALDELIVERY:
      "/marketing/mutiIntegral/productSendIntegral/sendIntegral",
    //会员退货列表
    GET_VIPSALESRETURN_LIST:
      "/marketing/mutiIntegral/productReturnIntegral/list",
    //退货导出
    EXPORT_SALESRETURN: "/marketing/mutiIntegral/productReturnIntegral/export",
    //积分减扣
    INTEGRALDELETE: "/marketing/mutiIntegral/productReturnIntegral/save",
    //通用积分设置
    COMMON_INTEGRAL_SET: "/marketing/mutiIntegral/integralRule/save",
    //获取生码批次
    GET_CODE_BATCH: "/hydra-code-manager/code/manager/code/apply/batch/list",
    //通用积分列表删除
    DEL_COMMON_LIST: "/marketing/mutiIntegral/integralRule/deleteById",
    //个性化积分列表删除
    DEL_INDIVIDUATION_LIST:
      "/marketing/mutiIntegral/integralRule/deleteIntegralSettinInfo",
    //导出公共积分列表
    EXPORT_COMMON_LIST: "/marketing/mutiIntegral/integralRule/export/common",
    //导出个性化积分列表
    EXPORT_INDIVIDUATION_LIST:
      "/marketing/mutiIntegral/integralRule/export/personalize",
    //个性化积分设置新增
    ADD_INDIVIDUATION_SET:
      "/marketing/mutiIntegral/integralRule/setSettingInfo",
    //个性化积分设置更新
    UPDATE_INDIVIDUATION_SET:
      "/marketing/mutiIntegral/integralRule/updateIntegralSettinInfo",
    //个性化积分设置查看
    INDIVIDUATION_SET_DETAILS:
      "/marketing/mutiIntegral/integralRule/getIntegralSettinInfo",
    //发送短信
    SEND_MESSAGE: "/marketing/mutiIntegral/integralRule/sendMessageAfterConfig",
    //导出兑换列表
    EXPORT_EXCHANGE_LIST: "/marketing/mutiIntegral/exchange/export",
    //获取积分设置头部
    GET_INDIVIDUATION_HEADER: "/marketing/mutiIntegral/integralRule/listHeader",
    //会员积分扣减
    VIP_INTEGRAL_DEDUCTION:
      "/marketing/integral-deduction/member-integral-deduction",
    //会员积分
    VIP_INTEGRAL: "/marketing/members/memberIntegral",
    //消息列表
    notificationList: "/marketing/notification/list",
    //添加消息
    addnotification: "/marketing/notification",
    //按渠道关联积分设置
    integralSettingByChannel: "/marketing/integral/rule/channel-dimension"
  },
  //订单管理
  order: {
    //订单列表
    GET_LIST: "/marketing/order/page",
    //导出
    EXPORT_CODE: "/marketing/order/export",
    //发货
    DELIVERY_GOODS: "/marketing/order/delivery"
  },
  //非自卖产品
  products: {
    //非自卖产品列表
    // GET_LIST: "/non-self-selling/product/market/list",
    // DELETE_PRODUCT: "/non-self-selling/product/market",
    // //新增非自卖品
    // ADD_NOPRODUCR: "/non-self-selling/product/market",
    // //编辑非自卖产品
    // EDIT_NOPRODUCT: "/non-self-selling/product/market",
    // //获取非自卖产品详情
    // GET_DETAIL: "/non-self-selling/product/market/no-filter"
    //非自卖产品列表
    GET_LIST: "/marketing/mutiIntegral/exchangeUnsaleProduct/list",
    //删除非自卖品
    DELETE_PRODUCT: "/marketing/mutiIntegral/exchangeUnsaleProduct/delete",
    //新增非自卖品
    ADD_NOPRODUCR: "/marketing/mutiIntegral/exchangeUnsaleProduct/save",
    //编辑非自卖产品
    EDIT_NOPRODUCT: "/marketing/mutiIntegral/exchangeUnsaleProduct/update",
    //获取非自卖产品详情
    GET_DETAIL: "/marketing/mutiIntegral/exchangeUnsaleProduct/detail"
  },
  charts: {
    //招募海报会员注册数量
    REGISTER: "/marketing/registerNumTask/query",
    //top6
    TOP_SIX: "/marketing/top6Task/query",
    //会员地域分布
    MEMBER_AREA: "/marketing/memberMapTask/query",
    //会员画像
    MEMBER_DETAIL: "/marketing/memberPortraitTask/query",
    //总产品销售趋势
    SALES_TREND: "/marketing/saleTask/query",
    //积分参与和统计
    INTEGRAL_ACTIVE: "/marketing/statisicsTask/query",
    //累计会员
    TOTAL_MEMBERS: "/marketing/totalMemberTask/query",
    //获取时间
    GET_CHOOSE_TIME: "/marketing/rememberMe/get",
    //更改时间
    SET_TIME: "/marketing/rememberMe/put"
  },

  //2019.9.5导购员新增部分接口
  saleManModal,

  saleMan: {
    //导购员列表
    SALEMAN_LIST: "/marketing/salemembers/list",
    //编辑导购员
    EDIT_SALEMAN: "/marketing/salemembers/update",
    //导购员审核
    AUDIT: "/marketing/salemembers/judge/status",
    //导购员状态
    EDIT_STATUS: "/marketing/salemembers/update/status",
    //批量启用或停用导购员
    EDIT_ALL_STATUS: "/marketing/salemembers/batchUpdate/status",
    //删除导购员
    DELETE: "/marketing/salemembers/delete",
    //获取导购员详情
    GET_DETAIL: "/marketing/salemembers/getMenberById",
    //导出导购员资料
    EXPORT_SALEMEN_INFO: "/marketing/export/exportSalemembers",
    //活动列表
    ACTIVE_LIST: "/marketing/saler/activity/set/page",
    //获取全部机构
    GET_SHOP: "/hydra-base-data/api/v1/customer/enable/store-org/page/list",
    //状态修改
    ACTIVE_STATUS: "/marketing/saler/activity/set/enableOrDisable",
    //导购参与记录
    PRIZE_RECORD: "/marketing/saler/winRecord/page",
    //参与记录导出
    EXPORT_RECORD: "/marketing/saler/winRecord/export",
    //复制活动
    COPY_ACTIVE: "/marketing/saler/activity/set/copy",
    //编辑活动
    EDIT_ACTIVE: "/marketing/saler/activity/set/update",
    //新建活动
    ADD_ACTIVE: "/marketing/saler/activity/set/add",
    //获取活动详情
    GET_ACTIVE_DETAIL: "/marketing/saler/activity/set/activityInfo",
    //导购员积分导出按钮
    GET_SALEMAN_INTEGRAL: "/marketing/mutiIntegral/integralRecordSaler/export",
    //获取快递公司列表
    GET_LOGISTICS: "/hydra-base-data/api/v1/shipper/company/list",
    //获取导购发放红包列表
    GET_GUIDE_REDBAG: "/marketing/red-packet/saler/list",
    //导出发放红包列表：
    exportSaleRedBag: "/marketing/red-packet/saler/export",
    //导购审核
    CHECK_REDBAG: "/marketing/red-packet/audit",
    //导购员积分扣减
    GUIDE_INTEGRAL_DEDUCTION:
      "/marketing/integral-deduction/sale-integral-deduction"
  },
  //导购员积分派送
  sendIntegral: {
    LIST: "/marketing/mutiIntegral/productSendIntegralSaler/list",
    //导购员导出
    EXPORT: "/marketing/mutiIntegral/productSendIntegralSaler/export",
    //导购员积分派送
    SALESMAN_INTEGRAL_DELYVELY:
      "/marketing/mutiIntegral/productSendIntegralSaler/sendIntegral"
  },
  //核销管理
  cancelMange: {
    LIST: "/marketing/coupon/verify/write-off-list",
    //导购员导出
    EXPORT: "/marketing/coupon/verify/write-off-export"
  },
  //兑换记录
  exchangeRecord: {
    LIST: "/marketing/mutiIntegral/exchangeRecord/list",
    //导购员导出
    EXPORT: "/marketing/mutiIntegral/exchangeRecord/export",
    //发货
    SEND_PRODUCT: "/marketing/mutiIntegral/exchangeRecord/delivery",
    //查看物流详情
    GET_LOGISTICS: "/hydra-open-thirdpart-service/express/info/expressInfo",
    //批量发货
    BTACH_DELIVERY: "/marketing/mutiIntegral/exchangeRecord/batch/delivery",
    //获取订单详情：
    GET_ORDER_DETAILS: "/marketing/mutiIntegral/exchangeRecord/select/with-id"
  },
  //数据埋点
  mark: {
    //模板埋点
    MARK_TEMPLATE: "/marketing/buryPoint/prizeWheels/addBTemplate",
    //公众号埋点
    MARK_WX: "/marketing/buryPoint/prizeWheels/addWxTb"
  },
  channel: {
    //渠道
    GET_TOP_LIST: "/hydra-base-data/api/v1/customer/enable/two/page/list", //获取顶级渠道
    GET_CHILDREN: "/hydra-base-data/api/v1/customer/enable/child/page/list", //根据父级获取子级渠道
    SEARCH: "/hydra-base-data/api/v1/customer/enable/intercept/store/page/list", //搜索渠道
    //积分派送列表
    CHANNEL_DELIVERY: "/marketing/channel/integral-dispatch/list",
    //积分派送
    CHANNEL_INTEGRAL_DELIVERY: "/marketing/channel/integral-dispatch",
    //渠道积分派送记录导出
    EXPORT_DELIVERY_LIST: "/marketing/channel/integral-dispatch/export",
    //更新渠道信息
    CHANNEL_UPDATE: '/hydra-marketing/api/v1/channel/batchUpdate'
  },
  //渠道管理
  channelList: {
    //渠道列表
    LIST: "/marketing/mutiIntegral/channelIntegralNum/list",
    //渠道列表导出
    EXPORT: "/marketing/mutiIntegral/channelIntegralNum/export",
    //获取渠道发放红包列表
    GET_CHANNELE_REDBAG: "/marketing/red-packet/exchange/list",
    //渠道审核
    CHECK_REDBAG: "/marketing/red-packet/audit",
    //渠道积分扣减
    CHENNEL_INTEGRAL_DEDUCTION:
      "/marketing/integral-deduction/channel-integral-deduction",
    //渠道列表（无下级分页）
    GET_CHANNEL_LIST: "/marketing/base/channel/base-list"
  },
  //渠道积分记录
  channelIntegralRecord: {
    LIST: "/marketing/mutiIntegral/integralRecordChannel/list",
    //渠道列表导出
    EXPORT: "/marketing/mutiIntegral/integralRecordChannel/export"
  },
  //渠道订货管理
  channelOrderManage: {
    //列表
    LIST: "/marketing/channelOrderForm/list",
    //动态表头信息
    GET_HEADER: "/marketing/channelOrderForm/pageHeader",
    //详情
    DETAIL: "/marketing/channelOrderForm/detail",
    //设置表单
    SET_ORDER: "/marketing/channelOrderForm/save",
    ADD_ORDER: "/marketing/channelOrderForm/saveOrder",
    UPDATE_ORDER: "/marketing/channelOrderForm/updateOrder",
    //删除订单
    DEL_ORDER: "/marketing/channelOrderForm/delete",
    //获取客户信息
    CUSTOMER_LIST: "/hydra-user/api/v1/cust/list/byloginuser",
    //根据id查看详情
    GET_ORDER_BYID: "/marketing/channelOrderForm/detailbyId",
    //导购员订单管理发货
    EXCHANGE_SEND_ORDER: "/marketing/channelOrderForm/deliver",
    //查看物流详情
    GET_LOGISTICS: "/hydra-open-thirdpart-service/express/info/expressInfo"
  },

  //导购、会员、渠道公用接口
  common: {
    //积分清零
    INTEGRAL_RESET: "/marketing/integral-deduction",
    //撤销积分清零
    CANCEL_RESET: "/marketing/integral-deduction/undo",
    //积分清零记录
    GET_RESET_LIST: "/marketing/integral-deduction/list",
    //积分清零记录明细
    RESET_RECORD_DETAIL: "/marketing/integral-deduction/detail",
    //获取产品批次
    GET_PRODUCT_BATCH: "/hydra-base-data/api/v1/product-batch/list"
  },

  /************************营销后台新***************************** */
  // 任务设置和积分设置相关
  setting: {
    reward: {
      list: "/hydra-marketing/api/v1/task/list",
      update: "/hydra-marketing/api/v1/task/status",
      detail: "/hydra-marketing/api/v1/task/detail",
      tags: "/hydra-marketing/api/v1/member/sysField"
    },
    exchange: {
      list: "/hydra-marketing/api/v1/exchange/list",
      add: "/hydra-marketing/api/v1/exchange/add",
      destroy: "/hydra-marketing/api/v1/exchange/delete",
      updateStatus: "/hydra-marketing/api/v1/exchange/status",
      saleProduct: "/hydra-marketing/api/v1/exchange/product/list",
      detail: "/hydra-marketing/api/v1/exchange/detail",
      detailUpdate: "/hydra-marketing/api/v1/exchange/update",
      unsaleProduct: "/hydra-marketing/api/v1/unsaleProduct/list"
    }
  },
  integralNew: {
    //列表
    integralListByType: "/hydra-marketing/api/v1/activity/listByType",
    //获取产品列表（新增的产品都能看到）
    GET_PRODUCTS_ALL: "/hydra-marketing/api/v1/product/list",
    //获取产品列表（新增的产品都能看到，过滤无批次）
    GET_PRODUCTS_ALL_NO_BATCH: "/hydra-marketing/api/v1/product-batch/v2/list",
    //获取生码批次
    GET_CODE_BATCH:
      "/hydra-marketing/api/v1/code/manager/code/apply/batch/list",
    //获取产品下的所有批次
    GET_PRODUCT_BATCH: "/hydra-marketing/api/v1/product-batch/batch/list",
    //添加
    ADD_INTEGRAL_ACTIVITY: "/hydra-marketing/api/v1/activity/add",
    //禁用/启用
    CHANGESTATUS: "/hydra-marketing/api/v1/activity/status",
    //详情/编辑
    DETAILS: "/hydra-marketing/api/v1/activity/detail",
    //删除
    DELETE: "/hydra-marketing/api/v1/activity/delete",
    //渠道列表
    GET_CHANNEL_LIST: "/hydra-marketing/api/v1/channel/base-list",
    //非自卖品列表
    USER_DEFINED_LIST: "/hydra-marketing/api/v1/unsaleProduct/list",
    //删除非自卖产品
    USER_DEFINED_DEL: "/hydra-marketing/api/v1/unsaleProduct/delete",
    //添加非自卖品
    USER_DEFINED_ADD: "/hydra-marketing/api/v1/unsaleProduct/add",
    //编辑非自卖品
    USER_DEFINED_UPDATE: "/hydra-marketing/api/v1/unsaleProduct/update",
    //非自卖品详情
    USER_DEFINED_DETAILS: "/hydra-marketing/api/v1/unsaleProduct/detail",
    //保存H5配置
    saveConfig: "/hydra-marketing/api/v1/h5setting/save",
    //获取H5配置
    getH5Config: "/hydra-marketing/api/v1/h5setting/getByType",
    //获取某类型任务状态
    getTaskStatus: "/hydra-marketing/api/v1/task/getStatus",
    //积分清零：
    integralReset: "/hydra-marketing/api/v1/integral/task/add",
    //积分清零记录：
    integralResetList: "/hydra-marketing/api/v1/integral/task/list",
    //撤销积分清零：
    cancelReset: "/hydra-marketing/api/v1/integral/task/revoked"
  },
  guideNew: {
    //列表
    guideList: "/hydra-marketing/api/v1/saler/list",
    //列表导出
    exportList: "/hydra-marketing/api/v1/saler/export",
    //批量启用导购
    batchChangeStatus: "/hydra-marketing/api/v1/saler/status",
    //积分记录：
    integralRecord: "/hydra-marketing/api/v1/saler/integral",
    //导出积分记录：
    integralRecordExport: "/hydra-marketing/api/v1/saler/exchange/export",
    //红包记录：
    envelopeRecord: "/hydra-marketing/api/v1/saler/money",
    //导出红包记录：
    envelopeRecordExport: "/hydra-marketing/api/v1/saler/money/export",
    //积分兑换记录
    exchangeRecord: "/hydra-marketing/api/v1/saler/exchange",
    //导出积分兑换记录
    exchangeRecordExport: "/hydra-marketing/api/v1/saler/exchange/export",
    //导购参与记录
    participateRecord: "/hydra-marketing/api/v1/saler/rewardRecord",
    //导出导购参与记录
    participateRecordExport:
      "/hydra-marketing/api/v1//saler/rewardRecord/export",
    //导购发货
    guideDelivery: "/hydra-marketing/api/v1/saler/exchange/delivery",
    //获取导购详情
    getGuideInfo: "/hydra-marketing/api/v1/saler/detail",
    //修改导购信息
    updateGuideInfo: "/hydra-marketing/api/v1/saler/updateDetail",
    //提现记录
    guideCashRecord: "/hydra-marketing/api/v1/saler/cash",
    //导出提现记录
    guideCashRecordExport: "/hydra-marketing/api/v1/saler/cash/export",
    //导购提现发放
    guideCashOffer: "/hydra-marketing/api/v1/saler/cash/permit",
    //提现驳回
    guideRefuseWithdraw: "/hydra-marketing/api/v1/saler/cash/reject",
    //导购积分清零明细
    guideIntegralResetRecord:
      "/hydra-marketing/api/v1/integral/task/saler/detail",
    //导购积分派送
    guideIntegralDelivery: "/hydra-marketing/api/v1/saler/integral/add",
    //导购积分扣减
    guideIntegralDeduction: "/hydra-marketing/api/v1/saler/integral/reduce"
  },
  memberNew: {
    //列表
    memberList: "/hydra-marketing/api/v1/member/list",
    //导出会员列表
    exportMember: "/hydra-marketing/api/v1/member/export",
    //导出会员列表(包括自定义信息)
    exportMemberTotalInfo: "/hydra-marketing/api/v1/member/exportAll",
    //批量启用导购
    batchChangeStatus: "/hydra-marketing/api/v1/member/status",
    //积分记录：
    integralRecord: "/hydra-marketing/api/v1/member/integral",
    //导出积分记录：
    integralRecordExport: "/hydra-marketing/api/v1/member/integral/export",
    //红包记录：
    envelopeRecord: "/hydra-marketing/api/v1/member/money",
    //导出红包记录：
    envelopeRecordExport: "/hydra-marketing/api/v1/member/money/export",
    //积分兑换记录
    exchangeRecord: "/hydra-marketing/api/v1/member/exchange",
    //导出积分兑换记录
    exchangeRecordExport: "/hydra-marketing/api/v1/member/exchange/export",
    //会员参与记录
    participateRecord: "/hydra-marketing/api/v1/member/rewardRecord",
    //导出会员参与记录
    participateRecordExport:
      "/hydra-marketing/api/v1/member/rewardRecord/export",
    //会员发货
    memberDelivery: "/hydra-marketing/api/v1/member/exchange/delivery",
    //会员详细头部
    memberHeader: "/hydra-marketing/api/v1/member/fieldsDefine",
    //设置会员自定义字段
    membreSetCustomFields: '/hydra-marketing/api/v1/member/setFields',
    //会员完整列表
    memberAllList: "/hydra-marketing/api/v1/member/listDetail",
    //获取会员详情
    getMemberInfo: "/hydra-marketing/api/v1/member/detail",
    //修改会员信息
    updateMemberInfo: "/hydra-marketing/api/v1/member/updateDetail",
    //提现记录
    memberCashRecord: "/hydra-marketing/api/v1/member/cash",
    //导出提现记录
    memberCashRecordExport: "/hydra-marketing/api/v1/member/cash/export",
    //会员提现发放
    memberCashOffer: "/hydra-marketing/api/v1/member/cash/permit",
    //提现驳回
    memberRefuseWithdraw: "/hydra-marketing/api/v1/member/cash/reject",
    //会员积分清零明细
    memberIntegralResetRecord:
      "/hydra-marketing/api/v1/integral/task/member/detail",
    //会员积分派送
    memberIntegralDelivery: "/hydra-marketing/api/v1/member/integral/add",
    //会员积分扣减
    memberIntegralDeduction: "/hydra-marketing/api/v1/member/integral/reduce"
  },
  channelNew: {
    //列表
    channelList: "/hydra-marketing/api/v1/channel/list",
    //导出渠道列表
    exportChannel: "/hydra-marketing/api/v1/channel/export",
    //红包记录：
    envelopeRecord: "/hydra-marketing/api/v1/channel/money",
    //导出红包记录：
    envelopeRecordExport: "/hydra-marketing/api/v1/channel/money/export",
    //积分记录：
    integralRecord: "/hydra-marketing/api/v1/channel/integral",
    //导出积分记录
    exportIntegralRecord: "/hydra-marketing/api/v1/channel/integral/export",
    //积分兑换记录
    exchangeRecord: "/hydra-marketing/api/v1/channel/exchange",
    //导出积分dui'haun记录
    exportExchangeRecord: "/hydra-marketing/api/v1/channel/exchange/export",
    //渠道发货
    channelDelivery: "/hydra-marketing/api/v1/channel/exchange/delivery",
    //渠道参与记录
    participateRecord: "/hydra-marketing/api/v1/channel/rewardRecord",
    //导出渠道参与记录
    participateRecordExport:
      "/hydra-marketing/api/v1/channel/rewardRecord/export",
    //提现记录
    channelCashRecord: "/hydra-marketing/api/v1/channel/cash",
    //导出提现记录
    channelCashRecordExport: "/hydra-marketing/api/v1/channel/cash/export",
    //渠道提现发放
    channelCashOffer: "/hydra-marketing/api/v1/channel/cash/permit",
    //提现驳回
    channelRefuseWithdraw: "/hydra-marketing/api/v1/channel/cash/reject",
    //渠道积分清零明细
    channelIntegralResetRecordNew:
      "/hydra-marketing/api/v1/integral/task/channel/detail",
    //渠道积分派送
    channelIntegralDelivery: "/hydra-marketing/api/v1/channel/integral/add",
    //渠道积分扣减
    channelIntegralDeduction: "/hydra-marketing/api/v1/channel/integral/reduce",
    marksChannel:'/hydra-marketing/api/v1/channel/batchTag'
  },
  //文章推送模块
  //文章推送
  articlePush:{
    //列表
    list:"/hydra-marketing/api/v1/notify/list",
    //发送消息
    sendMsg:'/hydra-marketing/api/v1/notify/add',
    update:'/hydra-marketing/api/v1/notify/update',
    delete:'/hydra-marketing/api/v1/notify/delete'
  },
  newActivity: { ...newActivity },
  record: { ...record },
  member,
  pickupTicket,
  lancui,
  shop,
  statistics,
  codeLevel
};
