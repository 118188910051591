import api from 'system/config/systemApi';
import {ajaxSync} from 'framework/utils/ajax';
//获取组织下的系统
export const getOrgSysList = async (organizationId) => {
  const {err, res} = await ajaxSync.get(api.GET_ORG_SYSTEM_LIST, {organizationId});
  if (err) {
    console.error(err);
    return [];
  } else if (res) {
    return res.results.list || [];
  }
}
