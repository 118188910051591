export default {
  //获取数据统计 头部的简略数据(非实时)
  GET_SHORT_DATA: '/hydra-marketing/api/v1/user/report/get',
  UPDATE_SHORT_DATA: '/hydra-marketing/api/v1/user/report/getAndRefresh',
  //获取实时的头部简略的数据
  SHORT_DATA_SYNC: '/hydra-marketing/api/v1/user/report/getAndRefresh',
  //获取数据统计 图表数据
  GET_DATA: '/hydra-marketing/api/v1/user/report/getByType',
  // 新增导购/渠道每日统计，根据省市区时间范围统计搜索
  GET_ADD_COUNT_DATA: '/hydra-marketing/api/v1/user/report/getAddCountByStoreArea',
  //获取新用户数量
  GET_NEW_USER_COUNT: '/hydra-marketing/api/v1/user/report/getNewUserCount',
  // 下级企业营销数据 (长兴扫码记录列表)
  SUB_ORG_MARKETING_DATA: '/hydra-marketing/api/v1/reward/changxing/scanCode',
  // 下级企业营销数据导出
  SUB_ORG_MARKETING_DATA_EXPORT: '/hydra-marketing/api/v1/reward/changxing/scanCode/export',
  // 获取下级组织列表
  GET_SUB_ORG_LIST: '/hydra-user/api/client/v1/org/relation',
  //按省市区统计导购员数量
  GET_GUIDE_AREA_DATA: '/hydra-marketing/api/v1/user/report/getCountBySalerArea',
  shop: {
    GET_SHORT_DATA: '/hydra-marketing/api/v1/channel/report/get',
    UPDATE_SHORT_DATA: '/hydra-marketing/api/v1/channel/report/getAndRefresh',
    GET_DATA: '/hydra-marketing/api/v1/channel/report/getByType',
    GET_SHOP_AREA_DATA: '/hydra-marketing/api/v1/user/report/getCountByStoreArea'
  }
};
