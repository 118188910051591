export default ({ app, dynamicWrapper }) => {
  return {
    //营销活动设置
    '/market/activity/setting': {
      component: dynamicWrapper(app, [], () =>
        import(/* webpackChunkName: "modules/layouts/MarketActivitySettingLayout" */ '../layouts/MarketActivitySettingLayout')
      ),
    },
    //积分商城设置
    '/market/shop/setting': {
      component: dynamicWrapper(app, [], () =>
        import(/* webpackChunkName: "modules/layouts/MarketShopSettingLayout" */ '../layouts/MarketShopSettingLayout')
      ),
    }
  };
};
