import dynamic from "dva/dynamic";

export default [
  //地块管理
  {
    path: "/xianfengxian/mass",
    component: dynamic({
      component: () =>
        import(/* webpackChunkName: "modules/runda/index0" */ "./views/massif"),
    }),
  },
  {
    path: "/xianfengxian/soilReport",
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/runda/index0" */ "./views/massif/pages/Report"
        ),
    }),
  },
  {
    path: "/xianfengxian/soilReportAdd",
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/runda/index0" */ "./views/massif/pages/SoilAdd"
        ),
    }),
  },
  //用户管理
  {
    path: "/xianfengxian/usermanage",
    component: dynamic({
      component: () =>
        import(
          /* webpackChunkName: "modules/runda/index0" */ "./views/userManage"
        ),
    }),
  },
];
